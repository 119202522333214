import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../../utils/query-helper';
import useAnalytics from '../../../../utils/useAnalytics';
import useBalance from '../../../../utils/useBalance';
import useWebSocketData from '../../../../utils/useWebSocketData';

function WidgetsCard({ data }) {
  const { lastPrice, priceChangePercent, getStatus, formatTime } =
    useWebSocketData();
  const { formatINR, roundUpWithSymbol } = useBalance();
  const history = useHistory();
  const location = useLocation();
  const { analytics } = useAnalytics();
  const {
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  //// console.log(formatTime(data.symbol));

  return (
    <>
      {data.name !== 'Rupee' && (
        <div
          onClick={() => {
            history.push({
              pathname: `/pricedetails/${data.symbol}`,
              search: location.search,
            });
            analytics?.track('Clicked the coin!', {
              symbol: data.symbol,
              uid: userInfo?.payload?.uid,
              from: 'Homepage',
              QueryParams: { ...getQueryParams() },
              ip: ip,
            });
          }}
          className="widgets__item"
          style={{ overflow: 'hidden', cursor: 'pointer' }}
        >
          <div className="widgets__head">
            <div className="widgets__company">
              <div className="widgets__logo">
                <img src={data.logo} alt="" />
              </div>
              <div className="widgets__details">
                <div className="widgets__category">{data.name}</div>
                <div
                  className="widgets__info"
                  style={{ textTransform: 'uppercase' }}
                >
                  {data.symbol}
                </div>
              </div>
            </div>
            {/* <div className="widgets__time">{formatTime(data.symbol)}</div> */}
            <div className="widgets__time">24h</div>
          </div>
          <div className="widgets__body" style={{ marginBottom: 20 }}>
            <div className="widgets__line">
              <div className="widgets__price" style={{ maxWidth: '170px' }}>
                ₹ {formatINR(lastPrice(data.symbol))}
              </div>
              <div className={`status ${getStatus(data.symbol)}`}>
                {priceChangePercent(data.symbol)}{' '}
              </div>
            </div>
            {/* <div className="widgets__chart">
          <div id="spark-4">
            <WidgetLineChart
              color={data.status === 'positive' ? '#4fbf67' : '#fc2c03'}
            />
          </div>
        </div> */}
          </div>
          <Link
            className="widgets__btn btn btn_orange btn_wide"
            to={(location) => ({
              ...location,
              pathname: `/pricedetails/${data.symbol}`,
            })}
            onClick={() => {
              analytics?.track('Clicked the coin!', {
                symbol: data.symbol,
                from: 'Homepage',
                uid: userInfo?.payload?.uid,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            View Asset
          </Link>
        </div>
      )}
    </>
  );
}

export default WidgetsCard;
