import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIPAddress } from '../../actions/userAction';
import AuthPreviewImage from '../../components/auth/preview-image/previewimage';
import SignIn from '../../components/auth/sign-in/sign-in';
import HelmetContainer from '../../components/common/helmet/helmet';
import MotionContainer from '../../components/common/motion-container/motion-container';
import ThemeToogler from '../../components/common/theme-toogler/theme-toogler';
import { getQueryParams } from '../../utils/query-helper';
import useAnalytics from '../../utils/useAnalytics';

function SignInPage() {
  const { analytics } = useAnalytics();
  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  useEffect(() => {
    analytics?.page('Login page', {
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  return (
    <MotionContainer>
      <div className="login">
        <HelmetContainer title="Sign In" />
        <div className="login__row">
          <AuthPreviewImage
            title="Asia's Largest Crypto Payment Network and Exchange"
            // subtitle="UI Design Kit"
          />
          <SignIn />
        </div>
        <ThemeToogler />
      </div>
    </MotionContainer>
  );
}

export default SignInPage;
