import React from 'react';

function DetailsButton({ title, ...otherProps }) {
  return (
    <button
      className="details__btn btn btn_orange btn_wide btn_big"
      {...otherProps}
    >
      {title}
    </button>
  );
}

export default DetailsButton;
