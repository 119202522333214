import React from 'react';
import Icon from '../../common/icons/icons';

function HeaderDropDown({
  addclassName,
  iconName,
  children,
  isNotificated,
  heading,
  ...otherProps
}) {
  return (
    <div className={`header__item  ${addclassName}`} {...otherProps}>
      <button className={`header__head ${isNotificated ? ' active' : ''}`}>
        {iconName ? (
          <div className="icon icon-arrow-down-square">
            <Icon icon={iconName} className="icon" />
          </div>
        ) : (
          heading
        )}
      </button>
      <div className="header__body">{children}</div>
    </div>
  );
}

export default HeaderDropDown;
