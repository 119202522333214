import React from 'react';
import Icon from '../../../../common/icons/icons';

function TableCell({ data, isaccountDetails, name, value, redirectFunc }) {
  return (
    <>
      {!isaccountDetails ? (
        <tr onClick={() => redirectFunc(data.link)}>
          <td>
            <a href={data.link} target="_blank" style={{ color: '#808191' }}>
              {data.name}
            </a>
          </td>
          <td>
            <a href={data.link} target="_blank" className="why__kyc">
              <i className="fas fa-arrow-right icon"></i>
            </a>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <p style={{ color: '#808191' }}>{name}</p>
          </td>
          <td style={{ textAlign: 'right' }}>
            <p className="why__kyc">{value}</p>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableCell;
