import React from 'react';
import Icon from '../../common/icons/icons';

function SendMail() {
  return (
    <div className="sidebar__mail">
      {/* <button className="sidebar__close">
        <Icon className="icon icon_sendmail" icon="close" />
      </button> */}
      <div className="sidebar__info">
        Download the Krypto app to trade on the go
      </div>
      <button className="sidebar__btn btn btn_white btn_sm">
        <a
          target="_blank"
          style={{ color: 'black' }}
          referrerPolicy="no-referrer"
          href="https://letskrypto.com/links"
        >
          Download Now
        </a>
      </button>
    </div>
  );
}

export default SendMail;
