import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../../common/icons/icons';

function HeaderSearch() {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (searchText.length >= 2) {
      const data = coinsDetails?.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setFilteredData(data);
    }
  }, [searchText]);

  return (
    <>
      <div className="header__search">
        <input
          className="header__input"
          type="text"
          placeholder="Search the coins"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="header__start">
          <div className="icon icon-search">
            <Icon icon="search" className="icon" />
          </div>
        </button>
        <div
          className={`details__company ${
            filteredData.length !== 0 && searchText.length > 2 ? 'active' : ''
          } `}
          style={{ width: '100%' }}
        >
          <div className="details__body">
            {filteredData.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="details__item"
                  onClick={() => {
                    history.push({
                      pathname: `/pricedetails/${item.symbol}`,
                      search: location.search,
                    });
                    setSearchText('');
                  }}
                >
                  <div className="details__logo">
                    <img src={item.logo} alt="" />
                  </div>
                  <div className="details__desc">
                    <div className="details__info">{item.name}</div>
                    <div
                      className="details__currency"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {item.symbol}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderSearch;
