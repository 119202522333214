import * as React from 'react';
import { Component } from 'react';
import binanceAPI from '../../../../utils/trade-view/api';
import { tradeViewBaseSymbol } from '../../../../utils/constants';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class ExchangePriceChart extends Component {
  constructor({ chartProperties }) {
    super();
    this.state = {
      isChartReady: false,
    };
  }

  bfAPI = new binanceAPI({ debug: false });
  tradingViewWidget = null;
  chartObject = null;

  disabled_features = [
    'border_around_the_chart',
    'chart_property_page_background',
    'chart_property_page_scales',
    'chart_property_page_style',
    'chart_property_page_timezone_sessions',
    'compare_symbol',
    // 'header_resolutions',
    'control_bar',
    'countdown',
    'create_volume_indicator_by_default',
    'display_market_status',
    'edit_buttons_in_legend',
    'go_to_date',
    // 'header_chart_type',
    'header_compare',
    // 'header_settings',
    // 'header_saveload',
    // 'header_fullscreen_button',
    'legend_widget',
    'header_screenshot',
    'header_symbol_search',
    'header_undo_redo',
    'header_widget_dom_node',
    'hide_last_na_study_output',
    'hide_left_toolbar_by_default',
    'legend_context_menu',
    'main_series_scale_menu',
    'pane_context_menu',
    // 'show_chart_property_page',
    'study_dialog_search_control',
    'symbol_info',
    'timeframes_toolbar',
    'timezone_menu',
    'volume_force_overlay',
  ];

  widgetOptions = {
    container_id: 'tv_chart_container',
    datafeed: this.bfAPI,
    library_path: '/scripts/charting_library/',
    timezone: 'Asia/Kolkata',
    autosize: true,
    disabled_features: this.disabled_features,
    enabled_features: ['side_toolbar_in_fullscreen_mode'],
    locale: getLanguageFromURL() || 'en',
  };

  chartReady = () => {
    if (!this.tradingViewWidget) return;
    this.tradingViewWidget.onChartReady(() => {
      this.chartObject = this.tradingViewWidget.activeChart();
      this.tradingViewWidget.changeTheme(this.props.themeColor);
      this.tradingViewWidget.symbolSync();
    });
  };

  removeItem(arr, item) {
    return arr.filter((el) => el !== item);
  }

  componentDidMount() {
    if (this.props.width <= 700) {
      this.disabled_features.push('left_toolbar');
    } else {
      this.disabled_features = this.removeItem(
        this.disabled_features,
        'left_toolbar'
      );
    }
    this.tradingViewWidget = window.tvWidget = new window.TradingView.widget({
      symbol:
        this.props.symbol === 'USDTUSDT'
          ? `USDC${tradeViewBaseSymbol?.toUpperCase()}`
          : this.props.symbol,
      ...this.widgetOptions,
      disabled_features: this.disabled_features,
    });
    // console.log(this.props.symbol);
    this.chartReady();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.symbol !== prevProps.symbol ||
      this.props.params !== prevProps.params ||
      this.props.width !== prevProps.width
    ) {
      if (this.props.width <= 700) {
        this.disabled_features.push('left_toolbar');
      } else {
        this.disabled_features = this.removeItem(
          this.disabled_features,
          'left_toolbar'
        );
      }
      this.tradingViewWidget = window.tvWidget = new window.TradingView.widget({
        symbol:
          this.props.symbol === 'USDTUSDT'
            ? `USDC${tradeViewBaseSymbol?.toUpperCase()}`
            : this.props.symbol,
        ...this.widgetOptions,
        disabled_features: this.disabled_features,
      });
      this.chartReady();
    }
    if (this.props.themeColor !== prevProps.themeColor) {
      if (this.props.themeColor.length > 0) {
        this.tradingViewWidget.onChartReady(() => {
          this.setState({ loading: false });
          this.tradingViewWidget.changeTheme(this.props.themeColor);
        });
      }
    }
  }

  render() {
    return <div id='tv_chart_container' className={'TVChartContainer'}></div>;
  }
}

export default ExchangePriceChart;
