import React, { useState, useRef, useCallback, useEffect } from 'react';
import CustomButton from '../../../../common/custom-button/custom-button';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import {
  kycViewDetails,
  networkCheck,
  uploadDocumentSelfie,
  uploadDocumentText,
} from '../../../../../actions/kycAction';
import { toast } from 'react-toastify';
import { toogleSetting } from '../../../../../actions/settingAction';
import SkeletonContainer from '../../../../common/skeleton/skeleton';
import KYCImage from '../../../../../assests/img/KYCExample.png';
import useAnalytics from '../../../../../utils/useAnalytics';
import { getQueryParams } from '../../../../../utils/query-helper';

function KycSelfie({ selfiePicture, setSelfiePicture }) {
  const [startCam, setStartCam] = useState(false);
  const [uploadedSuccessfull, setUploadSuccessfull] = useState(false);
  const [haswebCam, setHasWebCam] = useState(true);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const { analytics } = useAnalytics();

  const {
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSelfiePicture(imageSrc);
  }, [webcamRef]);

  const handleUploadSelfie = async () => {
    try {
      await uploadDocumentSelfie(
        'selfie',
        selfiePicture,
        userInfo.token.access
      );
      analytics?.track('Added Kyc selfie success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        kycStage: 'selfie upload completed',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.success('Successfully uploaded Selfie');
      setUploadSuccessfull(true);
    } catch (error) {
      analytics?.track('Added Kyc selfie failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }
  };

  const kycSubmit = async () => {
    try {
      const ids = JSON.parse(localStorage.getItem('krypto.id'));
      const idData = await uploadDocumentText(
        ids.pan_id,
        ids.n_id,
        userInfo.token.access
      );
      toast.success(idData.message);
      analytics?.track('Kyc submit success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        kycStage: 'KYC completed!',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      localStorage.removeItem('krypto.id');
      setTimeout(() => {
        dispatch(toogleSetting({ open: false }));
        dispatch(networkCheck());
        dispatch(kycViewDetails());
      }, 1500);
    } catch (error) {
      analytics?.track('Kyc submit failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }
  };

  function detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then((devices) => {
      callback(devices.some((device) => 'videoinput' === device.kind));
    });
  }
  useEffect(() => {
    detectWebcam(function (hasWebcam) {
      setHasWebCam(hasWebcam);
    });
  }, [navigator.mediaDevices]);

  return (
    <div>
      {haswebCam ? (
        <>
          <div className="kyc_stage__title mobile-show">
            <div className="h5">3 of 3 Kyc Verfication</div>
            <div className="h6">
              Hold a paper with Krypto and date written on it and take a selfie
              (No Eyewear Glasses)
            </div>
          </div>
          {startCam ? (
            !selfiePicture && (
              <div style={{ display: 'grid', placeItems: 'center', gap: 12 }}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={'100%'}
                  mirrored
                  videoConstraints={videoConstraints}
                />
                <div className="camera-btns">
                  <CustomButton title="Capture" onClick={capture} />
                  <CustomButton
                    style={{ marginLeft: 12 }}
                    title="Close"
                    addClassName="close-btn"
                    onClick={() => setStartCam(false)}
                  />
                </div>
              </div>
            )
          ) : (
            <div style={{ display: 'grid', placeItems: 'center', gap: 22 }}>
              <img src={KYCImage} alt="" style={{ width: 180 }} />
              <CustomButton title="Start" onClick={() => setStartCam(true)} />
            </div>
          )}
          {selfiePicture && !uploadedSuccessfull ? (
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <img src={selfiePicture} alt="" />
              <div className="camera-actions">
                <div
                  className="icon-container green-outline"
                  onClick={handleUploadSelfie}
                >
                  <i className="fas fa-check-circle"></i>
                </div>
                <div
                  className="icon-container blue-outline"
                  onClick={() => {
                    setStartCam(true);
                    setSelfiePicture(null);
                  }}
                >
                  <i className="fas fa-redo-alt"></i>
                </div>
                <div
                  className="icon-container red-outline"
                  onClick={() => {
                    setStartCam(false);
                    setSelfiePicture(null);
                  }}
                >
                  <i className="far fa-times-circle"></i>
                </div>
                {/* <CustomButton
              title="Upload"
              addClassName="green-btn"
              onClick={handleUploadSelfie}
            />
            <CustomButton
              title="Recapture"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setStartCam(true);
                setSelfiePicture(null);
              }}
            />
            <CustomButton
              title="Cancel"
              style={{ marginLeft: 10 }}
              addClassName="close-btn"
              onClick={() => {
                setStartCam(false);
                setSelfiePicture(null);
              }}
            /> */}
              </div>
              <div style={{ marginTop: 12 }}></div>
            </div>
          ) : (
            <>
              {uploadedSuccessfull && (
                <div
                  style={{
                    display: 'grid',
                    placeItems: 'center',
                    gap: 22,
                    marginTop: 12,
                  }}
                >
                  <img src={KYCImage} alt="" style={{ width: 180 }} />
                  <div className="h4">Selfie Uploaded Successfully</div>
                  <CustomButton
                    title="Submit KYC"
                    style={{ marginLeft: 10 }}
                    // addClassName="close-btn"
                    onClick={kycSubmit}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center', margin: 20 }}>
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>
            Please open{' '}
            <a href="https://web.letskrypto.com/" style={{ color: '#ee7c36' }}>
              web.letskrypto.com
            </a>{' '}
            from your mobile phone or connect webcam and upload your KYC selfie.
          </div>
        </div>
      )}
    </div>
  );
}

export default KycSelfie;
