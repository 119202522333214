import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

function useWebSocketData() {
  const { assetsData } = useSelector((state) => state.getAssests);
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const { response: watchListData } = useSelector(
    (state) => state.userWatchList
  );

  const roundUpWithSymbol = (number, symbol) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const info = convertedArray.find((item) => item.symbol === symbol);
      let rounded = number?.toFixed(parseInt(info?.decimal));
      return rounded;
    }
  };

  function lastPrice(key) {
    if (assetsData) {
      const price = assetsData[key + 'inr']?.last;
      return price;
    }
  }

  const checkItInWatchList = (data) => {
    const checkdata = watchListData?.payload?.coins?.coins?.filter((item) => {
      return item === data.symbol;
    });
    if (checkdata?.length) {
      return true;
    } else {
      return false;
    }
  };

  function priceChangePercent(key) {
    if (assetsData) {
      return assetsData[key + 'inr']?.price_change_percent;
    }
  }

  function getHigh(key) {
    if (assetsData) {
      const price = assetsData[key + 'inr']?.high;
      return key == 'shib'
        ? parseFloat(price).toFixed(5)
        : roundUpWithSymbol(parseFloat(price), 'inr');
    }
  }

  function getLow(key) {
    if (assetsData) {
      const price = assetsData[key + 'inr']?.low;
      return key == 'shib'
        ? parseFloat(price).toFixed(5)
        : roundUpWithSymbol(parseFloat(price), 'inr');
    }
  }

  function getStatus(key) {
    if (assetsData) {
      const value = assetsData[key + 'inr']?.price_change_percent;
      const status = value?.charAt(0) === '-' ? 'negative' : 'positive';
      return status;
    }
  }

  function getVolume(key) {
    if (assetsData) {
      const price = assetsData[key + 'inr']?.volume;
      return key == 'shib'
        ? parseFloat(price).toFixed(5)
        : roundUpWithSymbol(parseFloat(price), 'inr');
    }
  }

  function getAvgPrice(key) {
    if (assetsData) {
      const price = assetsData[key + 'inr']?.avg_price;
      return key == 'shib'
        ? parseFloat(price).toFixed(5)
        : roundUpWithSymbol(parseFloat(price), 'inr');
    }
  }

  function formatTime(key) {
    if (assetsData) {
      const value = assetsData[key + 'inr']?.at;
      let date = moment.unix(value).format('h:mm a');
      return date;
    }
  }

  const getTopLooser = () => {
    if (coinsDetails?.convertedArray && assetsData) {
      const result = _.filter(coinsDetails?.convertedArray, function (o) {
        return (
          assetsData[o.symbol + 'inr']?.price_change_percent?.charAt(0) === '-'
        );
      });
      const leastValue = _.orderBy(
        result,
        function (o) {
          return parseInt(assetsData[o.symbol + 'inr']?.price_change_percent);
        },
        ['asc']
      );

      return leastValue;
    }
  };

  const getTopGainer = () => {
    if (coinsDetails?.convertedArray && assetsData) {
      const result = _.filter(coinsDetails?.convertedArray, function (o) {
        return (
          assetsData[o.symbol + 'inr']?.price_change_percent?.charAt(0) === '+'
        );
      });
      const topValue = _.orderBy(
        result,
        function (o) {
          return parseInt(assetsData[o.symbol + 'inr']?.price_change_percent);
        },
        ['desc']
      );
      return topValue;
    }
  };

  return {
    getTopLooser,
    lastPrice,
    getVolume,
    getStatus,
    priceChangePercent,
    getTopGainer,
    formatTime,
    getHigh,
    getLow,
    getAvgPrice,
    checkItInWatchList,
  };
}

export default useWebSocketData;
