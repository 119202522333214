import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function Tooltip() {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  return (
    <ReactTooltip
      place="right"
      type={isdark ? 'light' : 'dark'}
      effect="solid"
      className="tooltip_styles"
    />
  );
}

export default Tooltip;
