import React from 'react';
import Popup from '../../common/popup/popup';

function NotificationPopup({
  showNotificationPopup,
  popUpData,
  setNotificationPopup,
}) {
  return (
    <Popup setState={setNotificationPopup} state={showNotificationPopup}>
      <div className="popup__container">
        <div className="notifications__popup notification_popup_container">
          <div className={`notifications__icon ${popUpData.bg}`}>
            <img src={popUpData.img} alt="" />
          </div>
          <div className="notifications__details">
            <div className="notifications__head">
              <div className="notifications__title">{popUpData.title}</div>
              <div className="notifications__time">{popUpData.time}</div>
            </div>
          </div>
        </div>
        <div className="popup_notification_body popup-text p">
          {popUpData.text}
        </div>
      </div>
    </Popup>
  );
}

export default NotificationPopup;
