import React, { useEffect, useState } from 'react';
import HelmetContainer from '../../components/common/helmet/helmet';
import Layout from '../../components/layout/layout';
import CategoriesSlider from '../../components/price/categories-slider/categories-slider';
import PriceTable from '../../components/price/price-table/price-table';
import SearchBanner from '../../components/price/search-banner/search-banner';
import BoxingGloveImage from '../../assests/img/boxing-glove.svg';
import MoneyBagFillImage from '../../assests/img/money-bag-fill.svg';
import SavingsImage from '../../assests/img/savings.svg';
import SpaceshipImage from '../../assests/img/savings.svg';
import MoneyTimeImage from '../../assests/img/money-time.svg';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocketData from '../../utils/useWebSocketData';
import { toast, ToastContainer } from 'react-toastify';
import useViewport from '../../utils/useViewPort';
import {
  addWatchList,
  getWatchList,
  removeWatchList,
} from '../../actions/assestsAction';
import Toast from '../../components/common/toast/toast';
import { getBalance, getUserIPAddress } from '../../actions/userAction';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';

function PricePage() {
  const data = [
    {
      label: 'WatchList',
      value: 'watchlist',
      img: SpaceshipImage,
      bg: 'bg-orange-gradient',
    },
    {
      label: 'All Assets',
      value: 'core_assets',
      img: MoneyTimeImage,
      bg: 'bg-green-gradient',
    },
    {
      label: 'Top Gainers',
      value: 'top_gainers',
      img: BoxingGloveImage,
      bg: 'bg-pink-gradient',
    },
    {
      label: 'Top Losers',
      value: 'top_losers',
      img: MoneyBagFillImage,
      bg: 'bg-purple-gradient',
    },
    // {
    //   label: 'Stable Coins',
    //   value: 'stable_coins',
    //   img: SavingsImage,
    //   bg: 'bg-orange-gradient',
    // },
    // {
    //   label: 'Potential Coins',
    //   value: 'potential_coins',
    //   img: SpaceshipImage,
    //   bg: 'bg-orange-gradient',
    // },
    // {
    //   label: 'Recently Added',
    //   value: 'recently_added',
    //   img: MoneyTimeImage,
    //   bg: 'bg-green-gradient',
    // },
    // {
    //   label: 'Stable Coins',
    //   value: 'stable_Coins',
    //   img: SavingsImage,
    //   bg: 'bg-orange-gradient',
    // },
  ];
  const [selectedCategory, setSelectedCategory] = useState(data[1]);
  const [filterByTabData, setFilterByTabData] = useState(null);
  const [showPagination, setShowPagination] = useState(true);
  const { getTopGainer, getTopLooser } = useWebSocketData();
  const [watchlistData, setWatchlistData] = useState([]);
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { analytics } = useAnalytics();

  const {
    error,
    loading: assestLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);

  const { coinsDetails } = useSelector((state) => state.getCoins);

  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);

  const { response: watchListData, loading } = useSelector(
    (state) => state.userWatchList
  );

  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);

  const getWatchListCoins = () => {
    const items = [];
    //console.log('called', watchListData?.payload?.coins?.coins);
    watchListData?.payload?.coins?.coins?.forEach((watchList) => {
      coinsDetails?.convertedArray?.forEach((item) => {
        //console.log(watchList == item.symbol, watchList, item.symbol);
        if (watchList == item.symbol) {
          items.push(item);
        }
      });
    });
    setWatchlistData(items);
  };

  const filterByTab = () => {
    if (coinsDetails?.convertedArray) {
      if (selectedCategory.value === 'top_losers') {
        const leastValue = getTopLooser();
        setFilterByTabData(leastValue);
      } else if (selectedCategory.value === 'top_gainers') {
        const topValue = getTopGainer();
        setFilterByTabData(topValue);
      } else if (selectedCategory.value === 'core_assets') {
        setFilterByTabData(coinsDetails.convertedArray);
      } else if (selectedCategory.value === 'watchlist') {
        setFilterByTabData(watchlistData);
      }
    }
  };

  useEffect(() => {
    filterByTab();
  }, [assetsData]);

  useEffect(() => {
    filterByTab();
    setShowPagination(true);
  }, [selectedCategory]);

  useEffect(() => {
    if (!watchListData) {
      dispatch(getWatchList(userInfo.token.access));
    }
    if (!balance) {
      dispatch(getBalance());
    }
  }, []);

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  useEffect(() => {
    getWatchListCoins();
  }, [loading, watchListData, coinsDetails]);

  useEffect(() => {
    analytics?.page('Price list page (coins list)', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  const handleWatchList = async (coin) => {
    analytics?.track('Watchlist button clicked!', {
      from: 'Price page',
      uid: userInfo?.payload?.uid,
      symbol: coin.symbol,
      ip: ip,
      QueryParams: { ...getQueryParams() },
    });
    if (watchListData) {
      const checkdata = watchListData?.payload?.coins.coins?.filter((item) => {
        return item == coin.symbol;
      });
      //console.log(checkdata);
      if (checkdata.length > 0) {
        try {
          const data = await removeWatchList(
            userInfo.token.access,
            coin.symbol
          );
          dispatch(getWatchList(userInfo.token.access));
          analytics?.track('Remove watchlist success!', {
            from: 'Price page',
            uid: userInfo?.payload?.uid,
            symbol: coin.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.success(data.message);
        } catch (e) {
          analytics?.track('Remove watchlist failed!', {
            from: 'Price page',
            uid: userInfo?.payload?.uid,
            symbol: coin.symbol,
            reason: e,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.error(e);
        }
      } else {
        try {
          const data = await addWatchList(userInfo.token.access, coin.symbol);
          dispatch(getWatchList(userInfo.token.access));
          analytics?.track('Add watchlist success!', {
            from: 'Price page',
            uid: userInfo?.payload?.uid,
            symbol: coin.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.success(data.message);
        } catch (e) {
          analytics?.track('Add watchlist failed!', {
            from: 'Price page',
            uid: userInfo?.payload?.uid,
            symbol: coin.symbol,
            QueryParams: { ...getQueryParams() },
            reason: e,
            ip: ip,
          });
          toast.error(e);
        }
      }
    }
  };

  useEffect(() => {
    analytics?.track('Clicked filter section!', {
      from: 'Price Page',
      uid: userInfo?.payload?.uid,
      keyword: selectedCategory?.label,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, selectedCategory]);

  return (
    <Layout>
      <HelmetContainer title="Prices" />
      <Toast />
      <div className="page__container">
        {width > 650 ? null : <SearchBanner />}
        <CategoriesSlider
          data={data}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <PriceTable
          selectedCategory={selectedCategory}
          filterByTabData={filterByTabData}
          showPagination={showPagination}
          setShowPagination={setShowPagination}
          handleWatchList={handleWatchList}
        />
      </div>
    </Layout>
  );
}

export default PricePage;
