import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get2fA } from '../../../../actions/settingAction';
import QRImage from '../../../../assests/img/qr-code.png';
import { getQueryParams } from '../../../../utils/query-helper';
import useAnalytics from '../../../../utils/useAnalytics';
import FormInput from '../../../auth/form-input/forminput';
import Toast from '../../../common/toast/toast';
import QRCode from './qr-code/qr-code';
import VerficationInputBox from './verfication-input-box/verfication-input-box';

function TwoFactorAuthentication() {
  const {
    get2FA,
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { loading, error, response } = get2FA;
  const dispatch = useDispatch();
  const [secret, setSecret] = useState('');
  const { analytics } = useAnalytics();

  useEffect(() => {
    dispatch(get2fA());
  }, []);

  const splitCode = () => {
    const code = response?.payload?.data?.url.split('&');
    const finalElement = code?.slice(-1);
    setSecret(
      finalElement ? finalElement[0]?.replace('secret=', '') : finalElement
    );
  };

  useEffect(() => {
    splitCode();
  }, [loading, response]);

  const handleCopyTheCode = () => {
    navigator.clipboard.writeText(secret);
    analytics?.track('Clicked copy code', {
      from: 'Settings tab',
      uid: userInfo?.payload?.uid,
      purpose: '2FA Auth',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    toast.success('Copied 👍🏻', { toastId: 'copy' });
  };

  return (
    <>
      <div className="popup__box">
        <div className="popup__title h6">Scan Code</div>
        <QRCode />
      </div>
      <div className="popup__box">
        <div
          style={{
            backgroundColor: 'transparent',
            width: '90%',
            height: '100px',
            position: 'absolute',
            zIndex: 5,
            cursor: 'pointer',
          }}
          onClick={handleCopyTheCode}
        ></div>
        <FormInput
          defaultValue={secret}
          active
          disabled
          value={secret}
          label="Click here to copy the code"
        />
        <div
          className="popup__title h6"
          style={{ marginBottom: 25, marginTop: 25 }}
        >
          Enter six-digit code
        </div>
        <VerficationInputBox />
      </div>
    </>
  );
}

export default TwoFactorAuthentication;
