import React, { useState } from 'react';
import { Notification, notificationTags } from '../../../utils/data';
import CustomSelect from '../../common/custom-select/custom-select';
import Icon from '../../common/icons/icons';
import Tags from '../../common/tags/tags';
import NotificationList from '../notification-list/notification-list';

function NotificationLeftConatiner({ handleOpenPopUp }) {
  const [selectedFilter, setSelectedFilter] = useState(notificationTags[0]);
  return (
    <div className="page__col">
      <div className="page__head">
        <div className="page__category h5">Krypto Exchange</div>
        <div className="page__title h2">Notifications</div>
      </div>
      <div className="notifications">
        <Tags
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          options={notificationTags}
          addTagclassName="notifications__tags  mobile-hide"
          addLinksclassName="notifications__link"
        />
        <div className="notifications__sorting">
          <div className="notifications__select mobile-show">
            <CustomSelect
              defaultValue={[{ label: 'All', value: 'All' }]}
              options={[
                { label: 'All', value: 'All' },
                { label: 'Exchange', value: 'Exchange' },
                { label: 'Promotions', value: 'Promotions' },
              ]}
            />
          </div>
          <div className="notifications__select">
            <CustomSelect
              defaultValue={[
                {
                  label: 'Recent Notification',
                  value: 'Recent Notification',
                },
              ]}
              options={[
                { label: 'All Notification', value: 'All Notification' },
                {
                  label: 'Recent Notification',
                  value: 'Recent Notification',
                },
              ]}
            />
          </div>
          <form className="notifications__search">
            <input
              className="notifications__input"
              type="text"
              placeholder="Search"
            />
            <button className="notifications__start">
              <Icon icon="search" className="icon" />
            </button>
          </form>
        </div>
        <div className="notifications__container">
          <div className="notifications__list">
            {Notification.map((item, idx) => (
              <NotificationList
                item={item}
                key={idx}
                handleOpenPopUp={handleOpenPopUp}
              />
            ))}
          </div>
          <div className="more">
            <button className="more__btn btn btn_orange">
              <div className="loader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationLeftConatiner;
