import React, { useState } from 'react';
import { LatestActivities } from '../../../utils/data';
import LatestActivitiesList from './latest-activities-list/latest-activities-list';
import { LatestActivitiesDropDown } from '../../../utils/data';
import CustomFilterDropDown from '../../common/custom-filter-dropdown/custom-filter-dropdown';
import Icon from '../../common/icons/icons';

function LatestActivity() {
  const [opendropDown, setOpenDropDown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Latest Activities');

  return (
    <div className="card">
      <div className="card__head">
        <div className="card__title h6">{selectedFilter}</div>
        <CustomFilterDropDown
          selectedOption={selectedFilter}
          setSelectedOptions={setSelectedFilter}
          openState={opendropDown}
          setOpenState={setOpenDropDown}
          options={LatestActivitiesDropDown}
        />
      </div>
      <div className="card__list">
        {LatestActivities.map((data, idx) => (
          <LatestActivitiesList data={data} key={idx} />
        ))}
      </div>
      <a className="card__view" href="/">
        <div className="card__arrow">
          <Icon icon="arrow-right" className="icon" />
        </div>
        View all activity
      </a>
    </div>
  );
}

export default LatestActivity;
