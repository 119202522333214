import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { assests } from '../../../utils/data';
import { getQueryParams } from '../../../utils/query-helper';
import useAnalytics from '../../../utils/useAnalytics';
import useBalance from '../../../utils/useBalance';
import useWebSocketData from '../../../utils/useWebSocketData';
import CustomButton from '../../common/custom-button/custom-button';
import CustomFilterDropDown from '../../common/custom-filter-dropdown/custom-filter-dropdown';
import IconBtn from '../../common/icon-text/icon-text';
import Icon from '../../common/icons/icons';
import Popup from '../../common/popup/popup';
import DetailsWidgetCard from '../../price-details/details-widget-card/details-widget-card';
import TableCell from '../../settings/settings-tabs/login-details/table-cell/table-cell';
import WalletsBankPopup from '../wallets-bank-popup/wallets-bank-popup';

function WalletTotalBalanceCard({
  setVisibleSideBar,
  selectedData,
  initiator,
  initiatorDepositState,
  openBankDetailsPopUp,
  setOpenBankDetailsPopup,
}) {
  const [openState, setOpenState] = useState(false);
  const [selectedOption, setSelectedOption] = useState('24h Markets');
  const [selectedInfoData, setSelectedInfoData] = useState(null);
  const {
    userDetails: { userData },
    networkCheck: { loading, response: networkResponse, error },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const history = useHistory();
  const { analytics } = useAnalytics();
  const location = useLocation();

  const { getInfoAboutCoins, roundUp } = useBalance();
  useEffect(() => {
    if (selectedData) {
      const value = getInfoAboutCoins(selectedData.currency);
      setSelectedInfoData(value);
    }
  });

  const openPopUp = () => {
    if (networkResponse && networkResponse.payload.level > 3) {
      return toast.error(
        'Kindly complete the KYC process and the 2FA process in settings'
      );
    }
    setOpenBankDetailsPopup(true);
  };
  return (
    <div className="card">
      {selectedData.currency !== 'inr' && (
        <>
          <div className="card__head">
            <div className="card__title h6">24h Markets</div>
            {/* <CustomFilterDropDown
          openState={openState}
          setOpenState={setOpenState}
          options={[
            { name: '24h Markets' },
            { name: '16h Markets' },
            { name: ' 8h Markets' },
            { name: '4h Markets' },
          ]}
          selectedOption={selectedOption}
          setSelectedOptions={setSelectedOption}
        /> */}
          </div>
          <div className="widgets">
            {selectedInfoData && <DetailsWidgetCard data={selectedInfoData} />}
          </div>
        </>
      )}
      <div className="card__btns card__btns_mt40">
        <button
          className="card__btn btn btn_orange"
          onClick={() => {
            setVisibleSideBar('withdraw');
            analytics?.track('Clicked withdraw section', {
              from: 'Wallet Page',
              symbol: selectedData.currency,
              uid: userInfo?.payload?.uid,
              QueryParams: { ...getQueryParams() },
              ip: ip,
            });
          }}
        >
          <Icon icon="withdraw" className="icon" />
          <span className="btn__text">
            {selectedData.currency === 'inr' ? 'Withdraw' : 'Send'}
          </span>
        </button>
        <button
          className="card__btn btn btn_border"
          onClick={() => {
            // if (selectedData.currency === 'inr') {
            //   if (networkResponse && networkResponse.payload.level < 3) {
            //     return toast.error(
            //       'Kindly complete the KYC process and the 2FA process in settings'
            //     );
            //   } else {
            //     openPopUp();
            //   }
            // } else {
            setVisibleSideBar('deposit');
            initiator();
            // }
          }}
        >
          <IconBtn
            className="icon"
            iconName="deposit"
            text={selectedData.currency === 'inr' ? 'Deposit' : 'Receive'}
          />
        </button>
      </div>
      {selectedData.currency !== 'inr' && (
        <div style={{ marginTop: 22, display: 'grid' }}>
          <CustomButton
            onClick={() => {
              history.push({
                pathname: `/exchange/${selectedData.currency}`,
                search: location.search,
              });
              analytics?.track('Clicked Buy/Sell button!', {
                from: 'Wallet Page',
                uid: userInfo?.payload?.uid,
                symbol: selectedData.currency,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
            title={`Buy/Sell ${selectedData?.currency?.toUpperCase()}`}
          />
        </div>
      )}

      <WalletsBankPopup
        state={openBankDetailsPopUp}
        setState={setOpenBankDetailsPopup}
      />
    </div>
  );
}

export default WalletTotalBalanceCard;
