import React from 'react';
import { useHistory, useLocation } from 'react-router';
import useBalance from '../../../utils/useBalance';

function ExchangeCellDropdownOptions({
  setSelectedCell,
  setOpenselectedCell,
  data,
  setSearchText,
  ...otherProps
}) {
  const history = useHistory();
  const location = useLocation();
  const { getInfoAboutCoins } = useBalance();
  return (
    <>
      {data.symbol !== 'inr' && (
        <button
          {...otherProps}
          className="exchange__item"
          onClick={() => {
            history.push({
              pathname: `/exchange/${data.symbol}`,
              search: location.search,
            });
            setOpenselectedCell((prev) => !prev);
            setSearchText('');
          }}
          style={{ width: '100%' }}
        >
          <>
            <div className="exchange__logo">
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <img src={data.logo} alt={data.info} />
                <img
                  style={{ width: 20, marginLeft: -12 }}
                  src={getInfoAboutCoins('inr')?.logo}
                  alt=""
                />
              </div>
            </div>
            <div className="exchange__details">
              <div className="exchange__currency">{data.name}</div>
              <div
                className="exchange__info"
                style={{
                  textTransform: 'uppercase',
                  fontFamily: 'Poppins',
                  marginTop: 3,
                }}
              >
                {data.symbol}/INR
              </div>
            </div>
          </>
        </button>
      )}
    </>
  );
}

export default ExchangeCellDropdownOptions;
