import React, { Component, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useBalance from '../../../../utils/useBalance';

// export default class RadialBarChart extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [44, 55, 67, 83],
//       options: this.options,
//       label: ['Apples', 'Oranges', 'Bananas', 'Berries'],
//     };
//   }

//   options = {
//     chart: {
//       height: 350,
//       type: 'radialBar',
//     },
//     plotOptions: {
//       radialBar: {
//         dataLabels: {
//           name: {
//             fontSize: '22px',
//           },
//           value: {
//             fontSize: '16px',
//           },
//         },
//       },
//     },
//     // labels: this.statelabel,
//   };

//   getChartData = () => {
//     if (this.props.chartData?.length > 0) {
//       const newchartData = this.props.chartData.slice(0, 3).map((item) => {
//         const value = (item?.balance * 100) / this.props.totalBalance();
//         return value.toFixed();
//       });
//       this.setState({ series: newchartData, options: this.options });
//     }
//   };

//   getLabelData = () => {
//     if (this.props.chartData?.length > 1) {
//       const newLabelData = this.props.chartData
//         .slice(0, 3)
//         .map((item) => item?.currency.toUpperCase());
//       this.setState({
//         options: {
//           chart: {
//             height: 350,
//             type: 'radialBar',
//           },
//           plotOptions: {
//             radialBar: {
//               dataLabels: {
//                 name: {
//                   fontSize: '22px',
//                 },
//                 value: {
//                   fontSize: '16px',
//                 },
//                 total: {
//                   show: true,
//                   label: `Top ${this.state.series[0]}`,
//                   color: 'rgba(255, 122, 104, 0.85)',
//                 },
//               },
//             },
//           },
//           labels: newLabelData,
//         },
//       });
//     }
//   };

//   componentDidMount() {
//     this.getChartData();
//     this.getLabelData();
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.chartData !== prevProps.chartData) {
//       this.getChartData();
//       this.getLabelData();
//     }
//   }

//   render() {
//     return (
//       <div id="chart">
//         {!this.props.loading && !this.props.assetLoading && (
//           <ReactApexChart
//             options={this.state.options}
//             series={this.state.series}
//             type="radialBar"
//             height={250}
//           />
//         )}
//       </div>
//     );
//   }
// }

function RadialBarChart({ chartData, totalBalance, loading, assetLoading }) {
  const [chartdata, setChartData] = useState([]);
  const [chartlabeldata, setChartLabelData] = useState([]);

  const getChartData = () => {
    if (chartData?.length > 0) {
      setChartData(
        chartData.slice(0, 3).map((item) => {
          const value = (item?.balance * 100) / totalBalance();
          return Math.round(value);
        })
      );
    } else {
      setChartData([87, 75, 62]);
    }
  };

  const getLabelData = () => {
    if (chartData?.length >= 1) {
      setChartLabelData(
        chartData.slice(0, 3).map((item) => item?.currency.toUpperCase())
      );
    } else {
      setChartLabelData(['USD', 'EUR', 'RUB']);
    }
  };

  useEffect(() => {
    getChartData();
    getLabelData();
  }, []);

  useEffect(() => {
    getChartData();
    getLabelData();
  }, [chartData, totalBalance]);

  //console.log(chartlabeldata, 'chartlabeldata');

  const data = {
    series: chartdata,
    options: {
      chart: {
        height: '100%',
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '16px',
            },
            value: {
              fontSize: '14px',
              color: 'rgb(128, 129, 145)',
            },
            total: {
              show: true,
              label: `Top ${chartlabeldata[0]}`,
              color: 'rgba(255, 122, 104, 0.85)',
              formatter: function (w) {
                if (chartdata.length > 0 && w) {
                  return `${chartdata[0]} %`;
                }
              },
            },
          },
        },
      },
      // colors: [
      //   'rgba(108, 93, 211, 0.85)',
      //   'rgba(61, 186, 162, 0.85)',
      //   'rgba(255, 122, 104, 0.85)',
      // ],
      labels: chartlabeldata,
    },
  };

  return (
    <>
      {chartData.length > 0 &&
        !chartData[0] !== undefined &&
        chartdata.length > 0 &&
        !isNaN(chartdata[0]) &&
        chartlabeldata[0] !== undefined &&
        chartlabeldata.length > 0 &&
        !loading &&
        !assetLoading && (
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="radialBar"
            height={250}
          />
        )}
    </>
  );
}

export default RadialBarChart;
