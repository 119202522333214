import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ExchangePage from '../pages/exchange-page/exchange-page';
import HomePage from '../pages/homepage/homepage';
import NotificationPage from '../pages/notification-page/notification-page';
import PriceDetailsPage from '../pages/price-details/price-details';
import PricePage from '../pages/price-page/price-page';
import PromotionsPage from '../pages/promotions-page/promotions-page';
import SignInPage from '../pages/signin-page/signin-page';
import Signup from '../pages/signup-page/signup-page';
import NotFoundPage from '../pages/not-found/not-found';
import WalletsPage from '../pages/wallets-page/wallets-page';
import HistoryPage from '../pages/activities-page/activities-page';
import PromotionsDetails from '../pages/promotions-details/promotions-details';
import PrivateRoute from './privateRoutes/privateRoutes';
import { userDetails } from '../actions/userAction';
import { networkCheck } from '../actions/kycAction';
import VerifyEmail from '../pages/ verify-email-page/ verify-email-page';
import { toast } from 'react-toastify';
import Toast from '../components/common/toast/toast';
import InformationPage from '../pages/information-page/information-page';
import InitialScratchCard from '../components/common/initial-scratch-card/initial-scratch-card';
import ReferralsPage from '../pages/referrals-page/referrals-page';
import { healthCheck } from '../actions/assestsAction';
import ServerDownImg from '../assests/img/nowifi.png';
import CustomButton from '../components/common/custom-button/custom-button';
import useAnalytics from '../utils/useAnalytics';
import PaymentStatusPage from '../pages/payment-status-page/payment-status-page';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function Index() {
  const {
    theme: { isdark },
    userLogin: { userInfo },
    userRegister: { loading, error, userInfo: userRegisterInfo },
    networkCheck: {
      loading: networkLoading,
      error: networkError,
      response: networkResponse,
    },
    userDetails: { error: userDetailsError, loading: UserDetailsLoading },
  } = useSelector((state) => state);
  const [isServerDown, setIsServerDown] = useState(false);

  const { analytics } = useAnalytics();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isdark === true) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isdark]);

  useEffect(() => {
    if (userInfo && userInfo.token.access) {
      dispatch(userDetails());
      dispatch(networkCheck());
    }
    if (userRegisterInfo) {
      dispatch(userDetails());
      dispatch(networkCheck());
    }
  }, [userInfo, userRegisterInfo]);

  function isInternetConnected() {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      toast.info(`You're offline. check your connection`, {
        autoClose: false,
      });
    }
  }

  useEffect(() => {
    isInternetConnected();
  }, [navigator.onLine]);

  useEffect(() => {
    async function checkServerStatus() {
      try {
        const data = await healthCheck();
        if (data.status === 'Working fine!') {
          setIsServerDown(false);
        }
      } catch (e) {
        // console.log(e);
        setIsServerDown(true);
      }
    }
    checkServerStatus();
  }, []);

  return (
    <div className={`${isdark ? 'dark' : ''}`}>
      {isServerDown ? (
        <div className="server__down">
          <div className="h2">
            Oops, there's a problem with the connection<span>.</span>{' '}
          </div>
          <img src={ServerDownImg} />
          <p>
            Kindly check if you're not using a VPN/proxy and you're connected to
            the internet, incase you're then we're sorry we are down for
            maintanence and will be back soon.
          </p>
          <CustomButton
            title="Try again"
            onClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <Router>
          <AnimatePresence>
            <Switch>
              <PrivateRoute exact path="/">
                <HomePage />
              </PrivateRoute>
              <PrivateRoute path="/prices">
                <PricePage />
              </PrivateRoute>
              <PrivateRoute path="/pricedetails/:symbol">
                <PriceDetailsPage />
              </PrivateRoute>
              <PrivateRoute path="/exchange/:symbol">
                <ExchangePage />
              </PrivateRoute>
              <PrivateRoute path="/wallet">
                <WalletsPage />
              </PrivateRoute>
              {/* <PrivateRoute path="/promotion">
            <PromotionsPage />
          </PrivateRoute> */}
              {/* <PrivateRoute path="/promotiondetails/:id">
            <PromotionsDetails />
          </PrivateRoute> */}
              <PrivateRoute path="/History">
                <HistoryPage />
              </PrivateRoute>
              <PrivateRoute path="/notification">
                <NotificationPage />
              </PrivateRoute>
              <PrivateRoute path="/referral">
                <ReferralsPage />
              </PrivateRoute>
              <Route path="/signup" component={Signup} />
              <Route path="/signin" component={SignInPage} />
              <Route path="/verify" component={VerifyEmail} />
              <Route path="/profile" component={InformationPage} />
              {/* <PrivateRoute path="/payment-status">
              <PaymentStatusPage />
            </PrivateRoute> */}
              <Route component={NotFoundPage} />
            </Switch>
          </AnimatePresence>
          <InitialScratchCard />
        </Router>
      )}
    </div>
  );
}

export default Index;
