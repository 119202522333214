import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { themeReducer } from './reducers/themeReducer';
import { navbarReducer } from './reducers/navbarReducer';
import {
  get2FAReducer,
  settingReducer,
  verify2FAReducer,
} from './reducers/settingsReducer';
import {
  userAddNameReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userRegisterReducer,
  userAddUniqueNameReducer,
  userGetBalanceReducer,
  userDetailsReducer,
  updateBankNumberReducer,
  updateUPIDetailReducer,
  updateIFSCDetailReducer,
  updatePasswordReducer,
  resendMailReducer,
  portfolioReducer,
  googleLoginReducer,
  userDepositLevelReducer,
  user2FALevelReducer,
  getUserIPAddressReducer,
} from './reducers/userReducer';
import {
  getAssestsReducer,
  getBannerDetailsReducer,
  getChartDataReducer,
  getCoinsDetailsReducer,
  getNewsReducer,
  getUserWatchListReducer,
} from './reducers/assestsReducer';
import {
  cancelOrderReducer,
  createOrderReducer,
  getOrderBookReducer,
} from './reducers/exchangeReducer';
import {
  addPersonalInfoReducer,
  kycViewDetailsReducer,
  networkCheckReducer,
  sendPhoneCodeReducer,
  uploadDocumentNumberReducer,
  uploadDocumentReducer,
  uploadDocumentSelfieReducer,
  verifyCodeReducer,
} from './reducers/kycReducer';
import {
  getDepositDataReducer,
  getOrderDataReducer,
  getTradeDataReducer,
  getWithrawDataReducer,
} from './reducers/historyReducer';
import {
  getReferralCodeEarningReducer,
  getReferralCodeReducer,
  getScratchCard,
  getScratchCardReducer,
  getTotalReferralsReducer,
} from './reducers/rewardsReducer';

export const prefix = 'krypto';

const reducer = combineReducers({
  theme: themeReducer,
  navbar: navbarReducer,
  setting: settingReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgotPassword: userForgotPasswordReducer,
  userAddName: userAddNameReducer,
  userAddUniqueName: userAddUniqueNameReducer,
  getAssests: getAssestsReducer,
  getCoins: getCoinsDetailsReducer,
  getBanner: getBannerDetailsReducer,
  news: getNewsReducer,
  userGetBalance: userGetBalanceReducer,
  userDepositState: userDepositLevelReducer,
  getPortfolio: portfolioReducer,
  getChartData: getChartDataReducer,
  createOrder: createOrderReducer,
  cancelOrder: cancelOrderReducer,
  getOrderBook: getOrderBookReducer,
  userDetails: userDetailsReducer,
  userWatchList: getUserWatchListReducer,
  updateBankNumber: updateBankNumberReducer,
  updateUPIDetail: updateUPIDetailReducer,
  updateIFSCDetail: updateIFSCDetailReducer,
  updatePassword: updatePasswordReducer,
  getOtpKyc: sendPhoneCodeReducer,
  verifyOtpKyc: verifyCodeReducer,
  addPersonalInfoKyc: addPersonalInfoReducer,
  networkCheck: networkCheckReducer,
  resendMail: resendMailReducer,
  orderData: getOrderDataReducer,
  tradeData: getTradeDataReducer,
  depositData: getDepositDataReducer,
  withDrawData: getWithrawDataReducer,
  get2FA: get2FAReducer,
  verify2FA: verify2FAReducer,
  kycViewDetails: kycViewDetailsReducer,
  googleAuth: googleLoginReducer,
  getReferralCode: getReferralCodeReducer,
  getReferralEarning: getReferralCodeEarningReducer,
  getTotalReferrals: getTotalReferralsReducer,
  getScratchCard: getScratchCardReducer,
  twoFAData: user2FALevelReducer,
  userIpAddress: getUserIPAddressReducer,
});

const getTheme = localStorage.getItem(prefix + '.theme') === 'true' || false;

const userInfoFromStorage = localStorage.getItem(`${prefix}_userInfo`)
  ? JSON.parse(localStorage.getItem(`${prefix}_userInfo`))
  : null;

const userWatchList = localStorage.getItem(`watchlist`)
  ? JSON.parse(localStorage.getItem(`watchlist`))
  : [];

const initialState = {
  theme: { isdark: getTheme },
  userLogin: { userInfo: userInfoFromStorage },
  userWatchList: { data: userWatchList },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
