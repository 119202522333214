export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const GOOGLE_LOGIN_RESET = 'GOOGLE_LOGIN_RESET';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL';

export const USER_ADD_NAME_REQUEST = 'USER_ADD_NAME_REQUEST';
export const USER_ADD_NAME_SUCCESS = 'USER_ADD_NAME_SUCCESS';
export const USER_ADD_NAME_FAIL = 'USER_ADD_NAME_FAIL';

export const USER_ADD_UNIQUE_USERNAME_REQUEST =
  'USER_ADD_UNIQUE_USERNAME_REQUEST';
export const USER_ADD_UNIQUE_USERNAME_SUCCESS =
  'USER_ADD_UNIQUE_USERNAME_SUCCESS';
export const USER_ADD_UNIQUE_USERNAME_FAIL = 'USER_ADD_UNIQUE_USERNAME_FAIL';

export const USER_GET_BALANCE_REQUEST = 'USER_GET_BALANCE_REQUEST';
export const USER_GET_BALANCE_SUCCESS = 'USER_GET_BALANCE_SUCCESS';
export const USER_GET_BALANCE_FAIL = 'USER_GET_BALANCE_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_CLEAN = 'USER_DETAILS_CLEAN';

export const UPDATE_BANK_ACCOUNT_NUMBER_REQUEST =
  'UPDATE_BANK_ACCOUNT_NUMBER_REQUEST';
export const UPDATE_BANK_ACCOUNT_NUMBER_SUCCESS =
  'UPDATE_BANK_ACCOUNT_NUMBER_SUCCESS';
export const UPDATE_BANK_ACCOUNT_NUMBER_FAIL =
  'UPDATE_BANK_ACCOUNT_NUMBER_FAIL';

export const UPDATE_UPI_REQUEST = 'UPDATE_UPI_REQUEST';
export const UPDATE_UPI_SUCCESS = 'UPDATE_UPI_SUCCESS';
export const UPDATE_UPI_FAIL = 'UPDATE_UPI_FAIL';

export const UPDATE_IFSC_REQUEST = 'UPDATE_IFSC_REQUEST';
export const UPDATE_IFSC_SUCCESS = 'UPDATE_IFSC_SUCCESS';
export const UPDATE_IFSC_FAIL = 'UPDATE_UPI_FAIL';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const SEND_EMAIL_CONFIRMATION_REQUEST =
  'SEND_EMAIL_CONFIRMATION_REQUEST';
export const SEND_EMAIL_CONFIRMATION_SUCCESS =
  'SEND_EMAIL_CONFIRMATION_SUCCESS';
export const SEND_EMAIL_CONFIRMATION_FAIL = 'SEND_EMAIL_CONFIRMATION_FAIL';
export const SEND_EMAIL_CONFIRMATION_CLEANUP =
  'SEND_EMAIL_CONFIRMATION_CLEANUP';

export const PROFILE_CLEANUP = 'PROFILE_CLEANUP';

export const USER_GET_PORTFOLIO_REQUEST = 'USER_GET_PORTFOLIO_REQUEST';
export const USER_GET_PORTFOLIO_SUCCESS = 'USER_GET_PORTFOLIO_SUCCESS';
export const USER_GET_PORTFOLIO_FAIL = 'USER_GET_PORTFOLIO_FAIL';

export const USER_DEPOSIT_CHECK_REQUEST = 'USER_DEPOSIT_CHECK_REQUEST';
export const USER_DEPOSIT_CHECK_SUCCESS = 'USER_DEPOSIT_CHECK_SUCCESS';
export const USER_DEPOSIT_CHECK_FAIL = 'USER_DEPOSIT_CHECK_FAIL';

export const USER_2FA_CHECK_REQUEST = 'USER_2FA_CHECK_REQUEST';
export const USER_2FA_CHECK_SUCCESS = 'USER_2FA_CHECK_SUCCESS';
export const USER_2FA_CHECK_FAIL = 'USER_2FA_CHECK_FAIL';
export const USER_2FA_CHECK_CLEANUP = 'USER_2FA_CHECK_CLEANUP';

export const USER_GET_IP_ADDRESS_REQUEST = 'USER_GET_IP_ADDRESS_REQUEST';
export const USER_GET_IP_ADDRESS_SUCCESS = 'USER_IP_ADDRESS_SUCCESS';
export const USER_GET_IP_ADDRESS_FAIL = 'USER_GET_IP_ADDRESS_FAIL';
export const USER_GET_IP_ADDRESS_CLEANUP = 'USER_GET_IP_ADDRESS_CLEANUP';
