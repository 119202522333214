import React, { useState } from 'react';
import CustomButton from '../../common/custom-button/custom-button';

function NotificationFilter() {
  const initialState = {
    Prices: false,
    Withdrawels: true,
    Deposit: true,
    Promotion: true,
    Settings: true,
    Support: false,
  };
  const [checkedValue, setCheckedValue] = useState(initialState);
  const data = [
    { name: 'Prices' },
    {
      name: 'Withdrawels',
    },
    {
      name: 'Deposit',
    },
    {
      name: 'Promotion',
    },
    {
      name: 'Settings',
    },
    {
      name: 'Support',
    },
  ];

  const handleOnChange = (e) => {
    setCheckedValue({ ...checkedValue, [e.target.name]: e.target.checked });
  };

  const handleSelectAll = (value) => {
    let newCheckedValue = {};

    for (let key in checkedValue) {
      if (checkedValue.hasOwnProperty(key)) {
        newCheckedValue[key] = value;
      }
    }
    setCheckedValue(newCheckedValue);
  };

  const resetFilter = () => setCheckedValue(initialState);

  return (
    <div className="card card_widget desktop-hide">
      <div className="card__head card__head_mb32">
        <div className="card__title h6">Filter</div>
      </div>
      <div className="card__filters">
        {data.map((item, idx) => (
          <label className="checkbox" key={idx}>
            <input
              className="checkbox__input"
              name={item.name}
              type="checkbox"
              onChange={(e) => handleOnChange(e)}
              checked={checkedValue[item.name] ? true : false}
            />
            <span className="checkbox__in">
              <span className="checkbox__tick"></span>
              <span className="checkbox__text">{item.name}</span>
            </span>
          </label>
        ))}
      </div>
      <div className="card__btns">
        <CustomButton
          title="Unselect All"
          onClick={() => handleSelectAll(false)}
        />
        <button
          className="card__btn btn btn_transparent"
          onClick={() => handleSelectAll(true)}
        >
          Select All
        </button>
      </div>
      <button className="card__reset" onClick={() => resetFilter()}>
        Reset all filters
      </button>
    </div>
  );
}

export default NotificationFilter;
