import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Popup from '../popup/popup';
import { toast } from 'react-toastify';
import { getBalance } from '../../../actions/userAction';
import DarkGif from '../../../assests/img/inital-scratch-card-dark.gif';
import WhiteGif from '../../../assests/img/inital-scratch-card-white.gif';
import animationData from '../../../assests/json/gift.json';
import Lottie from 'react-lottie';
import { redeemScratchCardsFromLists } from '../../../actions/rewardsAction';

function InitialScratchCard() {
  const [openPopUp, setOpenPopup] = useState(false);
  const [shakeState, setShakeState] = useState(1);
  const [stage, setStage] = useState(1);

  const {
    userAddName,
    userAddUniqueName,
    userLogin,
    userDetails: userDataState,
    theme: { isdark },
  } = useSelector((state) => state);

  const {
    loading: userNameLoading,
    error: userNameError,
    response: userNameResponse,
  } = userAddName;
  const {
    loading: userUniqueNameLoading,
    error: userUniqueNameError,
    response: userUniqueNameResponse,
  } = userAddUniqueName;

  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  useEffect(() => {
    if (
      userNameResponse?.payload?.card_currency_type &&
      userNameResponse?.payload?.card_amount &&
      userNameResponse?.payload?.card_id &&
      userUniqueNameResponse
    ) {
      setOpenPopup(true);
    }
  }, [userNameResponse, userUniqueNameResponse]);

  useEffect(() => {
    setTimeout(() => {
      setShakeState(Math.floor(Math.random() * 3) + 1);
    }, 2000);
  }, [openPopUp, setTimeout(() => {}, 2000)]);

  const reedeemScratchCard = async () => {
    try {
      const _ = await redeemScratchCardsFromLists(
        userInfo.token.access,
        userNameResponse?.payload?.card_id
      );
      setStage(2);
      dispatch(getBalance());
    } catch (e) {
      //// console.log(e);
      toast.error(e);
    }
  };

  return (
    <>
      <div
        className={`profile__scratch-popup ${stage == 1 ? 'mw-700' : 'mw-500'}`}
      >
        {/* {stage == 2 && openPopUp && <Confetti width={width} height={height} />} */}
        <Popup
          state={openPopUp}
          setState={setOpenPopup}
          confetti={stage === 2 && openPopUp}
        >
          <div className="profile__scratch">
            {stage == 1 ? (
              <>
                <div className="h4">Choose Your Free Coin.</div>
                <div className="h5" style={{ fontSize: 20, padding: 10 }}>
                  A gift to you from us.
                </div>
                <div className="profile__scratch-container">
                  <div
                    className={shakeState === 1 ? 'shake' : ''}
                    onClick={reedeemScratchCard}
                  >
                    <Lottie options={defaultOptions} height={200} width={200} />
                  </div>
                  <div
                    className={shakeState === 2 ? 'shake' : ''}
                    onClick={reedeemScratchCard}
                  >
                    <Lottie options={defaultOptions} height={200} width={200} />
                  </div>
                  <div
                    className={shakeState === 3 ? 'shake' : ''}
                    onClick={reedeemScratchCard}
                  >
                    <Lottie options={defaultOptions} height={200} width={200} />
                  </div>
                </div>
              </>
            ) : (
              <div className="inital-scratch-card-popup">
                <div className="h5">Hoorayy 🎉!</div>
                <img src={isdark ? DarkGif : WhiteGif} alt="" />
                <div className="h6">
                  You won{' '}
                  <span>
                    {userNameResponse?.payload?.card_amount}{' '}
                    {userNameResponse?.payload?.card_currency_type?.toUpperCase()}
                  </span>{' '}
                </div>
              </div>
            )}
          </div>
        </Popup>
      </div>
    </>
  );
}

export default InitialScratchCard;
