import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useBalance from '../../utils/useBalance';
import useViewport from '../../utils/useViewPort';
import useWebSocketData from '../../utils/useWebSocketData';
import HelmetContainer from '../common/helmet/helmet';
import ExchangeCellDropdown from './exchange-dropdown/exchange-dropdown';

function ExchangeCell({ selectedtab, setSelectedCell, selectedCell }) {
  const [openselected, setOpenselectedCell] = useState(false);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { width } = useViewport();
  const breakpoint = 766;

  const dispatch = useDispatch();

  const { loading, error, assetsData } = useSelector(
    (state) => state.getAssests
  );

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    getHigh,
    getLow,
    getVolume,
    getStatus,
    priceChangePercent,
    lastPrice,
    getAvgPrice,
  } = useWebSocketData();

  const { formatINR } = useBalance();

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(coinsDetails?.convertedArray);
    } else {
      const data = coinsDetails.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setResult(data);
    }
  }, [searchText, coinsDetails?.convertedArray]);

  // //console.log(coinsDetails?.convertedArray);

  return (
    <div
      className="exchange__top"
      // style={{
      //   display:
      //     selectedtab === 'market__tabs' && width < breakpoint
      //       ? 'inherit'
      //       : 'none',
      // }}
    >
      <HelmetContainer
        title={`${selectedCell.symbol?.toUpperCase()} | ${formatINR(
          lastPrice(selectedCell.symbol)
        )}  `}
      />
      {coinsDetails?.convertedArray && (
        <ExchangeCellDropdown
          openState={openselected}
          closeState={setOpenselectedCell}
          defaultValue={selectedCell}
          options={coinsDetails?.convertedArray}
          onChange={setSelectedCell}
          result={result}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      )}
      <div className="exchange__cell">
        <div className="exchange__info">24h change</div>
        <div
          style={{ textTransform: 'uppercase' }}
          className={`status ${
            selectedCell.symbol === 'inr'
              ? 'negative'
              : getStatus(selectedCell.symbol)
          }`}
        >
          {selectedCell.symbol === 'inr'
            ? 'fiat'
            : priceChangePercent(selectedCell.symbol)}
        </div>
      </div>
      <div className="exchange__cell">
        <div className="exchange__info">Last price</div>
        <div className="exchange__price">
          {selectedCell.symbol === 'inr'
            ? '₹ 0'
            : '₹ ' + formatINR(lastPrice(selectedCell.symbol))}
        </div>
      </div>
      <div className="exchange__cell">
        <div className="exchange__info">24h Low</div>
        <div className="exchange__price">
          {selectedCell.symbol === 'inr'
            ? '₹ 0'
            : '₹ ' + formatINR(getLow(selectedCell.symbol))}
        </div>
      </div>
      <div className="exchange__cell">
        <div className="exchange__info">24h High</div>
        <div className="exchange__price">
          {selectedCell.symbol === 'inr'
            ? '₹ 0'
            : '₹ ' + formatINR(getHigh(selectedCell.symbol))}
        </div>
      </div>

      <div className="exchange__cell">
        <div className="exchange__info">Average Price</div>
        <div className="exchange__price">
          {selectedCell.symbol === 'inr'
            ? '₹ 0'
            : '₹ ' + formatINR(getAvgPrice(selectedCell.symbol))}
        </div>
      </div>
    </div>
  );
}

export default ExchangeCell;
