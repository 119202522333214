import React, { useEffect, useState } from 'react';
import FormInput from '../../components/auth/form-input/forminput';
import CustomButton from '../../components/common/custom-button/custom-button';
import Popup from '../../components/common/popup/popup';
import Toast from '../../components/common/toast/toast';
import Layout from '../../components/layout/layout';
import ReferralsTab from '../../components/referrals/referrals/referrals';
import FriendsImage from '../../assests/img/friends.png';
import { useDispatch } from 'react-redux';
import {
  getReferralCode,
  redeemScratchCards,
  getScratchCardEarning,
  getScratchCards,
} from '../../actions/rewardsAction';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import useReferrals from '../../utils/useReferrals';
import { getAssests } from '../../actions/assestsAction';
import useBalance from '../../utils/useBalance';
import GiftImg from '../../assests/img/gift.png';
import HelmetContainer from '../../components/common/helmet/helmet';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';
import { getUserIPAddress } from '../../actions/userAction';

function ReferralsPage() {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [openInvitePopUp, setOpenInvitePopUp] = useState(false);
  const [totalEarnings, setTotalEarning] = useState(null);
  const filter = [
    {
      label: 'Redeemed',
      value: 'redeemed',
    },
    {
      label: 'Unredeemed',
      value: 'unredeemed',
    },
  ];

  const [selectFilter, setSelectFilter] = useState(filter[0]);
  const dispatch = useDispatch();
  const { analytics } = useAnalytics();
  const { getTotalReferralInINR } = useReferrals();
  const { formatINR } = useBalance();

  const {
    getReferralCode: { loading, response, error },
    getReferralEarning: {
      loading: earningLoading,
      response: earningResponse,
      error: earningError,
    },
    getAssests: { loading: assetLoading, error: assetError, assetsData },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!response) {
      dispatch(getReferralCode());
    }
    if (!earningResponse) {
      dispatch(getScratchCardEarning());
    }
    dispatch(getScratchCards('Redeemed', 1));
    if (!assetsData) {
      dispatch(getAssests());
    }
  }, []);

  useEffect(() => {
    const total = getTotalReferralInINR();
    if (typeof total == 'number') {
      const str = formatINR(total.toFixed(2));
      setTotalEarning(str);
    } else {
      setTotalEarning(total);
    }
  }, [assetsData]);

  const handleRedeem = async () => {
    analytics?.track('Clicked Redeem Code!', {
      from: 'Referral Page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    try {
      const data = await redeemScratchCards(
        userInfo.token.access,
        referralCode
      );
      analytics?.track('Redeem success!', {
        from: 'Referral Page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.success(data.message);
      setOpenPopUp(false);
      setReferralCode('');
      dispatch(
        getScratchCards(
          selectFilter.value === 'redeemed' ? 'Redeemed' : 'Not_Redeemed',
          1
        )
      );
    } catch (error) {
      analytics?.track('Redeem failed!', {
        from: 'Referral Page',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  const text = `
      Hey there ,\n
      Join Krypto and win exciting cryptocurrency rewards. In addition to this first 150K users can trade for free with least spread using Krypto and also send cryptocurrenices to your friends for free.\n
      Krypto is the India's first real-time crypto payment platform. With Krypto you can pay using cryptocurrencies in almost 2 million shops in India in less than a few seconds.\n
      Use my referral code to get some amount of Bitcoin/Ethereum/Rupees & more for free to start your Crypto journey \n
      Code : ${response?.payload?.referral_code} 
      Download Now:
      Website: https://letskrypto.com \n
      Android : https://play.google.com/store/apps/details?id=com.krypto \n
      iOS: https://apps.apple.com/in/app/krypto/id1513381502 \n
      Join now it's completely free! Only limited reward cards left 
      `;
  const url = 'https://letskrypto.com';
  const hashtag = '#Krypto, #cryptocurrency';

  const shareInvite = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `
          Buy, sell, trade and pay with cryptocurrency to anyone, anywhere, anytime. Krypto is all the gear you'd need for your crypto-hunt. \n`,
          url: url,
          text: text,
        })
        .then(() => {
          analytics?.track('Clicked Invite friends button!', {
            from: 'Referral Page',
            uid: userInfo?.payload?.uid,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.success('Thanks for sharing 👍🏻');
        })
        .catch(console.error);
    } else {
      // fallback
      analytics?.track('Clicked Invite friends button!', {
        from: 'Referral Page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setOpenInvitePopUp(true);
    }
  };

  useEffect(() => {
    analytics?.page('Referrals page', {
      uid: userInfo?.payload?.uid,
      ip: ip,
      QueryParams: { ...getQueryParams() },
    });
  }, [analytics, ip]);

  return (
    <Layout showborder>
      <HelmetContainer title="Referral" />
      <Toast />
      <div className="page__row">
        <div className="page__col referral_col">
          <ReferralsTab
            earning={totalEarnings}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            filter={filter}
          />
        </div>
        <div className="page__col referral_col-2">
          <div className="popup__money h4">Free Crypto for Invites</div>
          <img
            src="https://www.pngkit.com/png/full/990-9902585_freunde-werben-refer-a-friend-tego-cartoon.png"
            alt=""
            className="img_friends"
          />
          <div className="referral-col-2-container">
            <div className="h6">
              Invite your friends to Krypto and earn FREE cryptocurrencies like
              Bitcoin, Ethereum and more 🎉
            </div>
            <div className="card__btns card__btns_mt40">
              <button
                className="card__btn btn btn_orange"
                onClick={shareInvite}
              >
                <span className="btn__text">Invite Friends</span>
              </button>
              <button
                className="card__btn btn btn_border"
                onClick={() => setOpenPopUp((pre) => !pre)}
              >
                <span className="btn__text">Redeem Here</span>
              </button>
            </div>
            <div className="download__text">
              Use the Krypto mobile app daily to get free cryptocurrencies.
            </div>
          </div>
        </div>
      </div>
      <div className="referral__popup">
        <Popup state={openPopUp} setState={setOpenPopUp}>
          <div className="referral__popup_container">
            <div className="h5">Redeem Referral Code.</div>
            <div className="p">
              Enter the referral code and redeem your scratch card
              <br />
              <span>
                <a
                  referrerPolicy="no-referrer"
                  target="_blank"
                  className="instructions__link"
                  href="https://letskrypto.com/rewardcard"
                >
                  Instructions
                </a>
              </span>
            </div>
          </div>
          <FormInput
            label="Enter Referral Code"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <CustomButton
              title="Redeem Code"
              style={{ marginTop: 20 }}
              onClick={handleRedeem}
            />
          </div>
        </Popup>
        <div
          className="share__popup referral__share_popup"
          style={{ position: 'relative' }}
        >
          <Popup setState={setOpenInvitePopUp} state={openInvitePopUp}>
            <div
              className="share__bg"
              style={{ backgroundColor: '#2f9dce', height: '160px' }}
            ></div>
            <div className="share__container">
              <div className="share__coins">
                <img src={GiftImg} alt="" style={{ width: 190, height: 190 }} />
                <div className="h5">Share it with your friends</div>
                <div className="h6">
                  Invite your friends to Krypto and earn FREE cryptocurrencies
                  like <span> Bitcoin, Ethereum and more 🎉 </span>
                </div>
              </div>
              <div className="share__social_icon referral_share">
                <FacebookShareButton url={url} quote={text} hashtag={hashtag}>
                  <FacebookIcon size={50} round />
                </FacebookShareButton>
                <WhatsappShareButton url={url} title={text}>
                  <WhatsappIcon size={50} round />
                </WhatsappShareButton>
                <TwitterShareButton url={url} title={text} via="Krypto">
                  <TwitterIcon size={50} round />
                </TwitterShareButton>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </Layout>
  );
}

export default ReferralsPage;
