import React from 'react';

function CollapseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
    >
      <path d="M22 12H3" stroke="#11142d"></path>
      <g stroke="#808191">
        <path d="M22 4H13"></path>
        <path opacity=".301" d="M22 20H13"></path>
      </g>
      <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
    </svg>
  );
}

export default CollapseIcon;
