import React, { useEffect, useState, useRef } from 'react';
import Icon from '../../../common/icons/icons';

function WalletWithdrawFormInput({
  label,
  iconName,
  subTitle,
  disabledProperty,
  active,
  ...otherInputProps
}) {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (document.hasFocus() && ref.current.contains(document.activeElement)) {
      setFocus(true);
    }
  }, []);

  return (
    <div
      className={`operations__field field js-field ${
        hasFocus || disabledProperty || active ? 'active' : ''
      } `}
    >
      <div className="field__label">
        {label}
        {iconName && <Icon icon={iconName} className="icon icon-label" />}
        {subTitle && <span className="field__max">Max Amount</span>}
      </div>
      <div className="field__wrap">
        <input
          className="field__input js-field-input"
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...otherInputProps}
        />
      </div>
    </div>
  );
}

export default WalletWithdrawFormInput;
