import * as settingConstants from '../constants/settingConstant';

const initialState = {
  open: false,
  screenState: null,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.OPEN_SETTING:
      if (action.payload === undefined) {
        return { ...state, open: !state.open };
      }
      if (action.payload.screenState) {
        return {
          ...state,
          open: !state.open,
          screenState: action.payload.screenState,
        };
      }
      return { ...state, open: action.payload.open };
    case settingConstants.SETTING_CLEANUP:
      return { ...state, open: false, screenState: null };
    default:
      return state;
  }
};

export const get2FAReducer = (state = {}, action) => {
  switch (action.type) {
    case settingConstants.GET_2FA_REQUEST:
      return { loading: true };
    case settingConstants.GET_2FA_SUCCESS:
      return { loading: false, response: action.payload };
    case settingConstants.GET_2FA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const verify2FAReducer = (state = {}, action) => {
  switch (action.type) {
    case settingConstants.VERIFY_2FA_REQUEST:
      return { loading: true };
    case settingConstants.VERIFY_2FA_SUCCESS:
      return { loading: false, response: action.payload };
    case settingConstants.VERIFY_2FA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
