import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../icons/icons';

function IconBtn({ iconName, className, text }) {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  return (
    <>
      <Icon
        icon={iconName}
        className={className}
        color={isdark ? '#fff' : '#000'}
      />
      {text && <span className="btn__text">{text}</span>}
    </>
  );
}

export default IconBtn;
