import React, { useState } from 'react';

function ProfileNotification() {
  const initalState = {
    Promotions: false,
    Exchange: true,
    Withdrawals: true,
  };
  const [checkedValue, setCheckedValue] = useState(initalState);

  const checkBoxData = [
    {
      name: 'Promotions',
    },
    {
      name: 'Exchange',
    },
    {
      name: 'Withdrawals',
    },
  ];

  const handleOnChange = (e) => {
    setCheckedValue({ ...checkedValue, [e.target.name]: e.target.checked });
  };

  return (
    <>
      <div className="popup__label">Notifications</div>
      <div className="popup__variants">
        {checkBoxData.map((data, idx) => (
          <label className="checkbox" key={idx}>
            <input
              className="checkbox__input"
              type="checkbox"
              name={data.name}
              value={checkedValue[data.name]}
              onChange={(e) => handleOnChange(e)}
              checked={checkedValue[data.name] ? true : false}
            />
            <span className="checkbox__in">
              <span className="checkbox__tick"></span>
              <span className="checkbox__text">{data.name}</span>
            </span>
          </label>
        ))}
      </div>
    </>
  );
}

export default ProfileNotification;
