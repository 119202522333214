import axios from 'axios';
import * as assestsConstants from '../constants/assestsConstant';
import { baseUrl, newsUrl, productionUrl, webSocketUrl } from '../utils/urls';
import ReconnectingWebSocket from 'reconnecting-websocket';

export let ws = new ReconnectingWebSocket(`${webSocketUrl}/livewire`);

export const getAssests = () => async (dispatch) => {
  ws.onopen = () => {
    // on connecting, do nothing but log it to the console
    dispatch({
      type: assestsConstants.GET_ASSETS_DETAILS_WS_REQUEST,
    });
    //console.log('connected');
  };

  ws.onmessage = (evt) => {
    // listen to data sent from the websocket server
    if (evt.data !== 'Connection established with Krypto') {
      const message = JSON.parse(evt.data);
      dispatch({
        type: assestsConstants.GET_ASSETS_DETAILS_WS_SUCCESS,
        payload: message,
      });
    }
  };

  ws.onclose = () => {
    //console.log('disconnected');
    dispatch({
      type: assestsConstants.GET_ASSETS_DETAILS_WS_FAIL,
      // payload: 'Reconnecting....',
    });
    setTimeout(() => {
      dispatch(getAssests());
    }, 1000);
  };

  ws.onerror = (err) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    dispatch({
      type: assestsConstants.GET_ASSETS_DETAILS_WS_FAIL,
      payload: err.message,
    });
    setTimeout(() => {
      dispatch(getAssests());
    }, 1000);
  };
};

export const getCoinsDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(`${baseUrl}/stat/v2/coins/`, {}, config);
    let datas = {
      normalData: data,
      convertedArray: Object.keys(data?.payload).map((key) => {
        return data?.payload[key];
      }),
    };
    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_SUCCESS,
      payload: datas,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_COINS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBannerDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/stat/v2/markets/`,
      {},
      config
    );

    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_BANNER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getNews = (coin) => async (dispatch) => {
  try {
    dispatch({
      type: assestsConstants.GET_NEWS_REQUEST,
    });

    const { data } = await axios.get(
      `${productionUrl}/news/news/?coin=${coin}`
    );

    dispatch({
      type: assestsConstants.GET_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_NEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getChartData =
  (coin, inter = '', from_date = '', to_date = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: assestsConstants.GET_CHART_DATA_REQUEST,
      });

      const { data } = await axios.post(`${baseUrl}/market/kline/`, {
        market: `${coin}inr`,
        params: {
          limit: '10',
          period: inter,
          time_from: from_date,
          time_to: to_date,
        },
      });

      dispatch({
        type: assestsConstants.GET_CHART_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: assestsConstants.GET_CHART_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getWatchList = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}/watchlist/`, config);
    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: assestsConstants.GET_WATCHLIST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addWatchList = async (token, symbol) => {
  //console.log(token, symbol);
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/watchlist/add/`,
      { coin: symbol },
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const removeWatchList = async (token, symbol) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/watchlist/remove/`,
      { coin: symbol },
      config
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const healthCheck = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/`);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};
