import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import NotificationFilter from '../../components/notification/notification-filter/notification-filter';
import SideBarCategories from '../../components/common/sidebar_categories/sidebar_categories';
import NotificationLeftConatiner from '../../components/notification/notification-left-container/notification-left-conatiner';
import HelmetContainer from '../../components/common/helmet/helmet';
import NotificationPopup from '../../components/notification/notification-popup/notification-popup';

function NotificationPage() {
  const [showNotificationPopup, setNotificationPopup] = useState(false);
  const [popUpData, setPopUpData] = useState({});

  const handleOpenPopUp = (data) => {
    setPopUpData(data);
    setNotificationPopup(true);
  };

  return (
    <Layout isNotificationPage={true} showborder={true}>
      <HelmetContainer title="Notification" />
      <div className="page__row">
        <NotificationLeftConatiner handleOpenPopUp={handleOpenPopUp} />
        <div className="page__col page__col_pt100">
          <NotificationFilter />
          <SideBarCategories />
        </div>
      </div>
      <NotificationPopup
        popUpData={popUpData}
        setNotificationPopup={setNotificationPopup}
        showNotificationPopup={showNotificationPopup}
      />
    </Layout>
  );
}

export default NotificationPage;
