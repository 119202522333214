import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BitCoinImage from '../../../assests/img/logo/bitcoin.png';
import useBalance from '../../../utils/useBalance';
import useWebSocketData from '../../../utils/useWebSocketData';
import CustomButton from '../../common/custom-button/custom-button';
import SkeletonContainer from '../../common/skeleton/skeleton';
import { scroller } from 'react-scroll';
import _ from 'lodash';
import useAnalytics from '../../../utils/useAnalytics';
import NotFoundedImage from '../../../assests/img/notfound.png';
import Icon from '../../common/icons/icons';
import { getQueryParams } from '../../../utils/query-helper';

function WalletsAssestsTotal({
  visibleSideBar,
  setVisibleSideBar,
  selectedData,
  setSelectedData,
  remainingItem,
}) {
  const {
    getAssests,
    userGetBalance,
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { loading, error, balance } = userGetBalance;
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const { priceChangePercent, getStatus } = useWebSocketData();
  const [usd, setUsd] = useState(null);
  const [sortedBalance, setSortedBalance] = useState([]);
  const [remainingItemState, setRemainingState] = useState(remainingItem);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { analytics } = useAnalytics();

  const {
    getBalanceToInr,
    getInfoAboutCoins,
    getCurrencyBalances,
    convertINRtoUSD,
    roundUp,
    roundUpWithSymbol,
  } = useBalance();

  useEffect(() => {
    async function initalValue() {
      const value = getCurrencyBalances();
      const result = await convertINRtoUSD(value);
      setUsd(result.amount);
    }
    initalValue();
  }, [assetsData]);

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  function sortByBalance() {
    const newArray = [];
    let removedItem = null;
    let removedItemInRemainingCoin = null;
    balance?.payload.forEach((item) => {
      if (getInfoAboutCoins(item.currency)?.name === 'Rupee') {
        newArray.push({
          inrBalance: item.balance,
          balance: item.balance,
          currency: item.currency,
          locked: item.locked,
        });
      } else {
        newArray.push({
          balance: item.balance,
          inrBalance: getBalanceToInr(item),
          currency: item.currency,
          locked: item.locked,
        });
      }
    });

    const containsInr = newArray?.find((item) => item.currency === 'inr');

    if (containsInr?.currency === 'inr') {
      removedItem = _.remove(newArray, function (o) {
        return o.currency === 'inr';
      });
    }

    if (!containsInr) {
      removedItemInRemainingCoin = _.remove(remainingItem, function (o) {
        return o.symbol === 'inr';
      });
    }
    setRemainingState(remainingItem);

    setRemainingState(remainingItem);

    const sortedArray = _.orderBy(
      newArray,
      function (o) {
        return o.inrBalance;
      },
      ['desc']
    );

    if (removedItem?.length > 0) {
      sortedArray.unshift(removedItem[0]);
      return setSortedBalance(sortedArray);
    }

    if (removedItemInRemainingCoin?.length > 0) {
      let newObj = {
        inrBalance: 0,
        balance: 0,
        currency: 'inr',
      };
      sortedArray.unshift(newObj);
      return setSortedBalance(sortedArray);
    }

    setSortedBalance(sortedArray);
  }

  useEffect(() => {
    sortByBalance();
  }, [balance, remainingItem]);

  useEffect(() => {
    analytics?.track('Clicked coin', {
      symbol: selectedData?.currency,
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [selectedData]);

  useEffect(() => {
    if (searchText.length !== 0) {
      const data = sortedBalance.filter((data) => {
        return (
          getInfoAboutCoins(data.currency)
            ?.name.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          getInfoAboutCoins(data.currency)
            ?.symbol.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      });

      const data2 = remainingItemState.filter((data) => {
        return (
          getInfoAboutCoins(data.symbol)
            ?.name.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          getInfoAboutCoins(data.symbol)
            ?.symbol.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      });
      if (data.length > 0 || data2.length > 0) {
        setResult([...data2, ...data]);
      }
    }
  }, [searchText, sortedBalance, remainingItemState]);

  return (
    <div className="balances">
      {(loading || !assetsData || assetLoading) && (
        <>
          {[1, 2, 3, 4].map((data, idx) => (
            <SkeletonContainer
              key={idx}
              height={'70px'}
              style={{ borderRadius: 20, marginBottom: 10 }}
            />
          ))}
        </>
      )}
      {!loading && !error && assetsData && (
        <>
          <div className="wallets_search__container">
            <div className="balances__title h6">Asset Balances</div>
            <div style={{ position: 'relative' }}>
              <div>
                <input
                  className="actions__input exchange_search__input active"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <Icon
                  icon="search"
                  className="icon"
                  size={15}
                  color="#808191"
                />
              </div>
            </div>
          </div>

          <div className={`balances__table ${visibleSideBar ? 'small' : ''}`}>
            <div className="balances__row balances__row_head">
              {/* <div className="balances__cell"></div> */}
              <div className="balances__cell">ASSET</div>
              <div className="balances__cell">CURRENCY</div>
              <div className="balances__cell">24H MARKET</div>
              {/* <div className="balances__cell">ON ORDERS</div> */}
              <div className="balances__cell">AVAILABLE BALANCE</div>
              <div className="balances__cell">INR</div>
            </div>

            {searchText.length === 0 &&
              sortedBalance &&
              sortedBalance?.map((data, idx) => (
                <div
                  key={idx}
                  className="balances__row"
                  onClick={() => {
                    setVisibleSideBar('total-balance');
                    setSelectedData(data);
                    scrollToSection();
                  }}
                >
                  {/* <div className="balances__cell">
                  <button className="favorite"></button>
                </div> */}
                  <div className="balances__cell">
                    <div className="balances__company">
                      <div className="balances__logo">
                        <img
                          src={getInfoAboutCoins(data.currency)?.logo}
                          alt=""
                        />
                      </div>
                      <div className="balances__text">
                        {getInfoAboutCoins(data.currency)?.name}
                      </div>
                    </div>
                  </div>
                  <div
                    className="balances__cell"
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    <div className="balances__text">{data.currency}</div>
                  </div>
                  <div className="balances__cell">
                    {data.currency !== 'inr' ? (
                      <div className={`status ${getStatus(data.currency)}`}>
                        {priceChangePercent(data.currency)}
                      </div>
                    ) : (
                      <div
                        className="negative"
                        style={{
                          marginLeft: 20,
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                      >
                        <p>Fiat</p>
                      </div>
                    )}
                  </div>
                  {/* <div className="balances__cell">
              <div className="balances__number">19,266.6454898</div>
              <div className="balances__price">$2,134.325</div>
            </div> */}
                  {getInfoAboutCoins(data.currency)?.name !== 'Rupee' ? (
                    <div className="balances__cell">
                      <div className="balances__number">
                        {roundUpWithSymbol(
                          parseFloat(data.balance),
                          data.currency.toLowerCase()
                        ) +
                          ' ' +
                          data.currency.toUpperCase()}
                      </div>
                    </div>
                  ) : (
                    <div className="balances__cell">
                      <div className="balances__number">
                        ₹{' '}
                        {roundUpWithSymbol(
                          parseFloat(data.balance),
                          data.currency.toLowerCase()
                        )}
                      </div>
                      <div className="balances__price">
                        ₹
                        {roundUpWithSymbol(
                          parseFloat(data.balance),
                          data.currency.toLowerCase()
                        )}
                      </div>
                    </div>
                  )}
                  <div className="balances__cell">
                    <div className="balances__number">
                      ₹{' '}
                      {getInfoAboutCoins(data.currency)?.name === 'Rupee'
                        ? roundUpWithSymbol(parseFloat(data.balance), 'inr')
                        : isNaN(getBalanceToInr(data))
                        ? 0
                        : roundUpWithSymbol(getBalanceToInr(data), 'inr')}
                    </div>
                  </div>
                </div>
              ))}

            {searchText.length === 0 &&
              remainingItemState &&
              remainingItemState?.map((data, idx) => {
                const newData = {
                  currency: data.symbol,
                  balance: 0,
                };
                return (
                  <div
                    key={idx}
                    className="balances__row"
                    onClick={() => {
                      setVisibleSideBar('total-balance');
                      setSelectedData(newData);
                      scrollToSection();
                    }}
                  >
                    {/* <div className="balances__cell">
               <button className="favorite"></button>
             </div> */}
                    <div className="balances__cell">
                      <div className="balances__company">
                        <div className="balances__logo">
                          <img
                            src={getInfoAboutCoins(data.symbol)?.logo}
                            alt=""
                          />
                        </div>
                        <div className="balances__text">
                          {getInfoAboutCoins(data.symbol)?.name}
                        </div>
                      </div>
                    </div>
                    <div
                      className="balances__cell"
                      style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                    >
                      {data.symbol}
                    </div>
                    <div className="balances__cell">
                      {data.symbol !== 'inr' ? (
                        <div className={`status ${getStatus(data.symbol)}`}>
                          {priceChangePercent(data.symbol)}
                        </div>
                      ) : (
                        <div
                          className="negative"
                          style={{
                            marginLeft: 20,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                          }}
                        >
                          <p>Fiat</p>
                        </div>
                      )}
                    </div>
                    {/* <div className="balances__cell">
                      <div className="balances__number">19,266.6454898</div>
                      <div className="balances__price">$2,134.325</div>
                    </div> */}
                    {getInfoAboutCoins(data.symbol)?.name !== 'Rupee' ? (
                      <div className="balances__cell">
                        <div className="balances__number">
                          {`0.00` + ' ' + data.symbol?.toUpperCase()}
                        </div>
                      </div>
                    ) : (
                      <div className="balances__cell">
                        <div className="balances__number">₹ 0.00</div>
                        {/* <div className="balances__price">₹ {data.balance}</div> */}
                      </div>
                    )}
                    <div className="balances__cell">
                      <div className="balances__number">₹ 0.00</div>
                    </div>
                  </div>
                );
              })}

            {searchText.length > 0 &&
              result.length > 0 &&
              result?.map((data, idx) => {
                const newData = {
                  currency: data.symbol ? data.symbol : data.currency,
                  balance: data.balance ? data.balance : 0,
                };
                return (
                  <div
                    key={idx}
                    className="balances__row"
                    onClick={() => {
                      setVisibleSideBar('total-balance');
                      setSelectedData(newData);
                      scrollToSection();
                    }}
                  >
                    {/* <div className="balances__cell">
               <button className="favorite"></button>
             </div> */}
                    <div className="balances__cell">
                      <div className="balances__company">
                        <div className="balances__logo">
                          <img
                            src={getInfoAboutCoins(newData.currency)?.logo}
                            alt=""
                          />
                        </div>
                        <div className="balances__text">
                          {getInfoAboutCoins(newData.currency)?.name}
                        </div>
                      </div>
                    </div>
                    <div
                      className="balances__cell"
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      {newData.currency}
                    </div>
                    <div className="balances__cell">
                      {newData.currency !== 'inr' ? (
                        <div
                          className={`status ${getStatus(newData.currency)}`}
                        >
                          {priceChangePercent(newData.currency)}
                        </div>
                      ) : (
                        <div
                          className="negative"
                          style={{
                            marginLeft: 20,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                          }}
                        >
                          <p>Fiat</p>
                        </div>
                      )}
                    </div>
                    {/* <div className="balances__cell">
                <div className="balances__number">19,266.6454898</div>
                <div className="balances__price">$2,134.325</div>
              </div> */}
                    {newData.currency !== 'inr' ? (
                      <div className="balances__cell">
                        {newData.balance != 0 ? (
                          <div className="balances__number">
                            {roundUpWithSymbol(
                              parseFloat(data.balance),
                              newData.currency.toLowerCase()
                            ) +
                              ' ' +
                              newData.currency.toUpperCase()}
                          </div>
                        ) : (
                          <div className="balances__number">
                            {0 + ' ' + newData.currency.toUpperCase()}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="balances__cell">
                        {newData.currency != 0 ? (
                          <div className="balances__number">
                            ₹{' '}
                            {roundUpWithSymbol(
                              parseFloat(data.balance),
                              newData.currency.toLowerCase()
                            )}
                          </div>
                        ) : (
                          <div className="balances__number">
                            ₹ {0 + '' + newData.currency.toLowerCase()}
                          </div>
                        )}
                        {newData.currency != 0 ? (
                          <div className="balances__price">
                            ₹
                            {roundUpWithSymbol(
                              parseFloat(data.balance),
                              newData.currency.toLowerCase()
                            )}
                          </div>
                        ) : (
                          <div className="balances__price">
                            ₹ {0 + '' + newData.currency.toUpperCase()}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="balances__cell">
                      {newData.currency != 0 ? (
                        <div className="balances__number">
                          ₹{' '}
                          {getInfoAboutCoins(newData.currency)?.name === 'Rupee'
                            ? roundUpWithSymbol(parseFloat(data.balance), 'inr')
                            : isNaN(getBalanceToInr(data))
                            ? 0
                            : roundUpWithSymbol(getBalanceToInr(data), 'inr')}
                        </div>
                      ) : (
                        <div className="balances__cell">
                          <div className="balances__number">₹ 0.00</div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="balances__btns">
            <CustomButton title="Load More" />
          </div> */}
        </>
      )}
    </div>
  );
}

export default WalletsAssestsTotal;
