import React from 'react';
import { Helmet } from 'react-helmet-async';
function HelmetContainer({ title, description, content }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={description} content={content} />
    </Helmet>
  );
}
HelmetContainer.defaultProps = {
  title: 'krypto',
  description: 'krypto',
  content: 'krypto web application',
};
export default HelmetContainer;
