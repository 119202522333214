import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { forgotPassword } from '../../../actions/userAction';
import LoadingBtn from '../../common/loading-btn/loading-btn';
import Popup from '../../common/popup/popup';
import AuthButton from '../button/button';
import FormInput from '../form-input/forminput';
import ReCAPTCHA from 'react-google-recaptcha';
import { captchaKey } from '../../../utils/urls';

function ForgotPassword({ showForgotPasswordPopup, setForgotPasswordPopup }) {
  const [email, setEmail] = useState('');
  const [sentTime, setSentTime] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);

  const dispatch = useDispatch();
  const {
    userForgotPassword,
    theme: { isdark },
  } = useSelector((state) => state);
  const { loading, error, response } = userForgotPassword;

  const captchaRef = useRef();

  const handleSubmit = () => {
    if (email === '') {
      return toast.error('Please fill in all the required fields.');
    }
    if (!captchaToken) {
      return toast.error('Check the recaptcha');
    }

    dispatch(forgotPassword(email, captchaToken));
  };

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
  };

  useEffect(() => {
    if (response?.status === 'success') {
      setSentTime((prev) => prev + 1);
      setCaptchaToken(null);
      setBtnDisable(true);
      setTimeout(() => {
        setBtnDisable(false);
      }, 30000);
      return toast.success(response.message);
    }

    if (error) {
      setCaptchaToken(null);
      return toast.error(error);
    }
  }, [error, response]);

  return (
    <>
      <Popup state={showForgotPasswordPopup} setState={setForgotPasswordPopup}>
        <div className="popup__container forgot_password__container">
          <div className="forgot_password__innercontainer">
            <div className="h5 forgot_password__heading">Forgot Password</div>
            <div
              className="h5 forgot_password__heading"
              style={{ fontWeight: 'normal', fontSize: 12 }}
            >
              Do not worry. We got your back <br />
              You've been missed!
            </div>
            <FormInput
              label="Enter the Email Address"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {!captchaToken && (
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <ReCAPTCHA
                  sitekey={captchaKey}
                  ref={captchaRef}
                  theme={isdark ? 'dark' : 'light'}
                  onChange={onChangeCaptcha}
                />
              </div>
            )}
            <div style={{ marginTop: 25 }}>
              {loading ? (
                <LoadingBtn size="full" />
              ) : (
                <AuthButton
                  title={
                    sentTime === 0
                      ? 'Send Password Reset Email'
                      : 'Resend Password Reset Email'
                  }
                  onClick={handleSubmit}
                  disabled={btnDisable}
                />
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default ForgotPassword;
