import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useViewport from '../../../utils/useViewPort';
import Icon from '../../common/icons/icons';
import SkeletonContainer from '../../common/skeleton/skeleton';
import ExchangeDeepChart from './exchange-deep-chart/exchange-deep-chart';
import ExchangeMarketTransactions from './exchange-market-transactions/exchange-market-transactions';
import ExchangePriceChart from './exchange-price-chart/exchange-price-chart';
import { tradeViewBaseSymbol } from '../../../utils/constants';

function ExchangeMarket({ selectedtab, selectedCell, params }) {
  const [selectedGraphType, setGraphType] = useState('Price Chart');
  const { width } = useViewport();
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  const breakpoint = 766;
  // const graphtype = [
  //   {
  //     name: 'Price Chart',
  //   },
  //   {
  //     name: 'Deep Chart',
  //   },
  // ];

  const renderGraph = () => {
    // if (selectedGraphType === 'Price Chart') {
    return (
      <div className='market__item js-tabs-item' style={{ display: 'block' }}>
        <div className='market__legend'>
          <div className='market__variant'>
            {selectedCell?.symbol?.toUpperCase()}/INR
          </div>
          {/* {['O', 'H', 'L', 'C'].map((item, idx) => (
              <div className="market__variant" key={idx}>
                {item} <span className="negative">18432.3204898</span>
              </div>
            ))} */}
        </div>
        <div
          className='market__chart market__chart_price'
          style={{ display: 'block' }}
        >
          {selectedCell?.symbol ? (
            <ExchangePriceChart
              symbol={`${selectedCell?.symbol?.toUpperCase()}${tradeViewBaseSymbol?.toUpperCase()}`}
              themeColor={isdark ? 'dark' : 'light'}
              width={width}
              params={params}
            />
          ) : (
            <SkeletonContainer className='trade_view__chart' />
          )}
        </div>
      </div>
    );
    //   } else {
    //     return (
    //       <div className="market__item js-tabs-item" style={{ display: 'block' }}>
    //         <div className="market__legend">
    //           <div className="market__variant">BTC/USDT</div>
    //           {['O', 'H', 'L', 'C'].map((item, idx) => (
    //             <div className="market__variant" key={idx}>
    //               {item} <span className="positive">18432.3204898</span>
    //             </div>
    //           ))}
    //         </div>
    //         <div
    //           className="market__chart market__chart_deep"
    //           style={{ display: 'block' }}
    //         >
    //           <ExchangeDeepChart />
    //         </div>
    //       </div>
    //     );
    //   }
  };
  return (
    <div className='market'>
      <div
        className={`market__tabs  js-tabs`}
        style={{
          display:
            selectedtab === 'market__tabs' && width < breakpoint
              ? 'block'
              : 'none',
        }}
      >
        {/*<div className="market__head">
          <div className="market__menu">
            {graphtype.map((item, idx) => (
              <button
                key={idx}
                className={`market__link js-tabs-link ${
                  selectedGraphType === item.name ? 'active' : ''
                } `}
                onClick={() => setGraphType(item.name)}
              >
                {item.name}
              </button>
            ))}
          </div> */}
        {/* <div className="market__group">
            <div className="market__actions">
              <a className="market__action" href="/">
                <Icon icon="time-clock" className="icon" />
                <span>30m</span>
              </a>
              <a className="market__action" href="/">
                <Icon icon="ranking" className="icon" />
                <span>Indicator</span>
              </a>
              <a className="market__action" href="/">
                <Icon icon="arrow-up-right-square" className="icon" />
              </a>
            </div>
          </div> 
        </div>*/}
        <div className='market__body'>
          <div className='market__container'>{renderGraph()}</div>
        </div>
      </div>
      <ExchangeMarketTransactions
        selectedtab={selectedtab}
        selectedCell={selectedCell}
      />
    </div>
  );
}

export default ExchangeMarket;
