import React, { useEffect } from 'react';
import { priceTable } from '../../../utils/data';
import PriceTableCell from './price-table-cell/price-table-cell';
import CustomSelect from '../../common/custom-select/custom-select';
import CustomButton from '../../common/custom-button/custom-button';
import { useSelector, useDispatch } from 'react-redux';
import { getAssests, getCoinsDetails } from '../../../actions/assestsAction';
import SkeletonContainer from '../../common/skeleton/skeleton';
import useBalance from '../../../utils/useBalance';
import NotFoundedImg from '../../../assests/img/notfound.png';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function PriceTable({
  filterByTabData,
  showPagination,
  setShowPagination,
  selectedCategory,
  handleWatchList,
}) {
  const dispatch = useDispatch();
  const { error, assetsData } = useSelector((state) => state.getAssests);
  const { analytics } = useAnalytics();
  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    userWatchList: { loading, response: watchListData },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
    if (!assetsData) {
      dispatch(getAssests());
    }
  }, []);

  // const filterData = [
  //   {
  //     label: 'Market Cap',
  //     value: 'Market Cap',
  //   },
  //   {
  //     label: 'A - Z',
  //     value: 'A - Z',
  //   },
  //   {
  //     label: 'Volumes',
  //     value: 'Volumes',
  //   },
  //   {
  //     label: 'Last 24h',
  //     value: 'Last 24h',
  //   },
  //   {
  //     label: 'Price',
  //     value: 'Price',
  //   },
  // ];

  //console.log(watchListData?.payload);

  return (
    <div className="prices">
      <div className="prices__head">
        <div className="prices__title h5">
          Today's <br />
          Cryptocurrency Prices
        </div>
        {/* <div className="price__custom_select">
          <CustomSelect defaultValue={filterData[0]} options={filterData} />
        </div> */}
      </div>
      <div>
        {error && coinsError && <h1>Something went wrong</h1>}
        {(coinsLoading || !assetsData || loading) &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, idx) => (
            <SkeletonContainer
              key={idx}
              height={'70px'}
              style={{ borderRadius: 20, marginBottom: 10 }}
            />
          ))}
      </div>
      {selectedCategory?.value === 'watchlist' &&
      watchListData?.payload?.coins?.coins?.length == 0 &&
      !coinsLoading &&
      assetsData ? (
        <div className="h1" style={{ textAlign: 'center' }}>
          <img src={NotFoundedImg} alt="" style={{ height: 200 }} />
          <div className="h6" style={{ marginTop: 22 }}>
            Empty WatchList
          </div>
        </div>
      ) : (
        <>
          {coinsDetails?.convertedArray &&
            !coinsLoading &&
            assetsData &&
            !loading && (
              <>
                <div className="prices__container">
                  <div className="prices__table">
                    <div className="prices__row prices__row_head">
                      <div className="prices__cell"></div>
                      <div className="prices__cell">token</div>
                      <div className="prices__cell">symbol</div>
                      <div className="prices__cell">price</div>
                      <div className="prices__cell">24h</div>
                      <div className="prices__cell">Current Balance</div>
                    </div>
                    {!filterByTabData
                      ? coinsDetails?.convertedArray
                          .slice(
                            0,
                            showPagination
                              ? 10
                              : coinsDetails?.convertedArray.length
                          )
                          .map((data, idx) => (
                            <PriceTableCell
                              selectedCategory={selectedCategory}
                              data={data}
                              key={idx}
                              handleWatchList={handleWatchList}
                            />
                          ))
                      : filterByTabData
                          .slice(
                            0,
                            showPagination ? 10 : filterByTabData.length
                          )
                          .map((data, idx) => (
                            <PriceTableCell
                              selectedCategory={selectedCategory}
                              data={data}
                              key={idx}
                              handleWatchList={handleWatchList}
                            />
                          ))}
                  </div>
                </div>
                <div className="prices__btns">
                  {!filterByTabData
                    ? coinsDetails?.convertedArray.length > 9 && (
                        <CustomButton
                          title={!showPagination ? 'Show less' : 'Load More'}
                          onClick={() => {
                            setShowPagination((pre) => !pre);
                            analytics?.track('Clicked Load More!', {
                              from: 'Price Page',
                              uid: userInfo?.payload?.uid,
                              keyword: selectedCategory?.label,
                              QueryParams: { ...getQueryParams() },
                              ip: ip,
                            });
                          }}
                        />
                      )
                    : filterByTabData.length > 9 && (
                        <CustomButton
                          title={!showPagination ? 'Show less' : 'Load More'}
                          onClick={() => {
                            setShowPagination((pre) => !pre);
                            analytics?.track('User clicked Load More', {
                              from: 'Price Page',
                              uid: userInfo?.payload?.uid,
                              keyword: selectedCategory?.label,
                              QueryParams: { ...getQueryParams() },
                              ip: ip,
                            });
                          }}
                        />
                      )}
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
}

export default PriceTable;
