import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonContainer from '../../common/skeleton/skeleton';
import useViewPort from '../../../utils/useViewPort';
import { getBannerDetails } from '../../../actions/assestsAction';
import Lottie from 'react-lottie';
import animationDataWhite from '../../../assests/json/kyc-verify-white.json';
import animationDataDark from '../../../assests/json/kyc-verify-dark.json';
import CustomButton from '../../common/custom-button/custom-button';
import { toogleSetting } from '../../../actions/settingAction';
import GuideBanner from '../guide-banner/guide-banner';
import animationDataForDeposit from '../../../assests/json/wallets.json';
import { useHistory, useLocation } from 'react-router';
import BannerImg from '../../../assests/img/static-banner.jpeg';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';
import animationDataForShare from '../../../assests/json/share.json';
import { Link } from 'react-router-dom';

const SliderItemWithLottie = ({
  analytics,
  userInfo,
  animation,
  userData,
  link,
  title,
  description,
  btnTitle,
  open,
}) => {
  const userNameStyle = {
    fontSize: 12,
    letterSpacing: 1,
    marginBottom: 30,
  };
  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { width } = useViewPort();
  return (
    <div className="slider__container">
      <div className="slider__item">
        <div className="slider__wrap">
          <div className="slider__date" style={userNameStyle}>
            Hey {userData?.payload?.name} 👋
          </div>
          <div className="slider__title">{title}</div>
          <div className="slider__info">{description}</div>
          <Link
            to={link}
            className="slider__btn btn btn_white"
            onClick={() => {
              analytics?.track('Deposit button', {
                uid: userInfo?.payload?.uid,
                from: 'Homepage',
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            {btnTitle}
          </Link>
        </div>
        <div
          className={`slider__preview lottie__money ${
            btnTitle !== 'Invite Now' ? 'lottie_money_2' : ''
          }`}
          style={{ left: !open && width > 1169 ? '195px' : '90px' }}
        >
          <Lottie
            options={animation}
            width={btnTitle == 'Invite Now' ? 370 : 300}
          />
        </div>
      </div>
    </div>
  );
};

const Slider = () => {
  const dispatch = useDispatch();
  const { width } = useViewPort();
  const { analytics } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const {
    getBanner,
    networkCheck: { response, loading: nLoading },
    kycViewDetails: {
      loading: kycLoading,
      error: kycError,
      response: kycResponse,
    },
    userDetails: { userData, loading: uLoading },
    userDepositState: {
      state,
      loading: depositStateLoading,
      error: depositStateError,
    },
    theme: { isdark },
    userLogin: { userInfo },
    navbar: { open },
  } = useSelector((state) => state);
  const { loading, error, bannerDetails } = getBanner;

  const widthbreakpoint = 766;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: isdark ? animationDataDark : animationDataWhite,
  };

  const defaultOptionsForShare = {
    loop: true,
    autoplay: true,
    animationData: animationDataForShare,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsForDeposit = {
    loop: true,
    autoplay: true,
    animationData: animationDataForDeposit,
  };

  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  useEffect(() => {
    if (!bannerDetails) {
      dispatch(getBannerDetails());
    }
  }, [dispatch]);

  const sliderData = [
    {
      title: 'Free Crypto for Invites.',
      desc: "Invite your friends to Krypto and you'll both get free crypto like Bitcoin, Ethereum & more",
      animation: defaultOptionsForShare,
      link: '/referral',
      buttonTitle: 'Invite Now',
    },
  ];

  return (
    <div className="slider slider_home">
      {loading || depositStateLoading || nLoading ? (
        <SkeletonContainer
          delay={1}
          width={'100%'}
          height={width <= widthbreakpoint ? '200px' : '400px'}
          style={{ borderRadius: 30 }}
          className="box__shadow"
        />
      ) : (
        <>
          <>
            {!state?.payload?.has_deposited && !depositStateLoading ? (
              <div className="slider__container">
                <Carousel responsive={sliderResponsive}>
                  <SliderItemWithLottie
                    animation={defaultOptionsForDeposit}
                    analytics={analytics}
                    userInfo={userInfo}
                    sliderResponsive={sliderResponsive}
                    userData={userData}
                    open={open}
                    title={'Start your first Investment'}
                    description={
                      'Make your first deposit with Krypto and win exciting crypto rewards for FREE 🎉'
                    }
                    btnTitle={'Deposit'}
                    link={'wallet'}
                  />
                </Carousel>
              </div>
            ) : (
              <>
                {!loading && !error && bannerDetails && (
                  <div className="slider__container">
                    <Carousel responsive={sliderResponsive}>
                      {sliderData.map((item) => {
                        return (
                          <SliderItemWithLottie
                            key={item.title}
                            open={open}
                            animation={item.animation}
                            analytics={analytics}
                            userInfo={userInfo}
                            sliderResponsive={sliderResponsive}
                            userData={userData}
                            title={item.title}
                            description={item.desc}
                            btnTitle={item.buttonTitle}
                            link={item.link}
                          />
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default Slider;
