import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addWatchList,
  getWatchList,
  removeWatchList,
} from '../../../actions/assestsAction';
import WidgetLineChart from '../../../components/home/widgets/widget-line-chart/widget-line-chart';
import { getQueryParams } from '../../../utils/query-helper';
import useAnalytics from '../../../utils/useAnalytics';
import useViewport from '../../../utils/useViewPort';
import useWebSocketData from '../../../utils/useWebSocketData';
import Icon from '../../common/icons/icons';
import SkeletonContainer from '../../common/skeleton/skeleton';

function DetailsWidgetCard({ data, scrollToSection }) {
  const { getStatus, priceChangePercent, lastPrice, checkItInWatchList } =
    useWebSocketData();
  const { analytics } = useAnalytics();
  const location = useLocation();

  const {
    theme: { isdark },
    userWatchList: { loading, response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const [oppositeStar, setOppositeStar] = useState(false);

  const history = useHistory();

  const { width } = useViewport();

  const dispatch = useDispatch();

  const handleWatchList = async (coin) => {
    //console.log(data.symbol, response?.payload?.coins.coins, 'data');
    if (response) {
      const checkdata = response?.payload?.coins.coins?.filter((item) => {
        return item == coin.symbol;
      });
      //console.log(checkdata);
      if (checkdata.length > 0) {
        try {
          const data = await removeWatchList(
            userInfo.token.access,
            coin.symbol
          );
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          analytics?.track('Remove watchlist success', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: coin?.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
        } catch (e) {
          //console.log(e);
          analytics?.track('Remove watchlist failed', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: coin?.symbol,
            reason: e,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.error(e);
        }
      } else {
        try {
          const data = await addWatchList(userInfo.token.access, coin.symbol);
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          analytics?.track('Add watchlist success', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: coin?.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
        } catch (e) {
          analytics?.track('Add watchlist failed', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: coin?.symbol,
            reason: e,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.error(e);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getWatchList(userInfo.token.access));
  }, []);

  return (
    <>
      <div className={`${isdark ? 'dark' : ''}  widgets__box`}>
        <div
          onClick={() => {
            history.push({
              pathname: `/pricedetails/${data.symbol}`,
              search: location.search,
            });
            width < 700 && scrollToSection();
          }}
          className="widget__link"
        >
          <div className="widgets__top">
            <div className="widgets__company">
              <div className="widgets__logo">
                <img src={data.logo} alt="" />
              </div>
              <div className="widgets__details">
                <div className="widgets__category">{data.name}</div>
                <div
                  className="widgets__info"
                  style={{ textTransform: 'uppercase' }}
                >
                  {data.symbol}
                </div>
              </div>
            </div>
            <div className="widgets__group">
              <div className={`status ${getStatus(data.symbol)}`}>
                {priceChangePercent(data.symbol)}
              </div>
              <div className="widgets__price">₹ {lastPrice(data.symbol)}</div>
            </div>
          </div>
        </div>
        {/* <div className="widgets__chart">
          <WidgetLineChart
            color={data.status === 'positive' ? '#3DBAA2' : '#FF7A68'}
          />
        </div> */}
        {loading ? (
          <div className="widgets__favorite">
            <SkeletonContainer
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                marginTop: 5,
                marginLeft: 6,
              }}
            />
          </div>
        ) : (
          <button
            className="widgets__favorite"
            onClick={() => handleWatchList(data)}
          >
            {checkItInWatchList(data) ? (
              <Icon icon="star-fill" className="icon" />
            ) : (
              <Icon icon="star" className="icon" />
            )}
          </button>
        )}
      </div>
    </>
  );
}

export default DetailsWidgetCard;
