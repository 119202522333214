import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

function SkeletonContainer({ ...otherProps }) {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  return (
    <SkeletonTheme
      color={isdark ? '#242731' : '#ededed'}
      highlightColor={isdark ? '#191b20' : 'inherit'}
    >
      <Skeleton {...otherProps} />
    </SkeletonTheme>
  );
}

export default SkeletonContainer;
