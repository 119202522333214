export const GET_REFERRAL_CODE_REQUEST = 'GET_REFERRAL_CODE_REQUEST';
export const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS';
export const GET_REFERRAL_CODE_FAIL = 'GET_REFERRAL_CODE_FAIL';

export const GET_REFERRAL_CODE_EARNING_REQUEST =
  'GET_REFERRAL_CODE_EARNING_REQUEST';
export const GET_REFERRAL_CODE_EARNING_SUCCESS =
  'GET_REFERRAL_CODE_EARNING_SUCCESS';
export const GET_REFERRAL_CODE_EARNING_FAIL = 'GET_REFERRAL_CODE_EARNING_FAIL';

export const GET_SCRATCH_CARD_REQUEST = 'GET_SCRATCH_CARD_REQUEST';
export const GET_SCRATCH_CARD_SUCCESS = 'GET_SCRATCH_CARD_SUCCESS';
export const GET_SCRATCH_CARD_FAIL = 'GET_SCRATCH_CARD_FAIL';

export const GET_TOTAL_REFERRAL_REQUEST = 'GET_TOTAL_REFERRAL_REQUEST';
export const GET_TOTAL_REFERRAL_SUCCESS = 'GET_TOTAL_REFERRAL_SUCCESS';
export const GET_TOTAL_REFERRAL_FAIL = 'GET_TOTAL_REFERRAL_FAIL';
