import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toogleSetting } from '../../actions/settingAction';
import Settings from '../../pages/settings/settings';
import Header from '../header/header';
import Navbar from '../navbar/navbar';
import { useStopwatch } from 'react-timer-hook';
import MotionContainer from '../common/motion-container/motion-container';

function Layout({
  children,
  isExchangePage,
  isNotificationPage,
  showborder,
  isHomePage,
}) {
  const {
    navbar: { open },
    setting: { open: settingOpen },
    userDetails: { userData, loading: uLoading },
    networkCheck: { loading, error, response },
    kycViewDetails: {
      loading: kycLoading,
      error: kycError,
      response: kycResponse,
    },
    userGetBalance: { loading: bLoading, error: bError, balance },
  } = useSelector((state) => state);

  const { seconds, minutes, hours, start, reset, isRunning, pause } =
    useStopwatch({
      autoStart: false,
    });

  // if (seconds !== 0) {
  //   console.log('min', minutes, 'sec', seconds);
  // }

  useEffect(() => {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        // console.log('Browser tab is hidden');
        if (!isRunning) {
          start();
        }
      } else {
        // console.log('Browser tab is visible');
        reset();
        pause();
      }
    });
    if (!document.hidden && minutes >= 10) {
      window.location.reload();
    }
  }, [document.hidden, minutes]);

  return (
    <MotionContainer>
      {/* {response &&
        response.payload.level < 3 &&
        kycResponse?.payload?.verification_state !== 'pending' &&
        kycResponse?.payload?.verification_state !== 'completed' && (
          <div className="top_container">
            <div className="top_notification">
              👋 Hey {userData?.payload?.username}, Welcome to Krypto, complete
              the quick 5 min KYC to start investing and get free crypto rewards{' '}
              <span
                onClick={() => dispatch(toogleSetting({ screenState: 'KYC' }))}
              >
                Complete KYC
              </span>
            </div>
          </div>
        )} */}
      {/* 
      {response && response.payload.level > 3 && balance.payload.length <= 0 && (
        <div className="top_container">
          <div className="top_notification">
            👋 Hey {userData?.payload?.username}, Click deposit button to get
            started
          </div>
        </div>
      )} */}

      <div
        className={`page ${isExchangePage ? 'full' : ''} ${
          !open ? 'wide' : ''
        }`}
      >
        <div className="page__content">
          <Navbar isExchangePage={isExchangePage} />
          <Header
            isHomePage={isHomePage}
            isExchangePage={isExchangePage}
            isNotificationPage={isNotificationPage}
            showborder={showborder}
          />
          {children}
          {settingOpen ? <Settings /> : null}
        </div>
      </div>
    </MotionContainer>
  );
}

export default Layout;
