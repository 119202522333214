import React, { useEffect } from 'react';
import logo from '../../assests/img/logo-sm.svg';
import HeaderSearch from './header-search/header-search';
import HeaderLogo from './header-logo/header-logo';
import HeaderGroup from './header-group/header-group';
import { toogleNavbar } from '../../actions/navbarAction';
import { useDispatch } from 'react-redux';
import HeaderProfile from './header-profile/header-profile';
import CollapseIcon from '../navbar/nav-svgs/collapse';
import { useSelector } from 'react-redux';
import { kycViewDetails } from '../../actions/kycAction';

const Header = ({
  isExchangePage,
  isNotificationPage,
  showborder,
  isHomePage,
}) => {
  const dispatch = useDispatch();
  const {
    userDetails: { userData, loading: uLoading },
    networkCheck: { loading, error, response },
    kycViewDetails: {
      loading: kycLoading,
      error: kycError,
      response: kycResponse,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(kycViewDetails());
  }, []);

  //console.log(kycResponse?.payload?.verification_state);

  return (
    <div className={`header ${showborder ? 'header_border' : ''} `}>
      {isNotificationPage ? '' : <HeaderSearch />}
      <HeaderLogo logo={logo} />
      {/* {response &&
        response.payload.level < 3 &&
        isHomePage &&
        kycResponse?.payload?.verification_state !== 'pending' &&
        kycResponse?.payload?.verification_state !== 'completed' && (
          <div className="kyc_notification">
            <h6>
              👋 Hey {userData?.payload?.username}, Please verify your KYC from
              the Settings tab.This is done to ensure that krypto operates in
              compliance with all the regulation put up by the government at
              present and in the future
            </h6>
          </div>
        )} */}

      <HeaderGroup isExchangePage={isExchangePage} />
      <HeaderProfile />
      <button
        className="header__toggle"
        onClick={() => dispatch(toogleNavbar())}
      >
        <CollapseIcon />
      </button>
    </div>
  );
};

export default Header;
