import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIPAddress } from '../../actions/userAction';
import AuthPreviewImage from '../../components/auth/preview-image/previewimage';
import SignUp from '../../components/auth/sign-up/sign-up';
import HelmetContainer from '../../components/common/helmet/helmet';
import MotionContainer from '../../components/common/motion-container/motion-container';
import ThemeToogler from '../../components/common/theme-toogler/theme-toogler';
import { getQueryParams } from '../../utils/query-helper';
import useAnalytics from '../../utils/useAnalytics';

function SignupPage() {
  const { analytics } = useAnalytics();
  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  useEffect(() => {
    analytics?.page('Sign Up Page', {
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  return (
    <MotionContainer>
      <div className="login">
        <HelmetContainer title="Sign Up" />
        <div className="login__row">
          <AuthPreviewImage
            isSignupPage={true}
            title="Asia's Largest Crypto Payment Network and Exchange"
            // subtitle="UI Design Kit"
          />
          <SignUp />
        </div>
        <ThemeToogler />
      </div>
    </MotionContainer>
  );
}

export default SignupPage;
