import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

function CustomSelect({ defaultValue, options, ...otherProps }) {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#EE7C36' : isdark ? 'white' : 'black',
      background: state.isSelected ? 'transparent' : 'transparent',
    }),
  };
  return (
    <div
      className={`${isdark ? 'dark' : ''}`}
      style={{ fontFamily: 'Poppins' }}
    >
      <Select
        defaultValue={defaultValue}
        styles={customStyles}
        options={options}
        {...otherProps}
        isSearchable={false}
      />
    </div>
  );
}

export default CustomSelect;
