import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CheckImage from '../../../assests/img/check.svg';
import ChequeImage from '../../../assests/img/cheque.svg';
import CoinsImage from '../../../assests/img/coins-1.svg';
import WalletImage from '../../../assests/img/wallet.svg';
import FeeImage from '../../../assests/img/fees.svg';
import Icon from '../../common/icons/icons';
import moment from 'moment';
import IconBtn from '../../common/icon-text/icon-text';
import { cancelOrder } from '../../../actions/exchangeAction';
import { toast } from 'react-toastify';
import { CANCEL_ORDER_CLEANUP } from '../../../constants/exchangeConstant';
import { getOrderData } from '../../../actions/historyAction';
import { getBalance } from '../../../actions/userAction';

function ActivitiesSidebar({
  openState,
  sideBardata,
  handleClose,
  selectedFilterValue,
  setOpenSideBar,
}) {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const dispatch = useDispatch();

  const {
    cancelOrder: {
      response: cancelOrderResponse,
      loading: cancelOrderLoading,
      error: cancelOrderError,
    },
  } = useSelector((state) => state);

  const getCoinsDetails = (symbol) => {
    const data = coinsDetails?.convertedArray.find(
      (item) => item.symbol === symbol
    );
    return data;
  };

  const handleCancelOrder = (id) => {
    dispatch({
      type: CANCEL_ORDER_CLEANUP,
      payload: null,
    });
    if (window.confirm('Are you sure to cancel this order')) {
      dispatch(cancelOrder(id));
      handleClose();
    }
  };

  useEffect(() => {
    if (cancelOrderError) {
      toast.error(cancelOrderError);
    }
    if (cancelOrderResponse) {
      toast.success(cancelOrderResponse.message);
    }
  }, [cancelOrderLoading, cancelOrderResponse, cancelOrderError]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CANCEL_ORDER_CLEANUP,
        payload: null,
      });
    };
  }, []);

  return (
    <div className={`activity__sidebar ${openState ? 'visible' : ''}`}>
      <div className="activity__wrap">
        <button className="activity__close" onClick={handleClose}>
          <Icon icon="close" className="icon" />
        </button>
        <div className="activity__head">
          {selectedFilterValue !== 'Orders' && (
            <div className="activity__logo">
              {selectedFilterValue !== 'Trades' ? (
                <img
                  src={getCoinsDetails(sideBardata?.currency)?.logo}
                  alt=""
                />
              ) : (
                <img
                  src={getCoinsDetails(sideBardata?.fee_currency)?.logo}
                  alt=""
                />
              )}
            </div>
          )}
          <div className="activity__details">
            <div className="activity__info">
              {selectedFilterValue === 'Trades'
                ? getCoinsDetails(sideBardata?.fee_currency)?.name
                : selectedFilterValue === 'Orders'
                ? getCoinsDetails(sideBardata?.market?.replace('inr', ''))?.name
                : getCoinsDetails(sideBardata?.currency)?.name}
            </div>
            <div className="activity__date">
              {moment(sideBardata?.created_at).format(
                'MMMM Do YYYY, h:mm:ss a'
              )}
            </div>
          </div>
        </div>
        <div className="activity__line">
          <div className="activity__money">
            {selectedFilterValue === 'Trades'
              ? sideBardata.total
                ? parseFloat(sideBardata.total)?.toFixed(2)
                : '0.00'
              : selectedFilterValue === 'Orders'
              ? sideBardata.avg_price
                ? parseFloat(sideBardata.avg_price)?.toFixed(2)
                : '0.00'
              : sideBardata.amount
              ? parseFloat(sideBardata.amount)?.toFixed(2)
              : '0.00'}
          </div>
          <div className="activity__currency">
            {selectedFilterValue === 'Trades'
              ? 'INR'
              : selectedFilterValue === 'Orders'
              ? sideBardata?.market?.toUpperCase()
              : sideBardata.currency?.toUpperCase()}
          </div>
        </div>
        {selectedFilterValue === 'Withdraws' && (
          <div className="activity__code word_wrap">
            {sideBardata?.fee_currency === 'inr'
              ? sideBardata.rid
              : sideBardata.rid?.replace('?dt=', '/')}
          </div>
        )}
        {selectedFilterValue === 'Deposits' && (
          <div className="activity__code word_wrap">{sideBardata.tid}</div>
        )}
        <div className="activity__parameters">
          {selectedFilterValue !== 'Trades' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <img src={CheckImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Status</div>
                <div
                  className={`activity__status ${
                    sideBardata.state === 'rejected'
                      ? 'red'
                      : sideBardata.state === 'wait'
                      ? 'yellow'
                      : ''
                  }`}
                >
                  {sideBardata.state?.toUpperCase()}
                </div>
              </div>
            </div>
          )}
          <>
            {selectedFilterValue === 'Trades' && (
              <div className="activity__parameter">
                <div className="activity__preview">
                  <img src={WalletImage} alt="" />
                </div>
                <div className="activity__box">
                  <div className="activity__category">Total</div>
                  <div className="activity__value">
                    {(
                      Number(sideBardata.total) -
                      Number(sideBardata?.fee_amount)
                    )?.toFixed(2)}{' '}
                    INR
                  </div>
                </div>
              </div>
            )}
          </>

          {selectedFilterValue === 'Orders' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <img src={WalletImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Amount</div>
                <div className="activity__value">
                  {sideBardata.origin_volume}{' '}
                  {sideBardata?.market?.replace('inr', '')?.toUpperCase()}
                </div>
              </div>
            </div>
          )}
          {selectedFilterValue === 'Orders' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <img src={CoinsImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Price</div>
                <div className="activity__value">
                  {sideBardata.price
                    ? sideBardata.price
                    : sideBardata?.avg_price}
                </div>
              </div>
            </div>
          )}
          {selectedFilterValue !== 'Orders' && (
            <div className="activity__parameter">
              <div className="activity__preview">
                <img src={CoinsImage} alt="" />
              </div>
              <div className="activity__box">
                <div className="activity__category">Amount</div>
                <div className="activity__value">
                  {sideBardata.amount}{' '}
                  {sideBardata?.market?.replace('inr', '')?.toUpperCase()}
                </div>
              </div>
            </div>
          )}
          <>
            {selectedFilterValue === 'Trades' && (
              <div className="activity__parameter">
                <div className="activity__preview">
                  <img src={FeeImage} alt="" style={{ width: '20px' }} />
                </div>
                <div className="activity__box">
                  <div className="activity__category">TDS Deducted</div>
                  <div className="activity__value">
                    {sideBardata?.fee_amount}{' '}
                    {sideBardata?.fee_currency?.toUpperCase()}
                  </div>
                </div>
              </div>
            )}
          </>
          {selectedFilterValue !== 'Trades' &&
            selectedFilterValue !== 'Orders' && (
              <>
                <div className="activity__parameter">
                  <div className="activity__preview">
                    <img src={WalletImage} alt="" />
                  </div>
                  <div className="activity__box">
                    <div className="activity__category">Address</div>
                    {selectedFilterValue === 'Withdraws' && (
                      <div className="activity__value">
                        {sideBardata?.fee_currency === 'inr'
                          ? sideBardata.rid
                          : sideBardata.rid?.replace('?dt=', '/')}
                      </div>
                    )}
                    {selectedFilterValue === 'Deposits' && (
                      <div
                        className="activity__value"
                        style={{ fontSize: 12, minWidth: '20px' }}
                      >
                        {sideBardata.txid ? sideBardata.txid : 'fiat'}
                      </div>
                    )}
                  </div>
                </div>
                <div className="activity__parameter">
                  <div className="activity__preview">
                    <img src={ChequeImage} alt="" />
                  </div>
                  <div className="activity__box">
                    <div className="activity__category">Transaction ID</div>
                    {selectedFilterValue === 'Withdraws' && (
                      <div className="activity__value">
                        {sideBardata.blockchain_txid
                          ? sideBardata.blockchain_txid
                          : 'fiat'}
                      </div>
                    )}

                    {selectedFilterValue === 'Deposits' && (
                      <div className="activity__value">
                        {sideBardata.tid ? sideBardata.tid : 'fiat'}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          {selectedFilterValue === 'Orders' && sideBardata.state === 'wait' && (
            <div className="card__btns card__btns_mt40">
              <button
                className="card__btn btn btn_orange"
                onClick={() => handleCancelOrder(sideBardata.id)}
              >
                <Icon icon="withdraw" className="icon" />
                <span className="btn__text">Cancel</span>
              </button>
              <button
                className="card__btn btn btn_border"
                onClick={handleClose}
              >
                <IconBtn className="icon" iconName="deposit" text="Close" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivitiesSidebar;
