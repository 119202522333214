import BitCoinImage from '../assests/img/logo/bitcoin.png';
import EthereumImage from '../assests/img/logo/ethereum.png';
import TLogoImage from '../assests/img/logo/logo-t.png';
import RippleImage from '../assests/img/logo/ripple.png';
import UbexImage from '../assests/img/logo/ubex.png';
import SteemImage from '../assests/img/logo/steem.png';
import CoinsBlue from '../assests/img/coins-blue.svg';
import BitcoinBlue from '../assests/img/bitcoin-blue.svg';
import AnalyticsBlue from '../assests/img/analytics-blue.svg';
import MarketBlue from '../assests/img/market-blue.svg';
import CurrencyExchange from '../assests/img/currency-exchange.svg';
import Money from '../assests/img/money.svg';
import MoneyBag from '../assests/img/money-bag.svg';
import PaperSvg from '../assests/img/paper.svg';
import PigSvg from '../assests/img/pig.svg';
import FeaturedPromoImg1 from '../assests/img/promotions-icon-1.png';
import FeaturedPromoImg2 from '../assests/img/promotions-icon-2.png';
import FeaturedPromoImg3 from '../assests/img/promotions-icon-3.png';
import LatestPromoImg1 from '../assests/img/promotions-pic-1.jpg';
import LatestPromoImg2 from '../assests/img/promotions-pic-2.jpg';
import LatestPromoImg3 from '../assests/img/promotions-pic-3.jpg';
import LatestPromoImg4 from '../assests/img/promotions-pic-4.jpg';
import LatestPromoImg5 from '../assests/img/promotions-pic-5.jpg';

export const routes = [
  {
    path: '/',
    name: 'Home',
    active: true,
    icon: 'home',
  },
  {
    path: '/exchange/eth',
    name: 'Exchange',
    active: false,
    icon: 'chart',
  },
  {
    path: '/prices',
    name: 'Prices',
    active: false,
    icon: 'document',
  },
  {
    path: '/wallet',
    name: 'Wallets',
    active: false,
    icon: 'wallet',
  },
  // {
  //   path: '/promotion',
  //   name: 'Promotions',
  //   active: false,
  //   icon: 'discount',
  // },
  {
    path: '/history',
    name: 'History',
    active: false,
    icon: 'activity',
  },
  // {
  //   path: '/notification',
  //   name: 'Notifications',
  //   active: false,
  //   icon: 'notification',
  // },
  // {
  //   path: '/',
  //   name: 'Settings',
  //   active: false,
  //   icon: 'fas fa-cog',
  // },
];

export const languages = [
  {
    emoji: '🇺🇸',
    language: 'English',
    currency: 'USD',
  },
  {
    emoji: '🇨🇳',
    language: '中文',
    currency: 'EUR',
  },
  {
    emoji: '🇪🇸',
    language: 'Española',
    currency: 'JPY',
  },
  {
    emoji: '🇫🇷',
    language: 'Français',
    currency: 'BTC',
  },
  {
    emoji: '🇻🇳',
    language: 'Tiếng Việt',
    currency: 'BTC',
  },
];

export const currency = [
  {
    name: 'USD',
  },
  {
    name: 'EUR',
  },
  {
    name: 'JPY',
  },
  {
    name: 'BTC',
  },
];

export const sliderData = [
  {
    title: 'Elevation Update',
    description:
      ' The Grin blockchain has presented significant technical challenges.',
    date: 'June 2021',
    btnValue: 'Find Out More',
  },
  {
    title: 'Asset Delistings Update',
    description:
      ' The Grin blockchain has presented significant technical challenges.',
    date: 'February 2021',
    btnValue: 'Find Out More',
  },
  {
    title: 'Elevation Update',
    description:
      ' The Grin blockchain has presented significant technical challenges.',
    date: 'July 2021',
    btnValue: 'Find Out More',
  },
];

export const graphData = [
  { title: 'RUB', value: 60, color: '#fac13c' },
  { title: 'EUR', value: 85, color: '#3cabfa' },
  { title: 'USD', value: 77, color: '#14d987' },
];

export const LatestActivitiesDropDown = [
  { name: 'Latest Activities', path: '/' },
  { name: 'New Activities', path: '/' },
  { name: 'Old Activities', path: '/' },
  { name: 'All Activities', path: '/' },
];

export const LatestActivities = [
  {
    category: 'Withdrew USDT',
    date: 'Nov 22, 2020',
    status: 'Complete',
    price: '69.06654889 USDT',
    bg: 'bg-green',
    icon: 'fas fa-money-check-alt',
    image: CurrencyExchange,
  },
  {
    category: 'Exchange XRP',
    date: 'Nov 22, 2020',
    status: 'Complete',
    price: '69.06654889 USDT',
    bg: 'bg-orange',
    icon: 'fas fa-sync-alt',
    image: Money,
  },
  {
    category: 'Withdrew USDT',
    date: 'Nov 22, 2020',
    status: 'Complete',
    price: '69.06654889 USDT',
    bg: 'bg-green',
    icon: 'fas fa-money-check-alt',
    image: MoneyBag,
  },
  {
    category: 'Exchange XRP',
    date: 'Nov 22, 2020',
    status: 'Complete',
    price: '69.06654889 USDT',
    bg: 'bg-purple',
    icon: 'fas fa-sync-alt',
    image: CurrencyExchange,
  },
];

export const assests = [
  {
    category: 'Bitcoin',
    info: 'BTC',
    time: '24H',
    price: '18,245.4 USD',
    status: 'positive',
    count: '+2.73%',
    img: BitCoinImage,
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="#4fbf67"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            300, 40
                            320, 0
                            340, 100
                            360, 100
                            380, 120
                            400, 60
                            420, 70
                            440, 80
                            "
                    />
                  </svg>`,
  },
  {
    category: 'Ethereum',
    info: 'ETH',
    time: '24H',
    price: '598.296 USD',
    status: 'positive',
    count: '+2.73%',
    img: EthereumImage,
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="#4fbf67"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            440, 80
                            "
                    />
                  </svg>`,
  },
  {
    category: 'Steem',
    info: 'STM',
    time: '24H',
    price: '18,245.4 USD',
    status: 'positive',
    count: '+2.73%',
    img: SteemImage,
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="#4fbf67"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            300, 40
                            320, 0
                            340, 100
                            360, 100
                            380, 120
                            400, 60
                            420, 70
                            440, 80
                            "
                    />
                  </svg>`,
  },
  {
    category: 'Tokenbox',
    info: 'TBX',
    time: '24H',
    price: '0.245.4 USD',
    status: 'positive',
    count: '+2.73%',
    img: TLogoImage,
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="#4fbf67"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            300, 40
                            320, 0
                            340, 100
                            360, 100
                            380, 120
                            400, 60
                            420, 70
                            440, 80
                            "
                    />
                  </svg>`,
  },
  {
    category: 'Ripple',
    info: 'XRP',
    time: '24H',
    price: '0.545.4 USD',
    status: 'negative',
    count: '+2.73%',
    img: RippleImage,
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="red"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            300, 40
                            320, 0
                            340, 100
                            360, 100
                            380, 120
                            400, 60
                            420, 70
                            440, 80
                            "
                    />
                  </svg>`,
  },
  {
    category: 'Ubex',
    info: 'UBW',
    time: '24H',
    price: '18,245.4 USD',
    status: 'positive',
    img: UbexImage,
    count: '+2.73%',
    svg: `
    <svg viewBox="0 0 500 100" className="chart">
                    <polyline
                      fill="none"
                      stroke="#4fbf67"
                      stroke-width="2"
                      points="
                            00,120
                            20,60
                            40,80
                            60,20
                            80,80
                            100,80
                            120,60
                            140,100
                            160,90
                            180,80
                            200, 110
                            220, 10
                            240, 70
                            260, 100
                            280, 100
                            300, 40
                            320, 0
                            340, 100
                            360, 100
                            380, 120
                            400, 60
                            420, 70
                            440, 80
                            "
                    />
                  </svg>`,
  },
];

export const loginHistory = [
  {
    device: 'iMac PRO',
    time: '2020-12-02 07:50:18',
    flag: '🇺🇸',
    country: 'USA',
    ip: '123.45.678.987',
  },

  {
    device: 'iMac PRO',
    time: '2020-12-02 07:50:18',
    flag: '🇺🇸',
    country: 'USA',
    ip: '123.45.678.987',
  },
  {
    device: 'iPhone',
    time: '2020-12-02 07:50:18',
    flag: '🇻🇳',
    country: 'VN',
    ip: '123.45.678.987',
  },
  {
    device: 'iMac PRO',
    time: '2020-12-02 07:50:18',
    flag: '🇺🇸',
    country: 'USA',
    ip: '123.45.678.987',
  },
  {
    device: 'iMac PRO',
    time: '2020-12-02 07:50:18',
    flag: '🇺🇸',
    country: 'USA',
    ip: '123.45.678.987',
  },
];

export const exchangeCellDropdownData = [
  {
    image: BitCoinImage,
    currency: 'BTC/USDT',
    info: 'Bitcoin',
  },
  {
    image: EthereumImage,
    currency: 'BTC/USDT',
    info: 'Ethereum',
  },
  {
    image: RippleImage,
    currency: 'BTC/USDT',
    info: 'Ripple',
  },
];

export const exchangeCell = [
  {
    info: '24h Change',
    status: 'positive',
    price: '0.44%',
  },
  {
    info: 'Last prices',
    price: '18372.595198 USDT',
  },
  {
    info: '24h Low',
    price: '18372.595198 USDT',
  },
  {
    info: '24h High',
    price: '18372.595198 USDT',
  },
  {
    info: 'Volume',
    price: '18372.595198 USDT',
  },
];

export const orderUpTable = [
  {
    price: ' 18446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '100%',
  },
  {
    price: ' 18446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '40%',
  },
  {
    price: ' 28446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '50%',
  },
  {
    price: ' 38446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '60%',
  },

  {
    price: ' 48446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '70%',
  },
  {
    price: ' 18446.62230935',
    amount: '5.43268623',
    total: '5.43268623',
    percentage: '30%',
  },
];

export const priceTable = [
  {
    text: 'Bitcoin',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'positive',
    percentage: '+2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: BitCoinImage,
  },
  {
    text: 'Ethereum',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'negative',
    percentage: '-2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: EthereumImage,
  },
  {
    text: 'Steem',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'positive',
    percentage: '+2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: SteemImage,
  },
  {
    text: 'Ripple',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'positive',
    percentage: '+2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: RippleImage,
  },
  {
    text: 'Tokenbox',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'positive',
    percentage: '+2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: TLogoImage,
  },
  {
    text: 'Ubex',
    symbol: 'BTC',
    price: '$19,266.6454898',
    status: 'positive',
    percentage: '+2.05%',
    market_cap: '$357,466,449,337',
    volume: '$49,046,886,271',
    img: UbexImage,
  },
];

export const detailBox = [
  {
    category: 'Market cap Supply',
    price: '18,554,381 BTC',
    img: MarketBlue,
  },
  {
    category: 'Volume (24h)',
    price: '18,554,381 BTC',
    img: AnalyticsBlue,
  },
  {
    category: 'Circulating Supply',
    price: '18,554,381 BTC',
    img: CoinsBlue,
  },
  {
    category: 'Total Supply',
    price: '18,554,381 BTC',
    img: BitcoinBlue,
  },
];

export const Notification = [
  {
    bg: 'bg-green-gradient',
    title: 'Wrapped Bitcoin is now listed on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    time: '35m ago',
    readed: false,
  },
  {
    bg: 'bg-purple-gradient',
    title: 'Airdrop BCHA - 0.25118470 Your airdrop for Nov 15, 2020.',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PigSvg,
    time: '35m ago',
    readed: false,
  },
  {
    bg: 'bg-green-gradient',
    title: 'CyberVeinToken is Now Available on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    readed: false,
    time: '35m ago',
  },
  {
    bg: 'bg-orange-gradient',
    title: 'Wrapped Bitcoin is now listed on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    readed: false,
    time: '35m ago',
  },
  {
    bg: 'bg-pink-gradient',
    title: 'Wrapped Bitcoin is now listed on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    readed: true,
    time: '35m ago',
  },
  {
    bg: 'bg-purple-gradient',
    title: 'Wrapped Bitcoin is now listed on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    readed: false,
    time: '35m ago',
  },
  {
    bg: 'bg-green-gradient',
    title: 'Wrapped Bitcoin is now listed on Krypto Exchange',
    text: "With our newest listing, we’re welcoming Wrapped Bitcoin (wBTC) to our DeFi Innovation Zone! You can now deposit.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    status: 'bg-blue-light',
    img: PaperSvg,
    readed: false,
    time: '35m ago',
  },
];
export const statusData = [
  {
    label: 'All Assets',
    value: 'core_assets',
  },
  {
    label: 'Top Gainers',
    value: 'top_gainers',
  },
  {
    label: 'Top Losers',
    value: 'top_losers',
  },
];

export const sideCategories = [
  {
    name: 'Top Gainer',
  },
  {
    name: 'Top Looser',
  },
];

export const notificationTags = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Promotion',
    label: 'Promotion',
  },
  {
    value: 'Exchange',
    label: 'Exchange',
  },
];

export const PromotionSliderData = [
  {
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    description:
      'To celebrate our new multi-chain deposit and withdrawal support for Bitcoin on TRON (BTCTRON), we’re beginning a 30-day net deposit…',
    btnValue: 'Earn Crypto',
  },
  {
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    description:
      'To celebrate our new multi-chain deposit and withdrawal support for Bitcoin on TRON (BTCTRON), we’re beginning a 30-day net deposit…',
    btnValue: 'Earn Crypto',
  },
  {
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    description:
      'To celebrate our new multi-chain deposit and withdrawal support for Bitcoin on TRON (BTCTRON), we’re beginning a 30-day net deposit…',
    btnValue: 'Earn Crypto',
  },
  {
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    description:
      'To celebrate our new multi-chain deposit and withdrawal support for Bitcoin on TRON (BTCTRON), we’re beginning a 30-day net deposit…',
    btnValue: 'Earn Crypto',
  },
];

export const FeaturedPromotions = [
  {
    img: FeaturedPromoImg1,
    date: 'Oct 26th - Nov 25th',
    info: 'BTC x TRON Net Deposit Campaign',
    id: 1,
  },
  {
    img: FeaturedPromoImg2,
    date: 'Oct 26th - Nov 25th',
    info: 'BTC x TRON Net Deposit Campaign',
    id: 2,
  },
  {
    img: FeaturedPromoImg3,
    date: 'Oct 26th - Nov 25th',
    info: 'BTC x TRON Net Deposit Campaign',
    id: 3,
  },
];

export const LatestPromotionData = [
  {
    status: 'color-green',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg1,
    id: 1,
  },
  {
    status: 'color-green',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg2,
    id: 2,
  },
  {
    status: 'color-green',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg3,
    id: 3,
  },
  {
    status: 'color-red',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg4,
    id: 4,
  },
  {
    status: 'color-green',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg5,
    id: 5,
  },
  {
    status: 'color-green',
    date: 'Oct 26th - Nov 25th',
    title: 'Bitcoin x TRON Net Deposit Campaign',
    btnValue: 'Deposit Bitcoin',
    img: LatestPromoImg1,
    id: 6,
  },
];

export const FeatureDropDownData = [
  {
    name: 'Feature Promotion',
  },
  {
    name: 'All Promotion',
  },
  {
    name: 'New Promotion',
  },
];

export const currenyOptions = [
  { value: 'inr', label: 'INR' },
  { value: 'usd', label: 'USD' },
  { value: 'eur', label: 'EUR' },
  { value: 'sgd', label: 'SGD' },
  { value: 'cad', label: 'CAD' },
];

export const WithDrawAssets = [
  {
    label: 'Enabled $1,000,000 USD/day',
    value: 'Enabled $1,000,000 USD/day',
  },
  {
    label: 'Disable $1,000,000 USD/day',
    value: 'Disable $1,000,000 USD/day',
  },
];

export const ActivitieshistoryData = [
  {
    name: 'Withdraw BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: BitCoinImage,
    id: 1,
  },
  {
    name: 'Deposited BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: TLogoImage,
    id: 2,
  },
  {
    name: 'Withdraw BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: TLogoImage,
    id: 3,
  },
  {
    name: 'Deposited BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: UbexImage,
    id: 4,
  },
  {
    name: 'Withdraw BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: RippleImage,
    id: 5,
  },
  {
    name: 'Withdraw BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: BitCoinImage,
    id: 6,
  },
  {
    name: 'Withdraw BTC',
    date: 'Jan 17th, 2020 10:56:41',
    priceAndSymbol: '0.12340000 USDT',
    price: '0.12340000',
    symbol: 'USDT',
    address: 'Zja7BQo782QanKv4so',
    transaction: 'JHAEIF6374NXY3484748949',
    logo: UbexImage,
    id: 7,
  },
];
