import React, { useEffect, useState } from 'react';
import DetailsChart from '../../../components/price-details/details-chart/details-chart';
import DetailsTop from '../../../components/price-details/details-top/details-top';
import { useSelector } from 'react-redux';
import useViewport from '../../../utils/useViewPort';
import { detailBox } from '../../../utils/data';
import DetailsBox from '../../../components/price-details/details-box/details-box';
import DetailsAbout from '../../../components/price-details/details-about/details-about';
import DetailsButton from '../../../components/price-details/details-btn/details-btn';
import CoinsBlue from '../../../assests/img/coins-blue.svg';
import BitcoinBlue from '../../../assests/img/bitcoin-blue.svg';
import AnalyticsBlue from '../../../assests/img/analytics-blue.svg';
import MarketBlue from '../../../assests/img/market-blue.svg';
import useWebSocketData from '../../../utils/useWebSocketData';
import SkeletonContainer from '../../common/skeleton/skeleton';
import useBalance from '../../../utils/useBalance';
import { useHistory, useLocation } from 'react-router';
import InvestedIcon from '../../../assests/img/money.png';
import BuyingPriceIcon from '../../../assests/img/tag.png';
import CurrentIcon from '../../../assests/img/piggy-bank.png';
import LowPriceIcon from '../../../assests/img/low-price.png';
import HighPriceIcon from '../../../assests/img/price-high.png';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function DetailsContainer({
  coinDetails,
  selectedCell,
  setSelectedCell,
  symbol,
}) {
  const { width: screenwidth } = useViewport();
  const { getHigh, getLow, lastPrice, priceChangePercent, getStatus } =
    useWebSocketData();
  const [investedData, setInvestedData] = useState(null);
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');

  const history = useHistory();

  const {
    navbar: { open },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const {
    loading: chartLoading,
    error: chartError,
    chartData,
  } = useSelector((state) => state.getChartData);
  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);

  const { formatINR, getInvestedSingleCoin, roundUpWithSymbol, roundUp } =
    useBalance();

  const { analytics } = useAnalytics();
  const location = useLocation();

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { assetsData } = useSelector((state) => state.getAssests);

  const chartWidth = () => {
    if (screenwidth < 620) {
      return '100%';
    } else {
      if (!open) {
        return '720px';
      } else {
        return '550px';
      }
    }
  };

  const findUserHaveThisCoin = () => {
    //console.log('called');
    const isContains = balance?.payload?.find((item) => {
      //console.log(item.currency, symbol, 'vv');
      return item.currency == symbol;
    });
    setInvestedData(isContains);
  };

  useEffect(() => {
    findUserHaveThisCoin();
  }, [symbol, balance]);

  const getCryptotoINR = () => {
    if (investedData) {
      const value =
        lastPrice(investedData.currency) * parseFloat(investedData.balance);
      return roundUpWithSymbol(parseFloat(value), 'inr');
    }
  };

  function percentage(partialValue, totalValue) {
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(partialValue, totalValue, 'new');
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(coinsDetails?.convertedArray);
    } else {
      const data = coinsDetails.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setResult(data);
    }
  }, [searchText, coinsDetails?.convertedArray]);

  return (
    <div className="details">
      <div className="details__container">
        {!coinsLoading && coinsDetails?.convertedArray && assetsData && (
          <>
            <DetailsTop
              coinDetails={coinDetails}
              selectedCell={selectedCell}
              setSelectedCell={setSelectedCell}
              symbol={symbol}
              result={result}
              setSearchText={setSearchText}
              searchText={searchText}
            />
            {coinDetails?.name !== 'Rupee' && (
              <div className="details__flex">
                <div className="details__money">
                  {'₹ ' + formatINR(lastPrice(coinDetails?.symbol))}
                </div>
                <div
                  className={`details__status ${
                    getStatus(coinDetails?.symbol) === 'positive'
                      ? 'color-green'
                      : 'color-red'
                  } `}
                >
                  {priceChangePercent(coinDetails?.symbol)}
                </div>
              </div>
            )}
            {chartLoading && (
              <SkeletonContainer width={'100%'} height="300px" />
            )}
            {!chartLoading &&
              !chartError &&
              chartData &&
              chartData.payload.length !== 0 && (
                <div
                  style={{
                    width: chartWidth(),
                    marginRight: 10,
                    padding: '20px',
                  }}
                >
                  <DetailsChart
                    chartData={chartData}
                    color={
                      getStatus(coinDetails?.symbol) === 'positive'
                        ? '#4fbf67'
                        : '#ff6628'
                    }
                  />
                </div>
              )}
            {coinDetails?.name !== 'Rupee' && (
              <div className="details__list">
                {investedData && (
                  <>
                    <DetailsBox
                      img={InvestedIcon}
                      hasBalance={true}
                      title="Invested"
                      subTitle={`Return ${
                        investedData.balance == 0.0 || getCryptotoINR() === '0'
                          ? `0%`
                          : percentage(
                              getInvestedSingleCoin(
                                investedData.currency,
                                investedData.balance
                              ),
                              getCryptotoINR()
                            )
                      }`}
                      subTitleStatus={`${
                        parseFloat(getCryptotoINR()) <
                        parseFloat(
                          getInvestedSingleCoin(
                            investedData.currency,
                            investedData.balance
                          )
                        )
                          ? 'negative'
                          : 'positive'
                      }`}
                      value={
                        '₹ ' +
                        getInvestedSingleCoin(
                          investedData.currency,
                          investedData.balance
                        )
                      }
                      description="Total investment made in this asset (In Rupees) "
                    />
                    <DetailsBox
                      img={CurrentIcon}
                      title="Current"
                      hasBalance={true}
                      subTitle={` Total Return ${
                        parseFloat(getCryptotoINR()) <
                        parseFloat(
                          getInvestedSingleCoin(
                            investedData.currency,
                            investedData.balance
                          )
                        )
                          ? '₹ '
                          : '₹ +'
                      }${roundUp(
                        getCryptotoINR() -
                          getInvestedSingleCoin(
                            investedData.currency,
                            investedData.balance
                          )
                      )}`}
                      subTitleStatus={`${
                        parseFloat(getCryptotoINR()) <
                        parseFloat(
                          getInvestedSingleCoin(
                            investedData.currency,
                            investedData.balance
                          )
                        )
                          ? 'negative'
                          : 'positive'
                      }`}
                      value={'₹ ' + formatINR(getCryptotoINR())}
                      description="Present value of this asset held in your wallet (In Rupees)"
                    />
                  </>
                )}
                <DetailsBox
                  img={MarketBlue}
                  title="24Hr Change"
                  value={priceChangePercent(coinDetails?.symbol)}
                  color={getStatus(coinDetails?.symbol)}
                  description="Difference between the current price and the price 24 hours ago (In Rupees)"
                />
                <DetailsBox
                  img={HighPriceIcon}
                  title="24Hr High"
                  value={'₹ ' + formatINR(getHigh(coinDetails?.symbol))}
                  description="Highest price the coin has reached in the past 24 hours (In Rupees)"
                />
                <DetailsBox
                  img={BuyingPriceIcon}
                  title="Current Buying Price"
                  value={'₹ ' + formatINR(lastPrice(coinDetails?.symbol))}
                  description="The present buying price of the coin (In Rupees)"
                />
                <DetailsBox
                  img={LowPriceIcon}
                  title="24Hr Low"
                  value={'₹ ' + formatINR(getLow(coinDetails?.symbol))}
                  description="Lowest price the coin has reached in the past 24 hours (In Rupees)"
                />
              </div>
            )}
            <DetailsAbout coinDetails={coinDetails} />
            <DetailsButton
              title="Buy & Sell"
              onClick={() => {
                history.push({
                  pathname: `/exchange/${coinDetails?.symbol}`,
                  search: location.search,
                });
                analytics?.track('Clicked buy & sell button', {
                  from: 'Price details',
                  uid: userInfo?.payload?.uid,
                  symbol: selectedCell?.symbol,
                  QueryParams: { ...getQueryParams() },
                  ip: ip,
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DetailsContainer;
