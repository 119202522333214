import { convert } from 'current-currency';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import useWebSocketData from './useWebSocketData';

function useBalance() {
  const { getAssests, userGetBalance, getPortfolio } = useSelector(
    (state) => state
  );
  const { loading, error, balance } = userGetBalance;
  const { lastPrice } = useWebSocketData();
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    response: getPortfolioResponse,
  } = getPortfolio;

  const getInfoAboutCoins = (currency) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const data = convertedArray.find((data) => data.symbol === currency);
      return data;
    }
  };

  const convertINRtoUSD = async (value) => {
    const data = await convert('INR', value, 'USD');
    return data;
  };

  const roundUp = (number) => {
    var rounded = Math.round((number + Number.EPSILON) * 100) / 100;
    return rounded;
  };

  const roundUpWithSymbol = (number, symbol) => {
    if (coinsDetails?.convertedArray) {
      const { convertedArray } = coinsDetails;
      const info = convertedArray.find((item) => item.symbol === symbol);
      let rounded = number.toFixed(parseInt(info.decimal));
      return rounded;
    }
  };

  function getCurrencyBalances() {
    let values = 0;
    if (!loading && !error) {
      balance?.payload.forEach((data) => {
        if (data.currency === 'inr') {
          values = values + parseFloat(data.balance);
        } else if (!assetLoading && !assetError && assetsData) {
          let str = data.currency + 'inr';
          let value =
            parseFloat(assetsData[str].last) * parseFloat(data.balance);
          values = values + parseFloat(value);
        }
      });
    }
    return roundUpWithSymbol(values, 'inr');
  }
  const formatINR = (num) => {
    if (num) {
      var x = num;
      x = x.toString();
      var afterPoint = '';
      if (x.indexOf('.') > 0)
        afterPoint = x.substring(x.indexOf('.'), x.length);
      x = Math.floor(x);
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '') lastThree = ',' + lastThree;
      var res =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
        lastThree +
        afterPoint;

      return res;
    }
  };

  const getBalanceToInr = (data) => {
    if (assetsData) {
      let str = data.currency + 'inr';
      let value = parseFloat(assetsData[str]?.last) * parseFloat(data.balance);
      return value;
    }
  };

  function getChartData() {
    const chartData = [];
    balance?.payload.forEach((data) => {
      if (data.currency === 'inr') {
        chartData.push({
          currency: data.currency,
          balance: parseFloat(data.balance),
        });
      } else if (!assetLoading && !assetError && assetsData) {
        let str = data.currency + 'inr';
        let value = parseFloat(assetsData[str].last) * parseFloat(data.balance);
        chartData.push({ currency: data.currency, balance: value });
      }
    });
    const chartValue = _.orderBy(
      chartData,
      function (o) {
        return o?.balance;
      },
      'desc'
    );
    return chartValue;
  }

  function getInvestedPrice() {
    let invested = 0;
    if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
      getPortfolioResponse?.payload.forEach((item) => {
        balance.payload.forEach((balance) => {
          if (`${balance.currency}inr` === item.trading_pair) {
            invested =
              parseFloat(item.average_buy_price) * parseFloat(balance.balance) +
              invested;
          }
        });
      });
      return invested || 0;
    }
  }

  function getInvestedSingleCoin(symbol, amount) {
    let invested = 0;
    if (!getPortfolioLoading && !getPortfolioError && !loading && !error) {
      getPortfolioResponse?.payload.forEach((item) => {
        if (`${symbol}inr` === item.trading_pair) {
          invested = parseFloat(item.average_buy_price) * parseFloat(amount);
        }
      });
      return invested.toFixed(2) || 0;
    }
  }

  function getSingleCoinsBalanceInINR(symbol) {
    let value = 0;
    if (assetsData && balance) {
      balance.payload.forEach((balance) => {
        if (balance.currency == symbol) {
          const price = assetsData[symbol + 'inr']?.last;
          value = parseFloat(balance.balance) * parseFloat(price);
        }
      });
      return value > 0 ? formatINR(value.toFixed(2)) : '0.00';
    }
  }

  function getINRinBalance() {
    if (!loading && !error) {
      if (balance?.payload?.length == 0) {
        return 0.0;
      }
      const inrBalance = balance?.payload?.find(
        (item) => item.currency === 'inr'
      );
      if (inrBalance) {
        return Number(inrBalance?.balance) || 0;
      } else {
        return 0.0;
      }
    }
  }

  function isDecimal(num) {
    return !!(num % 1);
  }

  function toFixedTrunc(x, n) {
    if (x && n) {
      if (isDecimal(x)) {
        const v = (typeof x === 'string' ? x : x?.toString())?.split('.');
        if (n <= 0) return v[0];
        let f = v ? v[1] : '';
        if (f?.length > n) return `${v[0]}.${f?.substr(0, n)}`;
        while (f?.length < n) f += '0';
        return `${v[0]}.${f}`;
      } else {
        return x;
      }
    }
  }

  function getAvgPriceBalance(symbol) {
    if (!getPortfolioLoading && !getPortfolioError) {
      let isthere = getPortfolioResponse?.payload.find(
        (item) => item.trading_pair === `${symbol}inr`
      );
      return isthere?.average_buy_price || null;
    }
  }

  return {
    getInvestedSingleCoin,
    getChartData,
    getINRinBalance,
    roundUpWithSymbol,
    getBalanceToInr,
    getInfoAboutCoins,
    convertINRtoUSD,
    getCurrencyBalances,
    roundUp,
    formatINR,
    getSingleCoinsBalanceInINR,
    getInvestedPrice,
    toFixedTrunc,
    isDecimal,
    getAvgPriceBalance,
  };
}

export default useBalance;
