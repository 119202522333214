import axios from 'axios';
import * as historyConstants from '../constants/historyConstant';
import { baseUrl, productionUrl } from '../utils/urls';

const PAGE_LIMIT = 10;

export const getOrderData =
  (page = 1, pending = false) =>
  async (dispatch, getState) => {
    let body = {
      params: {
        limit: PAGE_LIMIT,
        page: page,
      },
    };
    if (pending) {
      body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
          state: 'wait',
        },
      };
    }

    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_ORDERDATA_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/getorders/`,
        body,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_ORDERDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_ORDERDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getWithdrawsData =
  (page = 1, currency = false) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (currency) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            currency: currency,
          },
        };
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/getwithdraws/`,
        body,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_WITHDRAWDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDepositsData =
  (page = 1, currency = false) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      let body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      if (currency) {
        body = {
          params: {
            limit: PAGE_LIMIT,
            page: page,
            currency: currency,
          },
        };
      }

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/getdeposits/`,
        body,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_DEPOSITSDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTradesData =
  (page = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      const body = {
        params: {
          limit: PAGE_LIMIT,
          page: page,
        },
      };
      dispatch({
        type: historyConstants.GET_TRADEDATA_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/gettrades/`,
        body,
        config
      );
      //@todo want to connect API
      dispatch({
        type: historyConstants.GET_TRADEDATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: historyConstants.GET_TRADEDATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const exportDataByYear = async (token, email, fi_year) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    const body = {
      email,
      fi_year,
    };
    const { data } = await axios.post(`${baseUrl}/user/tdsdata/`, body, config);
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
