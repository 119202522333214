export const SEND_OTP_KYC_REQUEST = 'SEND_OTP_KYC_REQUEST';
export const SEND_OTP_KYC_SUCCESS = 'SEND_OTP_KYC_SUCCESS';
export const SEND_OTP_KYC_FAIL = 'SEND_OTP_KYC_FAIL';

export const VERIFY_OTP_KYC_REQUEST = 'SEND_OTP_KYC_REQUEST';
export const VERIFY_OTP_KYC_SUCCESS = 'VERIFY_OTP_KYC_SUCCESS';
export const VERIFY_OTP_KYC_FAIL = 'VERIFY_OTP_KYC_FAIL';

export const ADD_PERSONAL_DETAILS_KYC_REQUEST =
  'ADD_PERSONAL_DETAILS_KYC_REQUEST';
export const ADD_PERSONAL_DETAILS_KYC_SUCCESS =
  'ADD_PERSONAL_DETAILS_KYC_SUCCESS';
export const ADD_PERSONAL_DETAILS_KYC_FAIL = 'ADD_PERSONAL_DETAILS_KYC_FAIL';

export const NETWORK_CHECK_KYC_REQUEST = 'NETWORK_CHECK_REQUEST';
export const NETWORK_CHECK_KYC_SUCCESS = 'NETWORK_CHECK_KYC_SUCCESS';
export const NETWORK_CHECK_KYC_FAIL = 'NETWORK_CHECK_KYC_FAIL';

export const CLEANUP_KYC_DOC = 'CLEANUP_KYC_DOC';

export const KYC_VIEW_DETAILS_REQUEST = 'KYC_VIEW_DETAILS_REQUEST';
export const KYC_VIEW_DETAILS_SUCCESS = 'KYC_VIEW_DETAILS_SUCCESS';
export const KYC_VIEW_DETAILS_FAIL = 'KYC_VIEW_DETAILS_FAIL';
