import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useViewport from '../../../utils/useViewPort';
import CustomSelect from '../../common/custom-select/custom-select';
import { createOrder } from '../../../actions/exchangeAction';
import { toast } from 'react-toastify';
import useWebSocketData from '../../../utils/useWebSocketData';
import { CREATE_ORDER_CLEANUP } from '../../../constants/exchangeConstant';
import LoadingBtn from '../../common/loading-btn/loading-btn';
import useBalance from '../../../utils/useBalance';
import { getBalance } from '../../../actions/userAction';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function ExchangeAction({ selectedtab, setSelectedCell, selectedCell }) {
  const [activetab, setActiveTab] = useState('Buy');
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [getAmount, setGetAmount] = useState(0);
  const [getlimit, setGetLimit] = useState(0);
  const [amountININR, setAmountININR] = useState(0);
  const [amountINCoins, setAmountINCoins] = useState(0);
  const [btnDisbale, setbtnDisable] = useState(false);
  const [investedData, setInvestedData] = useState(null);
  const [inrBalance, setINRBalance] = useState(null);
  const { width } = useViewport();
  const breakpoint = 766;
  const dispatch = useDispatch();
  const [pricePerCoinsInBuy, setPricePerCoinsInBuy] = useState('');
  const [pricePerCoinsInSell, setPricePerCoinsInSell] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [tds, setTds] = useState('');
  const inputRef = useRef();
  const { analytics } = useAnalytics();

  const [options] = useState([
    { value: 'limit', label: 'Limit' },
    { value: 'market', label: 'Market' },
  ]);

  const perValue = ['25', '50', '75', '100'];

  const { loading, error, orderDetails } = useSelector(
    (state) => state.createOrder
  );

  const {
    networkCheck: { loading: nloading, error: nError, response: nResponse },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    loading: balanceLoading,
    error: balanceError,
    balance,
  } = useSelector((state) => state.userGetBalance);

  const { lastPrice } = useWebSocketData();
  const { getINRinBalance, formatINR, toFixedTrunc } = useBalance();
  const {
    getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
  } = useSelector((state) => state);

  useEffect(() => {
    if (orderDetails) {
      toast.success(orderDetails?.message);
      analytics?.track(`Order created successfully!`, {
        from: 'Exchange page',
        symbol: selectedCell.symbol,
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      resetForm();
      dispatch(getBalance());
    }
    if (error) {
      analytics?.track(`Order creation failed!`, {
        from: 'Exchange page',
        symbol: selectedCell.symbol,
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }

    return () => {
      dispatch({
        type: CREATE_ORDER_CLEANUP,
        payload: null,
      });
    };
  }, [loading, error, orderDetails]);

  useEffect(() => {
    if (selectedCell.symbol !== 'inr' && !loading && orderBookData) {
      setOrderData(orderBookData[selectedCell?.symbol + 'inr']);
    }
    getAsksValue();
    getBidsValue();
  }, [selectedCell.symbol, loading, orderBookData, orderData]);

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const getAsksValue = () => {
    if (orderData) {
      const newOrderData = orderData;
      const data = newOrderData?.asks
        ?.sort((a, b) => Number(a[0]) - Number(b[0]))
        ?.slice(0, 5);
      if (data[0]) {
        setPricePerCoinsInBuy(data[0][0]);
      }
    }
  };

  const getBidsValue = () => {
    if (orderData) {
      const newOrderData = orderData;
      const data = newOrderData?.bids
        ?.sort((a, b) => Number(b[0]) - Number(a[0]))
        ?.slice(0, 5);
      if (data[0]) {
        setPricePerCoinsInSell(data[0][0]);
      }
    }
  };

  const handleBuy = (e, side) => {
    analytics?.track(`Order button clicked!`, {
      from: 'Exchange page',
      symbol: selectedCell.symbol,
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    e.preventDefault();
    if (
      selectedMarket.value === 'market' &&
      getAmount * lastPrice(selectedCell.symbol) <= 0
    ) {
      return toast.error('Amount should not be 0');
    }

    if (selectedMarket.value === 'limit' && getAmount * getlimit <= 0) {
      return toast.error('Amount should not be 0');
    }
    if (
      getAmount >= Number(selectedCell.minimum_order) &&
      Number(selectedCell.maximum_order) >= getAmount
    ) {
      // if (nResponse && nResponse.payload.level >= 3) {
      setbtnDisable(true);
      setTimeout(() => {
        setbtnDisable(false);
      }, 10000);
      dispatch(
        createOrder(
          `${selectedCell.symbol}inr`,
          side,
          Number(getAmount),
          selectedMarket?.value,
          // selectedMarket.value === 'market'
          //   ? getAmount * lastPrice(selectedCell.symbol)
          //   : getAmount * getlimit
          selectedMarket.value === 'market'
            ? Number(lastPrice(selectedCell.symbol))
            : Number(getlimit)
        )
      );
      //console.log(loading, error, orderDetails);
      // } else {
      //   toast.error('Please completed the KYC and 2FA process');
      // }
    } else {
      toast.error(
        `${selectedCell?.name} amount should be minimum ${selectedCell.minimum_order} and maximum  ${selectedCell.maximum_order}`
      );
    }
  };

  //console.log(selectedCell, 'selectedCell');

  const resetForm = () => {
    if (!selectedMarket || selectedMarket?.value === 'market') {
      setSelectedMarket(options[1]);
    } else {
      setSelectedMarket(options[0]);
    }
    setGetAmount('');
    setGetLimit('');
    setAmountININR('');
  };

  useEffect(() => {
    resetForm();
  }, [selectedCell]);

  const handlePreventDecimal = (e) => {
    let invalidChars = ['-', '+', 'e', '.'];
    if (invalidChars.includes(e.key)) {
      return e.preventDefault();
    }
  };

  const onChangeAmountINR = (e, value) => {
    // console.log(selectedCell.symbol);
    if (selectedCell.symbol == 'shib' || selectedCell.trade_decimal == 0) {
      setGetAmount(value);
      value = value.replace(/[e\+\-\.]/gi, '');
      const lastPrice =
        activetab === 'Buy'
          ? Number(pricePerCoinsInBuy)
          : Number(pricePerCoinsInSell);
      const price = value * lastPrice;
      setAmountININR(price?.toFixed(2));
    } else if (
      countDecimals(getAmount) + 1 <= selectedCell.trade_decimal ||
      (e.nativeEvent.inputType?.includes('delete') &&
        selectedCell?.symbol !== 'shib')
    ) {
      setGetAmount(value);
      const lastPrice =
        activetab === 'Buy'
          ? Number(pricePerCoinsInBuy)
          : Number(pricePerCoinsInSell);
      const price = value * lastPrice;
      setAmountININR(price?.toFixed(2));
    }
  };

  const onChangeAmountInCoins = (e, value) => {
    if (
      countDecimals(amountININR) + 1 <= 2 ||
      e.nativeEvent.inputType?.includes('delete')
    ) {
      setAmountININR(value);
      const lastPrice =
        activetab === 'Buy'
          ? Number(pricePerCoinsInBuy)
          : Number(pricePerCoinsInSell);
      const price = Number(value) / lastPrice;
      setGetAmount(Number(toFixedTrunc(price, selectedCell.trade_decimal)));
    }
  };

  const findUserHaveThisCoin = () => {
    const isContains = balance?.payload?.find((item) => {
      //console.log(item.currency, symbol, 'vv');
      return item.currency == selectedCell.symbol;
    });
    setInvestedData(isContains);
  };

  useEffect(() => {
    findUserHaveThisCoin();
  }, [selectedCell, balance]);

  const handleGetLimit = (e, value) => {
    if (
      countDecimals(value) <= 2 ||
      e.nativeEvent.inputType?.includes('delete')
    ) {
      setGetLimit(value);
    }
  };

  useEffect(() => {
    if (inputRef) {
      if (selectedCell.symbol == 'shib' || selectedCell.trade_decimal == 0) {
        inputRef.current.addEventListener('keydown', handlePreventDecimal);
        return () =>
          inputRef.current.removeEventListener('keydown', handlePreventDecimal);
      }
    }
  }, [inputRef, selectedCell.symbol, selectedCell.trade_decimal]);

  useEffect(() => {
    analytics?.track(`User clicked ${activetab} section!`, {
      from: 'Exchange page',
      symbol: selectedCell.symbol,
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [activetab]);

  const handlePercentInBuy = (percent) => {
    const temp1 = (Number(percent) * Number(getINRinBalance())) / 100;
    const perValue = (temp1 / 100) * 100;
    if (selectedMarket?.value === 'market') {
      setAmountININR(toFixedTrunc(perValue, 2));
      const lastPrice = Number(pricePerCoinsInBuy);
      const price = Number(perValue) / lastPrice;
      setGetAmount(toFixedTrunc(price, selectedCell.trade_decimal));
    } else {
      if (getlimit == 0) {
        setGetLimit(toFixedTrunc(Number(lastPrice(selectedCell?.symbol)), 2));
      }
      const lastPriceOfCoin = Number(pricePerCoinsInBuy);
      const price = Number(perValue) / lastPriceOfCoin;
      setGetAmount(toFixedTrunc(price, selectedCell.trade_decimal));
    }
  };

  const handlePercentInSell = (percent) => {
    const balanceOfCurrentCoin = investedData
      ? Number(toFixedTrunc(investedData?.balance, selectedCell.trade_decimal))
      : 0;
    if (balanceOfCurrentCoin !== 0) {
      if (selectedMarket?.value === 'market') {
        const temp1 = (Number(percent) * Number(balanceOfCurrentCoin)) / 100;
        const perValue = (temp1 / 100) * 100;
        setGetAmount(
          Number(toFixedTrunc(perValue, selectedCell.trade_decimal))
        );
        const price =
          perValue.toFixed(selectedCell.trade_decimal) *
          Number(pricePerCoinsInSell);
        setAmountININR(Number(toFixedTrunc(price, 2)));
      } else {
        const temp1 = (Number(percent) * Number(balanceOfCurrentCoin)) / 100;
        const perValue = (temp1 / 100) * 100;
        const price =
          perValue.toFixed(selectedCell.trade_decimal) *
          Number(pricePerCoinsInSell);
        if (getlimit == 0) {
          setGetLimit(toFixedTrunc(Number(lastPrice(selectedCell?.symbol)), 2));
        }
        setGetAmount(
          Number(toFixedTrunc(perValue, selectedCell.trade_decimal))
        );
      }
    } else {
      setGetAmount(0);
      setAmountININR(0);
    }
  };

  function percentage(percent, total) {
    return ((percent / 100) * total).toFixed(2);
  }

  useEffect(() => {
    setAmountININR(0);
    setGetLimit(0);
    setGetAmount(0);
  }, [activetab, selectedMarket]);

  useEffect(() => {
    if (selectedMarket?.value === 'limit') {
      let total = getAmount * getlimit;
      setTds(percentage(1, total));
    } else {
      setTds(percentage(1, amountININR));
    }
  }, [amountININR, getlimit, getAmount]);

  useEffect(() => {
    setTds('0.00');
  }, [selectedMarket, activetab]);

  function renderTab() {
    if (activetab === 'Buy') {
      return (
        <div className="actions__form">
          <div className="actions__fieldset">
            <div className="actions__field order_book_custom_select">
              <div className="operations__balance">
                <div className="operations__label">AVAILABLE BALANCE</div>
                <div className="operations__counter">
                  {investedData
                    ? toFixedTrunc(
                        investedData?.balance,
                        selectedCell.trade_decimal
                      )
                    : 0}{' '}
                  {selectedCell.symbol?.toUpperCase()}
                </div>
                <div className="operations__counter">
                  ₹{' '}
                  {balance?.payload?.length === 0
                    ? '0.00'
                    : toFixedTrunc(getINRinBalance(), 2)}{' '}
                  INR
                </div>
              </div>
              <CustomSelect
                defaultValue={[{ value: 'market', label: 'Market' }]}
                options={options}
                onChange={(item) => setSelectedMarket(item)}
              />
            </div>
            {/* <div className="actions__field">
              <div className="actions__label">limit price</div>
              <input className="actions__input" type="number" />
              <div className="actions__currency color-green">USDT </div>
            </div> */}
            {selectedMarket?.value === 'limit' && (
              <div className="actions__field">
                <div className="actions__label">
                  Price per {selectedCell?.symbol?.toUpperCase()} in INR
                </div>
                <input
                  className="actions__input"
                  type="number"
                  // defaultValue={0}
                  min={0}
                  value={getlimit}
                  onChange={(e) => handleGetLimit(e, e.target.value)}
                />
                <div className="actions__currency color-orange">INR</div>
              </div>
            )}

            <div className="actions__field">
              <div className="actions__label">
                Amount in {selectedCell?.symbol?.toUpperCase()}
              </div>
              <input
                className="actions__input"
                type="number"
                value={getAmount}
                min={0}
                ref={inputRef}
                onChange={(e) => {
                  onChangeAmountINR(e, e.target.value);
                }}
              />
              <div
                className="actions__currency color-green"
                style={{ textTransform: 'uppercase' }}
              >
                {selectedCell?.symbol}
              </div>
            </div>
            {selectedCell.symbol !== 'inr' && (
              <>
                {selectedMarket?.value !== 'limit' && (
                  <>
                    {selectedCell.symbol !== 'inr' && (
                      <div className="actions__field">
                        {/* <div className="actions__label">
                          Price per {selectedCell?.symbol?.toUpperCase()}
                        </div> */}
                        <div className="actions__label">Amount in INR</div>
                        <input
                          className="actions__input"
                          type="number"
                          min={0}
                          // disabled
                          // defaultValue={0}
                          value={amountININR}
                          onChange={(e) =>
                            onChangeAmountInCoins(e, e.target.value)
                          }
                        />
                        <div className="actions__currency color-orange">
                          INR
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                marginLeft: -23,
              }}
            >
              {perValue.map((value, idx) => (
                <span
                  className="numbers_slider"
                  key={idx}
                  onClick={() => handlePercentInBuy(value)}
                >
                  {value}%
                </span>
              ))}
            </div>
          </div>

          {selectedMarket?.value === 'limit' ? (
            <div
              className="h6 total-exchange__action"
              style={{ marginBottom: 30, textAlign: 'right' }}
            >
              Total = {(getAmount * getlimit).toFixed(2)}
            </div>
          ) : (
            <div
              className="h6 total-exchange__action"
              style={{ marginBottom: 30, textAlign: 'right' }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInBuy ? '₹ ' + formatINR(pricePerCoinsInBuy) : 0}
            </div>
          )}
          {loading ? (
            <LoadingBtn size="full" bgcolor="#53ce53" />
          ) : (
            <button
              className="actions__btn btn btn_orange btn_wide"
              onClick={(e) => handleBuy(e, 'buy')}
              disabled={btnDisbale}
              style={{ backgroundColor: '#53ce53' }}
            >
              Buy {selectedCell?.symbol?.toUpperCase()}
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="actions__form">
          <div className="actions__fieldset">
            <div className="actions__field order_book_custom_select">
              <div className="operations__balance">
                <div className="operations__label">AVAILABLE BALANCE</div>
                <div className="operations__counter">
                  {investedData
                    ? toFixedTrunc(
                        investedData?.balance,
                        selectedCell.trade_decimal
                      )
                    : 0}{' '}
                  {selectedCell.symbol?.toUpperCase()}
                </div>
                <div className="operations__counter">
                  ₹ {toFixedTrunc(getINRinBalance(), 2)} INR
                </div>
              </div>
              <CustomSelect
                defaultValue={[{ value: 'market', label: 'Market' }]}
                options={options}
                onChange={(item) => setSelectedMarket(item)}
              />
            </div>
            {/* <div className="actions__field">
              <div className="actions__label">limit price</div>
              <input className="actions__input" type="number" />
              <div className="actions__currency color-green">USDT </div>
            </div> */}

            {selectedMarket?.value === 'limit' && (
              <div className="actions__field">
                <div className="actions__label">
                  Price per {selectedCell?.symbol?.toUpperCase()} in INR
                </div>
                <input
                  className="actions__input"
                  type="number"
                  defaultValue={0}
                  min={0}
                  value={getlimit}
                  onChange={(e) => handleGetLimit(e, e.target.value)}
                />
                <div className="actions__currency color-orange">INR</div>
              </div>
            )}

            <div className="actions__field">
              <div className="actions__label">
                Amount in {selectedCell?.symbol?.toUpperCase()}
              </div>
              <input
                className="actions__input"
                type="number"
                value={getAmount}
                id={selectedCell.symbol === 'shib' ? '' : 'preventdecimal'}
                min={0}
                ref={inputRef}
                onChange={(e) => {
                  onChangeAmountINR(e, e.target.value);
                }}
              />
              <div
                className="actions__currency color-red"
                style={{ textTransform: 'uppercase' }}
              >
                {selectedCell?.symbol}
              </div>
            </div>
            {selectedCell.symbol !== 'inr' && (
              <>
                {selectedMarket?.value !== 'limit' && (
                  <>
                    {selectedCell.symbol !== 'inr' && (
                      <div className="actions__field">
                        <div className="actions__label">Amount in INR</div>
                        <input
                          className="actions__input"
                          type="number"
                          defaultValue={0}
                          min={0}
                          value={amountININR}
                          onChange={(e) =>
                            onChangeAmountInCoins(e, e.target.value)
                          }
                        />
                        <div className="actions__currency color-orange">
                          INR
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            <h3 className="tds__deduction">
              <a
                href="https://letskrypto.medium.com/what-you-need-to-know-about-tds-on-crypto-cb3ef7fd8840"
                target="_blank"
                rel="noreferrer"
              >
                📝 TDS Deducted @1% (Rs. {tds})
              </a>
            </h3>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                marginLeft: -23,
              }}
            >
              {perValue.map((value, idx) => (
                <span
                  className="numbers_slider"
                  key={idx}
                  onClick={() => handlePercentInSell(value)}
                >
                  {value}%
                </span>
              ))}
            </div>
          </div>
          {selectedMarket?.value === 'limit' ? (
            <div
              className="h6 total-exchange__action"
              style={{ marginBottom: 30, textAlign: 'right' }}
            >
              Total = {(getAmount * getlimit).toFixed(2)}
            </div>
          ) : (
            <div
              className="h6 total-exchange__action"
              style={{
                marginBottom: 30,
                textAlign: 'right',
              }}
            >
              Price per {selectedCell?.symbol?.toUpperCase()} ={' '}
              {pricePerCoinsInSell ? '₹ ' + formatINR(pricePerCoinsInSell) : 0}
            </div>
          )}
          {loading ? (
            <LoadingBtn size="full" bgcolor="#ff5353" />
          ) : (
            <button
              className="actions__btn btn btn_orange btn_wide"
              onClick={(e) => handleBuy(e, 'sell')}
              disabled={btnDisbale}
              style={{ backgroundColor: '#ff5353' }}
            >
              Sell {selectedCell?.symbol?.toUpperCase()}
            </button>
          )}
        </div>
      );
    }
  }

  return (
    <div
      className="actions"
      style={{
        display:
          selectedtab === 'market__tabs' && width < breakpoint
            ? 'block'
            : 'none',
        marginTop:
          selectedtab === 'market__tabs' && width < breakpoint ? '25px' : 0,
      }}
    >
      <div className="actions__menu">
        <button
          className={`actions__link ${activetab === 'Buy' ? 'active' : ''}`}
          onClick={() => setActiveTab('Buy')}
        >
          Buy
        </button>
        <button
          className={`actions__link ${activetab === 'Sell' ? 'active' : ''}`}
          onClick={() => setActiveTab('Sell')}
        >
          Sell
        </button>
      </div>
      {renderTab()}
    </div>
  );
}

export default ExchangeAction;
