import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { networkCheck } from '../../actions/kycAction';
import { getUserIPAddress, logout, resendMail } from '../../actions/userAction';
import AuthPreviewImage from '../../components/auth/preview-image/previewimage';
import CustomButton from '../../components/common/custom-button/custom-button';
import HelmetContainer from '../../components/common/helmet/helmet';
import LoadingBtn from '../../components/common/loading-btn/loading-btn';
import ReCAPTCHA from 'react-google-recaptcha';
import { captchaKey } from '../../utils/urls';
import Toast from '../../components/common/toast/toast';
import useAnalytics from '../../utils/useAnalytics';
import { SEND_EMAIL_CONFIRMATION_CLEANUP } from '../../constants/userConstant';
import { getQueryParams } from '../../utils/query-helper';
import { getAssests } from '../../actions/assestsAction';
import ThemeToogler from '../../components/common/theme-toogler/theme-toogler';
import MotionContainer from '../../components/common/motion-container/motion-container';

function VerifyEmail() {
  const history = useHistory();
  const { analytics } = useAnalytics();
  const {
    resendMail: { loading, error, response },
    userLogin: { userInfo },
    networkCheck: { loading: nloading, error: nError, response: nResponse },
    getAssests: { assetsData },
    theme: { isdark },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  const [captchaToken, setCaptchaToken] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);

  const captchaRef = useRef();

  const handleSendMail = () => {
    if (!captchaToken) return toast.error('Check the recaptcha');
    analytics?.track('Resend Email clicked!', {
      from: 'verfiy page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    clearState();
    dispatch(resendMail(userInfo.payload.email, captchaToken));
  };

  const handleVerify = () => {
    analytics?.track('Verfiy Email clicked!', {
      from: 'verfiy page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    clearState();
    dispatch(networkCheck());
  };

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
  };

  const clearState = () => {
    dispatch({
      type: SEND_EMAIL_CONFIRMATION_CLEANUP,
    });
  };

  useEffect(() => {
    if (error) {
      setCaptchaToken(null);
      toast.error(error);
      analytics?.track('Resend Email failed!', {
        from: 'verfiy page',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
    }
    if (response) {
      setCaptchaToken(null);
      analytics?.track('Resend Email success!', {
        from: 'verfiy page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      captchaRef.current.reset();
      setBtnDisable(true);
      setTimeout(() => {
        setBtnDisable(false);
      }, 30000);
      toast.success(response.message);
    }
    if (nResponse && nResponse.payload.level <= 0) {
      toast.info('Please verify the email', { toastId: 'check-email' });
    }
    if (nResponse && nResponse.payload.level > 0) {
      analytics?.track('Verify Email success!', {
        from: 'verfiy page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      history.push({
        pathname: '/profile',
        search: location.search,
      });
    }
  }, [loading, error, nResponse]);

  const handleAnotherAccount = () => {
    analytics?.track('User Login with other account success!', {
      from: 'profile page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    clearState();
    history.push({
      pathname: '/signin',
      search: location.search,
    });
    dispatch(logout(true));
  };

  useEffect(() => {
    analytics?.page('Verify email page', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  return (
    <MotionContainer>
      <HelmetContainer title="Sign Up" />
      <Toast />
      <div className="login__row">
        <AuthPreviewImage
          title="The Crypto Asset Exchange"
          // subtitle="UI Design Kit"
        />
        <div
          className="login__col verify__email"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="h3">
            <span style={{ fontSize: '65px' }}>&#9993;</span> Please check your
            mail
          </div>
          <div className="h6" style={{ textAlign: 'center' }}>
            We have sent you a confirmation mail to ensure that our mails always
            reach you. Kindly click on the confirm button in the mail and then
            click on verify button below
          </div>
          <div style={{ display: 'grid', placeItems: 'center', marginTop: 12 }}>
            <ReCAPTCHA
              sitekey={captchaKey}
              ref={captchaRef}
              theme={isdark ? 'dark' : 'light'}
              onChange={onChangeCaptcha}
            />
          </div>
          <div className="verify__btns">
            {loading ? (
              <LoadingBtn />
            ) : (
              <CustomButton
                title="Resend Email"
                onClick={handleSendMail}
                disabled={btnDisable}
              />
            )}
            {nloading ? (
              <div style={{ marginLeft: 10 }}>
                <LoadingBtn />
              </div>
            ) : (
              <CustomButton
                title="Verify"
                style={{ marginLeft: 10 }}
                onClick={handleVerify}
              />
            )}
            <CustomButton
              title="Login with another krypto account"
              style={{ marginLeft: 10 }}
              onClick={handleAnotherAccount}
            />
          </div>
        </div>
      </div>
      <ThemeToogler />
    </MotionContainer>
  );
}

export default VerifyEmail;
