import React from 'react';

function KycStepper({ stage }) {
  return (
    <div className="mobile-hide">
      <div className="register-stepper">
        <div
          className={`step ${stage === 'stage-2' ? 'editing' : ''} ${
            stage === 'stage-3' ? 'done' : ''
          }  ${stage === 'stage-4' ? 'done' : ''} `}
        >
          <div className="step-circle">
            <span>1</span>
          </div>
          <div className="step-title h6">Personal Information</div>
          <div style={{ fontSize: 10, textAlign: 'center' }}>
            Personal Information unlock all Krypto feature !
          </div>
          <div className="step-bar-left"></div>
          <div className="step-bar-right"></div>
        </div>
        <div
          className={`step ${stage === 'stage-3' ? 'editing' : ''} ${
            stage === 'stage-4' ? 'done' : ''
          } `}
        >
          <div className="step-circle">
            <span>2</span>
          </div>
          <div className="step-title h6">Document Verfication</div>
          <div style={{ fontSize: 10, textAlign: 'center' }}>
            Document Verfication unlock all Krypto feature !
          </div>
          <div className="step-bar-left"></div>
          <div className="step-bar-right"></div>
        </div>
        <div className={`step ${stage === 'stage-4' ? 'editing' : ''} `}>
          <div className="step-circle">
            <span>3</span>
          </div>
          <div className="step-title h6">Take a Selfie</div>
          <div style={{ fontSize: 10, textAlign: 'center' }}>
            Hold a paper with Krypto and date written on it and take a selfie
            (No Eyewear Glasses)
          </div>
          <div className="step-bar-left"></div>
          <div className="step-bar-right"></div>
        </div>
      </div>
    </div>
  );
}

export default KycStepper;
