import React from 'react';

const CustomButton = ({ title, addClassName, ...otherProps }) => {
  return (
    <button
      className={`card__btn btn btn_orange ${addClassName}`}
      {...otherProps}
    >
      {title}
    </button>
  );
};

export default CustomButton;
