import React, { useState, useEffect } from 'react';
import ExchangeBanner from '../../components/exchange/exchange-banner/exchange-banner';
import ExchangeCell from '../../components/exchange/exchange-table';
import OrderBook from '../../components/exchange/order-book/order-book';
import Layout from '../../components/layout/layout';
import ExchangeAction from '../../components/exchange/exchange-action/exchange-action';
import ExchangeMarket from '../../components/exchange/exchange-market/exchange-market';
import ExchangeFooter from '../../components/exchange/exchange-footer/exchange-footer';
import HelmetContainer from '../../components/common/helmet/helmet';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonContainer from '../../components/common/skeleton/skeleton';
import { getAssests, getCoinsDetails } from '../../actions/assestsAction';
import ToastContainer from '../../components/common/toast/toast';
import { getOrderBook } from '../../actions/exchangeAction';
import { GET_ORDER_BOOK_DETAILS_WS_DISCONNECT } from '../../constants/exchangeConstant';
import { getOrderData } from '../../actions/historyAction';
import { getBalance, getUserIPAddress } from '../../actions/userAction';
import { useHistory, useParams, useLocation } from 'react-router';
import Toast from '../../components/common/toast/toast';
import useAnalytics from '../../utils/useAnalytics';
import { scroller } from 'react-scroll';
import { getQueryParams } from '../../utils/query-helper';

function ExchangePage() {
  const [selectedtab, setSelectedTab] = useState('market__tabs');
  const [selectedCell, setSelectedCell] = useState([]);
  const dispatch = useDispatch();
  const { loading, error, assetsData } = useSelector(
    (state) => state.getAssests
  );

  const { analytics } = useAnalytics();

  const { symbol: symbolOfCoins } = useParams();
  const history = useHistory();
  const location = useLocation();

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    getOrderBook: { loading: orderLoading, error: orderError, orderBookData },
    userLogin: { userInfo },
    userGetBalance,
    getPortfolio,
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { balance } = userGetBalance;

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
    if (!orderBookData) {
      dispatch(getOrderBook());
    }
    if (!balance) {
      dispatch(getBalance());
    }
    return () => {
      dispatch({
        type: GET_ORDER_BOOK_DETAILS_WS_DISCONNECT,
      });
    };
  }, []);

  useEffect(() => {
    if (coinsDetails?.convertedArray) {
      if (symbolOfCoins) {
        const filteredItem = coinsDetails?.convertedArray.find(
          (item) => item.symbol == symbolOfCoins
        );
        if (!filteredItem || filteredItem.symbol == 'inr') {
          history.push({
            pathname: '/404',
            search: location.search,
          });
        } else {
          setSelectedCell(filteredItem);
        }
      } else {
        setSelectedCell(coinsDetails?.convertedArray[1]);
      }
    }
  }, [coinsDetails, symbolOfCoins]);

  useEffect(() => {
    analytics?.page('Exchange page', {
      coinsymbol: symbolOfCoins,
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, symbolOfCoins, ip]);

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  useEffect(() => {
    scroller.scrollTo('exchange__top', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }, [scroller]);

  return (
    <Layout isExchangePage={true}>
      <HelmetContainer title="Exchange" />
      <Toast />
      <div className="page__container page__container_exchange">
        <div className="exchange">
          {coinsLoading || loading || !assetsData ? (
            <SkeletonContainer
              width="100%"
              height="120px"
              className="box__shadow exchange__top"
              style={{ marginBottom: 35, borderRadius: 20 }}
            />
          ) : (
            <ExchangeCell
              selectedtab={selectedtab}
              setSelectedCell={setSelectedCell}
              selectedCell={selectedCell}
            />
          )}
          <div className="exchange__row">
            <div className="exchange__container">
              <ExchangeMarket
                params={symbolOfCoins}
                selectedtab={selectedtab}
                selectedCell={selectedCell}
              />
            </div>
            <div className="exchange__sidebar">
              {selectedCell?.symbol && (
                <OrderBook
                  selectedtab={selectedtab}
                  selectedCell={selectedCell}
                />
              )}
              {coinsLoading || (loading && !assetsData) ? (
                <SkeletonContainer
                  width="100%"
                  height="320px"
                  className="box__shadow"
                  style={{ marginBottom: 35, borderRadius: 20 }}
                />
              ) : (
                <ExchangeAction
                  selectedtab={selectedtab}
                  setSelectedCell={setSelectedCell}
                  selectedCell={selectedCell}
                />
              )}
              <ExchangeBanner
                title="Trading & Asset Updates"
                date="November 2020"
                btnValue="Explore Now"
                link={'/prices'}
                onClick={() => {
                  analytics?.track('Explore Now button clicked', {
                    coinsymbol: symbolOfCoins,
                    uid: userInfo?.payload?.uid,
                    from: 'Exchange page',
                    QueryParams: { ...getQueryParams() },
                    ip: ip,
                  });
                }}
              />
            </div>
          </div>
          <ExchangeFooter
            selectedtab={selectedtab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    </Layout>
  );
}

export default ExchangePage;
