import React from 'react';
import { loginHistory } from '../../../../utils/data';
import TableCell from './table-cell/table-cell';

function LoginDetails() {
  return (
    <>
      <div className="popup__box">
        <div className="popup__title h6">Active Sessions</div>
        <table className="popup__table">
          <tbody>
            <tr>
              <th>DATE/TIME</th>
              <th>DEVICE</th>
              <th>LOCATION</th>
              <th>IP ADDRESS</th>
              <th> </th>
            </tr>
            <TableCell data={loginHistory[0]} />
          </tbody>
        </table>
      </div>
      <div className="popup__box">
        <div className="popup__title h6">Login History</div>
        <table className="popup__table">
          <tbody>
            <tr>
              <th>DATE/TIME</th>
              <th>DEVICE</th>
              <th>LOCATION</th>
              <th>IP ADDRESS</th>
              <th>2FA</th>
            </tr>
            {loginHistory.map((data, idx) => (
              <TableCell data={data} key={idx} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LoginDetails;
