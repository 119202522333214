import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

export default function PaginatedItems({
  itemsPerPage,
  itemsLength,
  selectFilter,
  handleLoadMore,
  page,
  setPage,
}) {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setPageCount(Math.ceil(itemsLength / itemsPerPage));
  }, [itemOffset, itemsPerPage, itemsLength, selectFilter?.value]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsLength;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    handleLoadMore(event.selected);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setPage(1);
  }, [selectFilter]);

  if (itemsLength <= 10) {
    return null;
  }

  return (
    <>
      <ReactPaginate
        breakLabel="&bull;&bull;&bull;&bull;"
        nextLabel="&gt;&gt;"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        forcePage={page - 1}
        previousLabel="&lt;&lt;"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="pagination__item"
        nextClassName="pagination__item"
        previousClassName="pagination__item"
        activeClassName="pagination__item active"
        breakClassName="pagination__item"
      />
    </>
  );
}
