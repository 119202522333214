export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';
export const CREATE_ORDER_CLEANUP = 'CREATE_ORDER_CLEANUP';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';
export const CANCEL_ORDER_CLEANUP = 'CANCEL_ORDER_CLEANUP';

export const GET_ORDER_BOOK_DETAILS_WS_REQUEST =
  'GET_ORDER_BOOK_DETAILS_WS_REQUEST';
export const GET_ORDER_BOOK_DETAILS_WS_SUCCESS =
  'GET_ORDER_BOOK_DETAILS_WS_SUCCESS';
export const GET_ORDER_BOOK_DETAILS_WS_FAIL = 'GET_ORDER_BOOK_DETAILS_WS_FAIL';
export const GET_ORDER_BOOK_DETAILS_WS_DISCONNECT =
  'GET_ORDER_BOOK_DETAIL_WS_SDISCONNECT';
