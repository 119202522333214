import { OPEN_SETTING } from '../constants/settingConstant';
import * as settingConstant from '../constants/settingConstant';
import axios from 'axios';
import { baseUrl } from '../utils/urls';

export const toogleSetting = (state) => (dispatch) => {
  dispatch({
    type: OPEN_SETTING,
    payload: state,
  });
};

export const get2fA = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: settingConstant.GET_2FA_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/user/get2fa/`, {}, config);
    dispatch({
      type: settingConstant.GET_2FA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: settingConstant.GET_2FA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const verify2fA = (code) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: settingConstant.VERIFY_2FA_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/verify2fa/`,
      { code: code },
      config
    );
    dispatch({
      type: settingConstant.VERIFY_2FA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: settingConstant.VERIFY_2FA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
