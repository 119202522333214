import * as kycConstants from '../constants/kycConstant';

export const sendPhoneCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case kycConstants.SEND_OTP_KYC_REQUEST:
      return { loading: true };
    case kycConstants.SEND_OTP_KYC_SUCCESS:
      return { loading: false, response: action.payload };
    case kycConstants.SEND_OTP_KYC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const verifyCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case kycConstants.VERIFY_OTP_KYC_REQUEST:
      return { loading: true };
    case kycConstants.VERIFY_OTP_KYC_SUCCESS:
      return { loading: false, response: action.payload };
    case kycConstants.VERIFY_OTP_KYC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addPersonalInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case kycConstants.ADD_PERSONAL_DETAILS_KYC_REQUEST:
      return { loading: true };
    case kycConstants.ADD_PERSONAL_DETAILS_KYC_SUCCESS:
      return { loading: false, response: action.payload };
    case kycConstants.ADD_PERSONAL_DETAILS_KYC_FAIL:
      return { loading: false, error: action.payload };
    case kycConstants.CLEANUP_KYC_DOC:
      return {
        loading: false,
        error: action.payload,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const networkCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case kycConstants.NETWORK_CHECK_KYC_REQUEST:
      return { loading: true };
    case kycConstants.NETWORK_CHECK_KYC_SUCCESS:
      return { loading: false, response: action.payload };
    case kycConstants.NETWORK_CHECK_KYC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const kycViewDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case kycConstants.KYC_VIEW_DETAILS_REQUEST:
      return { loading: true };
    case kycConstants.KYC_VIEW_DETAILS_SUCCESS:
      return { loading: false, response: action.payload };
    case kycConstants.KYC_VIEW_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
