import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toogleNavbar } from '../../../actions/navbarAction';
import useViewport from '../../../utils/useViewPort';
import Icon from '../../common/icons/icons';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import Tooltip from '../../common/tooltip/tooltip';

function NavItem({ route, active, isExchangePage }) {
  const { width } = useViewport();
  const breakpoint = 1000;
  const dispatch = useDispatch();

  const {
    navbar: { open },
    theme: { isdark },
  } = useSelector((state) => state);

  return (
    <Link
      className={'sidebar__item ' + (active ? 'active' : '')}
      to={(location) => ({ ...location, pathname: route.path })}
      onClick={() => (width <= breakpoint ? dispatch(toogleNavbar()) : null)}
      data-tip={route.name}
    >
      <div className="sidebar__icon">
        <Icon icon={route.icon} className="icon" />
      </div>
      {(!open || isExchangePage) && <Tooltip />}
      <div className="sidebar__text">{route.name}</div>
    </Link>
  );
}

export default NavItem;
