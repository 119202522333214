import React, { useEffect, useState } from 'react';
import CustomSelect from '../../common/custom-select/custom-select';
import Tags from '../../common/tags/tags';
import InviteCard from './invite-card/invite-card';
import Image from '../../../assests/img/scratchCards.png';
import Popup from '../../common/popup/popup';
import WhiteGif from '../../../assests/img/scratch-card-white.gif';
import DarkGif from '../../../assests/img/scratch-card-dark.gif';
import { useSelector, useDispatch } from 'react-redux';
import TrophyIcon from '../../../assests/img/trophy.png';
import SkeletonContainer from '../../common/skeleton/skeleton';
import {
  getScratchCardEarning,
  getScratchCards,
  getTotalReferrals,
  redeemScratchCardsFromLists,
} from '../../../actions/rewardsAction';
import NotFoundImg from '../../../assests/img/notfound.png';
import { toast } from 'react-toastify';
import CustomButton from '../../common/custom-button/custom-button';
import Pagination from '../../common/pagination/pagination';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function ReferralsTab({ earning, selectFilter, setSelectFilter, filter }) {
  const {
    getReferralCode: { loading, response, error },
  } = useSelector((state) => state);

  const [popUpData, setPopUpData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [redeemCardPageCount, setRedeemCardPageCount] = useState(1);
  const dispatch = useDispatch();
  const [page, setPage] = useState(null);
  const { analytics } = useAnalytics();

  const {
    theme: { isdark },
    getReferralEarning: {
      loading: earningLoading,
      response: earningResponse,
      error: earningError,
    },
    getScratchCard: {
      loading: scratchCardLoading,
      error: scratchCardError,
      response: scratchCardResponse,
    },
    userLogin: { userInfo },
    getTotalReferrals: { response: totalReferrals },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  useEffect(() => {
    getScratchCardList();
  }, [selectFilter]);

  const getScratchCardList = () => {
    if (selectFilter.value == 'unredeemed') {
      dispatch(getScratchCards('Not_Redeemed', 1));
    } else {
      dispatch(getScratchCards('Redeemed', 1));
    }
  };

  const handleRedeemScratchCard = async (item) => {
    try {
      const data = await redeemScratchCardsFromLists(
        userInfo.token.access,
        item.id
      );
      setPopUpData(item);
      setOpenPopup(true);
      getScratchCardList();
      analytics?.track('Redeemed ScratchCard success!', {
        keyword: selectFilter?.value,
        from: 'Referral Page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      dispatch(getScratchCardEarning());
    } catch (e) {
      analytics?.track('Redeemed ScratchCard failed!', {
        keyword: selectFilter?.value,
        from: 'Referral Page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(e);
    }
  };

  const handleLoadMore = (page) => {
    analytics?.track('Clicked load More', {
      keyword: selectFilter?.value,
      from: 'Referral Page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    setPage(page + 1);
    if (selectFilter.value === 'redeemed') {
      dispatch(getScratchCards('Redeemed', page + 1));
      // if (scratchCardResponse?.payload?.results.length < 10) {
      //   setRedeemCardPageCount(0);
      // } else {
      //   setRedeemCardPageCount((pre) => pre + 1);
      // }
    } else if (selectFilter.value === 'unredeemed') {
      dispatch(getScratchCards('Not_Redeemed', page + 1));
      // if (scratchCardResponse?.payload?.results.length < 10) {
      //   setRedeemCardPageCount(0);
      // } else {
      //   setRedeemCardPageCount((pre) => pre + 1);
      // }
    }
  };

  useEffect(() => {
    setRedeemCardPageCount(1);
  }, [selectFilter]);

  useEffect(() => {
    analytics?.track('Clicked filter tab', {
      keyword: selectFilter?.value,
      from: 'Referral Page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, selectFilter]);

  // console.log(page + 10, 'page');

  useEffect(() => {
    dispatch(getTotalReferrals());
  }, []);

  return (
    <>
      <InviteCard analytics={analytics} />
      <div className="popup__category">Total Referrals Rewards</div>
      <div className="popup__flex">
        {earning ? (
          <>
            <div className="popup__money h3">{earning}</div>
            <div className="popup__currency">INR</div>
          </>
        ) : (
          <SkeletonContainer
            style={{ width: 300, height: 50, borderRadius: 10 }}
          />
        )}
      </div>
      <div className="popup__parameters">
        {/* <div className="popup__parameter">
          <div className="popup__head">
            <div className="popup__text">Inviter Rewards</div>
            <div className="popup__text">0.00 USDT</div>
          </div>
          <div className="popup__body">
            You’re earning 20% of the trading fees your referrals pay.
          </div>
        </div> */}
        <div className="popup__parameter">
          <div className="popup__head">
            <div className="popup__text">Total Invited</div>
            <div className="popup__text">
              {totalReferrals?.payload?.total_invited
                ? totalReferrals?.payload?.total_invited
                : 0}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        <div
          className="widgets__select mobile-show"
          style={{ marginBottom: 30 }}
        >
          <CustomSelect
            defaultValue={filter[0]}
            options={filter}
            onChange={(data) => setSelectFilter(data)}
          />
        </div>
        <Tags
          selectedFilter={selectFilter}
          setSelectedFilter={setSelectFilter}
          options={filter}
          addTagclassName="notifications__tags  mobile-hide"
          addLinksclassName="notifications__link"
        />
        <div className="referral__card promotions__list">
          {selectFilter.value === 'unredeemed' ? (
            <>
              {scratchCardLoading ? (
                [1, 2, 3, 4, 5].map((item) => (
                  <div key={item} className="promotions__item bg-transparent">
                    <SkeletonContainer
                      style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: 10,
                      }}
                    />
                  </div>
                ))
              ) : (
                <>
                  {scratchCardResponse?.payload?.results?.map((item, idx) => (
                    <div
                      key={idx}
                      className="promotions__item blue"
                      onClick={() => handleRedeemScratchCard(item)}
                      style={{
                        backgroundImage: `url(${Image})`,
                        cursor: 'pointer',
                      }}
                    ></div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {scratchCardLoading ? (
                [1, 2, 3, 4, 5].map((item) => (
                  <div key={item} className="promotions__item bg-transparent">
                    <SkeletonContainer
                      style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: 10,
                      }}
                    />
                  </div>
                ))
              ) : (
                <>
                  <>
                    {scratchCardResponse?.payload?.results?.map((item, idx) => (
                      <div key={idx} className="promotions__item unredeemed">
                        <div className="h6">You Got</div>
                        <img src={TrophyIcon} />
                        <div className="p">
                          {item.amount}{' '}
                          {item.currency_type?.toUpperCase()?.toUpperCase()}
                        </div>
                      </div>
                    ))}
                  </>
                </>
              )}
            </>
          )}
        </div>
        <Pagination
          itemsPerPage={10}
          handleLoadMore={handleLoadMore}
          itemsLength={scratchCardResponse?.payload?.count}
          selectFilter={selectFilter}
          page={page}
          setPage={setPage}
          scratchCardLoading={scratchCardLoading}
        />
        {!scratchCardLoading &&
          ((scratchCardResponse?.payload?.results?.length === 0 &&
            selectFilter.value === 'redeemed') ||
            (scratchCardResponse?.payload?.results?.length === 0 &&
              selectFilter.value === 'unredeemed')) && (
            <div className="no__scratch_card">
              <img src={NotFoundImg} />
              <div className="h6">No Scratch Card</div>
            </div>
          )}
        {/* {scratchCardResponse?.payload?.next && (
          <div style={{ display: 'grid', placeItems: 'center', padding: 20 }}>
            <CustomButton title="Load More" onClick={handleLoadMore} />
          </div>
        )} */}
      </div>
      <div className="referral__popup">
        <Popup state={openPopup} setState={setOpenPopup}>
          <div className="scratch-card-popup">
            <div className="h5">Hoorayy 🎉!</div>
            <img src={isdark ? DarkGif : WhiteGif} alt="" />
            <div className="h6">
              You won{' '}
              <span>
                {popUpData?.amount} {popUpData?.currency_type?.toUpperCase()}
              </span>{' '}
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
}

export default ReferralsTab;
