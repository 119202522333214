import React from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import SkeletonContainer from '../../../../common/skeleton/skeleton';

function QrCode() {
  const { get2FA } = useSelector((state) => state);
  const { loading, error, response } = get2FA;
  //console.log(response, 'response');
  return (
    <div className="popup__scan">
      <div className="popup__preview">
        {loading && <SkeletonContainer width={250} height={250} />}
        {response && <QRCode value={response.payload.data.url} />}
      </div>

      <div className="popup__details">
        <div className="popup__text">
          If you want to turn on 2FA, use{' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US"
            referrerPolicy="no-referrer"
            target="_blank"
          >
            Google Authenticator app
          </a>{' '}
          to scan code, then enter six-digit code provided by the app to the
          input below.
        </div>
        <button
          className="popup__btn btn btn_orange"
          onClick={() =>
            window.open(
              'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US',
              '_blank'
            )
          }
        >
          Download 2FA App
        </button>
      </div>
    </div>
  );
}

export default QrCode;
