import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import ActivitiesHeader from '../../components/activities/activities-header/activities-header';
import ActivitiesHistory from '../../components/activities/activities-history/activities-history';
import ActivitiesSidebar from '../../components/activities/activities-sidebar/activities-sidebar';
import HelmetContainer from '../../components/common/helmet/helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDepositsData,
  getOrderData,
  getTradesData,
  getWithdrawsData,
} from '../../actions/historyAction';
import { getCoinsDetails } from '../../actions/assestsAction';
import { HISTORY_PAGE_RESET } from '../../constants/historyConstant';
import Toast from '../../components/common/toast/toast';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';
import Icon from '../../components/common/icons/icons';
import IconBtn from '../../components/common/icon-text/icon-text';
import { getUserIPAddress } from '../../actions/userAction';

function ActivitiesPage() {
  const { analytics } = useAnalytics();

  const sortingOptions = [
    {
      label: 'Deposits',
      value: 'Deposits',
    },
    {
      label: 'Orders',
      value: 'Orders',
    },
    {
      label: 'Trades',
      value: 'Trades',
    },
    {
      label: 'Withdraws',
      value: 'Withdraws',
    },

    // {
    //   label: 'Send Crypto',
    //   value: 'Send Crypto',
    // },
    // {
    //   label: 'Krypto Pay',
    //   value: 'Krypto Pay',
    // },
  ];

  const filterOptions = [
    {
      label: 'All Time',
      value: 'All Time',
    },
    {
      label: 'This Past Week',
      value: 'This Past Week',
    },
    {
      label: 'This Past Month',
      value: 'This Past Month',
    },
    {
      label: 'This Past Year',
      value: 'This Past Year',
    },
  ];

  const [filterByMonth, setFilterByMonth] = useState(filterOptions[0]);

  const [openSideBar, setOpenSideBar] = useState(false);
  const [sideBardata, setSideBarData] = useState({});
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(sortingOptions[0]);
  const { orderData, tradeData, depositData, withDrawData, userLogin } =
    useSelector((state) => state);

  const { loading, error, response: orderResponse } = orderData;
  const {
    loading: tradeLoading,
    error: tradeError,
    response: tradeResponse,
  } = tradeData;

  const { userInfo } = userLogin;

  const {
    loading: depositLoading,
    error: depositError,
    response: depositResponse,
  } = depositData;
  const {
    loading: withDrawLoading,
    error: withDrawError,
    response: withDrawResponse,
  } = withDrawData;

  const {
    cancelOrder: {
      response: cancelOrderResponse,
      loading: cancelOrderLoading,
      error: cancelOrderError,
    },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const handleSideBarToogle = (data) => {
    if (data.id === sideBardata.id) {
      setSideBarData('');
      return setOpenSideBar(false);
    }
    setOpenSideBar(true);
    setSideBarData(data);
  };

  const handleCloseSideBar = () => {
    setOpenSideBar(false);
    setSideBarData({});
  };

  useEffect(() => {
    dispatch({ type: HISTORY_PAGE_RESET });
    dispatch(getOrderData());
    dispatch(getTradesData());
    dispatch(getWithdrawsData());
    dispatch(getDepositsData());
    dispatch(getCoinsDetails());
  }, [selectedFilter, cancelOrderResponse]);

  useEffect(() => {
    analytics?.page('History page', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  useEffect(() => {
    analytics?.track('Clicked history filter', {
      from: 'History Page',
      uid: userInfo?.payload?.uid,
      keyword: selectedFilter.label,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [selectedFilter, analytics]);

  useEffect(() => {
    analytics?.track('Viewed history', {
      from: 'History Page',
      uid: userInfo?.payload?.uid,
      historyId: sideBardata.id,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [sideBardata, analytics, ip]);

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  return (
    <Layout>
      <HelmetContainer title="History" />
      <Toast />
      <div className="activity">
        <div className="activity__container">
          <ActivitiesHeader
            openSideBar={openSideBar}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filterByMonth={filterByMonth}
            setFilterByMonth={setFilterByMonth}
            sortingOptions={sortingOptions}
            filterOptions={filterOptions}
            setOpenSideBar={setOpenSideBar}
          />
          <ActivitiesHistory
            openState={openSideBar}
            sideBardata={sideBardata}
            handleSideBarToogle={handleSideBarToogle}
            selectedFilter={selectedFilter}
            handleCloseSideBar={handleCloseSideBar}
          />
        </div>
        <ActivitiesSidebar
          openState={openSideBar}
          selectedFilterValue={selectedFilter.value}
          sideBardata={sideBardata}
          handleSideBarToogle={handleSideBarToogle}
          handleClose={handleCloseSideBar}
        />
      </div>
    </Layout>
  );
}

export default ActivitiesPage;
