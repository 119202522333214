import axios from 'axios';
import * as exchangeConstants from '../constants/exchangeConstant';
import { baseUrl, webSocketUrl } from '../utils/urls';
import ReconnectingWebSocket from 'reconnecting-websocket';

export let ws = new ReconnectingWebSocket(`${webSocketUrl}/liveorderbookwire`);

export const getOrderBook = () => async (dispatch) => {
  ws.onopen = () => {
    // on connecting, do nothing but log it to the console
    dispatch({
      type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
    });
    //console.log('connected');
  };

  ws.onmessage = (evt) => {
    // listen to data sent from the websocket server
    if (evt.data !== 'Connection established with Krypto') {
      const message = JSON.parse(evt.data);
      dispatch({
        type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_SUCCESS,
        payload: message,
      });
    }
  };

  ws.onclose = () => {
    //console.log('disconnected');
    dispatch({
      type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_FAIL,
    });
    setTimeout(() => {
      ws.onopen = () => {
        // on connecting, do nothing but log it to the console
        dispatch({
          type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
        });
        //console.log('connected');
      };
    }, 500);
  };

  ws.onerror = (err) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    dispatch({
      type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_FAIL,
      payload: err.message,
    });

    setTimeout(() => {
      ws.onopen = () => {
        // on connecting, do nothing but log it to the console
        dispatch({
          type: exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST,
        });
        //console.log('connected');
      };
    }, 500);
  };
};

export const createOrder =
  (market, side, volume, ord_type, price) => async (dispatch, getState) => {
    //console.log(market, side, volume, ord_type, price);
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const reqdata = {
        market: market,
        side: side,
        volume: volume,
        ord_type: ord_type,
        price: price,
        source: 'web',
      };

      dispatch({
        type: exchangeConstants.CREATE_ORDER_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      const { data } = await axios.post(
        `${baseUrl}/user/createorder/`,
        reqdata,
        config
      );

      dispatch({
        type: exchangeConstants.CREATE_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: exchangeConstants.CREATE_ORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const cancelOrder = (id) => async (dispatch, getState) => {
  //console.log(id);
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: exchangeConstants.CANCEL_ORDER_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/user/cancelorder/`,
      { id: id },
      config
    );

    dispatch({
      type: exchangeConstants.CANCEL_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: exchangeConstants.CANCEL_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
