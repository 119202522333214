import React, { useEffect, useState } from 'react';
import ProfileDropdown from './profile-dropdown/profile-dropdown';
import ProfileFormInput from './profile-form-input/profile-form-input';
import ProfileImage from './profile-image/profile-image';
import { WithDrawAssets, currenyOptions } from '../../../../utils/data';
import CustomButton from '../../../common/custom-button/custom-button';
import ProfileNotification from './profile-notification/profile-notification';
import { useSelector, useDispatch } from 'react-redux';
import { toogleSetting } from '../../../../actions/settingAction';
import {
  addName,
  addUniqueUserName,
  logout,
  updateBankNumber,
  updateIFSCDetail,
  updateUPIDetail,
  userDetails,
} from '../../../../actions/userAction';
import { toast } from 'react-toastify';
import { PROFILE_CLEANUP } from '../../../../constants/userConstant';
import LoadingBtn from '../../../common/loading-btn/loading-btn';
import SkeletonContainer from '../../../common/skeleton/skeleton';
import useAnalytics from '../../../../utils/useAnalytics';
import { getQueryParams } from '../../../../utils/query-helper';
import UserProfileImage from '../../../../assests/img/profile.svg';

function ProfileTab() {
  const [currencyOption, setCurrencyOptions] = useState(currenyOptions[0]);
  const [withDrawValue, setWithDrawValue] = useState(WithDrawAssets[0]);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const { analytics } = useAnalytics();
  const {
    userDetails: { userData, loading, error },
    userAddName,
    userAddUniqueName,
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const {
    loading: userNameLoading,
    error: userNameError,
    response: userNameResponse,
  } = userAddName;
  const {
    loading: userUniqueNameLoading,
    error: userUniqueNameError,
    response: userUniqueNameResponse,
  } = userAddUniqueName;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userData.payload.name !== name &&
      userData.payload.username !== userName &&
      userNameResponse &&
      userUniqueNameResponse
    ) {
      dispatch(userDetails());
      analytics?.track('Clicked update profile success', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.success('Updated Successfully', {
        autoClose: 2000,
      });
    }

    if (
      userData.payload.name !== name &&
      userData.payload.username === userName &&
      userNameResponse
    ) {
      dispatch(userDetails());
      analytics?.track('Clicked update profile success', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.success('Updated Successfully', {
        autoClose: 2000,
      });
    }

    if (
      userData.payload.name === name &&
      userData.payload.username !== userName &&
      userUniqueNameResponse
    ) {
      dispatch(userDetails());
      analytics?.track('Clicked update profile success', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.success('Updated Successfully', {
        autoClose: 2000,
      });
    }

    if (userUniqueNameError || userNameError) {
      analytics?.track('Clicked update profile failed', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        reason: userUniqueNameError || userNameError,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.error(userUniqueNameError || userNameError, {
        autoClose: 2000,
        toastId: '678',
      });
    }
  }, [
    userNameError,
    userUniqueNameError,
    userUniqueNameResponse,
    userNameResponse,
    userData,
  ]);

  const handleUpdateProfile = () => {
    analytics?.track('Clicked update profile button', {
      from: 'Settings tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    dispatch({
      type: PROFILE_CLEANUP,
    });

    if (userData && userData.payload.name !== name) {
      dispatch(addName(name));
    }
    if (userData && userData.payload.username !== userName) {
      dispatch(addUniqueUserName(userName));
    }
  };

  useEffect(() => {
    if (userData && userData.payload) {
      setName(userData.payload.name);
      setUserName(userData.payload.username);
    }
    return () =>
      dispatch({
        type: PROFILE_CLEANUP,
      });
  }, []);

  return (
    <>
      <div className="popup__item js-tabs-item" style={{ display: 'block' }}>
        {loading && !error && !userData ? (
          <>
            <SkeletonContainer count={10} width={'50%'} height="100px" />
          </>
        ) : (
          <>
            <ProfileImage
              profileImg={
                userData?.payload?.img ? userData.payload.img : UserProfileImage
              }
            />
            <div className="popup__fieldset">
              <div className="popup__row">
                <ProfileFormInput
                  label="Name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <ProfileFormInput
                  label="Username"
                  type="text"
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                />
              </div>
            </div>
            <div
              style={{ display: 'grid', placeItems: 'center', marginTop: 32 }}
            >
              {userNameLoading || userUniqueNameLoading ? (
                <LoadingBtn addClassName="card__btn" />
              ) : (
                <CustomButton
                  disabled={
                    userData.payload.name === name &&
                    userData.payload.username === userName
                  }
                  title="Update Profile"
                  onClick={handleUpdateProfile}
                />
              )}
            </div>
          </>
        )}
        <div className="popup__user popup__logout">
          <div className="popup__label popup__label_mb32">Logout</div>
          <div className="popup__line">
            <div className="popup__btns">
              <div className="popup__loading">
                <button
                  className="popup__upload"
                  onClick={() => {
                    dispatch(logout(true));
                    analytics?.track('Clicked logout button', {
                      from: 'Settings tab',
                      uid: userInfo?.payload?.uid,
                      QueryParams: { ...getQueryParams() },
                      ip: ip,
                    });
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <button className="popup__default">You will be missed</button>
        </div>
      </div>
    </>
  );
}

export default ProfileTab;
