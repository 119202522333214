import React, { useEffect, useState } from 'react';
import HelmetContainer from '../../components/common/helmet/helmet';
import Layout from '../../components/layout/layout';
import WalletsTotalBalances from '../../components/wallets/wallets-total-balances/wallets-total-balances';
import WalletsAssestsTotal from '../../components/wallets/wallets-assests-balance/wallets-assests-balance';
import WalletsTotalBalance from '../../components/wallets/wallets-total-balance/wallets-total-balance';
import EthereumImage from '../../assests/img/logo/ethereum.png';
import Icon from '../../components/common/icons/icons';
import WalletDesposit from '../../components/wallets/wallet-deposit/wallet-deposit';
import WalletWithdraw from '../../components/wallets/wallet-withdraw/wallet-withdraw';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBalance,
  getPortolio,
  getUserIPAddress,
} from '../../actions/userAction';
import { getAssests, getCoinsDetails } from '../../actions/assestsAction';
import useBalance from '../../utils/useBalance';
import Toast from '../../components/common/toast/toast';
import { getDepositAddress } from '../../actions/walletAction';
import { toast } from 'react-toastify';
import NotFoundImg from '../../assests/img/notfound.png';
import WalletsBankPopup from '../../components/wallets/wallets-bank-popup/wallets-bank-popup';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';

function WalletsPage() {
  const [visibleSideBar, setVisibleSideBar] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [remainingItem, setRemainingItem] = useState(null);
  const {
    getAssests: getAssest,
    userGetBalance,
    getCoins,
  } = useSelector((state) => state);
  const { loading, error, balance } = userGetBalance;
  const { loading: assetLoading, error: assetError, assetsData } = getAssest;
  const { coinsDetails } = getCoins;
  const { getInvestedPrice } = useBalance();
  const [openBankDetailsPopUp, setOpenBankDetailsPopup] = useState(false);
  const [openBankDetailsWalletPopUp, setOpenBankDetailsWalletPopUp] =
    useState(false);
  const { analytics } = useAnalytics();

  const [initiatorDepositState, setInitiatorDepositState] = useState('');
  const {
    userLogin: { userInfo },
    getPortfolio: {
      loading: getPortfolioLoading,
      error: getPortfolioError,
      response: getPortfolioResponse,
    },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { getInfoAboutCoins } = useBalance();

  useEffect(() => {
    if (!balance) {
      dispatch(getBalance());
    }
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
    if (!getPortfolioResponse) {
      dispatch(getPortolio());
    }
  }, []);

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  const initiator = async () => {
    if (selectedData?.currency == 'inr') {
      setInitiatorDepositState({ fiat: true, isLoading: false });
    } else {
      let data = await getDepositAddress(
        userInfo.token.access,
        selectedData?.currency
      );
      if (data['status']) {
        if (data['data']['payload']['address']) {
          let memoChecker = data['data']['payload']['address'].split('?dt=');
          //console.log(memoChecker, 'memoChecker');
          if (memoChecker.length > 1) {
            setInitiatorDepositState({
              address: memoChecker[0],
              dTag: memoChecker[1],
              isLoading: false,
            });
          } else {
            setInitiatorDepositState({
              address: data['data']['payload']['address'],
              isLoading: false,
            });
          }
        } else {
          setInitiatorDepositState({ isLoading: false, error: true });
          toast.info('Address generation in process');
        }
      } else {
        setInitiatorDepositState({
          isLoading: false,
          error: true,
          fatal: true,
        });
        toast.error(data['data']);
      }
    }
  };

  function filterItem() {
    let result = coinsDetails?.convertedArray?.filter(
      (o1) => !balance?.payload?.some((o2) => o1.symbol === o2.currency)
    );
    setRemainingItem(result);
  }

  useEffect(() => {
    filterItem();
  }, [assetsData, coinsDetails, balance]);

  useEffect(() => {
    analytics?.page('Wallets Page', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
  }, []);

  return (
    <Layout>
      <HelmetContainer title="Wallet" />
      <Toast />
      <div className="wallets">
        <div className="wallets__container">
          <div className={`wallets__wrapper ${visibleSideBar ? 'small' : ''} `}>
            <WalletsTotalBalances
              openBankDetailsWalletPopUp={openBankDetailsWalletPopUp}
              setOpenBankDetailsWalletPopUp={setOpenBankDetailsWalletPopUp}
              visibleSideBar={visibleSideBar}
              setVisibleSideBar={setVisibleSideBar}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
            />
          </div>
          {/* {(!balance && error) || balance?.payload?.length === 0 ? (
            <div
              className="h6"
              style={{ display: 'grid', placeItems: 'center' }}
            >
              <img
                src={NotFoundImg}
                alt=""
                style={{ width: 300, marginBottom: 22 }}
              />
              No Balance 💰
            </div>
          ) : ( */}
          <WalletsAssestsTotal
            visibleSideBar={visibleSideBar}
            setVisibleSideBar={setVisibleSideBar}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            remainingItem={remainingItem}
          />
          {/* )} */}
        </div>
        <div className={`wallets__sidebar ${visibleSideBar ? 'visible' : ''}`}>
          <div className="wallets__wrap">
            <button
              className="wallets__close"
              onClick={() => setVisibleSideBar(null)}
            >
              <Icon icon="close" className="icon" />
            </button>
            <div className="wallets__head">
              <div className="wallets__logo">
                {selectedData?.currency && (
                  <img
                    src={getInfoAboutCoins(selectedData.currency)?.logo}
                    alt=""
                  />
                )}
              </div>
              <div className="wallets__details">
                <div className="wallets__info">
                  {selectedData?.currency &&
                    selectedData?.currency?.toUpperCase()}{' '}
                  Balance
                </div>
                <div className="wallets__company">
                  {selectedData?.currency &&
                    getInfoAboutCoins(selectedData?.currency)?.name}
                </div>
              </div>
            </div>
            <div
              style={{
                display: visibleSideBar === 'total-balance' ? 'block' : 'none',
              }}
            >
              <WalletsTotalBalance
                visibleSideBar={visibleSideBar}
                setVisibleSideBar={setVisibleSideBar}
                selectedData={selectedData}
                initiator={initiator}
                setOpenBankDetailsPopup={setOpenBankDetailsPopup}
                openBankDetailsPopUp={openBankDetailsPopUp}
                setInitiatorDepositState={setInitiatorDepositState}
                initiatorDepositState={initiatorDepositState}
              />
            </div>
            <div className="operations">
              <WalletWithdraw
                visibleSideBar={visibleSideBar}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setVisibleSideBar={setVisibleSideBar}
              />
              <WalletDesposit
                visibleSideBar={visibleSideBar}
                selectedData={selectedData}
                initiator={initiator}
                setVisibleSideBar={setVisibleSideBar}
                setInitiatorDepositState={setInitiatorDepositState}
                initiatorDepositState={initiatorDepositState}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default WalletsPage;
