import React from 'react';
import useViewport from '../../../utils/useViewPort';
import OrderSort from './order-sort/order-sort';
import OrderTable from './order-table/order-table';

function OrderBook({ selectedtab, selectedCell }) {
  const { width } = useViewport();
  const breakpoint = 790;
  return (
    <div>
      {width < 790 && (
        <div
          className="orders"
          style={{
            display:
              selectedtab === 'orders' && width < breakpoint ? 'block' : 'none',
          }}
        >
          <div className="orders__head">
            <div className="orders__title h6">Order Book</div>
            {/* <OrderSort /> */}
          </div>
          <OrderTable selectedCell={selectedCell} />
        </div>
      )}
    </div>
  );
}

export default OrderBook;
