import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { kycViewDetails, networkCheck } from '../../actions/kycAction';
import { toogleSetting } from '../../actions/settingAction';
import { userDetails } from '../../actions/userAction';
import CustomSelect from '../../components/common/custom-select/custom-select';
import HelmetContainer from '../../components/common/helmet/helmet';
import Toast from '../../components/common/toast/toast';
import BankDetails from '../../components/settings/settings-tabs/bank-details/bank-details';
import HelpAndFeedBack from '../../components/settings/settings-tabs/feedback/feedback';
import Kyc from '../../components/settings/settings-tabs/kyc/kyc';
import LoginDetails from '../../components/settings/settings-tabs/login-details/login-details';
import PasswordTab from '../../components/settings/settings-tabs/password/password';
import ProfileTab from '../../components/settings/settings-tabs/profile/profile';
import TwoFactorAuthentication from '../../components/settings/settings-tabs/two-factor-authentication/two-factor-authentication';
import { SETTING_CLEANUP } from '../../constants/settingConstant';
import { getQueryParams } from '../../utils/query-helper';
import useAnalytics from '../../utils/useAnalytics';

function Settings() {
  const {
    userLogin: { userInfo, loading: uLoading },
    networkCheck: { loading, error, response },
    setting: { open: settingOpen, screenState },
    userDetails: { userData },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { analytics } = useAnalytics();

  const [tabData, setTabData] = useState([
    { label: 'Profile', value: 'Profile' },
    // { label: 'Referrals', value: 'Referrals' },
    // { label: 'Password', value: 'Password' },
    { label: 'KYC', value: 'KYC' },
    { label: 'Bank & UPI', value: 'Bank & UPI' },
    // {
    //   label: 'Sessions & Login History',
    //   value: 'Sessions & Login History',
    // },
    {
      label: 'Help and Feedback',
      value: 'Help and Feedback',
    },
  ]);

  useEffect(() => {
    const item = tabData.find((item) => item.label === '2FA');

    //console.log(item);
    if (!response?.payload?.otp && !item) {
      setTabData([...tabData, { label: '2FA', value: '2FA' }]);
    }
    if (response?.payload?.otp && item) {
      tabData.splice(tabData.indexOf(item), 0);
      setTabData(tabData);
    }

    if (!userInfo['is_oauth']) {
      setTabData((prev) => [...prev, { label: 'Password', value: 'Password' }]);
    }
  }, [response, loading, error, userInfo, uLoading]);

  const [sectionState, setSectionState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (screenState == 'KYC') {
      setSectionState({ label: 'KYC', value: 'KYC' });
    } else {
      setSectionState(tabData[0]);
    }
  }, []);

  const {
    theme: { isdark },
  } = useSelector((state) => state);

  const renderTab = () => {
    if (sectionState) {
      if (sectionState.label === 'Profile') {
        return <ProfileTab />;
      } else if (sectionState.label === 'Password') {
        return <PasswordTab />;
      } else if (sectionState.label === 'Sessions & Login History') {
        return <LoginDetails />;
      } else if (sectionState.label === '2FA') {
        return <TwoFactorAuthentication />;
      } else if (sectionState.label === 'KYC') {
        return <Kyc />;
      } else if (sectionState.label === 'Help and Feedback') {
        return <HelpAndFeedBack />;
      } else if (sectionState.label === 'Bank & UPI') {
        return <BankDetails />;
      }
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.token.access) {
      dispatch(kycViewDetails());
    }
  }, [userInfo]);

  useEffect(() => {
    analytics?.track('Setting tab', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics]);

  useEffect(() => {
    analytics?.track('Clicked settings tab', {
      from: 'Settings Tab',
      uid: userInfo?.payload?.uid,
      keyword: sectionState?.label,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, sectionState, ip]);

  useEffect(() => {
    return () =>
      dispatch({
        type: SETTING_CLEANUP,
      });
  }, []);

  useEffect(() => {
    if (!userData) {
      dispatch(userDetails());
    }
  }, [userData]);

  const location = useLocation();
  const history = useHistory();

  const handleRemoveRedirect = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('redirect_to')) {
      queryParams.delete('redirect_to');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  return (
    <>
      <HelmetContainer title="Settings" />
      <div className="mfp-bg mfp-zoom-in mfp-ready"></div>
      <Toast />
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-zoom-in mfp-ready"
        style={{ overflow: 'hidden auto' }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div className="popup popup_settings" id="popup-settings">
              <div className="popup__title h6">Account Settings</div>
              <div className="popup__tabs js-tabs">
                <div className={`${isdark ? 'dark' : ''}`}>
                  <div className="popup__select mobile-show setting_select">
                    <CustomSelect
                      defaultValue={
                        screenState === 'KYC'
                          ? { label: 'KYC', value: 'KYC' }
                          : sectionState
                          ? sectionState
                          : tabData[0]
                      }
                      options={tabData}
                      onChange={(data) => setSectionState(data)}
                    />
                  </div>
                </div>
                <div className="popup__nav mobile-hide">
                  {tabData.map((item, idx) => (
                    <button
                      className={`popup__link js-tabs-link ${
                        item.label === sectionState?.label ? 'active' : ''
                      } `}
                      onClick={() => setSectionState(item)}
                      key={idx}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                <div className="popup__select mobile-show"></div>
                <div className="popup__container">{renderTab()}</div>
              </div>
              <button
                title="Close (Esc)"
                type="button"
                className="mfp-close"
                onClick={() => {
                  dispatch(toogleSetting());
                  setSectionState(tabData[0]);
                  handleRemoveRedirect();
                }}
              >
                ×
              </button>
            </div>
          </div>
          <div className="mfp-preloader">Loading...</div>
        </div>
      </div>
    </>
  );
}

export default Settings;
