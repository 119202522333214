import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  updateBankNumber,
  updateIFSCDetail,
  updateUPIDetail,
  userDetails,
} from '../../../../actions/userAction';
import { PROFILE_CLEANUP } from '../../../../constants/userConstant';
import { getQueryParams } from '../../../../utils/query-helper';
import useAnalytics from '../../../../utils/useAnalytics';
import CustomButton from '../../../common/custom-button/custom-button';
import LoadingBtn from '../../../common/loading-btn/loading-btn';
import ProfileFormInput from '../profile/profile-form-input/profile-form-input';

function BankDetails() {
  const [IFSC, setIFSC] = useState('');
  const [accountNumber, setAccountNumberName] = useState('');
  const [UPI, setUPI] = useState('');
  const { analytics } = useAnalytics();

  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const handleUpdateBankDetails = () => {
    analytics?.track('Clicked update bank details button', {
      from: 'Settings tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    dispatch(updateBankNumber(accountNumber));
    dispatch(updateUPIDetail(UPI));
    dispatch(updateIFSCDetail(IFSC));
  };

  const dispatch = useDispatch();

  const {
    userDetails: { userData, loading, error },
    updateBankNumber: {
      loading: bankDetailsLoading,
      error: bankDetailsError,
      userData: bankDetailsData,
    },
    updateUPIDetail: {
      userData: upiData,
      loading: upiLoading,
      error: upiError,
    },
    updateIFSCDetail: {
      userData: ifscData,
      loading: ifscLoading,
      error: ifscError,
    },
    userLogin: { userInfo },
  } = useSelector((state) => state);

  useEffect(() => {
    if (bankDetailsData && upiData && ifscData) {
      analytics?.track('Clicked update bank details success', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      dispatch(userDetails());
      return toast.success(bankDetailsData.message, {
        autoClose: 2000,
        toastId: '1',
      });
    }
    if (bankDetailsError || upiError || ifscError) {
      analytics?.track('Clicked update bank details failed', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        reason: upiError || ifscError || bankDetailsError,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.error(bankDetailsError || upiError || ifscError, {
        autoClose: 2000,
        toastId: '2',
      });
    }
  }, [
    bankDetailsError,
    bankDetailsData,
    upiData,
    ifscData,
    ifscError,
    upiError,
  ]);

  useEffect(() => {
    if (userData && userData.payload) {
      setIFSC(userData.payload.ifsc_code);
      setAccountNumberName(userData.payload.account_number);
      setUPI(userData.payload.upi_id);
    }
  }, [userData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: PROFILE_CLEANUP,
      });
    };
  }, []);

  return (
    <div>
      <div className="popup__title h6">Update your bank & UPI details</div>
      <div className="popup__fieldset">
        <div className="popup__row">
          <ProfileFormInput
            label="IFSC Code"
            type="text"
            placeholder="ABCDXXXXXX"
            value={IFSC}
            onChange={(e) => setIFSC(e.target.value)}
          />
          <ProfileFormInput
            label="Acccout Number"
            type="text"
            placeholder="124456789"
            value={accountNumber}
            onChange={(e) => setAccountNumberName(e.target.value)}
          />
        </div>
        <div className="popup__row">
          <ProfileFormInput
            label="UPI ID"
            type="text"
            placeholder="ramesh@bank"
            value={UPI}
            onChange={(e) => setUPI(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'grid', placeItems: 'center', marginTop: 32 }}>
        {bankDetailsLoading ? (
          <LoadingBtn addClassName="card__btn" />
        ) : (
          <CustomButton
            disabled={
              userData.payload.ifsc_code === IFSC &&
              userData.payload.account_number === accountNumber &&
              userData.payload.upi_id === UPI
            }
            title="Update Bank Details"
            onClick={handleUpdateBankDetails}
          />
        )}
      </div>
    </div>
  );
}

export default BankDetails;
