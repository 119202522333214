import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BannerImage from '../../../assests/img/figures-2.png';
import { getQueryParams } from '../../../utils/query-helper';
import useAnalytics from '../../../utils/useAnalytics';
import Icon from '../../common/icons/icons';

function SearchBanner() {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { analytics } = useAnalytics();

  useEffect(() => {
    if (searchText.length > 2) {
      analytics?.track('Search the coin', {
        from: 'price',
        uid: userInfo?.payload?.uid,
        keyword: searchText,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      const data = coinsDetails.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setFilteredData(data);
    }
  }, [searchText, analytics]);

  return (
    <div className="search">
      <div className="search__container">
        <div className="search__wrap">
          <div className="search__title h4">Search by token name, symbol</div>
          <div className="search__info">
            Find the best prices across exchange networks
          </div>
        </div>
        <form className="search__form">
          <input
            className="search__input"
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search"
          />
          <button className="search__btn">
            <Icon icon="search" className="icon" />
          </button>
          <div
            className={`details__company ${
              filteredData.length !== 0 && searchText.length > 2 ? 'active' : ''
            } `}
            style={{ width: '100%' }}
          >
            <div className="details__body">
              {filteredData.map((item, idx) => {
                return (
                  <Link
                    key={idx}
                    className="details__item"
                    to={(location) => ({
                      ...location,
                      pathname: `/pricedetails/${item.symbol}`,
                    })}
                  >
                    <div className="details__logo">
                      <img src={item.logo} alt="" />
                    </div>
                    <div className="details__desc">
                      <div className="details__info">{item.name}</div>
                      <div
                        className="details__currency"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {item.symbol}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </form>
        <div className="search__preview">
          <img src={BannerImage} alt="search banner" />
        </div>
      </div>
    </div>
  );
}

export default SearchBanner;
