import axios from 'axios';
import { baseUrl } from '../utils/urls';

//Not an action
export const getBeneficiary = async (token, currency) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {
    state: 'active',
    currency: currency,
  };
  return await axios
    .post(`${baseUrl}/user/getbeneficiary/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const createFiatBeneficiary = async (token) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {};
  return await axios
    .post(`${baseUrl}/user/createfiatbeneficiary/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const verifyBeneficiary = async (token, id, pin) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {
    id: id,
    pin: pin,
  };
  return await axios
    .post(`${baseUrl}/user/verifybeneficiary/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const createCryptoWithdrawal = async (
  token,
  otp,
  currency,
  amount,
  id,
  note = false
) => {
  //console.log(otp, currency, amount, id, 'API');
  const AuthStr = 'JWT '.concat(token);
  let data = {
    otp: otp,
    currency: currency,
    amount: amount,
    note: 'Done through Krypto App',
    id: id,
  };
  if (note) {
    data = {
      otp: otp,
      currency: currency,
      amount: amount,
      note: note,
      id: id,
    };
  }
  return await axios
    .post(`${baseUrl}/user/createwithdraw/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const withdrawApproval = async (token, withdraw_id) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {
    withdraw_id: withdraw_id,
  };
  return await axios
    .post(`${baseUrl}/transaction/instantwithdraw/approval/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const withdrawProcess = async (token, withdraw_id) => {
  //console.log('Calling Withdraw Finally');
  const AuthStr = 'JWT '.concat(token);
  const data = {
    withdraw_id: withdraw_id,
    location: '33.4834783/38.7438472389',
  };
  return await axios
    .post(`${baseUrl}/transaction/instantwithdraw/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const createBeneficiary = async (token, name, currency, address) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {
    name: name,
    currency: currency,
    address: address,
  };
  //// console.log(data);
  return await axios
    .post(`${baseUrl}/user/createbeneficiary/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

//Deposit

export const getDepositAddress = async (token, currency) => {
  const AuthStr = 'JWT '.concat(token);
  const data = {
    currency: currency,
  };
  return await axios
    .post(`${baseUrl}/user/getaddress/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const paytmStart = async (token, amount, params) => {
  const AuthStr = 'JWT '.concat(token);
  let data = {};
  let size = Object.keys(params).length;
  if (size != 0) {
    data = {
      amount: Math.abs(amount),
      promotional_params: params,
    };
  } else {
    data = {
      amount: Math.abs(amount),
    };
  }
  return await axios
    .post(`${baseUrl}/payment/web/start/`, data, {
      headers: { Authorization: AuthStr },
    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      //console.log(error);
      return {
        status: false,
        data: error.response.data['message'],
        detail: error,
      };
    });
};

export const paytmStatus = async (token, mid, order_id) => {
  const AuthStr = 'JWT '.concat(token);
  try {
    const config = {
      headers: { Authorization: AuthStr },
      params: {
        mid: mid,
        order_id: order_id,
      },
    };
    const data = await axios.get(`${baseUrl}/payment/status/check/`, config);
    return data;
  } catch (e) {
    // console.log(e);
    throw e.response.data['message'];
  }
};

export const paytmStartv2 = async (token, amount) => {
  try {
    const AuthStr = 'JWT '.concat(token);
    const data = await axios.post(
      `${baseUrl}/payment/v4/payment/initiate/`,
      { amount: amount },
      {
        headers: { Authorization: AuthStr },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data['message'];
  }
};

export const paytmStatusv2 = async (token, order_id) => {
  const AuthStr = 'JWT '.concat(token);
  try {
    const config = {
      headers: { Authorization: AuthStr },
    };
    const data = await axios.post(
      `${baseUrl}/payment/v4/payment/status/`,
      { order_id: order_id },
      config
    );
    return data;
  } catch (e) {
    throw e.response;
  }
};
