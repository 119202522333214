import React, { useEffect } from 'react';
import useAnalytics from '../../../../utils/useAnalytics';
import TableCell from '../login-details/table-cell/table-cell';

function HelpAndFeedBack() {
  const { analytics } = useAnalytics();
  const HelpAndFeedBackData = [
    {
      name: 'FAQ',
      link: 'https://letskrypto.com/faq',
    },
    {
      name: 'Videos and Tutorials',
      link: 'https://www.youtube.com/channel/UCtouE0HFn62V76-nCoVpb3A',
    },
    {
      name: 'Contact us',
      link: 'mailto:support@letskrypto.com',
    },
    {
      name: 'Telegram',
      link: 'https://t.me/letskryptoofficial',
    },
  ];

  const SocialMediaData = [
    {
      name: 'Instagram',
      link: 'https://instagram.com/letskrypto',
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UCtouE0HFn62V76-nCoVpb3A',
    },
    {
      name: 'Twitter',
      link: 'https://mobile.twitter.com/letskrypto',
    },
    {
      name: 'Medium',
      link: 'https://letskrypto.medium.com/',
    },
  ];

  const redirectFunc = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <div className="popup__box">
        <div className="popup__title h6">Reach out ot us</div>
        <table className="popup__table">
          <tbody>
            {HelpAndFeedBackData.map((data, idx) => (
              <TableCell data={data} key={idx} redirectFunc={redirectFunc} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="popup__box">
        <div className="popup__title h6">Social Media</div>
        <table className="popup__table">
          <tbody>
            {SocialMediaData.map((data, idx) => (
              <TableCell data={data} key={idx} redirectFunc={redirectFunc} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default HelpAndFeedBack;
