import React, { useState } from 'react';
import NotificationDropDown from '../notification-actions/notification-actions';

function NotificationList({ item, handleOpenPopUp }) {
  const [openState, setOpenState] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const options = [
    {
      name: 'Mark as read',
    },
    {
      name: 'Remove this notification',
    },
    {
      name: 'Follow this read',
    },
    {
      name: 'Hide this notification',
    },
  ];
  return (
    <div className={`notifications__item ${item.readed ? 'disabled' : ''}`}>
      <div className={`notifications__icon ${item.bg}`}>
        <img src={item.img} alt="" />
      </div>
      <div className="notifications__details">
        <div className="notifications__head">
          <div
            className="notifications__title"
            onClick={() => handleOpenPopUp(item)}
          >
            {item.title}
          </div>
          <div className="notifications__time">{item.time}</div>
        </div>
        <div className="notifications__body">
          <div className="notifications__text">{item.text}</div>
          <div className="notifications__status bg-blue-light"></div>
          <NotificationDropDown
            options={options}
            openState={openState}
            setOpenState={setOpenState}
            selectedOption={selectedItem}
            setSelectedOptions={setSelectedItem}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationList;
