import React, { useState, useEffect } from 'react';
import { Analytics, AnalyticsBrowser, Context } from '@segment/analytics-next';
import { analyticsKey } from './urls';

function useAnalytics() {
  const [analytics, setAnalytics] = useState(null);
  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey: analyticsKey });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [analyticsKey]);

  return { analytics };
}

export default useAnalytics;
