import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CLEANUP_KYC_DOC } from '../../../../../constants/kycConstant';
import CustomButton from '../../../../common/custom-button/custom-button';
import ProfileFormInput from '../../profile/profile-form-input/profile-form-input';

function KycDocumentInfo({
  documentFileInfo,
  documentTextInfo,
  handleChangeDocumentFileInfo,
  handleChangeDocumentTextInfo,
  handleSubmit,
  unlockbtn,
}) {
  // imgFront: null,
  // imgBack: null,
  // pan: null,
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: CLEANUP_KYC_DOC, payload: null });
    };
  }, []);

  return (
    <div>
      <div className="kyc_stage__title mobile-show">
        <div className="h5">2 of 3 Kyc Verfication</div>
        <div className="h6">
          Personal Information <br /> Unlock all Krypto Features!
        </div>
      </div>
      <div className="popup__fieldset">
        <div className="popup__row">
          <ProfileFormInput
            label="Enter Uploaded PAN Card Number"
            type="text"
            placeholder="AAAAAA1234"
            name="pan_id"
            onChange={(e) => handleChangeDocumentTextInfo(e)}
            value={documentTextInfo.pan_id}
          />
          {/* <div className="popup__field">
            <div className="popup__label">{label}</div>
            <div className="popup__wrap">
              <input className="popup__input" {...otherProps} />
            </div>
          </div> */}
          <ProfileFormInput
            label="Upload Frontside side of Pan card"
            type="file"
            name="pan"
            isFileInput={true}
            onChange={(e) => handleChangeDocumentFileInfo(e, 'pan')}
            selectedValue={documentFileInfo.pan}
          />
        </div>
        <div className="popup__row">
          <ProfileFormInput
            label="Enter Uploaded Aadhar/Passport"
            type="text"
            name="national_id"
            // onChange={(e) => setName(e.target.value)}
            onChange={(e) => handleChangeDocumentTextInfo(e)}
            value={documentTextInfo.national_id}
            placeholder="xxxxxxxxxxx"
          />
          <ProfileFormInput
            label="Upload Front page/side of ID proof(Aadhar card or Passport)"
            type="file"
            name="imgFront"
            isFileInput={true}
            onChange={(e) => handleChangeDocumentFileInfo(e, 'imgFront')}
            selectedValue={documentFileInfo.imgFront}
          />
        </div>
        <div className="popup__row">
          <ProfileFormInput
            label="Upload Back page/side of ID proof(Aadhar card or Passport)"
            type="file"
            name="imgBack"
            isFileInput={true}
            onChange={(e) => handleChangeDocumentFileInfo(e, 'imgBack')}
            selectedValue={documentFileInfo.imgBack}
          />
          {/* <ProfileFormInput
            label="Selfie"
            type="file"
            name="selfie"
            isFileInput={true}
            onChange={(e) => handleChangeDocumentFileInfo(e, 'selfie')}
            selectedValue={documentFileInfo.imgBack}
          /> */}
        </div>
      </div>

      <div style={{ display: 'grid', placeItems: 'center', marginTop: 60 }}>
        <CustomButton
          title="Next step &#8594;"
          onClick={handleSubmit}
          disabled={unlockbtn < 3}
        />
        <p style={{ fontSize: 10, marginTop: 10 }}>
          &#9888; Uploading fake documents results is the ban of your account
        </p>
      </div>
    </div>
  );
}

export default KycDocumentInfo;
