import * as userConstants from '../constants/userConstant';

export const userLoginReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case userConstants.USER_LOGIN_REQUEST:
      return { loading: true };
    case userConstants.USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case userConstants.USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_LOGOUT:
      return {};
    case userConstants.USER_LOGIN_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_REGISTER_REQUEST:
      return { loading: true };
    case userConstants.USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case userConstants.USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_LOGOUT:
      return {};
    case userConstants.USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case userConstants.USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, response: action.payload };
    case userConstants.USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userAddNameReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_ADD_NAME_REQUEST:
      return { loading: true };
    case userConstants.USER_ADD_NAME_SUCCESS:
      return { loading: false, response: action.payload };
    case userConstants.USER_ADD_NAME_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const userAddUniqueNameReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_ADD_UNIQUE_USERNAME_REQUEST:
      return { loading: true };
    case userConstants.USER_ADD_UNIQUE_USERNAME_SUCCESS:
      return { loading: false, response: action.payload };
    case userConstants.USER_ADD_UNIQUE_USERNAME_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const userGetBalanceReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_GET_BALANCE_REQUEST:
      return { loading: true };
    case userConstants.USER_GET_BALANCE_SUCCESS:
      return { loading: false, balance: action.payload };
    case userConstants.USER_GET_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_DETAILS_REQUEST:
      return { loading: true };
    case userConstants.USER_DETAILS_SUCCESS:
      return { loading: false, userData: action.payload };
    case userConstants.USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_DETAILS_CLEAN:
      return { loading: false, error: null, userData: null };
    default:
      return state;
  }
};

export const updateBankNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.UPDATE_BANK_ACCOUNT_NUMBER_REQUEST:
      return { loading: true };
    case userConstants.UPDATE_BANK_ACCOUNT_NUMBER_SUCCESS:
      return { loading: false, userData: action.payload };
    case userConstants.UPDATE_BANK_ACCOUNT_NUMBER_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const updateUPIDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.UPDATE_UPI_REQUEST:
      return { loading: true };
    case userConstants.UPDATE_UPI_SUCCESS:
      return { loading: false, userData: action.payload };
    case userConstants.UPDATE_UPI_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const updateIFSCDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.UPDATE_IFSC_REQUEST:
      return { loading: true };
    case userConstants.UPDATE_IFSC_SUCCESS:
      return { loading: false, userData: action.payload };
    case userConstants.UPDATE_IFSC_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const updatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.UPDATE_PASSWORD_REQUEST:
      return { loading: true };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return { loading: false, userData: action.payload };
    case userConstants.UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.PROFILE_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const resendMailReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.SEND_EMAIL_CONFIRMATION_REQUEST:
      return { loading: true };
    case userConstants.SEND_EMAIL_CONFIRMATION_SUCCESS:
      return { loading: false, response: action.payload };
    case userConstants.SEND_EMAIL_CONFIRMATION_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.SEND_EMAIL_CONFIRMATION_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const portfolioReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_GET_PORTFOLIO_REQUEST:
      return { loading: true };
    case userConstants.USER_GET_PORTFOLIO_SUCCESS:
      return { loading: false, response: action.payload };
    case userConstants.USER_GET_PORTFOLIO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const googleLoginReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case userConstants.GOOGLE_LOGIN_REQUEST:
      return { loading: true };
    case userConstants.GOOGLE_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case userConstants.GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_LOGOUT:
      return {};
    case userConstants.GOOGLE_LOGIN_RESET:
      return {};
    default:
      return state;
  }
};

export const userDepositLevelReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case userConstants.USER_DEPOSIT_CHECK_REQUEST:
      return { loading: true };
    case userConstants.USER_DEPOSIT_CHECK_SUCCESS:
      return { loading: false, state: action.payload };
    case userConstants.USER_DEPOSIT_CHECK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const user2FALevelReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_2FA_CHECK_REQUEST:
      return { loading: true };
    case userConstants.USER_2FA_CHECK_SUCCESS:
      return { loading: false, state: action.payload };
    case userConstants.USER_2FA_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_2FA_CHECK_CLEANUP:
      return {};
    default:
      return state;
  }
};

export const getUserIPAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_GET_IP_ADDRESS_REQUEST:
      return { loading: true };
    case userConstants.USER_GET_IP_ADDRESS_SUCCESS:
      return { loading: false, ip: action.payload };
    case userConstants.USER_GET_IP_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    case userConstants.USER_GET_IP_ADDRESS_CLEANUP:
      return {};
    default:
      return state;
  }
};
