import React from 'react';
import Icon from '../icons/icons';

function CustomFilterDropDown({
  openState,
  setOpenState,
  options,
  selectedOption,
  setSelectedOptions,
}) {
  return (
    <div className={'dropdown ' + (openState ? 'active' : '')}>
      <button
        className="dropdown__head"
        onClick={() => setOpenState((prev) => !prev)}
      >
        <Icon icon="dots" className="icon" />
      </button>
      <div className="dropdown__body">
        {options.map((data, idx) => (
          <button
            className={`dropdown__link ${
              selectedOption === data.name ? 'selected' : ''
            }`}
            key={idx}
            onClick={() => {
              setSelectedOptions(data.name);
              setOpenState((prev) => !prev);
            }}
          >
            {data.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CustomFilterDropDown;
