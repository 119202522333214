import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updatePassword } from '../../../../actions/userAction';
import { PROFILE_CLEANUP } from '../../../../constants/userConstant';
import { getQueryParams } from '../../../../utils/query-helper';
import useAnalytics from '../../../../utils/useAnalytics';
import AuthButton from '../../../auth/button/button';
import FormInput from '../../../auth/form-input/forminput';
import LoadingBtn from '../../../common/loading-btn/loading-btn';

function PasswordTab() {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const {
    updatePassword: { loading, error, response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { analytics } = useAnalytics();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    analytics?.track('Clicked update password button', {
      from: 'Settings tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    try {
      if (currentPassword === confirmPassword) {
        return toast.error('New Password is not same as Old Password');
      }
      if (confirmPassword === newPassword) {
        let strongPasswordRegex = new RegExp(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
        );
        if (!strongPasswordRegex.test(newPassword)) {
          return toast.error(
            'The password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character ,six characters or longer',
            { toastId: 'password-toast' }
          );
        }
        const data = await updatePassword(
          userInfo.token.access,
          newPassword,
          currentPassword
        );
        toast.success(data.message);
        analytics?.track('update password success', {
          from: 'Settings tab',
          uid: userInfo?.payload?.uid,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        setConfirmPassword('');
        setNewPassword('');
        setCurrentPassword('');
      } else {
        toast.error('Password and confirm password does not match');
      }
    } catch (e) {
      toast.error(e);
      analytics?.track('update password failed', {
        from: 'Settings tab',
        uid: userInfo?.payload?.uid,
        reason: e,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
    }
  };

  useEffect(() => {
    if (response) {
      toast.success(response.message);
    }
    if (error) {
      toast.error(error);
    }
    return () =>
      dispatch({
        type: PROFILE_CLEANUP,
      });
  }, [response, error, loading]);

  return (
    <form className="popup__form">
      <div className="popup__title h6">Change Password</div>
      <FormInput
        value={currentPassword}
        label="current Password"
        type="password"
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <FormInput
        value={newPassword}
        label="new Password"
        type="password"
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <FormInput
        value={confirmPassword}
        label="Confirm new Password"
        type="password"
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {loading ? (
        <LoadingBtn size="full" />
      ) : (
        <AuthButton
          title="Change Password"
          onClick={(e) => handleResetPassword(e)}
        />
      )}
    </form>
  );
}

export default PasswordTab;
