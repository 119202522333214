import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadUserProfileImage } from '../../../../../actions/userAction';
import { userDetails } from '../../../../../actions/userAction';
import LoadingBtn from '../../../../common/loading-btn/loading-btn';

function ProfileImage({ profileImg }) {
  const [userImage, setUserImage] = useState(null);
  const {
    userLogin: { userInfo },
    userDetails: { userData, loading, error },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleChangeProfilePicture = async (e) => {
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    const filePath = e.target.value;

    if (!allowedExtensions.exec(filePath)) {
      return toast.error('Upload a valid file (allowed only jpg/jpeg/png)');
    }

    if (e?.target?.files[0].size > 1000000) {
      return toast.error('Please upload images that are under 1 MB');
    }

    try {
      toast.info('uploading', { toastId: 2000 });
      const { files } = e.target;
      setUserImage(URL.createObjectURL(e.target.files[0]));
      if (files.length) {
        const data = await uploadUserProfileImage(
          userInfo.token.access,
          files[0]
        );
        toast.success(`Successfully uploaded!`);
        dispatch(userDetails());
        //console.log(data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="popup__user">
      <div className="popup__label popup__label_mb32">Your Avatar</div>
      <div className="popup__line">
        <div className="popup__ava">
          {userData?.payload?.img ? (
            <img
              className="popup__pic"
              src={userData?.payload?.img.file}
              alt=""
            />
          ) : (
            <>
              {userImage ? (
                <img className="popup__pic" src={userImage} alt="" />
              ) : (
                <img className="popup__pic" src={profileImg} alt="" />
              )}
            </>
          )}
        </div>
        <div className="popup__details">
          <div className="popup__btns">
            <div className="popup__loading">
              <input
                className="popup__file"
                type="file"
                onChange={(e) => handleChangeProfilePicture(e)}
              />
              <button className="popup__upload">Upload New</button>
            </div>
            {/* <button className="popup__btn btn btn_gray">Delete Avatar</button> */}
          </div>
          <button className="popup__default">New Profile Picture</button>
        </div>
      </div>
    </div>
  );
}

export default ProfileImage;
