import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addWatchList,
  removeWatchList,
  getWatchList,
} from '../../../../actions/assestsAction';
import { getQueryParams } from '../../../../utils/query-helper';
import useAnalytics from '../../../../utils/useAnalytics';
import useBalance from '../../../../utils/useBalance';
import useWebSocketData from '../../../../utils/useWebSocketData';
import Icon from '../../../common/icons/icons';
import SkeletonContainer from '../../../common/skeleton/skeleton';

function PriceTableCell({ data, selectedCategory, handleWatchList }) {
  const {
    priceChangePercent,
    lastPrice,
    getStatus,
    getVolume,
    checkItInWatchList,
  } = useWebSocketData();
  const {
    userWatchList: { loading, response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { analytics } = useAnalytics();
  const location = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { formatINR, getSingleCoinsBalanceInINR } = useBalance();

  return (
    <>
      {data.name !== 'Rupee' && (
        <div className="prices__row">
          {loading ? (
            <SkeletonContainer
              style={{
                display: 'grid',
                placeItems: 'center',
                height: 30,
                width: 30,
                borderRadius: 15,
                marginLeft: 7,
                marginTop: 22,
              }}
            />
          ) : (
            <>
              {checkItInWatchList(data) ? (
                <div
                  className="prices__cell"
                  onClick={() => handleWatchList(data)}
                >
                  <Icon icon="star-fill" className="icon" color="#355dff" />
                </div>
              ) : (
                <div
                  className="prices__cell"
                  onClick={() => handleWatchList(data)}
                >
                  <button className="favorite"></button>
                </div>
              )}
            </>
          )}
          <div
            className="prices__cell"
            onClick={() =>
              history.push({
                pathname: `/pricedetails/${data.symbol}`,
                search: location.search,
              })
            }
          >
            <div className="prices__company">
              <div className="prices__logo">
                <img src={data.logo} alt="" />
              </div>
              <div className="prices__text">{data.name}</div>
            </div>
          </div>
          <div
            className="prices__cell"
            style={{ textTransform: 'uppercase' }}
            onClick={() => {
              history.push({
                pathname: `/pricedetails/${data.symbol}`,
                search: location.search,
              });
              analytics?.track('Clicked the coin!', {
                from: 'Price Page',
                uid: userInfo?.payload?.uid,
                symbol: data.symbol,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            {data.symbol}
          </div>
          <div
            className="prices__cell price_money"
            onClick={() => {
              history.push({
                pathname: `/pricedetails/${data.symbol}`,
                search: location.search,
              });
              analytics?.track('Clicked the coin!', {
                from: 'Price Page',
                uid: userInfo?.payload?.uid,
                symbol: data.symbol,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            {formatINR(lastPrice(data.symbol))}
          </div>
          <div
            className="prices__cell"
            onClick={() => {
              history.push({
                pathname: `/pricedetails/${data.symbol}`,
                search: location.search,
              });
              analytics?.track('Clicked the coin!', {
                from: 'Price Page',
                uid: userInfo?.payload?.uid,
                symbol: data.symbol,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            <div className={`status ${getStatus(data.symbol)}`}>
              {priceChangePercent(data.symbol)}
            </div>
          </div>
          {/* <div className="prices__cell">{data.market_cap}</div> */}
          <div
            className="prices__cell price_money"
            onClick={() => {
              history.push({
                pathname: `/pricedetails/${data.symbol}`,
                search: location.search,
              });
              analytics?.track('Clicked the coin!', {
                from: 'Price Page',
                uid: userInfo?.payload?.uid,
                symbol: data.symbol,
                QueryParams: { ...getQueryParams() },
                ip: ip,
              });
            }}
          >
            ₹ {getSingleCoinsBalanceInINR(data.symbol)}
          </div>
          {/* <div className="prices__cell">
        <div className="prices__chart">
          <PriceTableChart status={data.status} />
        </div>
      </div> */}
        </div>
      )}
    </>
  );
}

export default PriceTableCell;
