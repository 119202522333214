import React from 'react';
import Lottie from 'react-lottie';
import useViewport from '../../../utils/useViewPort';
import CustomButton from '../../common/custom-button/custom-button';

function GuideBanner({
  title,
  subtitle,
  animationOptions,
  btnTitle,
  onClick,
  animationSizePer,
}) {
  const { width } = useViewport();
  return (
    <div className="kyc__slider">
      <Lottie
        options={animationOptions}
        width={width < 500 ? '80%' : animationSizePer}
      />
      <div style={{ textAlign: 'center' }}>
        {title && <div className="h5">{title}</div>}
        {subtitle && <p>{subtitle}</p>}
        {btnTitle && <CustomButton title={btnTitle} onClick={onClick} />}
      </div>
    </div>
  );
}

export default GuideBanner;
