import * as assestsConstants from '../constants/assestsConstant';

export const getAssestsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_ASSETS_DETAILS_WS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_ASSETS_DETAILS_WS_SUCCESS:
      return { loading: false, assetsData: action.payload['global.tickers'] };
    case assestsConstants.GET_ASSETS_DETAILS_WS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCoinsDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_COINS_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_COINS_DETAILS_SUCCESS:
      return { loading: false, coinsDetails: action.payload };
    case assestsConstants.GET_COINS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBannerDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_BANNER_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_BANNER_DETAILS_SUCCESS:
      return { loading: false, bannerDetails: action.payload };
    case assestsConstants.GET_BANNER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_NEWS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_NEWS_SUCCESS:
      return { loading: false, news: action.payload };
    case assestsConstants.GET_NEWS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getChartDataReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_CHART_DATA_REQUEST:
      return { loading: true };
    case assestsConstants.GET_CHART_DATA_SUCCESS:
      return { loading: false, chartData: action.payload };
    case assestsConstants.GET_CHART_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getUserWatchListReducer = (state = {}, action) => {
  switch (action.type) {
    case assestsConstants.GET_WATCHLIST_DETAILS_REQUEST:
      return { loading: true };
    case assestsConstants.GET_WATCHLIST_DETAILS_SUCCESS:
      return { loading: false, response: action.payload };
    case assestsConstants.GET_WATCHLIST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
