import axios from 'axios';
import { useSelector } from 'react-redux';
import * as kycConstants from '../constants/kycConstant';
import { baseUrl } from '../utils/urls';

export const sendPhoneCode = async (token, phoneNo) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    // dispatch({
    //   type: kycConstants.SEND_OTP_KYC_REQUEST,
    // });
    const { data } = await axios.post(
      `${baseUrl}/kyc/sendcode/`,
      { phone_number: phoneNo },
      config
    );
    // dispatch({
    //   type: kycConstants.SEND_OTP_KYC_SUCCESS,
    //   payload: data,
    // });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const verifyPhoneCode = async (token, phoneNo, verification_code) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    // dispatch({
    //   type: kycConstants.VERIFY_OTP_KYC_REQUEST,
    // });
    const { data } = await axios.post(
      `${baseUrl}/kyc/verifycode/`,
      { phone_number: phoneNo, verification_code: verification_code },
      config
    );
    // dispatch({
    //   type: kycConstants.VERIFY_OTP_KYC_SUCCESS,
    //   payload: data,
    // });
    return data;
  } catch (error) {
    // dispatch({
    //   type: kycConstants.VERIFY_OTP_KYC_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    throw error.response.data.message;
  }
};

export const addPersonalInfo =
  ({ firstName, lastName, dob, address, state, city, zip }) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      dispatch({
        type: kycConstants.ADD_PERSONAL_DETAILS_KYC_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/kyc/profile/`,
        {
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          address: address,
          state: 'IN',
          city: city,
          zip: zip,
        },
        config
      );
      dispatch({
        type: kycConstants.ADD_PERSONAL_DETAILS_KYC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: kycConstants.ADD_PERSONAL_DETAILS_KYC_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const networkCheck = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/user/account/`, {}, config);
    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: kycConstants.NETWORK_CHECK_KYC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const kycViewDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: kycConstants.KYC_VIEW_DETAILS_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/kyc/details/`, {}, config);
    dispatch({
      type: kycConstants.KYC_VIEW_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: kycConstants.KYC_VIEW_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ---------- -------- --------- NOT AN ACTIONS,NORMAL FUNCTIONS --------- --------- ----------

export const uploadDocumentText = async (pan_id, national_id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    const { data } = await axios.post(
      `${baseUrl}/kyc/submit/`,
      {
        pan_id: pan_id,
        national_id: national_id,
        version: '8.6.6',
      },
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const uploadDocument = async (element, file, token) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let url = `${baseUrl}/user/`;
    if (element === 'imgFront') {
      url = `${baseUrl}/kyc/idfrontimage`;
    } else if (element === 'imgBack') {
      url = `${baseUrl}/kyc/idbackimage`;
    } else if (element === 'pan') {
      url = `${baseUrl}/kyc/panimage`;
    }

    let formData = new FormData();
    let name = file.name
      .replace(/[^\w\s\.]|_/g, '')
      .replace(/\s+/g, '')
      .toLowerCase();
    let parts = name.split(/\.(?=[^.]*$)/);
    parts[0] = parts[0].replace(/\W+/g, 'i');

    //console.log(file, url);
    const renamedFile = new File([file], parts.join('.'));

    formData.append('file', renamedFile);

    const { data } = await axios.post(
      `${url}/${parts.join('.')}/`,
      formData,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const uploadDocumentSelfie = async (element, imageUrl, token) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    fetch(imageUrl)
      .then((res) => res.blob())
      .then(async (blob) => {
        let fd = new FormData();
        let file = new File([blob], 'userImage.jpeg');
        fd.append('file', file);
        let url = `${baseUrl}/kyc/selfie`;

        const { data } = await axios.post(`${url}/${file.name}/`, fd, config);
        //console.log(data);
        return data;
      });
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
