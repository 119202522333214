import React from 'react';

function Tags({
  setSelectedFilter,
  selectedFilter,
  options,
  addTagclassName,
  addLinksclassName,
  ...otherProps
}) {
  return (
    <div className={addTagclassName}>
      {options.map((data, idx) => (
        <button
          className={`${addLinksclassName} ${
            selectedFilter.label === data.label ? 'active' : ''
          }`}
          {...otherProps}
          key={idx}
          onClick={() => setSelectedFilter(data)}
        >
          {data.label}
        </button>
      ))}
    </div>
  );
}

export default Tags;
