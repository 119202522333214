import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sideCategories } from '../../../utils/data';
import useWebSocketData from '../../../utils/useWebSocketData';
import DetailsWidgetCard from '../../price-details/details-widget-card/details-widget-card';
import CustomFilterDropDown from '../custom-filter-dropdown/custom-filter-dropdown';
import SkeletonContainer from '../skeleton/skeleton';

function SideBarCategories({ scrollToSection }) {
  const [opendropDown, setOpenDropDown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(sideCategories[0].name);
  const { getTopGainer, getTopLooser } = useWebSocketData();
  const [selectedTabData, setSelectedTabData] = useState(null);

  const {
    loading: coinsLoading,
    // error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    // error,
    loading: assestLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);

  useEffect(() => {
    if (selectedFilter === 'Top Gainer') {
      const topGainer = getTopGainer();
      setSelectedTabData(topGainer);
    } else {
      const topLooser = getTopLooser();
      setSelectedTabData(topLooser);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (coinsDetails && assetsData && !selectedTabData) {
      const topGainer = getTopGainer();
      setSelectedTabData(topGainer);
    }
  }, [coinsDetails, coinsLoading, assestLoading]);

  //console.log(selectedTabData, 'selectedTabData');

  return (
    <div className="card">
      <div className="card__head card__head_mb32">
        <div className="card__title h6">
          {selectedFilter === '' ? 'Top Gainers' : selectedFilter}
        </div>
        <div className="dropdown">
          <CustomFilterDropDown
            options={sideCategories}
            openState={opendropDown}
            setOpenState={setOpenDropDown}
            selectedOption={selectedFilter}
            setSelectedOptions={setSelectedFilter}
          />
        </div>
      </div>
      <div className="widgets">
        <div className="widgets__wrap">
          {(coinsLoading || assestLoading || !assetsData || !coinsDetails) &&
            [1, 2, 3, 4].map((item) => (
              <SkeletonContainer
                key={item}
                height="100px"
                style={{ borderRadius: 30, marginBottom: 20 }}
              />
            ))}
          {
            <>
              {!coinsLoading &&
              !assestLoading &&
              selectedTabData &&
              selectedTabData.length > 0 ? (
                selectedTabData
                  .slice(0, 4)
                  .map((data, idx) => (
                    <DetailsWidgetCard
                      scrollToSection={scrollToSection}
                      data={data}
                      key={idx}
                    />
                  ))
              ) : (
                <>
                  {!coinsLoading && !assestLoading && assetsData && (
                    <div className="h6" style={{ textAlign: 'center' }}>
                      No Lists 📊
                    </div>
                  )}
                </>
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default SideBarCategories;
