import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSetting } from '../../../actions/settingAction';
import { changeTheme } from '../../../actions/themeAction';
import { getQueryParams } from '../../../utils/query-helper';
import useAnalytics from '../../../utils/useAnalytics';
import Icon from '../../common/icons/icons';
import ProfileImage from '../../../assests/img/profile.svg';

function NavBottom() {
  const {
    theme: { isdark },
    userDetails: { userData, loading, error },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { analytics } = useAnalytics();

  const dispatch = useDispatch();

  return (
    <div className="sidebar__bottom">
      <label className="switch switch_theme js-switch-theme">
        <input
          className="switch__input"
          type="checkbox"
          onChange={() => {
            dispatch(changeTheme(isdark));
            analytics?.track('User changed theme!', {
              value: isdark ? 'dark' : 'white',
              uid: userInfo?.payload?.uid,
              QueryParams: { ...getQueryParams() },
              ip: ip,
            });
          }}
          checked={isdark ? true : false}
        />
        <span className="switch__in">
          <span className="switch__box"></span>
          <span className="switch__icon">
            <Icon
              icon={`theme-${isdark ? 'light' : 'dark'}`}
              className="icon"
            />
          </span>
        </span>
      </label>
      <div className="sidebar__user">
        {userData && (
          <img
            src={
              userData.payload.img?.file
                ? userData.payload.img.file
                : ProfileImage
            }
            alt=""
            onClick={() => dispatch(toogleSetting())}
          />
        )}
      </div>
    </div>
  );
}

export default NavBottom;
