import React from 'react';
import SkeletonContainer from '../../../common/skeleton/skeleton';

function WidgetSkeleton() {
  return (
    <SkeletonContainer
      className="box__shadow"
      width={'90%'}
      height={'200px'}
      style={{
        borderRadius: 30,
        padding: 10,
        marginLeft: '20px',
        marginBottom: '20px',
      }}
    />
  );
}

export default WidgetSkeleton;
