import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toogleSetting } from '../../../actions/settingAction';
import Icon from '../../common/icons/icons';
import NavItem from '../nav-item/nav-item';
import NavSearch from '../nav-search/nav-search';
import SendMail from '../send-mail/send-mail';
import RewardsIcon from '../../../assests/img/gift.svg';
import useViewport from '../../../utils/useViewPort';
import { toogleNavbar } from '../../../actions/navbarAction';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../../common/tooltip/tooltip';

function NavContainer({ routes, location, isExchangePage }) {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const breakpoint = 1000;
  const {
    navbar: { open },
    theme: { isdark },
  } = useSelector((state) => state);

  return (
    <div className="sidebar__body">
      <nav className="sidebar__nav">
        {routes.map((route, index) => (
          <NavItem
            route={route}
            key={index}
            isExchangePage={isExchangePage}
            active={location.pathname === route.path}
          />
        ))}
        <Link
          className={
            'sidebar__item ' +
            (location.pathname === '/referral' ? 'active' : '')
          }
          data-tip="Referrals"
          to={(location) => ({ ...location, pathname: '/referral' })}
          onClick={() =>
            width <= breakpoint ? dispatch(toogleNavbar()) : null
          }
        >
          <div className="sidebar__icon">
            <img src={RewardsIcon} alt="" style={{ width: 20 }} />
          </div>
          {(!open || isExchangePage) && <Tooltip />}
          <div className="sidebar__text">Referrals</div>
        </Link>
        <Link
          to={location?.pathname}
          className="sidebar__item"
          style={{ fontSize: 14 }}
          onClick={() => dispatch(toogleSetting())}
          data-tip="Settings"
        >
          <div className="sidebar__icon">
            <Icon icon="settings" className="icon" />
            {(!open || isExchangePage) && <Tooltip />}
          </div>
          <div className="sidebar__text">Settings</div>
        </Link>
      </nav>
      <SendMail />
      <NavSearch />
    </div>
  );
}

export default withRouter(NavContainer);
