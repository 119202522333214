import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import useBalance from '../../../utils/useBalance';

function DetailsChart({ chartData, color }) {
  const { formatINR } = useBalance();
  let data = {
    series: [
      {
        data: chartData.payload,
      },
    ],
    xaxis: {
      type: 'numeric',
    },
    options: {
      chart: {
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        type: 'line',
        height: 160,
        width: '100%',
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        show: true,
        width: 3,
        curve: 'smooth',
        // curve: 'straight',
      },
      fill: { colors: 'transparent', opacity: 0.2, type: 'solid' },
      colors: [color],
      tooltip: {
        x: {
          show: true,
          formatter: (data) => {
            //console.log(data);
            return moment.unix(data).format('MMM Do YY');
          },
        },
        y: {
          formatter: (data) => formatINR(data),
          title: {
            formatter: (seriesName) => 'Price : ',
          },
        },
        marker: {
          show: false,
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="area"
      width={'100%'}
      height={132}
    />
  );
}

export default DetailsChart;
