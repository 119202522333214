import React from 'react';
import useBalance from '../../../utils/useBalance';
import Icon from '../../common/icons/icons';
import ExchangeCellDropdownOptions from '../exchange-cell-dropdown-options/exchange-cell-dropdown-options';

function ExchangeDropdown({
  openState,
  closeState,
  defaultValue,
  options,
  onChange,
  result,
  setSearchText,
  searchText,
}) {
  const { getInfoAboutCoins } = useBalance();
  return (
    <div className="exchange__cell">
      <div className={`exchange__company ${openState ? 'active' : ''}`}>
        <div
          className="exchange__head"
          onClick={() => closeState((prev) => !prev)}
        >
          <div className="exchange__logo">
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'flex-end',
              }}
            >
              <img src={defaultValue.logo} alt="" />
              <img
                src={getInfoAboutCoins('inr')?.logo}
                alt=""
                style={{ width: 20, marginLeft: -12 }}
              />
            </div>
          </div>
          <div className="exchange__details">
            <div className="exchange__currency">{defaultValue.name}</div>
            <div
              className="exchange__info"
              style={{ textTransform: 'uppercase', fontFamily: 'Poppins' }}
            >
              {defaultValue.symbol}/INR
            </div>
          </div>
          <div className="exchange__arrow">
            <Icon icon="arrow-down" className="icon" />
          </div>
        </div>
        <div className="exchange__body">
          <div className="f-center">
            <input
              className="actions__input exchange_search__input active"
              type="search"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div style={{ position: 'absolute', right: 15, top: 20 }}>
              <Icon icon="search" className="icon" size={15} color="#808191" />
            </div>
          </div>

          {result.map((data, idx) => (
            <ExchangeCellDropdownOptions
              data={data}
              key={idx}
              setSelectedCell={onChange}
              setOpenselectedCell={closeState}
              setSearchText={setSearchText}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExchangeDropdown;
