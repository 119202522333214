import React from 'react';
import { Link } from 'react-router-dom';
import CustomSelect from '../../common/custom-select/custom-select';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function CategoriesSlider({ data, selectedCategory, setSelectedCategory }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
  };

  //console.log(data);

  return (
    <div className="categories">
      <div className="categories__title h5 mobile-hide">Categories</div>
      <div className="categories__select mobile-show">
        <CustomSelect
          defaultValue={data[1]}
          options={data}
          dynamicHeight={true}
          onChange={(data) => setSelectedCategory(data)}
        />
      </div>
      <div className="categories__container">
        <div className="categories__slider js-slider-categories">
          <Carousel responsive={responsive}>
            {data.map((item, idx) => (
              <div
                className={`categories__item ${
                  selectedCategory.value === item.value ? 'active' : ''
                } `}
                key={idx}
                onClick={() => setSelectedCategory(item)}
                style={{ cursor: 'pointer' }}
              >
                <div className={`categories__preview ${item.bg}`}>
                  <img src={item.img} alt={item.label} />
                </div>
                <div className="categories__text">{item.label}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default CategoriesSlider;
