import React from 'react';
import ReactApexChart from 'react-apexcharts';
import useViewport from '../../../../utils/useViewPort';

function ExchangeDeepChart() {
  const { width } = useViewport();
  const breakpoint = 766;
  const data = {
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      colors: ['#3DBAA2', '#FF7A68'],
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="area"
      height={width < breakpoint ? '350' : '550'}
      className="exchange__chart"
    />
  );
}

export default ExchangeDeepChart;
