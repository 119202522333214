import { TOOGLE_NAV } from '../constants/navbarConstant';

const initialState = {
  open: true,
};

export const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOOGLE_NAV:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
