import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../common/toast/toast';
import {
  addUniqueUserName,
  addName,
  logout,
  userDetails,
} from '../../../actions/userAction';
import FormInput from '../form-input/forminput';
import LoadingBtn from '../../common/loading-btn/loading-btn';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthButton from '../button/button';
import CustomSelecter from '../../common/custom-select/custom-select';
import { country } from '../../../utils/country';
import { networkCheck } from '../../../actions/kycAction';
import { getAssests } from '../../../actions/assestsAction';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function AuthUsername({ history }) {
  const [userInfo, setUserInfo] = useState({
    name: '',
    uniqueName: '',
  });
  const [nationality, setNationality] = useState(country[0].value);
  const { analytics } = useAnalytics();

  const {
    userAddName,
    userAddUniqueName,
    userLogin,
    userDetails: userDataState,
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();

  const { userInfo: userLoginInfo } = userLogin;
  const { userData } = userDataState;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const {
    loading: userNameLoading,
    error: userNameError,
    response: userNameResponse,
  } = userAddName;
  const {
    loading: userUniqueNameLoading,
    error: userUniqueNameError,
    response: userUniqueNameResponse,
  } = userAddUniqueName;

  useEffect(() => {
    if (userNameError || userUniqueNameError) {
      analytics?.track('User Profile Edit failed!', {
        from: 'profile page',
        uid: userInfo?.payload?.uid,
        reason: userNameError ? userNameError : userUniqueNameError,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.error(userNameError || userUniqueNameError);
    }
    if (
      userNameResponse &&
      userUniqueNameResponse &&
      userLoginInfo &&
      userLoginInfo?.token
    ) {
      analytics?.track('User Profile Edit success!', {
        from: 'profile page',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      dispatch(userDetails());
      history.push({ pathname: '/', search: location.search });
    }
  }, [
    userLoginInfo,
    history,
    userUniqueNameError,
    userNameError,
    userNameResponse,
    userUniqueNameResponse,
  ]);

  const handleLogout = () => {
    analytics?.track('User Login with other account clicked!', {
      from: 'profile page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    dispatch(logout());
    analytics?.track('User Login with other account success!', {
      from: 'profile page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    history.push({ pathname: '/signin', search: location.search });
  };

  const handleSubmit = () => {
    analytics?.track('User Profile Edit button clicked!', {
      from: 'profile page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    const onlyAllowedUnderScoreRe = /^[a-zA-Z0-9_]+$/;

    if (userInfo.name === '' || userInfo.uniqueName === '') {
      return toast.error('Please fill in all the required fields.');
    }

    if (!onlyAllowedUnderScoreRe.test(userInfo.uniqueName)) {
      return toast.error('Invalid characters in Unique Username field.');
    }

    if (!userNameResponse || userNameError) {
      dispatch(addName(userInfo.name, nationality));
    }
    if (!userUniqueNameResponse || userUniqueNameError)
      dispatch(addUniqueUserName(userInfo.uniqueName));
  };

  useEffect(() => {
    dispatch(userDetails());
    if (userData?.payload?.name) {
      history.push({ pathname: '/', search: location.search });
    }
    dispatch(getAssests());
  }, [userData]);

  return (
    <div className="login__col">
      <div className="login__form">
        <div className="login__stage h4">Pick your profile</div>
        <Toast />
        <form>
          <FormInput
            label="Name"
            type="text"
            name="name"
            value={userInfo.name}
            onChange={(e) => handleChange(e)}
          />
          <FormInput
            label="Unique userName"
            type="text"
            name="uniqueName"
            value={userInfo.uniqueName}
            onChange={(e) => handleChange(e)}
          />
          <div className="signup_dropdown">
            <CustomSelecter
              defaultValue={country[0]}
              options={country}
              onChange={(data) => setNationality(data.value)}
            />
          </div>
        </form>
        <div style={{ marginTop: 40 }}>
          {userNameLoading || userUniqueNameLoading ? (
            <LoadingBtn size="full" />
          ) : (
            <AuthButton title="Submit Details" onClick={handleSubmit} />
          )}
        </div>
        <div className="login__flex">
          <div className="login__text">Login With Another Account ? </div>{' '}
          <div
            className="login__link"
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            Click Here
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AuthUsername);
