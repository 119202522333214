export const GET_ORDERDATA_REQUEST = 'GET_ORDERDATA_REQUEST';
export const GET_ORDERDATA_SUCCESS = 'GET_ORDERDATA_SUCCESS';
export const GET_ORDERDATA_FAIL = 'GET_ORDERDATA_FAIL';
export const HISTORY_PAGE_RESET = 'GET_ORDERDATA_FAIL';

export const GET_TRADEDATA_REQUEST = 'GET_TRADEDATA_REQUEST';
export const GET_TRADEDATA_SUCCESS = 'GET_TRADEDATA_SUCCESS';
export const GET_TRADEDATA_FAIL = 'GET_TRADEDATA_FAIL';

export const GET_WITHDRAWDATA_REQUEST = 'GET_WITHDRAWDATA_REQUEST';
export const GET_WITHDRAWDATA_SUCCESS = 'GET_WITHDRAWDATA_SUCCESS';
export const GET_WITHDRAWDATA_FAIL = 'GET_WITHDRAWDATA_FAIL';

export const GET_DEPOSITSDATA_REQUEST = 'GET_DEPOSITSDATA_REQUEST';
export const GET_DEPOSITSDATA_SUCCESS = 'GET_DEPOSITSDATA_SUCCESS';
export const GET_DEPOSITSDATA_FAIL = 'GET_DEPOSITSDATA_FAIL';
