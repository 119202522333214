import React from 'react';

function OrderSort() {
  return (
    <div className="orders__sorting">
      {[1, 2, 3].map((data, idx) => (
        <button
          className={`orders__link ${data === 3 ? 'active' : ''}`}
          key={idx}
        >
          <span style={{ backgroundColor: '#3DBAA2' }}></span>
          <span style={{ backgroundColor: '#3DBAA2' }}></span>
          {data === 1 && <span style={{ backgroundColor: '#3DBAA2' }}></span>}
          {data === 2 && <span style={{ backgroundColor: '#FF7A68' }}></span>}
          {data === 3 && <span style={{ backgroundColor: '#E4E4E4' }}></span>}
        </button>
      ))}
    </div>
  );
}

export default OrderSort;
