import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, userDetails } from '../../../actions/userAction';
import CustomButton from '../../common/custom-button/custom-button';
import Icon from '../../common/icons/icons';
import RadialBarChart from './radial-bar-chart/radial-bar-chart';
import SkeletonContainer from '../../common/skeleton/skeleton';
import useBalance from '../../../utils/useBalance';
import { useHistory } from 'react-router';
import { toogleSetting } from '../../../actions/settingAction';
import useAnalytics from '../../../utils/useAnalytics';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../utils/query-helper';

function TotalBalance() {
  const dispatch = useDispatch();
  const { analytics } = useAnalytics();
  const location = useLocation();
  const {
    getAssests,
    userGetBalance,
    networkCheck: { loading: nLoading, error: nError, response: nResponse },
    kycViewDetails: {
      loading: kycLoading,
      error: kycError,
      response: kycResponse,
    },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { loading, error, balance } = userGetBalance;
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const [chartData, setChartData] = useState([]);
  const [usd, setUsd] = useState('');
  const history = useHistory();

  const {
    getCurrencyBalances,
    convertINRtoUSD,
    roundUp,
    getChartData,
    formatINR,
  } = useBalance();

  useEffect(() => {
    if (!balance) {
      dispatch(getBalance());
    }
  }, []);

  useEffect(() => {
    async function initialValue() {
      const value = getCurrencyBalances();
      const result = await convertINRtoUSD(value);
      setUsd(result.amount);
    }
    const data = getChartData();
    setChartData(data);
    initialValue();
  }, [balance, loading, assetsData]);

  return (
    <>
      {(loading || assetLoading) && !error && !assetError && (
        <SkeletonContainer
          width={'100%'}
          height={'400px'}
          className="box__shadow"
          style={{ borderRadius: 20, marginBottom: 90 }}
        />
      )}
      {(!loading || !assetLoading) &&
        !assetError &&
        !error &&
        assetsData &&
        balance && (
          <div className="card card_widget text-center">
            <button
              className="card__next"
              onClick={() =>
                history.push({
                  pathname: `wallet`,
                  search: location.search,
                })
              }
            >
              <Icon icon="arrow-up-right" className="icon" />
            </button>
            {balance.payload.length > 0 &&
            parseFloat(formatINR(getCurrencyBalances())) != 0.0 ? (
              <>
                <div className="card__chart card__chart_total-balance">
                  <div id="chart-total-balance mb-4">
                    {!loading && assetsData && !assetLoading && chartData && (
                      <RadialBarChart
                        chartData={chartData}
                        totalBalance={getCurrencyBalances}
                        loading={loading}
                        assetLoading={assetLoading}
                      />
                    )}
                  </div>
                </div>
                <div className="card__inner">
                  <div className="card__title h6">Total Balance</div>

                  <>
                    <div className="card__currency">INR</div>
                    <div
                      className={`card__number ${
                        getCurrencyBalances()?.length > 10 ? 'h5' : 'h4'
                      }`}
                    >
                      {/* {totalbalance === 0 ? 0 : totalbalance} ₹{' '}*/}₹{' '}
                      {formatINR(getCurrencyBalances())
                        ? formatINR(getCurrencyBalances())
                        : 0}
                    </div>
                    {isNaN(usd) || usd == 0 ? null : (
                      <div className="card__price h6 color-green">
                        {isNaN(usd) ? 0 : roundUp(parseFloat(usd))} USD
                      </div>
                    )}
                    {nResponse && nResponse.payload.level < 3 ? (
                      <CustomButton
                        title={` ${
                          kycResponse?.payload?.verification_state === 'pending'
                            ? 'Verfiy KYC Status'
                            : 'Complete KYC'
                        } `}
                        onClick={() => {
                          dispatch(toogleSetting({ screenState: 'KYC' }));
                          analytics?.track(
                            kycResponse?.payload?.verification_state ===
                              'pending'
                              ? 'Verfiy KYC Status button'
                              : 'Complete KYC button',
                            {
                              uid: userInfo?.payload?.uid,
                              from: 'Homepage',
                              ip: ip,
                              QueryParams: { ...getQueryParams() },
                            }
                          );
                        }}
                      />
                    ) : (
                      <CustomButton
                        title="Deposit"
                        onClick={() => {
                          history.push({
                            pathname: `wallet`,
                            search: location.search,
                          });
                          analytics?.track('Deposit button clicked!', {
                            uid: userInfo?.payload?.uid,
                            from: 'Homepage',
                            QueryParams: { ...getQueryParams() },
                            ip: ip,
                          });
                        }}
                      />
                    )}
                  </>
                </div>
              </>
            ) : (
              <div className="card__inner">
                <div className="card__title h6">Total Balance</div>
                <div className="card__number h4">₹ 0</div>
                <div style={{ marginTop: 12 }}>
                  {nResponse && nResponse.payload.level < 3 ? (
                    <CustomButton
                      title={` ${
                        kycResponse?.payload?.verification_state === 'pending'
                          ? 'Verfiy Status'
                          : 'Complete KYC'
                      } `}
                      onClick={() =>
                        dispatch(toogleSetting({ screenState: 'KYC' }))
                      }
                    />
                  ) : (
                    <CustomButton
                      title="Deposit"
                      onClick={() =>
                        history.push({
                          pathname: `wallet`,
                          search: location.search,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
}

export default TotalBalance;
