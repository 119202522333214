export const GET_ASSETS_DETAILS_WS_REQUEST = 'GET_ASSETS_DETAILS_WS_REQUEST';
export const GET_ASSETS_DETAILS_WS_SUCCESS = 'GET_ASSETS_DETAILS_WS_SUCCESS';
export const GET_ASSETS_DETAILS_WS_FAIL = 'GET_ASSETS_DETAILS_WS_FAIL';

export const GET_COINS_DETAILS_REQUEST = 'GET_COINS_DETAILS_REQUEST';
export const GET_COINS_DETAILS_SUCCESS = 'GET_COINS_DETAILS_SUCCESS';
export const GET_COINS_DETAILS_FAIL = 'GET_COINS_DETAILS_FAIL';

export const GET_BANNER_DETAILS_REQUEST = 'GET_BANNER_DETAILS_REQUEST';
export const GET_BANNER_DETAILS_SUCCESS = 'GET_BANNER_DETAILS_SUCCESS';
export const GET_BANNER_DETAILS_FAIL = 'GET_BANNER_DETAILS_FAIL';

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const GET_CHART_DATA_REQUEST = 'GET_CHART_DATA_REQUEST';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_FAIL = 'GET_CHART_DATA_FAIL';

export const GET_WATCHLIST_DETAILS_REQUEST = 'GET_WATCHLIST_DETAILS_REQUEST';
export const GET_WATCHLIST_DETAILS_SUCCESS = 'GET_WATCHLIST_DETAILS_SUCCESS';
export const GET_WATCHLIST_DETAILS_FAIL = 'GET_WATCHLIST_DETAILS_FAIL';

export const ADD_WATCHLIST_REQUEST = 'ADD_WATCHLIST_REQUEST';
export const ADD_WATCHLIST_SUCCESS = 'ADD_WATCHLIST_SUCCESS';
export const ADD_WATCHLIST_FAIL = 'ADD_WATCHLIST_FAIL';

export const REMOVE_WATCHLIST_REQUEST = 'REMOVE_WATCHLIST_REQUEST';
export const REMOVE_WATCHLIST_SUCCESS = 'REMOVE_WATCHLIST_SUCCESS';
export const REMOVE_WATCHLIST_FAIL = 'REMOVE_WATCHLIST_FAIL';
