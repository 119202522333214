import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActivitieshistoryData } from '../../../utils/data';
import ActivitiesCell from './activities-cell/activities-cell';
import SkeletonContainer from '../../../components/common/skeleton/skeleton';
import Pagination from '../../common/pagination/pagination';
import CustomButton from '../../common/custom-button/custom-button';
import {
  getWithdrawsData,
  getOrderData,
  getTradesData,
  getDepositsData,
} from '../../../actions/historyAction';
import NotFoundedImg from '../../../assests/img/notfound.png';
import { toast } from 'react-toastify';
import _ from 'lodash';
import LoadingBtn from '../../common/loading-btn/loading-btn';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';
import { CANCEL_ORDER_CLEANUP } from '../../../constants/exchangeConstant';

function ActivitiesHistory({
  openState,
  handleSideBarToogle,
  sideBardata,
  selectedFilter,
  handleCloseSideBar,
}) {
  const {
    orderData,
    tradeData,
    depositData,
    withDrawData,
    getCoins,
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const { loading: coinsLoading, error: coinsError, coinsDetails } = getCoins;

  const [selectedFilterData, setSelectedFilterData] = useState(null);
  const [selectedFilterDataError, setSelectedFilterDataError] = useState(null);
  const [selectedFilterDataLoading, setSelectedFilterDataLoading] =
    useState(null);

  const dispatch = useDispatch();

  const [withdrawsData, setWithDrawsData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [depositsData, setDepositsData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  const [orderPageCount, setOrderPageCount] = useState(1);
  const [tradePageCount, setTradePageCount] = useState(1);
  const [withdrawsPageCount, setWithdrawsPageCount] = useState(1);
  const [depositsPageCount, setDepositsPageCount] = useState(1);
  const [btnDisable, setBtnDisable] = useState(false);
  const { analytics } = useAnalytics();

  const { loading, error, response: orderResponse } = orderData;
  const {
    loading: tradeLoading,
    error: tradeError,
    response: tradeResponse,
  } = tradeData;

  const {
    loading: depositLoading,
    error: depositError,
    response: depositResponse,
  } = depositData;

  const {
    loading: withDrawLoading,
    error: withDrawError,
    response: withDrawResponse,
  } = withDrawData;

  const {
    cancelOrder: {
      response: cancelOrderResponse,
      loading: cancelOrderLoading,
      error: cancelOrderError,
    },
  } = useSelector((state) => state);

  const handleLoadMore = () => {
    dispatch({
      type: CANCEL_ORDER_CLEANUP,
      payload: null,
    });
    analytics?.track('User clicked load more!', {
      from: 'History Page',
      uid: userInfo?.payload?.uid,
      keyword: selectedFilter.value,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (selectedFilter.value === 'Withdraws') {
      dispatch(getWithdrawsData(withdrawsPageCount + 1, false));
      if (withDrawResponse?.payload.length < 10) {
        setWithdrawsPageCount(0);
        setBtnDisable(true);
      } else {
        setWithdrawsPageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Deposits') {
      dispatch(getDepositsData(depositsPageCount + 1));
      if (depositResponse?.payload.length < 10) {
        setDepositsPageCount(0);
        setBtnDisable(true);
      } else {
        setDepositsPageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Trades') {
      dispatch(getTradesData(tradePageCount + 1));
      if (tradeResponse?.payload.length < 10) {
        setTradePageCount(0);
        setBtnDisable(true);
      } else {
        setTradePageCount((pre) => pre + 1);
      }
    } else if (selectedFilter.value === 'Orders') {
      dispatch(getOrderData(orderPageCount + 1));
      if (orderResponse?.payload.length < 10) {
        setOrderPageCount(0);
        setBtnDisable(true);
      } else {
        setOrderPageCount((pre) => pre + 1);
      }
    }
  };

  useEffect(() => {
    if (
      selectedFilter.value === 'Withdraws' &&
      withDrawResponse?.payload &&
      withDrawResponse?.status !== 'error'
    ) {
      const data = [...withdrawsData, ...withDrawResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setWithDrawsData(uniqData);
      setSelectedFilterDataError(withDrawError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(withDrawLoading);
      setStateLoading(false);
    } else if (
      selectedFilter.value === 'Deposits' &&
      depositResponse?.payload &&
      depositResponse?.status !== 'error'
    ) {
      const data = [...depositsData, ...depositResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setDepositsData(uniqData);
      setSelectedFilterDataError(depositError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(depositLoading);
      setStateLoading(false);
    } else if (
      selectedFilter.value === 'Trades' &&
      tradeResponse?.payload &&
      tradeResponse?.status !== 'error'
    ) {
      const data = [...tradesData, ...tradeResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setTradesData(uniqData);
      setSelectedFilterDataError(tradeError);
      setSelectedFilterData(uniqData);
      setSelectedFilterDataLoading(tradeLoading);
      setStateLoading(false);
    } else if (
      selectedFilter.value === 'Orders' &&
      orderResponse?.payload &&
      orderResponse?.status !== 'error'
    ) {
      const data = cancelOrderResponse
        ? orderResponse?.payload
        : [...ordersData, ...orderResponse?.payload];
      const uniqData = _.uniqBy(data, 'id');
      setSelectedFilterDataError(error);
      setSelectedFilterDataLoading(loading);
      setOrdersData(uniqData);
      setSelectedFilterData(uniqData);
      setStateLoading(false);
    }
  }, [
    loading,
    withDrawLoading,
    tradeLoading,
    depositLoading,
    selectedFilter,
    withDrawResponse,
    tradeResponse,
    orderResponse,
    depositResponse,
  ]);

  useEffect(() => {
    setDepositsData([]);
    setOrdersData([]);
    setTradesData([]);
    setWithDrawsData([]);

    setDepositsPageCount(1);
    setOrderPageCount(1);
    setTradePageCount(1);
    setWithdrawsPageCount(1);
    setBtnDisable(false);
  }, [dispatch, selectedFilter]);

  useEffect(() => {
    if (depositError || withDrawError || tradeError || error) {
      if (
        depositError == 'Unknown Error Occurred' ||
        withDrawError == 'Unknown Error Occurred' ||
        tradeError == 'Unknown Error Occurred' ||
        error == 'Unknown Error Occurred'
      )
        toast.info(
          'Complete your KYC and 2FA to start investing and get FREE crypto rewards 🎉',
          {
            toastId: 'history',
          }
        );
    } else {
      toast.error(depositError || withDrawError || tradeError || error);
    }
  }, [depositError, withDrawError, tradeError, error]);

  useEffect(() => {
    setStateLoading(true);
    setSelectedFilterData([]);
  }, [selectedFilter]);

  return (
    <>
      {/* {loading ||
      withDrawLoading ||
      depositLoading ||
      tradeLoading ||
      coinsLoading ? (
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, idx) => (
          <SkeletonContainer
            key={idx}
            height={'70px'}
            style={{ borderRadius: 20, marginBottom: 10 }}
          />
        ))
      ) : ( */}
      <>
        {(selectedFilterData?.length === 0 || selectedFilterDataError) &&
        !selectedFilterDataLoading &&
        !stateLoading ? (
          <div
            className="h3"
            style={{
              textAlign: 'center',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <img src={NotFoundedImg} alt="" />
          </div>
        ) : (
          <>
            {!selectedFilterDataError && (
              <>
                <div className="activity__stage h6 mobile-hide">History</div>
                <div className="activity__table">
                  <div className="activity__row activity__row_head">
                    <div className="activity__cell"></div>
                    <div className="activity__cell">TYPE</div>
                    <div className="activity__cell">DATE</div>
                    {selectedFilter.value !== 'Trades' &&
                      selectedFilter.value !== 'Orders' && (
                        <>
                          <div className="activity__cell">
                            AMOUNT{' '}
                            <span className="activity__hidden">/ DATE</span>
                          </div>
                          <div className="activity__cell">
                            ADDRESS / TRANSACTION ID
                          </div>
                        </>
                      )}
                    {selectedFilter.value === 'Trades' && (
                      <>
                        <div className="activity__cell"> PRICE</div>
                        <div className="activity__cell">
                          TOTAL <span className="activity__hidden">/ DATE</span>
                        </div>
                      </>
                    )}
                    {selectedFilter.value === 'Orders' && (
                      <>
                        <div className="activity__cell">PRICE</div>
                        <div className="activity__cell">
                          AMOUNT
                          <span className="activity__hidden">/ DATE</span>
                        </div>
                        <div className="activity__cell">
                          EXECUTED{' '}
                          <span className="activity__hidden">/ DATE</span>
                        </div>
                      </>
                    )}
                  </div>
                  {selectedFilterData &&
                    selectedFilterData.map((data, idx) => (
                      <ActivitiesCell
                        data={data}
                        sideBardata={sideBardata}
                        key={idx}
                        selectedFilterValue={selectedFilter.value}
                        openState={openState}
                        handleSideBarToogle={handleSideBarToogle}
                      />
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </>
      {/* )} */}
      {loading ||
      withDrawLoading ||
      depositLoading ||
      tradeLoading ||
      coinsLoading ? (
        <div style={{ display: 'grid', placeItems: 'center', padding: 40 }}>
          <LoadingBtn />
        </div>
      ) : (
        <>
          {!btnDisable && (
            <div style={{ display: 'grid', placeItems: 'center', padding: 40 }}>
              <CustomButton title="Load More" onClick={handleLoadMore} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ActivitiesHistory;
