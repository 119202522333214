import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addPersonalInfo,
  sendPhoneCode,
  verifyPhoneCode,
  uploadDocument,
} from '../../../../actions/kycAction';
import KycDocumentInfo from './kyc-document-info/kyc-document-info';
import KycOtp from './kyc-otp/kyc-otp';
import KycPersonalInfo from './kyc-personal-info/kyc-personal-info';
import KycSelfie from './kyc-selfie/kyc-selfie';
import KycStepper from './kyc-stepper/kyc-stepper';
import ToastContainer from '../../../common/toast/toast';
import axios from 'axios';
import { postalUrl } from '../../../../utils/urls';
import useAnalytics from '../../../../utils/useAnalytics';
import { getQueryParams } from '../../../../utils/query-helper';

function Kyc() {
  const [mobileNo, setMobileNo] = useState('+91');
  const [otp, setOTP] = useState('');
  const [otpStage, setOtpStage] = useState('stage-1');
  const [selfiePicture, setSelfiePicture] = useState(null);
  const [unlockbtn, setUnlockbtn] = useState(0);
  const [kycStage, setKycStage] = useState('stage-1');
  //* kycStage:
  //* stage-1: OTP verf
  //* stage-2: Personal Info
  //* stage-3: Doc verf
  //* stage-4: Selfie (Photo)
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    address: '',
    city: '',
    zip: '',
  });
  const [documentTextInfo, setDocumentTextInfo] = useState({
    pan_id: '',
    national_id: '',
  });

  const [documentFileInfo, setDocumentFileInfo] = useState({
    imgFront: null,
    imgBack: null,
    pan: null,
  });

  const {
    getOtpKyc: { loading, error, response },
    verifyOtpKyc: { loading: vloading, error: vError, response: vresponse },
    addPersonalInfoKyc: {
      loading: ploading,
      error: pError,
      response: pResponse,
    },
    networkCheck: { loading: nloading, error: nError, response: nResponse },
    kycViewDetails: {
      loading: kycDetailsLoading,
      error: kycDetailsError,
      response: kycDetailsResponse,
    },
    userDetails: { userData },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { analytics } = useAnalytics();

  const handleSendPhoneCode = async () => {
    analytics?.track('Clicked send code button', {
      from: 'Setting tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (mobileNo === '') {
      return toast.error('Please enter the valid Mobile Number');
    }
    //indian mobile no regex
    const mobileNoRegex =
      /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
    if (!mobileNoRegex.test(mobileNo)) {
      return toast.error('Please enter the valid Mobile Number');
    }
    try {
      const data = await sendPhoneCode(userInfo.token.access, mobileNo);
      // setMobileNo('');
      toast.success(data.message);
      setOtpStage('stage-2');
      analytics?.track('Send code success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
    } catch (e) {
      analytics?.track('Send code failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
        reason: e,
      });
      toast.error(e);
    }
  };

  const handleVerifyCode = async () => {
    analytics?.track('Clicked verify code', {
      from: 'Setting tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (otp === '') {
      return toast.error('Please enter the valid otp');
    }
    try {
      const data = await verifyPhoneCode(userInfo.token.access, mobileNo, otp);
      toast.success(data.message);
      analytics?.track('Verify code success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        kycStage: 'OTP completed',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setOtpStage('stage-1');
      setKycStage('stage-2');
    } catch (e) {
      analytics?.track('Verify code failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: e,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(e);
    }
  };

  const handleChangePersonalInfo = async (e) => {
    const { value, name } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleChangeDocumentTextInfo = (e) => {
    const { value, name } = e.target;
    setDocumentTextInfo({ ...documentTextInfo, [name]: value });
  };

  const handleChangeDocumentFileInfo = async (e, type) => {
    try {
      const { files, name } = e.target;
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

      const filePath = e.target.value;

      if (!allowedExtensions.exec(filePath)) {
        return toast.error('Upload a valid file (allowed only jpg/jpeg/png) ');
      }

      setDocumentFileInfo({ ...documentFileInfo, [name]: files[0] });
      if (files.length) {
        toast.info('Uploading..', { toastId: 'uploading_doc' });
        const data = await uploadDocument(
          name,
          files[0],
          userInfo.token.access
        );
        const type =
          name === 'pan'
            ? 'Pan Card Image'
            : name === 'imgFront'
            ? 'Front page/side of ID proof'
            : 'Back page/side of ID proof';
        analytics?.track('Added Kyc document success', {
          from: 'Setting tab',
          uid: userInfo?.payload?.uid,
          document: type,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        toast.success(`${type} Successfully uploaded!`);
        setUnlockbtn((prev) => prev + 1);
        //console.log(data, 'data');
      }
    } catch (error) {
      analytics?.track('Added Kyc document failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }
  };

  const handleAddPersonalInfo = () => {
    analytics?.track('Add Personal Information', {
      from: 'Setting tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    const splitedDate = personalInfo.dob.split('-');
    const date = new Date();
    if (date.getFullYear() <= parseInt(splitedDate[0])) {
      return toast.error('Enter the valid date of birth');
    } else {
      const d = new Date(personalInfo.dob);
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      const formatChangedDate = `${da}/${mo}/${ye}`;
      const personalData = {
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        dob: formatChangedDate,
        address: personalInfo.address,
        city: personalInfo.city,
        zip: personalInfo.zip,
      };
      dispatch(addPersonalInfo(personalData));
    }
  };

  const handleSubmit = () => {
    let panRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    let nationalIdRegex = /^[2-9]{1}[0-9]{11}$/;

    if (
      panRegex.test(documentTextInfo.pan_id.toUpperCase().trim()) &&
      nationalIdRegex.test(documentTextInfo.national_id.toUpperCase().trim()) &&
      documentTextInfo.pan_id !== '' &&
      documentTextInfo.national_id !== ''
    ) {
      if (
        documentFileInfo.imgBack &&
        documentFileInfo.imgFront &&
        documentFileInfo.pan &&
        unlockbtn >= 3
      ) {
        localStorage.setItem(
          'krypto.id',
          JSON.stringify({
            pan_id: documentTextInfo.pan_id.trim(),
            n_id: documentTextInfo.national_id.trim(),
          })
        );
        setKycStage('stage-4');
      } else {
        toast.error('Upload all the documents');
      }
    } else {
      toast.error('Enter the valid number for PAN/National Id');
    }
  };

  useEffect(() => {
    if (pError) {
      analytics?.track('Add Personal Information failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: pError,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      return toast.error(pError);
    } else if (pResponse) {
      analytics?.track('Added Personal Information success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        kycStage: 'Personal Information completed',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setKycStage('stage-3');
      return toast.success(pResponse.message);
    }
  }, [pError, pResponse, analytics]);

  useEffect(() => {
    async function handleCountry() {
      if (personalInfo.zip.length >= 5) {
        try {
          const { data } = await axios.get(`${postalUrl}${personalInfo.zip}`);
          if (data[0]?.PostOffice?.length > 0) {
            setPersonalInfo({
              ...personalInfo,
              city: `${data[0].PostOffice[0].Name?.replaceAll(
                '(',
                ''
              ).replaceAll(')', '')}`,
            });
          }
        } catch (e) {
          // console.log(e);
        }
      }
    }
    handleCountry();
  }, [personalInfo.zip]);

  useEffect(() => {
    const checker = localStorage.getItem('krypto.id');

    if (
      nResponse.payload.level === 2 ||
      userData?.payload?.nationality !== 'IN'
    ) {
      if (nResponse?.payload.profiles?.length !== 0) {
        return setKycStage('stage-3');
      } else {
        return setKycStage('stage-2');
      }
    }
    if (
      (nResponse.payload.level === 3 &&
        nResponse.payload.profiles.length > 0) ||
      checker
    ) {
      return setKycStage('stage-4');
    }
  }, [nloading, nResponse, userData]);

  useEffect(() => {
    if (
      kycDetailsResponse?.payload?.verification_state === 'reject' ||
      kycDetailsResponse?.payload?.verification_state === 'rejected'
    ) {
      return toast.error(kycDetailsResponse?.payload?.reject_reason);
    }
  }, [kycDetailsResponse, kycDetailsLoading]);

  return (
    <>
      {kycDetailsResponse &&
      (kycDetailsResponse?.payload?.verification_state === 'pending' ||
        kycDetailsResponse?.payload?.verification_state === 'completed') ? (
        <>
          <div
            className="h4"
            style={{
              textAlign: 'center',
              display: 'grid',
              placeItems: 'center',
              padding: 20,
            }}
          >
            {kycDetailsResponse?.payload?.verification_state === 'pending' &&
              'Your KYC verification is in progress.'}
            {kycDetailsResponse?.payload?.verification_state === 'completed' &&
              'You have completed your KYC'}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <div className="h5" style={{ marginBottom: 20 }}>
              KYC Verification
            </div>
            {kycStage !== 'stage-1' && <KycStepper stage={kycStage} />}
            {kycStage === 'stage-1' && (
              <KycOtp
                mobileNovalue={mobileNo}
                setMobileValue={setMobileNo}
                optValue={otp}
                setOTP={setOTP}
                handleSendPhoneCode={handleSendPhoneCode}
                otpStage={otpStage}
                handleVerifyCode={handleVerifyCode}
              />
            )}
          </div>
          <>
            {kycStage === 'stage-2' && (
              <KycPersonalInfo
                onChange={handleChangePersonalInfo}
                state={personalInfo}
                handleAddPersonalInfo={handleAddPersonalInfo}
              />
            )}
            {kycStage === 'stage-3' && (
              <KycDocumentInfo
                documentFileInfo={documentFileInfo}
                documentTextInfo={documentTextInfo}
                handleChangeDocumentFileInfo={handleChangeDocumentFileInfo}
                handleChangeDocumentTextInfo={handleChangeDocumentTextInfo}
                handleSubmit={handleSubmit}
                unlockbtn={unlockbtn}
              />
            )}
            {kycStage === 'stage-4' && (
              <KycSelfie
                selfiePicture={selfiePicture}
                setSelfiePicture={setSelfiePicture}
              />
            )}
          </>
        </>
      )}
      <a
        href="https://www.letskrypto.com/whykyc"
        target="_blank"
        className="why__kyc"
      >
        <p style={{ fontSize: 10, marginTop: 10, textAlign: 'center' }}>
          Why Verfication ?
        </p>
      </a>
    </>
  );
}

export default Kyc;
