import * as exchangeConstants from '../constants/exchangeConstant';

export const getOrderBookReducer = (state = {}, action) => {
  switch (action.type) {
    case exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_REQUEST:
      return { loading: true };
    case exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_SUCCESS:
      return {
        loading: false,
        orderBookData: action.payload,
      };
    case exchangeConstants.GET_ORDER_BOOK_DETAILS_WS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case exchangeConstants.CREATE_ORDER_REQUEST:
      return { loading: true };
    case exchangeConstants.CREATE_ORDER_SUCCESS:
      return { loading: false, orderDetails: action.payload };
    case exchangeConstants.CREATE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case exchangeConstants.CREATE_ORDER_CLEANUP:
      return { loading: false, error: action.payload, orderDetails: null };
    default:
      return state;
  }
};

export const cancelOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case exchangeConstants.CANCEL_ORDER_REQUEST:
      return { loading: true };
    case exchangeConstants.CANCEL_ORDER_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      };
    case exchangeConstants.CANCEL_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case exchangeConstants.CANCEL_ORDER_CLEANUP:
      return { loading: false, error: action.payload, orderDetails: null };
    default:
      return state;
  }
};
