import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../assests/img/login.svg';
import logoWhite from '../../../assests/img/logo-white.png';

function AuthPreviewImage({ title, subtitle, isSignupPage }) {
  return (
    <div className="login__col overflow-hidden">
      <a className="login__logo" href="https://www.letskrypto.com/">
        <img src={logoWhite} alt="Krypto logo" />
      </a>
      <h1 className="login__title h1">{title}</h1>
      <div className="login__info h6">{subtitle}</div>
      <div className={`login__preview ${isSignupPage ? 'large' : ''}`}>
        <img src={previewImage} alt="previewImage" />
      </div>
    </div>
  );
}

export default AuthPreviewImage;
