import React from 'react';
import { useSelector } from 'react-redux';
import NavHeader from './nav-header/nav-header';
import NavContainer from './nav-container/nav-container';
import NavBottom from './nav-bottom/nav-bottom';
import { routes } from '../../utils/data';

function Navbar({ isExchangePage }) {
  const {
    navbar: { open },
  } = useSelector((state) => state);
  return (
    <div>
      <div
        className={`sidebar ${isExchangePage ? 'small' : ''} ${
          !open ? 'active' : ''
        }`}
      >
        <NavHeader />
        <NavContainer routes={routes} isExchangePage={isExchangePage} />
        <NavBottom isExchangePage={isExchangePage} />
      </div>
    </div>
  );
}

export default Navbar;
