import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toogleNavbar } from '../../../actions/navbarAction';
import CollapseIcon from '../nav-svgs/collapse';
import logo from '../../../assests/img/krypto-logo-dark.png';
import logoDark from '../../../assests/img/logo-white.png';
import { Link } from 'react-router-dom';
import Icon from '../../common/icons/icons';

function NavHeader() {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div className="sidebar__head">
      <Link
        className="sidebar__logo"
        to={(location) => ({ ...location, pathname: '/' })}
      >
        <img
          className={
            'sidebar__pic' +
            (isdark ? 'sidebar__pic_dark' : 'sidebar__pic_light')
          }
          src={isdark ? logoDark : logo}
          style={{ width: '130px' }}
          alt="App logo"
        />
      </Link>
      <button
        className="sidebar__toggle"
        onClick={() => dispatch(toogleNavbar())}
      >
        <CollapseIcon />
      </button>
      <button
        className="sidebar__close"
        onClick={() => dispatch(toogleNavbar())}
      >
        <Icon icon="close" className="icon" />
      </button>
    </div>
  );
}

export default NavHeader;
