export const url = 'prod';

export const webSocketUrl = 'wss://api.letskrypto.com';
export const newsUrl = 'http://127.0.0.1:8000';
export const productionUrl = 'https://api.letskrypto.com';
export const devUrl = 'http://18.183.95.105:8001';
export const postalUrl = 'https://api.postalpincode.in/pincode/';

export const googleAuthKey = process.env.REACT_APP_GOOGLE_AUTH_KEY;
export const analyticsKey = process.env.REACT_APP_ANALYTICS_KEY;
export const payTmKey = process.env.REACT_APP_PAYTM_KEY;
export const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

export const baseUrl = url === 'dev' ? devUrl : productionUrl;
