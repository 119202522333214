import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '../../common/icons/icons';
import AuthButton from '../button/button';
import FormInput from '../form-input/forminput';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserHasEnabled2FA,
  googleLogin,
  login,
  otpLogin,
} from '../../../actions/userAction';
import Toast from '../../common/toast/toast';
import { toast } from 'react-toastify';
import LoadingBtn from '../../common/loading-btn/loading-btn';
import ForgotPassword from '../forgot-password/forgot-password';
import ReCAPTCHA from 'react-google-recaptcha';
import { captchaKey, googleAuthKey, url } from '../../../utils/urls';
import { GoogleLogin } from 'react-google-login';
import Popup from '../../common/popup/popup';
import CustomButton from '../../common/custom-button/custom-button';
import {
  GOOGLE_LOGIN_RESET,
  USER_2FA_CHECK_CLEANUP,
  USER_LOGIN_RESET,
} from '../../../constants/userConstant';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';
import { getAssests } from '../../../actions/assestsAction';
import { scroller } from 'react-scroll';

export function TwoFASupportPrompt({ setCount }) {
  return (
    <div className="twofa_otp_error_container">
      <h3>
        ⚠️ If OTP in your authenticator app is not working, please contact us at{' '}
        <a href="mailto:support@letskrypto.com">support@letskrypto.com</a>
      </h3>
      <i className="fas fa-times" onClick={() => setCount(0)}></i>
    </div>
  );
}

function SignIn({ history, location }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    otp: '',
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [togglePassword, setTooglePassword] = useState(true);
  const [openGoogleAuthPopUp, setOpenGoogleAuthPopup] = useState(false);
  const [open2FASupportPopUp, set2FASupportPopup] = useState(false);
  const [googleToken, setGoogleToken] = useState('');
  const [googleOTP, setGoogleOTP] = useState('');
  const [muteEmail, setMuteEmail] = useState(false);
  const [otpErrorCount, setOtpErrorCount] = useState(0);
  const [oAuthOtpErrorCount, setOAuthOtpErrorCount] = useState(0);

  const dispatch = useDispatch();
  const captchaRef = useRef();
  const { analytics } = useAnalytics();

  const userLogin = useSelector((state) => state.userLogin);
  const googleLoginState = useSelector((state) => state.googleAuth);
  const twoFAData = useSelector((state) => state.twoFAData);
  const {
    theme: { isdark },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { loading, error, userInfo: userLoginInfo } = userLogin;
  const {
    loading: googleLoading,
    error: googleError,
    userInfo: googleLoginInfo,
  } = googleLoginState;

  const { loading: twoFALoading, error: twoFAError, state } = twoFAData;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const resetEmail = () => {
    setMuteEmail(false);
    setUserInfo({ email: '', password: '', otp: '' });
    if (captchaToken) {
      captchaRef.current.reset();
      setCaptchaToken(null);
    }
    dispatch({
      type: USER_LOGIN_RESET,
    });

    dispatch({
      type: USER_2FA_CHECK_CLEANUP,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
  };

  const handleSubmit = () => {
    //console.log(userInfo, 'submit');

    analytics?.track('User Login button clicked!', {
      from: 'Signin page',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    if (userInfo.email === '' || userInfo.password === '') {
      return toast.error('Please fill in all the required fields.');
    }

    if (!captchaToken && url !== 'dev') {
      return toast.error('Check the recaptcha');
    }

    dispatch({
      type: USER_LOGIN_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });

    //console.log(captchaToken);
    dispatch(login(userInfo.email, userInfo.password, captchaToken));
  };

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
  };

  const handleSubmitOTP = () => {
    //console.log(userInfo, 'otpsubmit');
    analytics?.track('User OTP Login button clicked!', {
      from: 'Signin page',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (
      userInfo.email === '' ||
      userInfo.password === '' ||
      userInfo.otp === ''
    ) {
      setCaptchaToken(null);
      return toast.error('Please fill in all the required fields.');
    }

    if (!captchaToken && url !== 'dev') {
      return toast.error('Check the recaptcha');
    }

    dispatch({
      type: USER_LOGIN_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });

    dispatch(
      otpLogin(userInfo.email, userInfo.password, userInfo.otp, captchaToken)
    );
  };

  const clearState = () => {
    dispatch({
      type: USER_LOGIN_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
    setOpenGoogleAuthPopup(false);
    setOAuthOtpErrorCount(0);
    setOtpErrorCount(0);
  };

  useEffect(() => {
    if (otpErrorCount >= 3) {
      scroller.scrollTo('twofa_otp_error_container', {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
      toast.dismiss();
    }
    if (
      googleError === 'Please enter the OTP from authenticator app' &&
      oAuthOtpErrorCount >= 2
    ) {
      toast.error('Invalid OTP');
    }
  }, [otpErrorCount, oAuthOtpErrorCount]);

  useEffect(() => {
    if (
      (error || twoFAError) &&
      error !== 'Please enter the OTP from authenticator app' &&
      googleError !== 'Please enter the OTP from authenticator app'
    ) {
      if (captchaToken) {
        captchaRef.current.reset();
        setCaptchaToken(null);
      }
      analytics?.track(
        twoFAError ? 'User has 2FA button failed!' : 'User Login failed!',
        {
          from: 'Signin page',
          reason: twoFAError ? twoFAError : error,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        }
      );
      if (userInfo.email == '') {
        return toast.error('Enter the email');
      }
      toast.error(error || twoFAError);
    }
    if (error === 'Please enter the OTP from authenticator app') {
      captchaRef.current.reset();
      setCaptchaToken(null);
      toast.info(error);
    }
    if (error === 'Incorrect OTP') {
      setOtpErrorCount((prev) => prev + 1);
    }

    if (googleError === 'Please enter the OTP from authenticator app') {
      setOAuthOtpErrorCount((prev) => prev + 1);
    }
    if (userLoginInfo && userLoginInfo.token) {
      analytics?.track(
        userInfo && googleLoginInfo
          ? 'User Google Login success!'
          : 'User Login success!',
        {
          from: 'Signin page',
          QueryParams: { ...getQueryParams() },
          ip: ip,
        }
      );
      history.push({ pathname: '/', search: location.search });
    }
    if (googleError === 'Please enter the OTP from authenticator app') {
      setOpenGoogleAuthPopup(true);
    } else if (
      googleError &&
      googleError !== 'Please enter the OTP from authenticator app'
    ) {
      analytics?.track('User Google Login failed!', {
        from: 'Signin page',
        reason: googleError,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(googleError);
    }
    if (state) {
      analytics?.track('User has 2FA button success!', {
        from: 'Signin page',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setMuteEmail(true);
    }
  }, [
    userLoginInfo,
    googleError,
    twoFAError,
    history,
    error,
    googleLoginInfo,
    state,
  ]);

  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };

  const responseGoogle = (response) => {
    analytics?.track('User Google Login button clicked!', {
      from: 'Signin page',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (response['tokenId']) {
      setGoogleToken(response['tokenId']);

      dispatch({
        type: USER_LOGIN_RESET,
      });

      dispatch({
        type: USER_2FA_CHECK_CLEANUP,
      });

      dispatch({
        type: GOOGLE_LOGIN_RESET,
      });
      setMuteEmail(false);
      dispatch(googleLogin(response['tokenId'], false));
    }
  };

  const responseOTPGoogle = () => {
    dispatch({
      type: USER_LOGIN_RESET,
    });
    dispatch({
      type: USER_2FA_CHECK_CLEANUP,
    });
    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
    if (googleOTP) {
      dispatch(googleLogin(googleToken, googleOTP));
    }
  };

  const handleUser2FA = () => {
    analytics?.track('User has 2FA button clicked!', {
      from: 'Signin page',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (userInfo.email == '') {
      return toast.error('Enter the email');
    }
    dispatch({
      type: USER_2FA_CHECK_CLEANUP,
    });
    dispatch({
      type: USER_LOGIN_RESET,
    });

    dispatch({
      type: GOOGLE_LOGIN_RESET,
    });
    dispatch(getUserHasEnabled2FA(userInfo.email));
  };

  useEffect(() => {
    return () =>
      dispatch({
        type: USER_2FA_CHECK_CLEANUP,
      });
  }, []);

  useEffect(() => {
    if (
      (location.search && location.search.split('=')[1]) ||
      location.pathname.split('=')[1]
    ) {
      localStorage.setItem('tempQuery', '?redirect_to=kyc');
    }
  }, [location, history, dispatch]);

  useEffect(() => {
    dispatch(getAssests());
  }, [dispatch]);

  return (
    <div className="login__col">
      <div className="login__form">
        <div className="login__stage h4">
          Sign in to <br />
          Krypto Exchange
        </div>
        <Toast />
        {otpErrorCount >= 3 && (
          <TwoFASupportPrompt setCount={setOtpErrorCount} />
        )}
        <div>
          <FormInput
            label="Email address"
            type="email"
            name="email"
            value={userInfo.email}
            onChange={(e) => handleChange(e)}
            required
            disabled={muteEmail}
          />
          {state && !twoFAError && (
            <div
              className="login__links text-right"
              style={{ marginTop: 7, marginBottom: 10 }}
            >
              <button className="login__link" onClick={resetEmail}>
                <i className="fas fa-envelope"></i>
                Change Email
              </button>
            </div>
          )}
          {state && !twoFAError && (
            <div style={{ position: 'relative' }}>
              <FormInput
                label="password"
                type={togglePassword ? 'password' : 'text'}
                name="password"
                value={userInfo.password}
                onChange={(e) => handleChange(e)}
                required
              />
              <div
                style={{
                  position: 'absolute',
                  top: '40%',
                  right: 32,
                  color: '#808191',
                  cursor: 'pointer',
                }}
                onClick={() => setTooglePassword((pre) => !pre)}
              >
                <i
                  className={`fas fa${togglePassword ? '-eye-slash' : '-eye'}`}
                ></i>
              </div>
            </div>
          )}
          {state && !twoFAError && (
            <>
              {(error === 'Please enter the OTP from authenticator app' ||
                error === 'Incorrect OTP' ||
                state?.payload?.is_2FA_enabled) && (
                <FormInput
                  label="otp"
                  type="text"
                  name="otp"
                  value={userInfo.otp}
                  onChange={(e) => handleChange(e)}
                  required
                />
              )}
            </>
          )}
          {/* <Link className="login__verify" to="/">
            <div className="login__status"> </div>
            Click to verify
            <Icon icon="verify" className="icon" />
          </Link> */}
        </div>
        {state && !twoFAError && (
          <>
            <div className="login__links text-right" style={{ marginTop: 20 }}>
              <button
                className="login__link"
                onClick={() => setShowForgotPassword(true)}
              >
                <Icon icon="link" className="icon" />
                Forgot Password?
              </button>
            </div>
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              <ReCAPTCHA
                sitekey={captchaKey}
                ref={captchaRef}
                theme={isdark ? 'dark' : 'light'}
                onChange={onChangeCaptcha}
              />
            </div>
          </>
        )}
        <div style={{ marginTop: '50px' }}>
          {state && !twoFAError ? (
            <>
              {loading ? (
                <LoadingBtn size="full" />
              ) : error === 'Please enter the OTP from authenticator app' ||
                error === 'Incorrect OTP' ||
                state?.payload?.is_2FA_enabled ? (
                <AuthButton title="Sign in now" onClick={handleSubmitOTP} />
              ) : (
                <AuthButton title="Sign in now" onClick={handleSubmit} />
              )}
            </>
          ) : (
            <>
              {twoFALoading ? (
                <LoadingBtn size="full" />
              ) : (
                <AuthButton title="Sign in now" onClick={handleUser2FA} />
              )}
            </>
          )}
          <div
            className="h4"
            style={{
              color: '#808191',
              margin: 10,
              fontSize: 12,
              textAlign: 'center',
            }}
          >
            or
          </div>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              marginBottom: 12,
              marginTop: 12,
            }}
            className="google__btn"
          >
            <GoogleLogin
              clientId={googleAuthKey}
              buttonText="Login with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              theme="dark"
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className="login__flex">
            <div className="login__text">Not a member?</div>
            <Link
              className="login__link"
              to={(location) => ({ ...location, pathname: '/signup' })}
              onClick={clearState}
            >
              Sign up now
            </Link>
          </div>
          <ForgotPassword
            showForgotPasswordPopup={showForgotPassword}
            setForgotPasswordPopup={setShowForgotPassword}
          />
        </div>
      </div>
      <Popup state={openGoogleAuthPopUp} setState={setOpenGoogleAuthPopup}>
        <div
          className="forgot_password__innercontainer"
          style={{ marginTop: 30 }}
        >
          {oAuthOtpErrorCount >= 4 && (
            <TwoFASupportPrompt setCount={setOAuthOtpErrorCount} />
          )}
          <FormInput
            value={googleOTP}
            label="Please enter the OTP from authenticator app"
            onChange={(e) => setGoogleOTP(e.target.value)}
          />
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <CustomButton title="Login" onClick={responseOTPGoogle} />
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default withRouter(SignIn);
