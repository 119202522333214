import React from 'react';
import Layout from '../../components/layout/layout';
import HelmetContainer from '../../components/common/helmet/helmet';
import PromotionSlider from '../../components/promotions/promotion-slider/promotion-slider';
import FeaturePromotion from '../../components/promotions/feature-promotion/feature-promotion';
import LatestPromotions from '../../components/promotions/latest-promotions/latest-promotions';
import Pagination from '../../components/promotions/pagination/pagination';
function PromotionsPage() {
  return (
    <Layout>
      <HelmetContainer title="Promotions" />
      <div className="promotions">
        <div className="promotions__wrapper">
          <div className="promotions__brand h5">Krypto Exchange</div>
          <div className="promotions__title h2">Promotions</div>
          <div className="promotions__row">
            <div className="promotions__col">
              <PromotionSlider />
            </div>
            <div className="promotions__col">
              <FeaturePromotion />
            </div>
          </div>
          <LatestPromotions />
          <Pagination />
        </div>
      </div>
    </Layout>
  );
}

export default PromotionsPage;
