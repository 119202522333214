import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderUpTable } from '../../../../utils/data';
import useBalance from '../../../../utils/useBalance';
import useWebSocketData from '../../../../utils/useWebSocketData';
import SkeletonContainer from '../../../common/skeleton/skeleton';

function OrderTable({ selectedCell }) {
  const [orderData, setOrderData] = useState(null);
  const [orderbids, setOrderBids] = useState([]);
  const [orderasks, setOrderAsks] = useState([]);

  const { lastPrice, getStatus } = useWebSocketData();
  const { formatINR } = useBalance();

  const {
    getOrderBook: { loading, error, orderBookData },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCell.symbol !== 'inr' && !loading && orderBookData) {
      setOrderData(orderBookData[selectedCell?.symbol + 'inr']);
    }
  }, [selectedCell.symbol, loading, orderBookData]);

  useEffect(() => {
    getHighestBidsValue();
    getHighestAsksValue();
  }, [selectedCell.symbol, orderData]);

  // //console.log(orderData);

  function percentage(partialValue, totalValue) {
    const value = (100 * partialValue) / totalValue;
    return value?.toFixed();
  }

  // red - Lowest top ()
  // green - highest top (bids)

  function getHighestBidsValue() {
    // const newArr = orderData?.bids?.splice(0, 5);
    const newArr = orderData;
    const data = newArr?.bids?.sort((a, b) => b[0] - a[0])?.slice(0, 5);
    const orderWithPer = data?.map((item) => {
      return {
        data1: item[0],
        data2: item[1],
        per: percentage(item[1], data[0][1]),
      };
    });
    //console.log(orderWithPer, 'orderWithPer');
    setOrderBids(orderWithPer);
  }

  function getHighestAsksValue() {
    const newArr = orderData;
    const data = newArr?.asks?.sort((a, b) => a[0] - b[0])?.slice(0, 5);
    const orderWithPer = data?.map((item) => {
      //console.log(data[0][1], item[0], item[1]);
      return {
        data1: item[0],
        data2: item[1],
        per: percentage(item[1], data[0][1]),
      };
    });
    setOrderAsks(orderWithPer);
  }

  return (
    <>
      {loading || !orderData || !orderBookData || !orderbids ? (
        <SkeletonContainer
          height={'300px'}
          width={'100%'}
          style={{ borderRadius: '10px' }}
        />
      ) : (
        <div className="orders__table">
          <div className="orders__row orders__row_head">
            <div className="orders__cell">AMOUNT ({selectedCell.symbol})</div>
            <div className="orders__cell">PRICE (INR)</div>
          </div>
          {!loading &&
            orderData &&
            orderasks &&
            orderasks.map((data, idx) => (
              <div className="orders__row" key={idx}>
                <div className="orders__cell orders__up">
                  {data.data2}
                  <div
                    className="orders__bg"
                    style={{
                      width: `${data.per}%`,
                    }}
                  ></div>
                </div>
                <div className="orders__cell"> ₹ {data.data1}</div>
              </div>
            ))}
          <div
            className={`orders__price ${
              getStatus(selectedCell.symbol) === 'positive'
                ? 'color-green'
                : 'color-red'
            } `}
          >
            {formatINR(lastPrice(selectedCell?.symbol))} INR
          </div>
          {!loading &&
            orderData &&
            orderbids &&
            orderbids.map((data, idx) => (
              <div className="orders__row" key={idx}>
                <div className="orders__cell orders__down">
                  {data.data2}
                  <div
                    className="orders__bg"
                    style={{ width: `${data.per}%` }}
                  ></div>
                </div>
                <div className="orders__cell"> ₹ {data.data1}</div>
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default OrderTable;
