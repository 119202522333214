import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssests, getCoinsDetails } from '../../../actions/assestsAction';
import { statusData } from '../../../utils/data';
import CustomButton from '../../common/custom-button/custom-button';
import CustomSelect from '../../common/custom-select/custom-select';
import Tags from '../../common/tags/tags';
import WidgetCard from './widgets-card/widgets-card';
import _ from 'lodash';
import WidgetSkeleton from './widget-skeleton/widget-skeleton';
import useWebSocketData from '../../../utils/useWebSocketData';
import NotFoundedImg from '../../../assests/img/notfound.png';
import useAnalytics from '../../../utils/useAnalytics';
import Icon from '../../common/icons/icons';
import { getQueryParams } from '../../../utils/query-helper';

// const filterData = [
//   {
//     label: 'Market Cap',
//     value: 'Market Cap',
//   },
//   {
//     label: 'A - Z',
//     value: 'A - Z',
//   },
//   {
//     label: 'Volumes',
//     value: 'Volumes',
//   },
//   {
//     label: 'Last 24h',
//     value: 'Last 24h',
//   },
//   {
//     label: 'Price',
//     value: 'Price',
//   },
// ];

function Widgets() {
  const [selectedFilter, setSelectedFilter] = useState(statusData[0]);
  const [filterByTabData, setFilterByTabData] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const [watchlistData, setWatchlistData] = useState([]);
  const [tabs, setTabs] = useState(statusData);
  const { analytics } = useAnalytics();
  const [result, setResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const showCard = 10;

  const dispatch = useDispatch();

  const {
    error,
    loading: assestsLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);
  const { userInfo } = useSelector((state) => state.userLogin);

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { response: watchListData, loading } = useSelector(
    (state) => state.userWatchList
  );

  const { getTopGainer, getTopLooser } = useWebSocketData();

  const handleLoadMore = () => {
    analytics?.track('Clicked the coins pagination!', {
      uid: userInfo?.payload?.uid,
      from: 'Homepage',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    setShowMore((prev) => !prev);
  };

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
    // //console.log(assetsData);
    if (!coinsDetails) {
      dispatch(getCoinsDetails());
    }
  }, []);

  const getWatchListCoins = () => {
    const items = [];
    //console.log('called', watchListData?.payload?.coins?.coins);
    watchListData?.payload?.coins?.coins?.forEach((watchList) => {
      coinsDetails?.convertedArray?.forEach((item) => {
        //console.log(watchList == item.symbol, watchList, item.symbol);
        if (watchList == item.symbol) {
          items.push(item);
        }
      });
    });
    setWatchlistData(items);
  };

  const filterByTab = () => {
    if (coinsDetails?.convertedArray) {
      if (selectedFilter.value === 'top_losers') {
        const leastValue = getTopLooser();
        setFilterByTabData(leastValue);
      } else if (selectedFilter.value === 'top_gainers') {
        const topGainerValue = getTopGainer();
        setFilterByTabData(topGainerValue);
      } else if (selectedFilter.value === 'core_assets') {
        setFilterByTabData(coinsDetails.convertedArray);
      } else if (selectedFilter.value === 'watchlist') {
        setFilterByTabData(watchlistData);
      }
    }
  };

  useEffect(() => {
    filterByTab();
    setShowMore(true);
  }, [selectedFilter, tabs]);

  useEffect(() => {
    if (coinsDetails) {
      setFilterByTabData(coinsDetails?.convertedArray);
    }
  }, [coinsLoading, coinsDetails]);

  useEffect(() => {
    getWatchListCoins();
  }, [loading, coinsLoading, coinsDetails]);

  useEffect(() => {
    if (watchListData?.payload?.coins?.coins?.length > 0) {
      setTabs([
        ...tabs,
        {
          label: 'WatchList',
          value: 'watchlist',
        },
      ]);
    }
    return () => setTabs(statusData);
  }, [loading]);

  useEffect(() => {
    analytics?.track('Filtering coins!', {
      keyword: selectedFilter.label,
      uid: userInfo?.payload?.uid,
      from: 'Homepage',
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, selectedFilter]);

  useEffect(() => {
    if (searchText.length === 0) {
      setResult(filterByTabData);
    } else {
      const data = filterByTabData.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setResult(data);
    }
  }, [searchText, filterByTabData, coinsDetails?.convertedArray]);

  return (
    <div>
      <div className="widgets">
        <div className="widgets__sorting">
          <div className="widgets__select mobile-show">
            <CustomSelect
              defaultValue={tabs[0]}
              options={tabs}
              onChange={(data) => setSelectedFilter(data)}
            />
          </div>
          <Tags
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            options={tabs}
            addLinksclassName="widgets__link"
            addTagclassName="widgets__variants"
          />
          {/* <div className="widgets__select" style={{ width: '150px' }}>
            <CustomSelect defaultValue={filterData[1]} options={filterData} />
          </div> */}
          <div className="widget_search">
            <div className="widget_search__container">
              <input
                className="actions__input exchange_search__input widget_filter__input "
                type="search"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="widget_searchicon__container">
              <Icon icon="search" className="icon" size={15} color="#808191" />
            </div>
          </div>
        </div>
        {error ||
          (coinsError && (
            <div className="h4 error-message">Something went wrong</div>
          ))}
        <div className="widget__skeleton_container">
          {(coinsLoading || !assetsData || !filterByTabData || loading) &&
            [1, 2, 3, 4, 5, 6, 7].map((data, idx) => (
              <WidgetSkeleton key={idx} />
            ))}
        </div>
        {!coinsLoading &&
          coinsDetails?.convertedArray &&
          assetsData &&
          !loading &&
          result &&
          filterByTabData && (
            <>
              <div className="widgets__list">
                {result.length > 0 ? (
                  result
                    .slice(0, showMore ? showCard : filterByTabData.length)
                    .map((data, idx) => <WidgetCard data={data} key={idx} />)
                ) : (
                  <div className="h6 no__list">No Coins 📊</div>
                )}
              </div>
              <div className="widgets__btns">
                {result.length > 9 && (
                  <CustomButton
                    title={!showMore ? 'Show less' : 'Discover more assets'}
                    onClick={handleLoadMore}
                  />
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
}
export default Widgets;
