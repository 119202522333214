import React from 'react';
import Lottie from 'react-lottie';
import CustomButton from '../../common/custom-button/custom-button';

function PaymentStatusContainer({
  title,
  status,
  animationData,
  description,
  btnTitle,
  btnAction,
  disabled,
}) {
  return (
    <div className="payment-status">
      <div className="h3">
        {title} <span>{status}</span>{' '}
      </div>
      <Lottie options={animationData} height={300} width={300} />
      <p>{description}</p>
      {btnTitle && (
        <CustomButton
          title={btnTitle}
          onClick={btnAction}
          disabled={disabled}
        />
      )}
      {/* <p className="sm-text">
        Please click {btnTitle} if you are not redirected within 10 seconds
      </p> */}
    </div>
  );
}

export default PaymentStatusContainer;
