import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import DetailsContainer from '../../components/price-details/details-container/details-container';
import SideBarCategories from '../../components/common/sidebar_categories/sidebar_categories';
import HelmetContainer from '../../components/common/helmet/helmet';
import { useLocation, useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssests,
  getChartData,
  getCoinsDetails,
  getWatchList,
} from '../../actions/assestsAction';
import SkeletonContainer from '../../components/common/skeleton/skeleton';
import useTime from '../../utils/useTime';
import { ToastContainer } from 'react-toastify';
import { scroller } from 'react-scroll';
import useViewport from '../../utils/useViewPort';
import PriceNews from '../../components/price-details/price-news/price-news';
import {
  getBalance,
  getPortolio,
  getUserIPAddress,
} from '../../actions/userAction';
import Toast from '../../components/common/toast/toast';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';

function PriceDetailsPage() {
  const { symbol } = useParams();
  const history = useHistory();
  const [coinDetails, setCoinDetails] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [investedData, setInvestedData] = useState(null);
  const { presentDay, beforeDay } = useTime();
  const { width } = useViewport();

  const dispatch = useDispatch();
  const { analytics } = useAnalytics();

  const {
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const {
    error,
    loading: assestLoading,
    assetsData,
  } = useSelector((state) => state.getAssests);

  const {
    loading: chartLoading,
    error: chartError,
    chartData,
  } = useSelector((state) => state.getChartData);

  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);

  const { response: watchListData, loading } = useSelector(
    (state) => state.userWatchList
  );

  const {
    userGetBalance: { balance },
    getPortfolio: { response: getPortfolioResponse },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!balance) {
      dispatch(getBalance());
    }
    if (!getPortfolioResponse) {
      dispatch(getPortolio());
    }
    if (!coinDetails) {
      dispatch(getCoinsDetails());
    }
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!watchListData) {
      dispatch(getWatchList(userInfo.token.access));
    }
    if (!chartData) {
      dispatch(getChartData(symbol, 30, beforeDay, presentDay));
    }
    return () => setCoinDetails({});
  }, []);

  useEffect(() => {
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  // useEffect(() => {
  //   if (symbol === selectedCell?.symbol || !selectedCell) {
  //     const value = coinsDetails?.convertedArray.find(
  //       (item) => item.symbol === symbol
  //     );
  //     setCoinDetails(value);
  //   } else if (symbol !== selectedCell?.symbol) {
  //     const value = coinsDetails?.convertedArray.find(
  //       (item) => item.symbol === selectedCell.symbol
  //     );
  //     setCoinDetails(value);
  //   }
  // }, [symbol, assetsData, selectedCell]);

  useEffect(() => {
    if (symbol) {
      const value = coinsDetails?.convertedArray.find(
        (item) => item.symbol === symbol
      );
      setCoinDetails(value);
      setSelectedCell(value);
    }
  }, [symbol, coinsLoading]);

  useEffect(() => {
    if (selectedCell) {
      dispatch(getChartData(selectedCell?.symbol, 30, beforeDay, presentDay));
    }
  }, [selectedCell]);

  useEffect(() => {
    if (symbol && coinsDetails) {
      const value = coinsDetails?.convertedArray.find(
        (item) => item.symbol === symbol
      );
      //console.log(value, 'value');
      if (!value || symbol === 'inr') {
        history.push({
          pathname: '/404',
          search: location.search,
        });
      }
    }
  }, [symbol, coinsDetails]);

  useEffect(() => {
    analytics?.page('Price details page', {
      coinsymbol: symbol,
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics]);

  useEffect(() => {
    scroller.scrollTo('details__container', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }, []);

  return (
    <Layout showborder={true}>
      <HelmetContainer title="Price details" />
      <Toast />
      <div className="page__row">
        <div className="page__col">
          {(!coinDetails || !assetsData || coinsLoading || assestLoading) && (
            <SkeletonContainer
              width={'100%'}
              height={'700px'}
              style={{ borderRadius: 40 }}
              className="details__container"
            />
          )}
          {!coinsLoading &&
            !assestLoading &&
            coinsDetails &&
            assetsData &&
            coinDetails && (
              <DetailsContainer
                symbol={symbol}
                selectedCell={selectedCell}
                setSelectedCell={setSelectedCell}
                coinDetails={coinDetails}
                chartData={chartData}
              />
            )}
        </div>
        <div className="page__col">
          <PriceNews term={symbol?.toUpperCase()} />
          {width < 850 && (
            <SideBarCategories scrollToSection={scrollToSection} />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default PriceDetailsPage;
