import { CHANGE_THEME } from '../constants/themeConstant';
import { prefix } from '../store';

export const changeTheme = (theme) => (dispatch) => {
  dispatch({
    type: CHANGE_THEME,
    payload: !theme,
  });
  localStorage.setItem(prefix + '.theme', !theme);
};
