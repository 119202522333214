import * as historyConstants from '../constants/historyConstant';

export const getOrderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case historyConstants.GET_ORDERDATA_REQUEST:
      return { loading: true };
    case historyConstants.GET_ORDERDATA_SUCCESS:
      return { loading: false, response: action.payload };
    case historyConstants.GET_ORDERDATA_FAIL:
      return { loading: false, error: action.payload };
    case historyConstants.HISTORY_PAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const getWithrawDataReducer = (state = {}, action) => {
  switch (action.type) {
    case historyConstants.GET_WITHDRAWDATA_REQUEST:
      return { loading: true };
    case historyConstants.GET_WITHDRAWDATA_SUCCESS:
      return { loading: false, response: action.payload };
    case historyConstants.GET_WITHDRAWDATA_FAIL:
      return { loading: false, error: action.payload };
    case historyConstants.HISTORY_PAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const getTradeDataReducer = (state = {}, action) => {
  switch (action.type) {
    case historyConstants.GET_TRADEDATA_REQUEST:
      return { loading: true };
    case historyConstants.GET_TRADEDATA_SUCCESS:
      return { loading: false, response: action.payload };
    case historyConstants.GET_TRADEDATA_FAIL:
      return { loading: false, error: action.payload };
    case historyConstants.HISTORY_PAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const getDepositDataReducer = (state = {}, action) => {
  switch (action.type) {
    case historyConstants.GET_DEPOSITSDATA_REQUEST:
      return { loading: true };
    case historyConstants.GET_DEPOSITSDATA_SUCCESS:
      return { loading: false, response: action.payload };
    case historyConstants.GET_DEPOSITSDATA_FAIL:
      return { loading: false, error: action.payload };
    case historyConstants.HISTORY_PAGE_RESET:
      return {};
    default:
      return state;
  }
};
