import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBalance from '../../../utils/useBalance';
import useWebSocketData from '../../../utils/useWebSocketData';
import CustomButton from '../../common/custom-button/custom-button';
import CustomSelect from '../../common/custom-select/custom-select';
import WalletWithdrawFormInput from './wallet-withdraw-input/wallet-withdraw-input';
import Popup from '../../common/popup/popup';
import {
  createFiatBeneficiary,
  getBeneficiary,
  verifyBeneficiary,
  createCryptoWithdrawal,
  withdrawApproval,
  withdrawProcess,
  createBeneficiary,
} from '../../../actions/walletAction';
import { toast } from 'react-toastify';
import { getBalance } from '../../../actions/userAction';
import { set } from 'lodash';
import useAnalytics from '../../../utils/useAnalytics';
import { toogleSetting } from '../../../actions/settingAction';
import { getQueryParams } from '../../../utils/query-helper';

function WalletWithdraw({
  visibleSideBar,
  selectedData,
  setSelectedData,
  setVisibleSideBar,
}) {
  // change here
  const options = [
    {
      label: 'Fast (1-2 days)',
      value: 'fast',
    },
    {
      label: 'Normal (5-6 days)',
      value: 'normal',
    },
  ];
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [total, setTotal] = useState(0);
  const [minMaxWithdraw, setMinMaxWithdraw] = useState(null);
  const [amount, setAmount] = useState(0);
  const [withdrawBridgeState, setWithdrawBridgeState] = useState(null);
  const [verifyBeneficaryState, setVerifyBeneficaryState] = useState(null);
  const [sendCryptoBridgeState, setSendCryptoBridgeState] = useState(null);
  const [verifyCryptoBeneficaryState, setVerifCryptoCrBeneficaryState] =
    useState(null);
  const [createNormalWithdrawalState, setCreateNormalWithdrawalState] =
    useState(null);
  const [finalApproveState, setFinalApproveState] = useState(null);
  const [approvalDispatchState, setApprovalDispatchState] = useState(null);
  const [otp, setOtp] = useState('');
  const [authOtp, setAuthOtp] = useState('');
  const [selectedOption, setSelectedOptions] = useState(options[0]);
  const [withDrawalId, setWithDrawalId] = useState('');
  const [address, setAddress] = useState('');
  const [memo, setMemo] = useState('');
  const { lastPrice } = useWebSocketData();
  const { formatINR, roundUpWithSymbol } = useBalance();
  const inputRef = document.getElementById('amount-input');
  const [coinsInfoStore, setCoinInfoStore] = useState({});
  const { analytics } = useAnalytics();

  const {
    getCoins: { coinsDetails },
    userLogin: { userInfo },
    userDetails: { userData },
    networkCheck: { loading, response: networkResponse, error },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const getCryptotoINR = () => {
    if (selectedData) {
      const value =
        lastPrice(selectedData.currency) * parseFloat(selectedData.balance);
      return roundUpWithSymbol(value, 'inr');
    }
  };

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const getMinimumLimit = (symbol) => {
    if (coinsDetails?.convertedArray) {
      const coins = coinsDetails?.convertedArray.find(
        (item) => symbol === item.symbol
      );
      return coins.minimum_withdraw;
    }
  };

  const getLimitINR = (symbol) => {
    if (coinsDetails?.convertedArray) {
      const coins = coinsDetails?.convertedArray.find(
        (item) => symbol === item.symbol
      );
      return {
        fast: coins.max_withdraw_instant,
        normal: coins.max_withdraw_normal,
      };
    }
  };

  const handlePreventDecimal = (e) => {
    let invalidChars = ['-', '+', 'e', '.'];
    if (invalidChars.includes(e.key)) {
      return e.preventDefault();
    }
  };

  const getMaxandMinWithdrawal = () => {
    if (selectedData?.currency !== 'inr') {
      if (coinsDetails?.convertedArray) {
        const coins = coinsDetails?.convertedArray.find(
          (item) => selectedData?.currency === item?.symbol
        );
        setMinMaxWithdraw(coins?.minimum_withdraw);
      }
    } else {
      if (coinsDetails?.convertedArray) {
        const coins = coinsDetails?.convertedArray.find(
          (item) => selectedData?.currency === item.symbol
        );
        const data = {
          min:
            selectedOption.value === 'fast'
              ? coins?.min_withdraw_instant
              : coins?.min_withdraw_normal,
          max:
            selectedOption.value === 'fast'
              ? coins?.max_withdraw_instant
              : coins?.max_withdraw_normal,
        };
        setMinMaxWithdraw(data);
      }
    }
  };

  const getTradeFee = (symbol) => {
    if (symbol !== 'inr') {
      if (coinsDetails?.convertedArray) {
        const coins = coinsDetails?.convertedArray.find(
          (item) => symbol === item.symbol
        );
        return coins?.fee;
      }
    } else {
      if (coinsDetails?.convertedArray) {
        const coins = coinsDetails?.convertedArray.find(
          (item) => symbol === item.symbol
        );
        return selectedOption.value === 'fast'
          ? coins?.instant_fee
          : coins?.normal_deposit_fee;
      }
    }
  };

  const handleChangeInput = (e, currency) => {
    if (
      currency == 'shib' ||
      currency == 'inr' ||
      coinsInfoStore.trade_decimal == 0
    ) {
      let value = e.target.value.replace(/[e\+\-\.]/gi, '');
      setAmount(parseFloat(value));
      if (
        parseFloat(e.target.value) === 0 ||
        e.target.value === '' ||
        parseFloat(e.target.value) < getTradeFee(currency)
      ) {
        return setTotal(0);
      }
      setTotal(parseFloat(value) - parseFloat(getTradeFee(currency)));
    } else if (
      countDecimals(amount) + 1 <= coinsInfoStore.decimal ||
      (e.nativeEvent.inputType?.includes('delete') &&
        coinsInfoStore?.symbol !== 'shib')
    ) {
      setAmount(parseFloat(e.target.value));
      if (
        parseFloat(e.target.value) === 0 ||
        e.target.value === '' ||
        parseFloat(e.target.value) < getTradeFee(currency)
      ) {
        return setTotal(0);
      }
      setTotal(
        (Number(e.target.value) - Number(getTradeFee(currency))).toFixed(
          coinsInfoStore.decimal
        )
      );
    }
  };

  useEffect(() => {
    const coinInfo = coinsDetails?.convertedArray?.find((item) => {
      return (
        item.symbol == selectedData?.symbol ||
        item.symbol == selectedData?.currency
      );
    });
    setCoinInfoStore(coinInfo);
  }, [coinsDetails, selectedData?.symbol, selectedData?.currency]);

  useEffect(() => {
    if (inputRef) {
      if (
        coinsInfoStore?.symbol == 'shib' ||
        coinsInfoStore?.decimal == 0 ||
        coinsInfoStore?.symbol == 'inr'
      ) {
        inputRef.addEventListener('keydown', handlePreventDecimal);
        return () =>
          inputRef.removeEventListener('keydown', handlePreventDecimal);
      }
    }
  }, [
    inputRef,
    coinsInfoStore?.symbol,
    coinsInfoStore?.currency,
    coinsInfoStore?.decimal,
  ]);

  const withdrawBridge = async (e) => {
    e.preventDefault();

    analytics?.track('Withdraw verify button clicked!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    if (
      networkResponse &&
      (networkResponse.payload.level > 3 ||
        networkResponse.payload.otp == false)
    ) {
      return toast.error(
        'Kindly complete the KYC process and the 2FA process in settings'
      );
    }

    if (amount === 0) {
      return toast.info('Amount should not be 0');
    }

    if (selectedData?.currency === 'inr') {
      if (amount < minMaxWithdraw.min || amount > minMaxWithdraw.max) {
        return toast.error(
          `Maximum Withdraw ${minMaxWithdraw.max} and Minimum Withdraw is ${minMaxWithdraw.min}`
        );
      }
      if (parseFloat(minMaxWithdraw) < amount || total === '0' || total === 0) {
        return toast.error(`Minimum Withdraw is ${minMaxWithdraw}`);
      }
    }

    toast.info('Verifying', { toastId: 'verify', autoClose: 1000 });

    analytics?.track('Withdraw verify initialized!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    let data = await getBeneficiary(
      userInfo.token.access,
      selectedData.currency
    );
    if (data['status']) {
      let payload = data['data']['payload'];
      let beneficiary = {};
      let accountNumberUser = userData.payload.account_number;
      let ifscCodeUser = userData.payload.ifsc_code;
      let upiIDUser = userData.payload.upi_id;
      payload.map((val, _) => {
        let address = val['data']['upi_id'];
        let bankNumber = val['data']['account_number'];
        let ifscCode = val['data']['ifsc_code'];

        if (
          ifscCodeUser == ifscCode &&
          accountNumberUser == bankNumber &&
          upiIDUser == address
        ) {
          beneficiary[address] = val['id'];
        }
      });
      //// console.log('beneficiary', beneficiary);
      let beneficiary_id = beneficiary[userData.payload.upi_id];
      if (beneficiary_id || beneficiary_id == 0.0) {
        toast.success('Verified Successfull', {
          toastId: 'verify_success',
          autoClose: 1000,
        });
        analytics?.track('Withdraw verified successfully!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        setWithdrawBridgeState({
          beneficiary: beneficiary_id,
          isLoading: false,
          screen: 'fast',
          oldOne: true,
          withdrawType: selectedOption.value,
        });
      } else {
        if (
          userData.payload.account_number == '' ||
          userData.payload.ifsc_code == '' ||
          userData.payload.upi_id == ''
        ) {
          toast.error(
            'Kindly fill all your bank details in the Settings screen to proceed with payment.'
          );
          analytics?.track('Withdraw verify failed!', {
            from: 'Wallet Page',
            uid: userInfo?.payload?.uid,
            symbol: selectedData?.currency,
            QueryParams: { ...getQueryParams() },
            ip: ip,
            reason:
              'Kindly fill all your bank details in the Settings screen to proceed with payment.',
          });
          return;
        } else {
          let ben_data = await createFiatBeneficiary(userInfo.token.access);
          analytics?.track('Withdraw create Beneficiary initialized!', {
            from: 'Wallet Page',
            uid: userInfo?.payload?.uid,
            symbol: selectedData?.currency,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          if (ben_data['status']) {
            analytics?.track('Withdraw create Beneficiary success!', {
              from: 'Wallet Page',
              uid: userInfo?.payload?.uid,
              symbol: selectedData?.currency,
              QueryParams: { ...getQueryParams() },
              ip: ip,
            });
            setOpenPopup(true);
            toast.info('Please Verify your bank details');
            setWithdrawBridgeState({
              isLoading: false,
              beneficiary: ben_data['data']['payload']['id'],
              screen: 'verify',
              oldOne: false,
              withdrawType: selectedOption.value,
            });
          } else {
            analytics?.track('Withdraw create Beneficiary failed!', {
              from: 'Wallet Page',
              uid: userInfo?.payload?.uid,
              symbol: selectedData?.currency,
              reason: 'Something went wrong',
              QueryParams: { ...getQueryParams() },
              ip: ip,
            });
            setOpenPopup(false);
            toast.error('Something went wrong');
            return false;
          }
        }
      }
    } else {
      setOpenPopup(false);
      toast.error('Something went wrong');
    }
  };

  const sendCryptoBridge = async (e) => {
    e.preventDefault();
    analytics?.track('SendCrypto verfiy button clicked!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (
      networkResponse &&
      (networkResponse.payload.level > 3 || !networkResponse.payload.otp)
    ) {
      return toast.error(
        'Kindly complete the KYC process and the 2FA process in settings'
      );
    }
    if (amount === 0) {
      return toast.info('Amount should not be 0');
    }
    if (address === '') {
      return toast.info('Enter the Address');
    }

    if (minMaxWithdraw > amount || total === 0) {
      return toast.error(`Minimum Withdraw ${minMaxWithdraw} `);
    }

    toast.info('Verifying', { toastId: 'verify', autoClose: 1000 });
    let data = await getBeneficiary(
      userInfo.token.access,
      selectedData.currency
    );
    if (data['status']) {
      //// console.log(data, 'cryptoData');
      let payload = data['data']['payload'];
      let beneficiary = {};
      let addressOfCrypto = '';

      payload.map((val, _) => {
        //// console.log(val, 'val');
        let oldAddress = val['data']['address'].split('?dt=');
        if (oldAddress.length === 1 && address !== '' && memo === '') {
          if (address === oldAddress[0]) {
            addressOfCrypto = address;
            beneficiary[address] = val['id'];
          }
        } else {
          if (
            address === oldAddress[0] &&
            memo == oldAddress[1] &&
            address !== '' &&
            memo !== ''
          ) {
            addressOfCrypto = `${address}?dt=${memo}`;
            beneficiary[`${address}?dt=${memo}`] = val['id'];
          }
        }
      });

      let beneficiary_id = beneficiary[addressOfCrypto];

      //// console.log(beneficiary_id, 'beneficiary_id');

      if (beneficiary_id) {
        toast.success('Verified Successfull', {
          toastId: 'verify_success',
          autoClose: 1000,
        });
        analytics?.track('SendCrypto verify success!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        return setWithdrawBridgeState({
          beneficiary: beneficiary_id,
          isLoading: false,
          screen: 'fast',
          oldOne: true,
          withdrawType: selectedOption.value,
        });
      } else {
        const value = memo !== '' ? `${address}?dt=${memo}` : address;
        let ben_data = await createBeneficiary(
          userInfo.token.access,
          userData?.payload?.name,
          selectedData.currency,
          value
        );
        analytics?.track('SendCrypto create beneficiary initialized!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        // console.log(ben_data, 'ben_data');
        if (ben_data['status']) {
          setOpenPopup(true);
          toast.info('Please Verify your bank details');
          analytics?.track('SendCrypto create beneficiary success!', {
            from: 'Wallet Page',
            uid: userInfo?.payload?.uid,
            symbol: selectedData?.currency,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          setWithdrawBridgeState({
            isLoading: false,
            beneficiary: ben_data['data']['payload']['id'],
            screen: 'verify',
            oldOne: false,
            withdrawType: selectedOption.value,
          });
        } else {
          analytics?.track('SendCrypto create beneficiary failed!', {
            from: 'Wallet Page',
            uid: userInfo?.payload?.uid,
            symbol: selectedData?.currency,
            reason: 'Something went wrong',
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          setOpenPopup(false);
          toast.error('Something went wrong');
          return false;
        }
      }
    } else {
      analytics?.track('SendCrypto verfiy failed!', {
        from: 'Wallet Page',
        uid: userInfo?.payload?.uid,
        symbol: selectedData?.currency,
        reason: 'Something went wrong',
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setOpenPopup(false);
      toast.error('Something went wrong');
    }
  };

  const verifyBeneficary = async (e) => {
    e.preventDefault();
    let beneficiary_id = withdrawBridgeState.beneficiary;
    if (otp == '') {
      toast.error('OTP cannot be empty');
      return;
    }
    if (withdrawBridgeState?.withdrawType == 'fast') {
      if (beneficiary_id || beneficiary_id == 0.0) {
        let beneficiary_result = await verifyBeneficiary(
          userInfo.token.access,
          beneficiary_id,
          otp
        );
        //// console.log(beneficiary_result, 'beneficiary_result');
        if (beneficiary_result['status'] == false) {
          toast.error('Enter the right OTP');
          return;
        }
        setVerifyBeneficaryState({
          beneficiary: beneficiary_id,
          buttonLoad: false,
          screen: 'fast',
          oldOne: true,
        });
        analytics?.track('Withdraw verify Beneficiary success!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        toast.success('Successfully added the new beneficiary');
        setAuthOtp('');
        setOpenPopup(false);
      } else {
        setOpenPopup(false);
        analytics?.track('Withdraw verify Beneficiary failed!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        toast.error(
          'There was a problem in verifying your account, kindly try again later'
        );
        setAuthOtp('');
        return;
      }
    } else {
      let beneficiary_result = await verifyBeneficiary(
        userInfo.token.access,
        beneficiary_id,
        otp
      );
      if (beneficiary_result['status'] == false) {
        toast.error('Enter the right OTP');
        return;
      }
      if (beneficiary_id || beneficiary_id == 0.0) {
        setVerifyBeneficaryState({
          beneficiary: beneficiary_id,
          buttonLoad: false,
          screen: 'normal',
        });
        toast.success('Add New One');
        setOpenPopup(false);
      } else {
        setOpenPopup(false);
        toast.error(
          'There was a problem in verifying your account, kindly try again later'
        );
        return;
      }
    }
  };

  const openAuthPopUp = (e) => {
    e.preventDefault();
    setOpenAuthPopup(true);
  };

  const finalApprove = async (withDrawalid) => {
    //// console.log(withDrawalId);
    try {
      let response = await withdrawProcess(userInfo.token.access, withDrawalid);
      if (response.status) {
        analytics?.track('Withdraw success!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        toast.success('Withdrawal Submitted Successfully');
        let data = response.data['message'];
        setFinalApproveState({
          responseText: data,
          transactionComplete: true,
          transactionStatus: true,
        });
        setWithDrawalId('');
        setWithdrawBridgeState(null);
        setVisibleSideBar(null);
        setSelectedData(null);
        setTotal(0);
      } else {
        let data = response.data;
        toast.error(
          'Kindly contact support@letskrypto.com in case of any funds debited.'
        );
        setFinalApproveState({
          responseText: data,
          transactionComplete: true,
          transactionStatus: false,
        });
        setWithDrawalId('');
        setWithdrawBridgeState(null);
        setOpenAuthPopup(false);
        setSelectedData(null);
        setVisibleSideBar(null);
        setSelectedData(null);
      }
    } catch (error) {
      //console.log(error, 'Error in payout final withdraw');
      toast.error(
        'Kindly contact support@letskrypto.com in case of any funds debited.'
      );
      await new Promise((r) => setTimeout(r, 6000));
      setFinalApproveState({
        responseText:
          'Kindly contact support@letskrypto.com in case of any funds debited.',
        transactionComplete: true,
        transactionStatus: false,
      });
      setOpenAuthPopup(false);
      setCreateNormalWithdrawalState({ buttonLoad: false });
      setVerifyBeneficaryState(null);
      setApprovalDispatchState(null);
      setFinalApproveState(null);
      setAuthOtp('');
      setVisibleSideBar(null);
      setSelectedData(null);
      setWithdrawBridgeState(null);
    }
  };

  const approvalDispatch = async (withDrawalid) => {
    //// console.log(withDrawalId, 'withDrawalId');
    try {
      let response = await withdrawApproval(
        userInfo.token.access,
        withDrawalid
      );
      //// console.log(response);
      if (response.status) {
        finalApprove(withDrawalid);
      } else {
        let data = response.data;
        toast.error(
          data ||
            'Payment failed, Kindly contact support@letskrypto.com in case of any funds debited.'
        );
        setApprovalDispatchState({
          responseText: data,
          transactionComplete: true,
          transactionStatus: false,
        });
        setOpenAuthPopup(false);
        setCreateNormalWithdrawalState({ buttonLoad: false });
        setVerifyBeneficaryState(null);
        setApprovalDispatchState(null);
        setFinalApproveState(null);
        setAuthOtp('');
        setVisibleSideBar(null);
        setSelectedData(null);
        setWithdrawBridgeState(null);
        setTotal(0);
      }
    } catch (error) {
      //console.log(error, 'Error in payout final withdraw');
      toast.error(
        'Kindly contact support@letskrypto.com in case of any funds debited.'
      );
      await new Promise((r) => setTimeout(r, 6000));
      setApprovalDispatchState({
        responseText:
          'Kindly contact support@letskrypto.com in case of any funds debited.',
        transactionComplete: true,
        transactionStatus: false,
      });
      setOpenAuthPopup(false);
      setCreateNormalWithdrawalState({ buttonLoad: false });
      setVerifyBeneficaryState(null);
      setApprovalDispatchState(null);
      setFinalApproveState(null);
      setAuthOtp('');
      setVisibleSideBar(null);
      setSelectedData(null);
      setWithdrawBridgeState(null);
    }
  };

  const createNormalWithdraw = async (e, type) => {
    e.preventDefault();
    setCreateNormalWithdrawalState({ buttonLoad: true });
    analytics?.track('Withdraw initialized!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    let data = await createCryptoWithdrawal(
      userInfo.token.access,
      authOtp,
      selectedData.currency,
      amount,
      withdrawBridgeState.beneficiary,
      withdrawBridgeState.withdrawType == 'fast'
        ? 'Instant Withdraw'
        : 'Normal Withdraw'
    );
    if (data['status']) {
      if (selectedOption.value == 'fast' && selectedData.currency === 'inr') {
        //// console.log(data['data']['payload']['id'], 'withdraw');
        setWithDrawalId(data['data']['payload']['id']);
        toast.info(
          'Please do not go refresh or close the tab. Payment processing'
        );
        setOpenAuthPopup(false);
        approvalDispatch(data['data']['payload']['id']);
      } else {
        analytics?.track('Withdraw success!', {
          from: 'Wallet Page',
          uid: userInfo?.payload?.uid,
          symbol: selectedData?.currency,
          QueryParams: { ...getQueryParams() },
          ip: ip,
        });
        toast.success('Withdrawal Submitted Successfully');
        setWithDrawalId('');
        setWithdrawBridgeState(null);
        setOpenAuthPopup(false);
        setVisibleSideBar(null);
        setSelectedData(null);
        setTotal(0);
      }
      dispatch(getBalance());
      setCreateNormalWithdrawalState({ buttonLoad: true, screen: 'success' });
    } else {
      // setOpenAuthPopup(false);
      // setCreateNormalWithdrawalState({ buttonLoad: false });
      // setVerifyBeneficaryState(null);
      // setApprovalDispatchState(null);
      // setFinalApproveState(null);
      // setAuthOtp('');
      // setVisibleSideBar(null);
      // setSelectedData(null);
      // setWithdrawBridgeState(null);
      toast.error(data['data']);
      const str = data.data.toLowerCase();
      if (str.includes('insufficient')) {
        setOpenAuthPopup(false);
        setCreateNormalWithdrawalState({ buttonLoad: false });
        setVerifyBeneficaryState(null);
        setApprovalDispatchState(null);
        setFinalApproveState(null);
        setAuthOtp('');
        setVisibleSideBar(null);
        setSelectedData(null);
        setWithdrawBridgeState(null);
      }
      return false;
    }
  };

  useEffect(() => {
    getMaxandMinWithdrawal();
    setAddress('');
    setVerifyBeneficaryState('');
    setApprovalDispatchState('');
    setFinalApproveState('');
    setAuthOtp('');
    setWithdrawBridgeState('');
    setTotal(0);
    setAmount(0);
  }, [selectedData, selectedOption.value]);

  useEffect(() => {
    return () => {
      //console.log('calling cleanup');
      setAddress('');
      setVerifyBeneficaryState('');
      setApprovalDispatchState('');
      setFinalApproveState('');
      setAuthOtp('');
      setWithdrawBridgeState('');
      setMemo('');
      setOtp('');
      setAmount(0);
      setTotal(0);
      // setTotal('');
    };
  }, [selectedData]);

  return (
    <div
      className='operations__form'
      style={{
        display: visibleSideBar === 'withdraw' ? 'block' : 'none',
      }}
    >
      {networkResponse.payload.level > 3 ||
        (!networkResponse.payload.otp && (
          <div className='p warning_kyc_2fa_wallet'>
            Kindly complete your KYC and set your 2FA in{' '}
            <span onClick={() => dispatch(toogleSetting())}>settings page</span>{' '}
            to {selectedData?.currency === 'inr' ? 'withdraw' : 'send'}{' '}
            {selectedData?.currency?.toUpperCase()}
          </div>
        ))}
      {selectedData && (
        <>
          {selectedData?.currency === 'inr' && (
            <div className='actions__field order_book_custom_select'>
              <CustomSelect
                defaultValue={selectedOption}
                options={options}
                onChange={(item) => setSelectedOptions(item)}
              />
            </div>
          )}
          {selectedData?.currency !== 'inr' && (
            <>
              <WalletWithdrawFormInput
                label="Enter receiver's address"
                active
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  if (withdrawBridgeState || verifyBeneficaryState) {
                    setWithdrawBridgeState(null);
                    setVerifyBeneficaryState(null);
                  }
                }}
              />
              <WalletWithdrawFormInput
                label='(optional) Enter destination tag'
                active
                value={memo}
                onChange={(e) => {
                  setMemo(e.target.value);
                  if (withdrawBridgeState || verifyBeneficaryState) {
                    setWithdrawBridgeState(null);
                    setVerifyBeneficaryState(null);
                  }
                }}
              />
            </>
          )}
          <div className='operations__balance'>
            <div className='operations__label'>AVAILABLE BALANCE</div>
            <div className='operations__counter'>
              {roundUpWithSymbol(
                parseFloat(selectedData.balance),
                selectedData.currency
              ) +
                ' ' +
                selectedData.currency.toUpperCase()}
            </div>
            {selectedData.currency !== 'inr' && (
              <div className='operations__price'>
                ₹ {formatINR(getCryptotoINR())}
              </div>
            )}
          </div>
          <div className='operations__action'>
            <WalletWithdrawFormInput
              // subTitle="Max Amount"
              // value={amount}
              label={
                selectedData.currency === 'inr'
                  ? 'AMOUNT TO WITHDRAW'
                  : 'AMOUNT'
              }
              active
              id='amount-input'
              type='number'
              value={amount}
              onChange={(e) => handleChangeInput(e, selectedData.currency)}
            />
            <div className='operations__note'>
              {selectedData?.currency === 'inr'
                ? selectedOption?.value == 'fast'
                  ? `Maximum Withdrawal ₹ ${formatINR(
                      parseFloat(getLimitINR('inr')?.fast)
                    )}`
                  : `Maximum Withdrawal ₹ ${formatINR(
                      parseFloat(getLimitINR('inr')?.normal)
                    )}`
                : 'Minimum Withdrawal ' +
                  getMinimumLimit(selectedData.currency)}
              {/* daily
              withdrawal limit. */}
            </div>
          </div>
          <WalletWithdrawFormInput
            label='TRANSACTION FEE'
            iconName='info-square'
            value={getTradeFee(selectedData.currency)}
            disabled
            disabledProperty
          />
          <WalletWithdrawFormInput
            label='TOTAL'
            value={total}
            disabled
            disabledProperty
          />

          {selectedData.currency === 'inr' ? (
            <CustomButton
              className='operations__btn btn btn_orange btn_wide'
              title={
                !verifyBeneficaryState && !withdrawBridgeState
                  ? 'Verify'
                  : 'Withdraw'
              }
              onClick={
                !verifyBeneficaryState && !withdrawBridgeState
                  ? (e) => withdrawBridge(e)
                  : (e) => openAuthPopUp(e)
              }
            />
          ) : (
            <CustomButton
              className='operations__btn btn btn_orange btn_wide'
              title={
                (!verifyBeneficaryState ||
                  verifyBeneficaryState.screen !== 'fast') &&
                (withdrawBridgeState?.screen === 'verify' ||
                  !withdrawBridgeState)
                  ? 'Verify'
                  : 'Send'
              }
              onClick={
                (!verifyBeneficaryState ||
                  verifyBeneficaryState.screen !== 'fast') &&
                (withdrawBridgeState?.screen === 'verify' ||
                  !withdrawBridgeState)
                  ? (e) => sendCryptoBridge(e)
                  : (e) => openAuthPopUp(e)
              }
            />
          )}
        </>
      )}
      <Popup state={openPopup} setState={setOpenPopup} disabledClosebtn>
        <div className='withdraw_popup'>
          <div className='withdraw_popup_title h6'>
            Seems like you're transferring to this account for the first time ?
            Enter the code you recieved in mail
          </div>
          <WalletWithdrawFormInput
            label='Enter the code'
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            active
          />
          <CustomButton
            title='Confirm Account'
            onClick={(e) => verifyBeneficary(e)}
          />
        </div>
        <p style={{ fontSize: 10, marginTop: 20, textAlign: 'center' }}>
          &#9888; Please don't refresh the page
        </p>
      </Popup>
      <Popup state={openAuthPopup} setState={setOpenAuthPopup} disabledClosebtn>
        <div className='withdraw_popup'>
          <div className='withdraw_popup_title h6'>
            Are you sure you want to transfer your coins ? Copy Otp from
            Authenticator App and paste here
          </div>
          <WalletWithdrawFormInput
            label='Enter the OTP'
            active
            value={authOtp}
            onChange={(e) => setAuthOtp(e.target.value)}
          />
          <CustomButton
            title={`Confirm Withdraw`}
            onClick={(e) => createNormalWithdraw(e, selectedData.currency)}
          />
        </div>
        <p style={{ fontSize: 10, marginTop: 20, textAlign: 'center' }}>
          &#9888; Please dont refresh the page
        </p>
      </Popup>
    </div>
  );
}

export default WalletWithdraw;
