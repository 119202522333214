import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderData, getTradesData } from '../../../../actions/historyAction';
import useViewport from '../../../../utils/useViewPort';
import CustomButton from '../../../common/custom-button/custom-button';
import CustomSelect from '../../../common/custom-select/custom-select';
import SkeletonContainer from '../../../common/skeleton/skeleton';
import ExchangeMarketTable from '../exchange-market-table/exchange-market-table';
import NotFoundedImage from '../../../../assests/img/notfound.png';
import OrderTable from '../../order-book/order-table/order-table';
import ExchangeOrderTable from '../exchange-market-order-book/exchange-market-order-book';
import _ from 'lodash';
import { cancelOrder } from '../../../../actions/exchangeAction';
import LoadingBtn from '../../../common/loading-btn/loading-btn';
import useAnalytics from '../../../../utils/useAnalytics';
import { getQueryParams } from '../../../../utils/query-helper';

function ExchangeMarketTransactions({ selectedtab, selectedCell }) {
  const data = [
    {
      label: 'All Orders',
      value: 'all_orders',
    },
    {
      label: 'Pending Orders',
      value: 'pending_orders',
    },
    {
      label: 'Trades',
      value: 'trades',
    },
  ];
  const [orderPageCount, setOrderPageCount] = useState(1);
  const [orderPendingPageCount, setOrderPendingPageCount] = useState(1);
  const [tradePageCount, setTradePageCount] = useState(1);
  const [allOrders, setAllOrder] = useState([]);
  const [trade, setTrade] = useState([]);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [tempId, setTempId] = useState('');
  const [btnDisable, setBtnDisbale] = useState(false);

  const dispatch = useDispatch();
  const [selectedlabel, setselectedLabel] = useState(data[0]);
  const { width } = useViewport();
  const breakpoint = 766;
  const { analytics } = useAnalytics();
  const {
    orderData: {
      loading: orderLoading,
      response: orderResponse,
      error: orderError,
    },
    tradeData: {
      loading: tradeLoading,
      response: tradeResponse,
      error: tradeError,
    },
    createOrder: { loading, error, orderDetails },
    cancelOrder: {
      response: cancelOrderResponse,
      loading: cancelOrderLoading,
      error: cancelOrderError,
    },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const getData = () => {
    if (selectedlabel.value === 'all_orders') {
      dispatch(getOrderData(orderPageCount, false));
    } else if (selectedlabel.value === 'pending_orders') {
      dispatch(getOrderData(orderPendingPageCount, true));
    } else if (selectedlabel.value === 'trades') {
      dispatch(getTradesData(tradePageCount));
    }
  };

  useState(() => {
    dispatch(getOrderData(1, false));
    dispatch(getTradesData());
  }, []);

  useEffect(() => {
    getData();
    setOrderPageCount(1);
    setOrderPendingPageCount(1);
    setTradePageCount(1);
  }, [selectedlabel.value]);

  useEffect(() => {
    if (orderDetails) {
      getData();
    }
  }, [orderDetails]);

  const handleLoadMore = () => {
    analytics?.track(`Load More clicked!`, {
      from: 'Exchange page',
      symbol: selectedCell.symbol,
      uid: userInfo?.payload?.uid,
      keyword: selectedlabel.label,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    if (selectedlabel.value === 'all_orders') {
      dispatch(getOrderData(orderPageCount + 1, false));
      if (orderResponse && orderResponse?.payload.length < 10) {
        setOrderPageCount(0);
        setBtnDisbale(true);
      } else {
        setOrderPageCount((pre) => pre + 1);
      }
    } else if (selectedlabel.value === 'pending_orders') {
      dispatch(getOrderData(orderPendingPageCount + 1, true));
      if (orderResponse && orderResponse?.payload.length < 10) {
        setOrderPendingPageCount(0);
        setBtnDisbale(true);
      } else {
        setOrderPendingPageCount((pre) => pre + 1);
      }
    } else if (selectedlabel.value === 'trades') {
      dispatch(getTradesData(tradePageCount + 1));
      if (tradeResponse && tradeResponse?.payload.length < 10) {
        setTradePageCount(0);
        setBtnDisbale(true);
      } else {
        setTradePageCount((pre) => pre + 1);
      }
    }
  };

  const handleMergeItemForLoadMore = () => {
    if (
      (orderResponse?.payload || tradeResponse?.payload) &&
      !tradeLoading &&
      !orderLoading
    ) {
      if (selectedlabel.value === 'all_orders') {
        const data = [...allOrders, ...orderResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setAllOrder(uniqData);
      } else if (selectedlabel.value === 'pending_orders') {
        const data = [...pendingOrder, ...orderResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setPendingOrder(uniqData);
      } else if (selectedlabel.value === 'trades') {
        const data = [...trade, ...tradeResponse?.payload];
        const uniqData = _.uniqBy(data, 'id');
        setTrade(uniqData);
      }
    }
  };

  const handleCancelOrder = (id) => {
    if (window.confirm('Are you sure to cancel this order')) {
      analytics?.track(`Order cancel button clicked!`, {
        from: 'Exchange page',
        symbol: selectedCell.symbol,
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setTempId(id);
      dispatch(cancelOrder(id));
    }
  };

  useEffect(() => {
    handleMergeItemForLoadMore();
  }, [
    dispatch,
    orderResponse,
    tradeResponse,
    tradeLoading,
    orderLoading,
    cancelOrderResponse,
  ]);

  useEffect(() => {
    setAllOrder([]);
    setPendingOrder([]);
    setTrade([]);
    setBtnDisbale(false);
  }, [selectedlabel.value]);

  useEffect(() => {
    if (cancelOrderResponse && tempId !== '') {
      const data = pendingOrder.filter((x) => x.id !== tempId);
      // console.log(data);
      analytics?.track(`Order cancelled successfully!`, {
        from: 'Exchange page',
        symbol: selectedCell.symbol,
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setPendingOrder(data);
      dispatch(getOrderData(orderPendingPageCount, true));
    }
  }, [cancelOrderResponse]);

  useEffect(() => {
    analytics?.track(`Clicked filter section!`, {
      from: 'Exchange page',
      symbol: selectedCell.symbol,
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      keyword: selectedlabel.label,
      ip: ip,
    });
  }, [selectedlabel.value, ip]);

  return (
    <>
      {width > 790 && <ExchangeOrderTable selectedCell={selectedCell} />}
      <div
        className="market__transactions"
        style={{
          display:
            selectedtab === 'market__transactions' && width < breakpoint
              ? 'block'
              : 'none',
        }}
      >
        <div className="market__top js-tabs">
          <div className="market__menu mobile-hide">
            {data.map((item, idx) => (
              <button
                className={`market__link js-tabs-link ${
                  selectedlabel.label === item.label ? 'active' : ''
                } `}
                key={idx}
                onClick={() => setselectedLabel(item)}
              >
                {item.label}
              </button>
            ))}
          </div>
          <div className="market__select mobile-show">
            <CustomSelect
              defaultValue={[data[0]]}
              options={data}
              onChange={(data) => setselectedLabel(data)}
            />
          </div>
        </div>
        {/* {tradeLoading || orderLoading ? (
          [1, 2, 3, 4, 5, 6].map((item, idx) => (
            <SkeletonContainer
              key={idx}
              width={'100%'}
              height="50px"
              style={{ borderRadius: 6, marginBottom: 12 }}
            />
          ))
        ) : ( */}
        <>
          <ExchangeMarketTable
            allOrders={allOrders}
            trade={trade}
            pendingOrder={pendingOrder}
            handleCancelOrder={handleCancelOrder}
            selectedlabel={selectedlabel}
            orderPendingPageCount={orderPendingPageCount}
          />

          <div style={{ display: 'grid', placeItems: 'center', padding: 20 }}>
            {!orderLoading &&
              !tradeLoading &&
              ((selectedlabel.value === 'pending_orders' &&
                pendingOrder?.length === 0) ||
                (selectedlabel.value === 'all_orders' &&
                  allOrders?.length === 0) ||
                (selectedlabel.value === 'trades' && trade?.length === 0)) && (
                <img
                  src={NotFoundedImage}
                  alt=""
                  style={{ height: 280, padding: 10, marginBottom: 12 }}
                />
              )}
            {tradeLoading || orderLoading ? (
              <>
                <LoadingBtn />
              </>
            ) : (
              <>
                {!btnDisable && (
                  <CustomButton title="Load More" onClick={handleLoadMore} />
                )}
              </>
            )}
          </div>
        </>
        {/* )} */}
      </div>
    </>
  );
}

export default ExchangeMarketTransactions;
