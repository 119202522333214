import React from 'react';
import IconBtn from '../../../common/icon-text/icon-text';
import QrCode from 'react-qr-code';
import { toast } from 'react-toastify';

function DespositOperationItem({
  title,
  description,
  QRCodeValue,
  value,
  minValue,
}) {
  const handleCopyTheCode = () => {
    navigator.clipboard.writeText(value);
    toast.success('Copied 👍🏻', { toastId: 'copy' });
  };

  const minDepositStyle = {
    fontFamily: 'Poppins',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: 12,
  };

  return (
    <div className="operations__item">
      <div className="operations__title">{title}</div>
      <div className="operations__text">{description}</div>
      {minValue && (
        <div style={{ ...minDepositStyle }} className="color-red">
          ⚠️ Minimum Deposit : {minValue}
        </div>
      )}
      <div className="operations__code">
        <input
          className="operations__input"
          type="text"
          disabled
          defaultValue={QRCodeValue}
        />
        <button className="operations__copy" onClick={handleCopyTheCode}>
          <IconBtn className="icon" iconName="copy" />
        </button>
      </div>
      {/* <div className="operations__preview"> */}
      {/* <img src={QRCodeImg} alt="" /> */}
      {QRCodeValue && <QrCode value={QRCodeValue} style={{ width: '100%' }} />}
      {/* </div> */}
    </div>
  );
}

export default DespositOperationItem;
