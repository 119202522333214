import React from 'react';
import MinImage from '../../../../assests/img/size-min.svg';
import MaxImage from '../../../../assests/img/size-max.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { scroller } from 'react-scroll';
import useBalance from '../../../../utils/useBalance';

function ActivitiesCell({
  data,
  sideBardata,
  handleSideBarToogle,
  selectedFilterValue,
}) {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);

  const { formatINR } = useBalance();

  const getCoinsDetails = (symbol) => {
    const data = coinsDetails?.convertedArray.find(
      (item) => item.symbol === symbol
    );
    return data;
  };

  const truncateText = (txt, length) => {
    if (txt?.length <= length) {
      return txt;
    }
    return txt?.slice(0, length) + '....';
  };

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div
      className="activity__row"
      onClick={() => {
        handleSideBarToogle(data);
        scrollToSection();
      }}
    >
      <div className="activity__cell">
        <div className="activity__size">
          <img src={sideBardata.id === data.id ? MaxImage : MinImage} alt="" />
        </div>
      </div>
      <div className="activity__cell">
        <div className="activity__company">
          {selectedFilterValue !== 'Orders' && (
            <div className="activity__logo">
              {selectedFilterValue !== 'Trades' ? (
                <img src={getCoinsDetails(data?.currency)?.logo} alt="" />
              ) : (
                <img src={getCoinsDetails(data?.fee_currency)?.logo} alt="" />
              )}
            </div>
          )}
          <div
            className="activity__name"
            style={{
              color: data?.side
                ? data?.side == 'buy'
                  ? '#4fbf67'
                  : '#ff5353'
                : 'inherit',
            }}
          >
            {selectedFilterValue === 'Withdraws' &&
              `Withdrawn ${data?.currency?.toUpperCase()}`}
            {selectedFilterValue === 'Deposits' &&
              `Deposited ${data?.currency?.toUpperCase()}`}
            {selectedFilterValue === 'Trades' &&
              `${data?.side?.toUpperCase()} ${
                data?.amount
              } - ${data?.market?.toUpperCase()}`}
            {selectedFilterValue === 'Orders' &&
              `${data?.side?.toUpperCase()} - ${data?.market?.toUpperCase()}`}
          </div>
        </div>
      </div>
      <div className="activity__cell">
        <div className="activity__date">
          {moment(data?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
        </div>
      </div>
      <div className="activity__cell">
        <div className="activity__price">
          {selectedFilterValue === 'Trades'
            ? formatINR(Number(data?.price)) +
              ' per ' +
              data?.fee_currency?.toUpperCase()
            : selectedFilterValue === 'Orders'
            ? data?.price
              ? formatINR(Number(data?.price))
              : formatINR(Number(data?.avg_price))
            : data?.amount + ' ' + data?.currency?.toUpperCase()}
        </div>
      </div>
      <div className="activity__cell activity__word_wrap">
        {selectedFilterValue === 'Withdraws' && (
          <>
            {data.currency === 'inr' ? (
              <div className="activity__address">
                {truncateText(data?.rid, 20)}
              </div>
            ) : (
              <div className="activity__address">
                {truncateText(data?.rid?.replace('?dt=', '/'), 20)}
              </div>
            )}
            <div className="activity__transaction">
              {data.blockchain_txid && truncateText(data.blockchain_txid, 20)}
            </div>
          </>
        )}
        {selectedFilterValue === 'Deposits' && (
          <div className="activity__address">{truncateText(data?.tid, 20)}</div>
        )}
        {selectedFilterValue === 'Trades' && (
          <div className="activity__address">
            {parseFloat(data?.total)?.toFixed(2)} INR
          </div>
        )}
        {selectedFilterValue === 'Orders' && (
          <div className="activity__address">{data?.origin_volume}</div>
        )}
      </div>
      {selectedFilterValue === 'Orders' && (
        <div className="activity__cell activity__address">
          {data?.executed_volume}
        </div>
      )}
    </div>
  );
}

export default ActivitiesCell;
