import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getQueryParams } from '../../../../utils/query-helper';
import Icon from '../../../common/icons/icons';

function InviteCard({ analytics }) {
  const {
    getReferralCode: { loading, response, error },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const handleCopy = () => {
    const input = document.getElementById('referral-input');
    input.select();
    if (navigator.clipboard) {
      analytics?.track('clicked copy', {
        from: 'Referral Page',
        purpose: 'Referral Code',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.success('Copied to clipboard, You can share the link');
      navigator.clipboard.writeText(response?.payload?.referral_code);
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  return (
    <div className="popup__invite">
      <div className="popup__info h6">
        Invite your friends to Krypto Exchange and Earn.
      </div>
      <div className="popup__label">Your Referral Code</div>
      <div className="popup__wrap">
        <input
          className="popup__input"
          defaultValue={response?.payload?.referral_code}
          type="text"
          id="referral-input"
          readOnly
        />
        <button className="popup__copy" onClick={handleCopy}>
          <Icon icon="copy" className="icon" />
        </button>
      </div>
    </div>
  );
}

export default InviteCard;
