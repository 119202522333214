import React from 'react';
import FormInput from '../../../../auth/form-input/forminput';
import Button from '../../../../auth/button/button';
import useAnalytics from '../../../../../utils/useAnalytics';

function KycOtp({
  mobileNovalue,
  setMobileValue,
  optValue,
  setOTP,
  handleSendPhoneCode,
  otpStage,
  handleVerifyCode,
}) {
  return (
    <div className="kyc__otp">
      <div className="h6">Lets get you verified.</div>
      <div>
        Please enter your phone number to get a unique confirmation code.
      </div>
      {otpStage === 'stage-1' ? (
        <>
          <FormInput
            label="Enter your mobile with code (+91)"
            value={mobileNovalue}
            style={{ minWidth: '400px' }}
            onChange={(e) => setMobileValue(e.target.value)}
          />
          <Button title="Send Code" onClick={handleSendPhoneCode} />
        </>
      ) : (
        <>
          <FormInput
            active
            label="Enter the OTP"
            value={optValue}
            style={{ minWidth: '400px' }}
            onChange={(e) => setOTP(e.target.value)}
          />
          <Button title="Verfiy" onClick={handleVerifyCode} />
        </>
      )}
    </div>
  );
}

export default KycOtp;
