export const OPEN_SETTING = 'OPEN_SETTING';
export const SETTING_CLEANUP = 'SETTING_CLEANUP';

export const GET_2FA_REQUEST = 'GET_2FA_REQUEST';
export const GET_2FA_SUCCESS = 'GET_2FA_SUCCESS';
export const GET_2FA_FAIL = 'GET_2FA_FAIL';

export const VERIFY_2FA_REQUEST = 'VERIFY_2FA_REQUEST';
export const VERIFY_2FA_SUCCESS = 'VERIFY_2FA_SUCCESS';
export const VERIFY_2FA_FAIL = 'VERIFY_2FA_FAIL';
