import React, { useEffect } from 'react';
import LatestActivity from '../../components/home/latest-activities/latest-activities';
import Layout from '../../components/layout/layout';
import Slider from '../../components/home/slider/slider';
import TotalBalance from '../../components/home/total-balance/total-balance';
import Widgets from '../../components/home/widgets/widgets';
import HelmetContainer from '../../components/common/helmet/helmet';
import News from '../../components/home/news/news';
import { useDispatch } from 'react-redux';
import {
  getUserDepositLevel,
  getUserIPAddress,
  userDetails,
} from '../../actions/userAction';
import { useSelector } from 'react-redux';
import { getAssests, getWatchList } from '../../actions/assestsAction';
import { useLocation } from 'react-router';
import { toogleSetting } from '../../actions/settingAction';
import useAnalytics from '../../utils/useAnalytics';
import { getQueryParams } from '../../utils/query-helper';

function HomePage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { analytics } = useAnalytics();
  const {
    userDetails: { userData },
    userLogin: { userInfo },
    getAssests: { assetsData },
    userWatchList: { loading, response: watchListData },
    userDepositState: {
      state: depositState,
      loading: depositStateLoading,
      error: depositStateError,
    },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!userData) {
      dispatch(userDetails());
    }
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!watchListData) {
      dispatch(getWatchList(userInfo.token.access));
    }
    if (!depositState) {
      dispatch(getUserDepositLevel());
    }
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, []);

  useEffect(() => {
    // const tempQuery = localStorage.getItem('tempQuery')
    //   ? localStorage.getItem('tempQuery')
    //   : null;
    const hasredirect = new URLSearchParams(location.search).get('redirect_to');
    if (hasredirect == 'kyc') {
      dispatch(toogleSetting({ screenState: 'KYC' }));
      localStorage.removeItem('tempQuery');
    }
  }, [location]);

  useEffect(() => {
    analytics?.page('Home page', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  return (
    <Layout showborder={true} isHomePage={true}>
      <HelmetContainer title="Home" />
      <div className="page__row">
        <div className="page__col">
          <Slider />
          <Widgets />
        </div>
        <div className="page__col">
          <TotalBalance />
          {/* <LatestActivity /> */}
          <News />
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;
