import React, { useState } from 'react';
import { languages, currency } from '../../../utils/data';
import AppStore from '../../../assests/img/app-store.svg';
import GooglePlay from '../../../assests/img/google-play.svg';
import QRCode from '../../../assests/img/qr-code.png';
import HeaderDropDown from '../header-dropdown/header-dropdown';
import HeaderNotification from '../header-notification/header-notification';
import HeaderIndicator from '../header-indicator/header-indicator';
import QRCodeComponent from 'react-qr-code';

function HeaderGroup({ isExchangePage }) {
  const [toogleList, setToogleList] = useState(false);
  const [selectedlanguage, setSelectedLanguage] = useState('English/USD');
  return (
    <div className="header__group">
      {/* {isExchangePage ? <HeaderIndicator value={24} /> : null} */}
      {/* <HeaderDropDown
        addclassName="header__item_notifications"
        iconName="notification"
        isNotificated={true}
      >
        <div className="header__notifications ">
          {[1, 2, 3].map((item, idx) => (
            <HeaderNotification key={idx} />
          ))}
        </div>
        <a className="header__btn btn btn_orange btn_wide" href="/">
          See all notifications
        </a>
      </HeaderDropDown> */}
      <HeaderDropDown
        addclassName="header__item_download"
        iconName="arrow-down-square"
      >
        <div className="header__row">
          <div className="header__col">
            <div className="header__category">Downloads</div>
            <div className="header__downloads">
              <a
                className="header__download"
                href="https://apps.apple.com/in/app/krypto-buy-sell-bitcoin/id1513381502"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <img src={AppStore} alt="" />
              </a>
              <a
                className="header__download"
                href="https://play.google.com/store/apps/details?id=com.krypto"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <img src={GooglePlay} alt="" />
              </a>
            </div>
          </div>
          <div className="header__col">
            <div className="header__category">Scan Code</div>
            <div
              className="header__code"
              style={{ width: '100px', height: '100px' }}
            >
              {/* <img src={QRCode} alt="" /> */}
              <QRCodeComponent value="letskrypto.com/links" size={100} />
            </div>
          </div>
        </div>
      </HeaderDropDown>

      {/* <HeaderDropDown
        addclassName={`header__item_lang ${toogleList ? 'active' : ''}`}
        heading={selectedlanguage}
        onClick={() => setToogleList((prev) => !prev)}
      >
        <div className="header__lang">
          <div className="header__cell">
            {languages.map((data, index) => (
              <button
                className={`header__link ${
                  data.language + '/' + data.currency === selectedlanguage
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  setSelectedLanguage(`${data.language}/${data.currency}`)
                }
                key={index}
              >
                <span className="header__flag">{data.emoji}</span>
                {data.language}
              </button>
            ))}
          </div>

          <div className="header__cell">
            {currency.map((data, idx) => (
              <a className="header__link" href="/" key={idx}>
                {data.name}
              </a>
            ))}
          </div>
        </div>
      </HeaderDropDown> */}
    </div>
  );
}

export default HeaderGroup;
