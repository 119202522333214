import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../components/common/custom-select/custom-select';
import Tabs from '../../../components/common/tags/tags';
import CustomButton from '../../common/custom-button/custom-button';
import Popup from '../../common/popup/popup';
import PasswordTab from '../../settings/settings-tabs/password/password';
import FormInput from '../../auth/form-input/forminput';
import AuthButton from '../../auth/button/button';
import { toast } from 'react-toastify';
import { exportDataByYear } from '../../../actions/historyAction';
import { useSelector } from 'react-redux';
import LoadingBtn from '../../common/loading-btn/loading-btn';

function trimTwentyFromYearRange(yearRange) {
  if (typeof yearRange === 'string') {
    // Split the year range string into start and end years
    const [startYear, endYear] = yearRange.split('-');

    // Remove '20' from the start of each year string and convert back to integers
    const trimmedStartYear = parseInt(startYear.replace(/^20/, ''));
    const trimmedEndYear = parseInt(endYear.replace(/^20/, ''));

    // Return the trimmed years as a string
    return `${trimmedStartYear}-${trimmedEndYear}`;
  }
  return yearRange; // Return unchanged if not a string
}

function isValidYearString(yearString) {
  if (typeof yearString !== 'string') {
    return false;
  }

  // Check if it matches the format YYYY or YYYY-YYYY
  const yearPattern = /^\d{4}(-\d{4})?$/;
  return yearPattern.test(yearString);
}

function ActivitiesHeader({
  openSideBar,
  selectedFilter,
  setSelectedFilter,
  filterByMonth,
  setFilterByMonth,
  sortingOptions,
  filterOptions,
  setOpenSideBar,
}) {
  const [openExportDataPopup, setOpenExportDataPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [financialYear, setFinancialYear] = useState('');
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isExportDataLoading, setIsExportDataLoading] = useState(false);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2021;
    const yearsArray = [];

    // Generate years in reverse order
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setFinancialYear(`${yearsArray[0]}-${yearsArray[0] + 1}`);
    setYears(yearsArray);
  }, [openExportDataPopup]);

  const handleSubmitExportData = async () => {
    if (!email.length || !financialYear) {
      return toast.error('Please fill in all the required fields.');
    }
    if (!isValidYearString(financialYear)) {
      return toast.error(
        'Invalid input. Please provide a valid year or year range'
      );
    }
    setIsExportDataLoading(true);
    try {
      let data = await exportDataByYear(
        userInfo.token.access,
        email,
        financialYear
      );
      setIsExportDataLoading(false);
      setOpenExportDataPopup(false);
      setEmail('');
      setFinancialYear('');
      toast.success(data.message);
    } catch (e) {
      toast.error(e);
      setIsExportDataLoading(false);
    }
  };

  return (
    <>
      <div className='activity__stage h6 mobile-show'>History</div>
      <div
        className='activity__sorting'
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div className='activity__select notifications__select tablet-show'>
          <CustomSelect
            defaultValue={selectedFilter}
            options={sortingOptions}
            onChange={(data) => setSelectedFilter(data)}
          />
        </div>
        {openSideBar ? (
          <div
            className='activity__select notifications__select'
            style={{ marginLeft: 0 }}
          >
            <CustomSelect
              defaultValue={selectedFilter}
              options={sortingOptions}
              onChange={(data) => {
                setSelectedFilter(data);
                setOpenSideBar(false);
              }}
            />
          </div>
        ) : (
          <Tabs
            style={{ fontSize: openSideBar ? '12px' : 'inherit' }}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            options={sortingOptions}
            addTagclassName='activity__tags tablet-hide'
            addLinksclassName='activity__link'
          />
        )}
        {selectedFilter?.value === 'Trades' ? (
          <CustomButton
            title={'Export Data'}
            addClassName='btn-small'
            onClick={() => setOpenExportDataPopup(true)}
          />
        ) : null}
        {/* <div className="activity__select notifications__select ">
          <CustomSelect
            defaultValue={filterByMonth}
            options={filterOptions}
            onChange={(data) => setFilterByMonth(data)}
          />
        </div> */}
        <div className='export-data-popup-container'>
          <Popup state={openExportDataPopup} setState={setOpenExportDataPopup}>
            <div className='popup__form'>
              <div className='popup__title h6'>Export Data to Email</div>
              <FormInput
                value={email}
                label='Enter your email address'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* <FormInput
                value={financialYear}
                label='Enter the financial year (e.g., 2024-2025)'
                type='text'
                onChange={(e) => setFinancialYear(e.target.value)}
              /> */}
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <label
                  htmlFor='financialYear'
                  style={{
                    marginRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Select Financial Year:
                </label>
                <span className='financial-select-wrapper'>
                  <select
                    id='financialYear'
                    onChange={(e) =>
                      setFinancialYear(
                        `${e.target.value}-${Number(e.target.value) + 1}`
                      )
                    }
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {`${year}-${year + 1}`}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <p className='hint'>
                Your trade data will be sent to the provided email address
                within 1-2 hours. If you haven't received it, please check your
                spam folder or reach out to us at{' '}
                <a
                  href='mailto:support@letskrypto.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  support@letskrypto.com
                </a>
              </p>
              {isExportDataLoading ? (
                <LoadingBtn size='full' />
              ) : (
                <AuthButton
                  title='Submit'
                  onClick={() => handleSubmitExportData()}
                />
              )}
            </div>
          </Popup>
        </div>
      </div>
    </>
  );
}

export default ActivitiesHeader;
