import React from 'react';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assests/img/selection.json';

const Icon = (props) => {
  const { color, size, icon, className } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
};

Icon.defaultProps = {
  className: '',
  color: '',
  size: '100%',
};

export default Icon;
