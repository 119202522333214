import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import {
  addWatchList,
  removeWatchList,
  getWatchList,
} from '../../../actions/assestsAction';
import { exchangeCellDropdownData } from '../../../utils/data';
import ConsoleHelper from '../../../utils/logger';
import useBalance from '../../../utils/useBalance';
import useViewport from '../../../utils/useViewPort';
import useWebSocketData from '../../../utils/useWebSocketData';
import FormInput from '../../auth/form-input/forminput';
import Icon from '../../common/icons/icons';
import Popup from '../../common/popup/popup';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import useAnalytics from '../../../utils/useAnalytics';
import { getQueryParams } from '../../../utils/query-helper';

function DetailsTop({
  coinDetails,
  selectedCell,
  setSelectedCell,
  symbol,
  result,
  setSearchText,
  searchText,
}) {
  const [openselected, setOpenselectedCell] = useState(false);
  const { lastPrice, checkItInWatchList } = useWebSocketData();
  const { formatINR } = useBalance();
  const history = useHistory();
  const [openPopUp, setOpenPopUp] = useState(false);
  const { width } = useViewport();
  const { analytics } = useAnalytics();
  const location = useLocation();

  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const dispatch = useDispatch();
  const {
    theme: { isdark },
    userWatchList: { loading, response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  useEffect(() => {
    setSelectedCell(coinDetails);
  }, [selectedCell]);

  //console.log(coinDetails, 'coinDetails');

  const title = `Buy, sell, trade and pay with cryptocurrency to anyone, anywhere, anytime. Krypto is all the gear you'd need for your crypto-hunt. \n`;
  const url = window.location.href;
  const hashtags = '#Krypto #cryptocurrency';
  const via = 'krypto';

  const shareCoins = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
        })
        .then(() => {
          analytics?.track('Clicked share button', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: !selectedCell ? coinDetails?.name : selectedCell?.name,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.success('Thanks for sharing 👍🏻');
        })
        .catch(console.error);
    } else {
      // fallback
      analytics?.track('Clicked share button', {
        from: 'Price details',
        uid: userInfo?.payload?.uid,
        symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setOpenPopUp(true);
    }
  };

  const handleWatchList = async (coin) => {
    if (response) {
      const checkdata = response?.payload?.coins.coins?.filter((item) => {
        return item == coin.symbol;
      });
      //console.log(checkdata);
      if (checkdata.length > 0) {
        try {
          const data = await removeWatchList(
            userInfo.token.access,
            coin.symbol
          );
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          analytics?.track('Remove watchlist success', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
        } catch (e) {
          //console.log(e);
          analytics?.track('Remove watchlist failed', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
            reason: e,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.error(e);
        }
      } else {
        try {
          const data = await addWatchList(userInfo.token.access, coin.symbol);
          dispatch(getWatchList(userInfo.token.access));
          toast.success(data.message);
          analytics?.track('Add watchlist success', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
        } catch (e) {
          analytics?.track('Add watchlist failed', {
            from: 'Price details',
            uid: userInfo?.payload?.uid,
            symbol: !selectedCell ? coinDetails?.symbol : selectedCell?.symbol,
            reason: e,
            QueryParams: { ...getQueryParams() },
            ip: ip,
          });
          toast.error(e);
        }
      }
    }
  };

  const handleCopy = () => {
    const input = document.getElementById('copy-box');
    input.select();
    if (navigator.clipboard) {
      toast.success('Copied to clipboard, You can share the link');
      navigator.clipboard.writeText(window.location.href);
    } else {
      //console.log('In http connection ~ 🎯');
    }
  };

  return (
    <div className="details__top">
      <div className={`details__company ${openselected ? 'active' : ''}`}>
        <div
          className="details__head"
          onClick={() => setOpenselectedCell((pre) => !pre)}
        >
          <div className="details__logo">
            <img
              src={!selectedCell ? coinDetails?.logo : selectedCell?.logo}
              alt=""
            />
          </div>
          <div className="details__desc">
            <div className="details__info">
              {!selectedCell ? coinDetails?.name : selectedCell?.name}
            </div>
            <div
              className="details__currency"
              style={{ textTransform: 'uppercase' }}
            >
              {selectedCell?.symbol}
            </div>
          </div>
          <button className="details__burger"></button>
        </div>
        <div className="details__body">
          <div className="f-center">
            <input
              className="actions__input exchange_search__input active"
              type="search"
              value={searchText}
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div style={{ position: 'absolute', right: 23, top: 25 }}>
              <Icon icon="search" className="icon" size={15} color="#808191" />
            </div>
          </div>

          {result.map((data, idx) => {
            if (data.symbol !== 'inr') {
              return (
                <button
                  key={idx}
                  className="details__item"
                  // onClick={() => setSelectedCell(data)}
                  onClick={() => {
                    history.push({
                      pathname: `/pricedetails/${data.symbol}`,
                      search: location.search,
                    });
                    setOpenselectedCell((pre) => !pre);
                    setSearchText('');
                  }}
                >
                  <div className="details__logo">
                    <img src={data.logo} alt="" />
                  </div>
                  <div className="details__desc">
                    <div className="details__info">{data.name}</div>
                    <div
                      className="details__currency"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {data.symbol}
                    </div>
                  </div>
                </button>
              );
            }
          })}
        </div>
      </div>
      <div className="details__btns">
        <button className="details__btn btn btn_border" onClick={shareCoins}>
          <Icon icon="share" className="details_icon icon" />
          <span className="btn__text">Share</span>
        </button>

        {checkItInWatchList(selectedCell) ? (
          <button
            className="details__btn btn btn_border"
            onClick={() => handleWatchList(selectedCell)}
          >
            <Icon icon="star-fill" className="details_icon icon" />
            <span className="btn__text">Remove</span>
          </button>
        ) : (
          <button
            className="details__btn btn btn_border"
            onClick={() => handleWatchList(selectedCell)}
          >
            <Icon icon="star" className="details_icon icon" />
            <span className="btn__text">Add</span>
          </button>
        )}
      </div>

      <div className="share__popup" style={{ position: 'relative' }}>
        <Popup setState={setOpenPopUp} state={openPopUp}>
          <div className="share__bg"></div>
          <div className="share__container">
            <div className="share__coins">
              <img
                src={!selectedCell ? coinDetails?.logo : selectedCell?.logo}
              />
              <div className="h5">Share it with your friends</div>
              <div className="h6">
                The price of <span>{coinDetails?.name}</span> is{' '}
                {'₹ ' + formatINR(lastPrice(coinDetails?.symbol))}
              </div>
            </div>
            <div className="share__social_icon referral_share">
              <FacebookShareButton url={url} quote={title} hashtag={hashtags}>
                <FacebookIcon size={50} round />
              </FacebookShareButton>
              <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
              <TwitterShareButton url={url} title={title} via="Krypto">
                <TwitterIcon size={50} round />
              </TwitterShareButton>
            </div>
            <div className="h6 share__copy_link">Or copy link</div>
            <FormInput
              value={window.location.href}
              label="Click here to Copy"
              onFocus={handleCopy}
              id="copy-box"
              readOnly
            />
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default DetailsTop;
