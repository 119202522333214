import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircleBlue from '../../../assests/img/circle-blue.svg';
import CirclePurple from '../../../assests/img/circle-purple.svg';
import IconBtn from '../../common/icon-text/icon-text';
import Icon from '../../common/icons/icons';
import { convert } from 'current-currency';
import useBalance from '../../../utils/useBalance';
import SkeletonContainer from '../../common/skeleton/skeleton';
import Popup from '../../common/popup/popup';
import CustomButton from '../../common/custom-button/custom-button';
import CustomSelect from '../../common/custom-select/custom-select';
import TotalBalance from '../../home/total-balance/total-balance';
import WalletColumn from '../wallet-column/wallet-column';
import WalletsBankPopup from '../wallets-bank-popup/wallets-bank-popup';
import { toast } from 'react-toastify';
import useAnalytics from '../../../utils/useAnalytics';
import { scroller } from 'react-scroll';
import { getQueryParams } from '../../../utils/query-helper';
import ReactTooltip from 'react-tooltip';

function WalletsTotalBalance({
  setVisibleSideBar,
  openPopUp,
  setOpenPopUp,
  setSelectedData,
  initiator,
  setInitiatorDepositState,
  initiatorDepositState,
  visibleSideBar,
  openBankDetailsWalletPopUp,
  selectedData,
  setOpenBankDetailsWalletPopUp,
}) {
  const {
    getAssests,
    userGetBalance,
    getPortfolio,
    networkCheck: { response: networkResponse },
    theme: { isdark },
  } = useSelector((state) => state);

  const [usd, setUsd] = useState('');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedWData, setSelectedWData] = useState(null);

  const { analytics } = useAnalytics();

  const {
    getCurrencyBalances,
    convertINRtoUSD,
    roundUp,
    formatINR,
    getChartData,
    getInvestedPrice,
    getINRinBalance,
    roundUpWithSymbol,
  } = useBalance();

  const { loading, error, balance } = userGetBalance;
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const [openBankPopup, setOpenBankPopUp] = useState(false);
  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    response: getPortfolioResponse,
  } = getPortfolio;
  const {
    networkCheck: { response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  useEffect(() => {
    async function initialValue() {
      const value = getCurrencyBalances();
      const result = await convertINRtoUSD(value);
      setUsd(result.amount);
    }
    getChartData();
    initialValue();
  }, [balance, loading, assetsData]);

  useEffect(() => {
    setSelectedWData(balance?.payload[0]);
  }, [balance]);

  // const handleContinue = () => {
  //   setSelectedData(selectedWData);
  //   setOpenPopUp(false);
  //   setVisibleSideBar('withdraw');
  // };

  function percentage(partialValue, totalValue) {
    // |7300000 - 3700000|/7300000 = 3600000/7300000 = 0.49315068493151 = 49.315068493151% if - == increase | + == decrease
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(value);
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  const scrollToSection = () => {
    scroller.scrollTo('header', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const handleDepositPopUp = () => {
    // if (networkResponse && networkResponse.payload.level < 3) {
    //   return toast.error(
    //     'Kindly complete the KYC process and the 2FA process in settings'
    //   );
    // }

    // setOpenBankDetailsWalletPopUp((prev) => !prev);

    analytics?.track('Deposit INR button clicked ', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    const containsInr = balance?.payload.find(
      (item) => item.currency === 'inr'
    );

    const data = {};

    if (containsInr) {
      data['inrBalance'] = containsInr.balance;
      data['balance'] = containsInr.balance;
      data['currency'] = containsInr.currency;
      data['locked'] = containsInr.locked;

      setVisibleSideBar('total-balance');
      setSelectedData(data);
      scrollToSection();
    } else {
      data['inrBalance'] = 0;
      data['balance'] = 0;
      data['currency'] = 'inr';

      setVisibleSideBar('total-balance');
      setSelectedData(data);
      scrollToSection();
    }
  };

  return (
    <>
      <div className="wallets__row">
        <div className="wallets__total">
          <div className="wallets__title h6">Total Balance</div>
          {loading || !assetsData || !balance || assetLoading ? (
            <>
              <SkeletonContainer
                width={'150px'}
                height="50px"
                style={{ borderRadius: 20 }}
              />
              <SkeletonContainer
                width={'100px'}
                height="20px"
                style={{ borderRadius: 20 }}
              />
            </>
          ) : (
            <>
              <div className="wallets__flex">
                <div
                  className={`wallets__number ${visibleSideBar ? 'h5' : 'h3'}`}
                >
                  ₹{' '}
                  {formatINR(getCurrencyBalances())
                    ? formatINR(getCurrencyBalances())
                    : 0}
                </div>
                <div className="wallets__currency">INR</div>
              </div>
              {balance && !error && (
                <div className="wallets__price h5 color-gray">
                  {roundUp(parseFloat(usd)) ? roundUp(parseFloat(usd)) : 0} USD
                </div>
              )}
            </>
          )}
        </div>
        {/* <div className="wallets__group">
          <div className="wallets__box">
            <div className="wallets__info">Exchange Balance</div>
            <div
              className="wallets__progress bg-purple"
              style={{ width: '65%' }}
            ></div>
          </div>
          <div className="wallets__box">
            <div className="wallets__info">Asset Balances</div>
            <div
              className="wallets__progress bg-blue"
              style={{ width: '100%' }}
            ></div>
          </div>
        </div> */}
        {balance && !error && (
          <>
            {loading || assetLoading || (!balance && !assetsData) ? (
              <SkeletonContainer width={'100%'} />
            ) : (
              <WalletColumn chartData={getChartData()} />
            )}
          </>
        )}
      </div>
      {getCurrencyBalances() - roundUp(getINRinBalance()) !== 0 && (
        <>
          {(loading || getPortfolioLoading || assetLoading) && !assetsData ? (
            <div
              style={{
                marginTop: '52px',
                display: 'grid',
                gridTemplateColumns: 'repeat(2,1fr)',
                maxWidth: '50%',
              }}
            >
              <SkeletonContainer
                width={'100%'}
                height={'100px'}
                style={{ borderRadius: '20px' }}
              />
              <SkeletonContainer
                width={'100%'}
                height={'100px'}
                style={{ marginLeft: 12, borderRadius: '20px' }}
              />
            </div>
          ) : (
            <div className="wallets__inner">
              <div className="wallets__list">
                <div className="wallets__item">
                  <div className="wallets__icon">
                    <img src={CirclePurple} alt="" />
                  </div>
                  <div className="wallets__details">
                    <div className="wallets__info">
                      Invested
                      <span
                        data-tip={
                          'Total investment made in this asset (In Rupees)'
                        }
                        data-for={'Invested'}
                      >
                        <Icon icon="info-circle" className="icon" />
                        <ReactTooltip
                          place="bottom"
                          type={isdark ? 'light' : 'dark'}
                          className="tooltip_styles price_details"
                          id={'Invested'}
                        />
                      </span>
                    </div>
                    <div className="wallets__number h6">
                      ₹{' '}
                      {isNaN(roundUp(parseFloat(getInvestedPrice())))
                        ? 0
                        : formatINR(
                            roundUpWithSymbol(
                              parseFloat(getInvestedPrice()),
                              'inr'
                            )
                          )}
                    </div>
                    <div
                      className={`wallets__price ${
                        getCurrencyBalances() - getINRinBalance() <
                        getInvestedPrice()
                          ? 'negative'
                          : ''
                      } small`}
                    >
                      Returns{' '}
                      {getCurrencyBalances() > 0
                        ? percentage(
                            getInvestedPrice(),
                            getCurrencyBalances() - getINRinBalance()
                          )
                        : `0%`}
                    </div>
                  </div>
                </div>
                <div className="wallets__item">
                  <div className="wallets__icon">
                    <img src={CircleBlue} alt="" />
                  </div>
                  <div className="wallets__details">
                    <div className="wallets__info">
                      Current
                      <span
                        data-tip={
                          'Present value of this asset held in your wallet (In Rupees)'
                        }
                        data-for={'Current'}
                      >
                        <Icon icon="info-circle" className="icon" />
                        <ReactTooltip
                          place="bottom"
                          type={isdark ? 'light' : 'dark'}
                          className="tooltip_styles price_details"
                          id={'Current'}
                        />
                      </span>
                    </div>
                    <div className="wallets__number h6">
                      ₹{' '}
                      {/* {isNaN(
                        formatINR(getCurrencyBalances() - getINRinBalance())
                      ) ||
                      formatINR(getCurrencyBalances() - getINRinBalance()) == ''
                        ? 0
                        : 
                         */}
                      {isNaN(
                        roundUp(getCurrencyBalances() - getINRinBalance())
                      ) ||
                      formatINR(getCurrencyBalances() - getINRinBalance()) == ''
                        ? 0
                        : formatINR(
                            roundUpWithSymbol(
                              getCurrencyBalances() - getINRinBalance(),
                              'inr'
                            )
                          )}
                    </div>
                    <div
                      className={`wallets__price ${
                        getCurrencyBalances() - getINRinBalance() <
                        getInvestedPrice()
                          ? 'negative'
                          : ''
                      } small`}
                    >
                      Total Profit{' '}
                      {getCurrencyBalances() - getINRinBalance() <
                      getInvestedPrice()
                        ? ''
                        : '+'}{' '}
                      ₹{' '}
                      {isNaN(
                        getCurrencyBalances() -
                          getINRinBalance() -
                          getInvestedPrice()
                      )
                        ? '0'
                        : formatINR(
                            roundUpWithSymbol(
                              getCurrencyBalances() -
                                getINRinBalance() -
                                getInvestedPrice(),
                              'inr'
                            )
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {(!loading || !getPortfolioLoading || !assetLoading) && assetsData && (
        <div
          className="wallets__btns"
          style={{
            bottom:
              getCurrencyBalances() - roundUp(getINRinBalance()) == 0
                ? '11px'
                : '32px',
          }}
        >
          <button
            className="wallets__btn btn btn_orange"
            onClick={handleDepositPopUp}
          >
            <Icon icon="withdraw" className="icon" />
            <span className="btn__text">Deposit INR</span>
          </button>
          {/* <button
            className="wallets__btn btn btn_border"
            onClick={() => setVisibleSideBar('deposit')}
          >
            <IconBtn className="icon" iconName="deposit" text="Deposit" />
          </button> */}
        </div>
      )}

      <WalletsBankPopup
        setState={setOpenBankDetailsWalletPopUp}
        state={openBankDetailsWalletPopUp}
      />
    </>
  );
}

export default WalletsTotalBalance;
