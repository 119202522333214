import React, { useEffect, useRef, useState } from 'react';

function FormInput({ label, value, active, ...otherProps }) {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (document.hasFocus() && ref.current.contains(document.activeElement)) {
      setFocus(true);
    }
  }, []);

  return (
    <div
      className={`login__field field js-field " ${
        hasFocus || value?.length > 0 || active ? 'active' : ''
      }`}
    >
      <div className="field__label">{label}</div>
      <div className="field__wrap">
        <input
          className="field__input js-field-input"
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          {...otherProps}
        />
      </div>
    </div>
  );
}

export default FormInput;
