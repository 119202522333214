import React, { useEffect, useState } from 'react';
import WalletTotalBalanceCard from '../wallet-total-balance-card/wallet-total-balance-card';
import HandFreeImg from '../../../assests/img/hand-coin.svg';
import BitCoinImg from '../../../assests/img/bitcoin.svg';
import useBalance from '../../../utils/useBalance';
import useWebSocketData from '../../../utils/useWebSocketData';
import useAnalytics from '../../../utils/useAnalytics';
import TagImage from '../../../assests/img/per.png';

function WalletsTotalBalance({
  visibleSideBar,
  setVisibleSideBar,
  selectedData,
  initiator,
  setInitiatorDepositState,
  initiatorDepositState,
  setOpenBankDetailsPopup,
  openBankDetailsPopUp,
}) {
  const {
    getInfoAboutCoins,
    convertINRtoUSD,
    getInvestedSingleCoin,
    roundUp,
    formatINR,
    roundUpWithSymbol,
  } = useBalance();
  const [usd, setUsd] = useState(null);
  const { lastPrice } = useWebSocketData();
  const { analytics } = useAnalytics();
  const { getAvgPriceBalance } = useBalance();
  useEffect(() => {
    async function initialValue() {
      if (selectedData) {
        const value = await convertINRtoUSD(selectedData.balance);
        setUsd(value.amount);
      }
    }
    initialValue();
  }, []);

  const getCryptotoINR = () => {
    if (selectedData) {
      const value =
        lastPrice(selectedData.currency) * parseFloat(selectedData.balance);
      return roundUpWithSymbol(value, 'inr');
    }
  };

  function percentage(partialValue, totalValue) {
    if (partialValue == 0) {
      return 0 + '%';
    }
    const value = ((partialValue - totalValue) / partialValue) * 100;
    //console.log(partialValue, totalValue, 'new');
    return value?.toString().charAt(0) === '-'
      ? '+' + value?.toFixed(2).toString().replace('-', '') + '%'
      : '-' + value?.toFixed(2).toString().replace('+', '') + '%';
  }

  return (
    <>
      {selectedData && (
        <div className="wallets__body">
          <div className="wallets__line">
            <div className={'wallets__money'}>
              {roundUpWithSymbol(
                parseFloat(selectedData.balance),
                selectedData.currency
              )}
            </div>
            <div
              className="wallets__currency"
              style={{ textTransform: 'uppercase' }}
            >
              {selectedData.currency}
            </div>
          </div>
          {selectedData.currency !== 'inr' && (
            <div className="wallets__code">
              {formatINR(getCryptotoINR())} INR
            </div>
          )}
          {selectedData.currency !== 'inr' && (
            <div className="wallets__parameters">
              <div className="wallets__parameter">
                <div className="wallets__preview bg-purple">
                  {selectedData && (
                    <img
                      src={getInfoAboutCoins(selectedData?.currency)?.logo}
                      alt=""
                    />
                  )}
                </div>
                <div className="wallets__box">
                  <div className="wallets__category">Invested</div>
                  <div className={`wallets__number  h6`}>
                    ₹{' '}
                    {formatINR(
                      getInvestedSingleCoin(
                        selectedData.currency,
                        selectedData.balance
                      )
                    )}
                  </div>
                  <div
                    className={`wallets__price small ${
                      parseFloat(getCryptotoINR()) <
                      parseFloat(
                        getInvestedSingleCoin(
                          selectedData.currency,
                          selectedData.balance
                        )
                      )
                        ? 'negative'
                        : ''
                    } `}
                  >
                    Return{' '}
                    {selectedData.balance == 0.0 || getCryptotoINR() === '0'
                      ? `0%`
                      : percentage(
                          getInvestedSingleCoin(
                            selectedData.currency,
                            selectedData.balance
                          ),
                          getCryptotoINR()
                        ) === '-0.00%'
                      ? '0.00%'
                      : percentage(
                          getInvestedSingleCoin(
                            selectedData.currency,
                            selectedData.balance
                          ),
                          getCryptotoINR()
                        )}
                  </div>
                </div>
              </div>
              <div className="wallets__parameter">
                <div className="wallets__preview bg-blue">
                  <img src={HandFreeImg} alt="" />
                </div>
                <div className="wallets__box">
                  <div className="wallets__category">Current</div>
                  <div className={`wallets__number h6`}>
                    {selectedData.balance == 0.0 || getCryptotoINR() == '0'
                      ? '₹ 0'
                      : '₹ ' + formatINR(getCryptotoINR())}
                  </div>
                  <div
                    className={`wallets__price small  ${
                      parseFloat(getCryptotoINR()) <
                      parseFloat(
                        getInvestedSingleCoin(
                          selectedData.currency,
                          selectedData.balance
                        )
                      )
                        ? 'negative'
                        : ''
                    }`}
                  >
                    Total Returns
                    {parseFloat(getCryptotoINR()) <
                    parseFloat(
                      getInvestedSingleCoin(
                        selectedData.currency,
                        selectedData.balance
                      )
                    )
                      ? ' '
                      : ' +'}
                    {roundUp(
                      getCryptotoINR() -
                        getInvestedSingleCoin(
                          selectedData.currency,
                          selectedData.balance
                        )
                    )}{' '}
                    INR
                  </div>
                </div>
              </div>
              {getAvgPriceBalance(selectedData?.currency) && (
                <div className="wallets__parameter">
                  <div className="wallets__preview bg-blue">
                    <img src={TagImage} alt="" style={{ width: 18 }} />
                  </div>
                  <div className="wallets__box">
                    <div className="wallets__category">Avg. Buy Price</div>
                    <div className={`wallets__number h6`}>
                      {'₹ ' +
                        formatINR(
                          Number(
                            getAvgPriceBalance(selectedData?.currency)
                          ).toFixed(2),
                          selectedData?.currency
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <WalletTotalBalanceCard
            setOpenBankDetailsPopup={setOpenBankDetailsPopup}
            openBankDetailsPopUp={openBankDetailsPopUp}
            visibleSideBar={visibleSideBar}
            setVisibleSideBar={setVisibleSideBar}
            selectedData={selectedData}
            initiator={initiator}
            initiatorDepositState={initiatorDepositState}
          />
        </div>
      )}
    </>
  );
}

export default WalletsTotalBalance;
