import React from 'react';
import { Link } from 'react-router-dom';

function HeaderLogo({ logo }) {
  return (
    <Link
      className="header__logo"
      to={(location) => ({ ...location, pathname: '/' })}
    >
      <img src={logo} alt="" />
    </Link>
  );
}

export default HeaderLogo;
