import { convert } from 'current-currency';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import useWebSocketData from './useWebSocketData';

function useReferrals() {
  const { getAssests, userGetBalance, getPortfolio } = useSelector(
    (state) => state
  );
  const { loading, error, balance } = userGetBalance;
  const { lastPrice } = useWebSocketData();
  const { loading: assetLoading, error: assetError, assetsData } = getAssests;
  const { coinsDetails } = useSelector((state) => state.getCoins);
  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    response: getPortfolioResponse,
  } = getPortfolio;

  const {
    getReferralEarning: {
      loading: earningLoading,
      response: earningResponse,
      error: earningError,
    },
  } = useSelector((state) => state);

  function getTotalReferralInINR() {
    let total = 0;
    if (assetsData && earningResponse && earningResponse.payload.length > 0) {
      earningResponse.payload.forEach((item) => {
        //// console.log(
        //   'price ' +
        //     assetsData[item['currency_type'].toLowerCase() + 'inr']?.last
        // );
        total =
          parseFloat(
            assetsData[item['currency_type'].toLowerCase() + 'inr']?.last *
              item['total_earning']
          ) + total;
      });
      return total;
    } else {
      return '0.00';
    }
  }

  return {
    getTotalReferralInINR,
  };
}

export default useReferrals;
