import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { LatestPromotionData } from '../../../utils/data';
import CalendarImg from '../../../assests/img/calendar.svg';
import Icon from '../../common/icons/icons';

function PromotionDetailsContainer({ match }) {
  const [data, setData] = useState({});

  const prizesList = [
    {
      name: '1st Place 2.5 BTC',
    },
    {
      name: '2nd Place 1.5 BTC',
    },
    {
      name: '3rd Place 1 BTC',
    },
    {
      name: '4th Place 0.5 BTC',
    },
    {
      name: ' 5th - 10th Place Share a pool of 1.5 BTC',
    },
  ];

  useEffect(() => {
    const filterdata = LatestPromotionData.find(
      (item) => item.id === parseInt(match.params.id)
    );
    setData(filterdata);
  }, [match.params.id]);

  return (
    <>
      {data.title && (
        <>
          <div className="page__category h5">Krypto Exchange</div>
          <div className="page__title h2">{data.title}</div>
          <div className="promotion">
            <div className="promotion__preview">
              <img src={data.img} alt="" style={{ borderRadius: '50px' }} />
            </div>
            <div className="promotion__body">
              <div className="promotion__title">Competition Overview</div>
              <div className="promotion__text">
                To celebrate our new multi-chain deposit and withdrawal support
                for Bitcoin on TRON (BTCTRON), we’re beginning a 30-day net
                deposit campaign for Bitcoin where users can earn APR rewards
                based on their total net deposits over the campaign period. The
                top 40 net depositors over the campaign period will earn
                additional prizes. At the end of the campaign, we will convert
                the total net deposits from this campaign from BTC to BTC on
                TRON.
              </div>
              <div className="promotion__note">
                <div className="promotion__icon">
                  <img src={CalendarImg} alt="" />
                </div>
                <div className="promotion__info">
                  Campaign Period: October 26, 2020 at 21:00 UTC to November 25,
                  2020 at 20:59 UTC
                </div>
              </div>
              <div className="promotion__category">Prizes</div>
              <div className="promotion__list">
                {prizesList.map((data, idx) => (
                  <div className="promotion__line" key={idx}>
                    <Icon icon="award" className="icon" />
                    {data.name}
                  </div>
                ))}
              </div>
              <div className="promotion__text">
                Have a total net deposit score of at least 0.01 BTC during the
                competition to be eligible for prizes.
              </div>
              <button className="promotion__btn btn btn_orange">
                Deposit Now
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withRouter(PromotionDetailsContainer);
