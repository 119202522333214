import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toast() {
  const {
    theme: { isdark },
  } = useSelector((state) => state);

  return (
    <ToastContainer
      position="top-right"
      // toastStyle={{
      //   backgroundColor: isdark ? '#1d2426' : '#fff',
      // }}
      // theme={isdark ? 'dark' : 'light'}
      theme="colored"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

export default Toast;
