import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { cancelOrder } from '../../../../actions/exchangeAction';
import { getOrderData } from '../../../../actions/historyAction';
import { getBalance } from '../../../../actions/userAction';
import { CANCEL_ORDER_CLEANUP } from '../../../../constants/exchangeConstant';
import useViewport from '../../../../utils/useViewPort';
import useBalance from '../../../../utils/useBalance';

function ExchangeMarketTable({
  selectedlabel,
  orderPendingPageCount,
  allOrders,
  trade,
  pendingOrder,
  handleCancelOrder,
}) {
  const { width } = useViewport();
  const { formatINR } = useBalance();
  const [orderItem, setOrderItem] = useState(null);

  useEffect(() => {
    if (selectedlabel.value === 'all_orders') {
      setOrderItem(allOrders);
    } else if (selectedlabel.value === 'pending_orders') {
      setOrderItem(pendingOrder);
    }
  }, [allOrders, pendingOrder, selectedlabel]);

  const {
    orderData: {
      loading: orderLoading,
      response: orderResponse,
      error: orderError,
    },
    tradeData: {
      loading: tradeLoading,
      response: tradeResponse,
      error: tradeError,
    },
    cancelOrder: {
      response: cancelOrderResponse,
      loading: cancelOrderLoading,
      error: cancelOrderError,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelOrderError) {
      toast.error(cancelOrderError);
    }
    if (cancelOrderResponse) {
      toast.success(cancelOrderResponse.message);
    }
  }, [cancelOrderLoading, cancelOrderResponse, cancelOrderError]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CANCEL_ORDER_CLEANUP,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (cancelOrderResponse) {
      dispatch(getOrderData(orderPendingPageCount, true));
      dispatch(getBalance());
    }
  }, [cancelOrderLoading]);

  return (
    <>
      {selectedlabel.value !== 'trades' ? (
        <div className="market__table">
          <div className="market__row market__row_head">
            <div className="market__cell">
              Market
              {/* <span className="mobile-hide">(usdt)</span> */}
            </div>
            <div className={`market__cell`}>time</div>
            <div
              className={`market__cell ${
                selectedlabel.value === 'pending_orders' ? 'mobile-hide' : ''
              }`}
            >
              price
              {/* <span className="mobile-hide">(usdt)</span> */}
            </div>
            <div className="market__cell">
              amount
              {/* <span className="mobile-hide">(btc)</span> */}
            </div>
            <div className="market__cell">
              side
              {/* <span className="mobile-hide">(usdt)</span> */}
            </div>
            <div
              className={`market__cell ${
                selectedlabel.value === 'pending_orders' ? 'mobile-hide' : ''
              } ${width < 750 ? 'mobile-hide' : ''}`}
            >
              status
              {/* <span className="mobile-hide">(usdt)</span> */}
            </div>

            {selectedlabel.value === 'pending_orders' && (
              <div className="market__cell">
                cancel Order
                {/* <span className="mobile-hide">(usdt)</span> */}
              </div>
            )}
          </div>
          {orderItem?.map((data, idx) => (
            <div className="market__row" key={idx}>
              <div className="market__cell">{data.market?.toUpperCase()}</div>
              <div className={`market__cell`}>
                {width > 750
                  ? moment(data.updated_at).format('MMM Do YY, h:mm a')
                  : moment(data.updated_at).format('MMM Do')}
              </div>
              <div className={`market__cell `}>
                {data.price
                  ? formatINR(parseFloat(data.price))
                  : data.market === 'shibinr'
                  ? formatINR(parseFloat(data.avg_price)?.toFixed(5))
                  : formatINR(parseFloat(data.avg_price)?.toFixed(2))}
              </div>
              <div
                className={`market__cell ${
                  selectedlabel.value === 'pending_orders' ? 'mobile-hide' : ''
                }`}
              >
                {data.origin_volume}
              </div>
              <div
                className={`market__cell ${
                  data.side === 'buy' ? 'positive' : 'negative'
                }`}
              >
                {data?.side?.toUpperCase()}
              </div>
              <div
                className={`market__cell ${
                  data.state === 'done' ? 'positive' : 'negative'
                } ${
                  selectedlabel.value === 'pending_orders' ? 'mobile-hide' : ''
                } ${width < 750 ? 'mobile-hide' : ''}`}
              >
                {data.state?.toUpperCase()}
              </div>
              {selectedlabel.value === 'pending_orders' && (
                <div className="market__cell">
                  <button
                    className="btn btn_orange"
                    style={{ width: 40, height: 40, margin: 5 }}
                    onClick={() => handleCancelOrder(data.id)}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="market__table">
            <div className="market__row market__row_head">
              <div className="market__cell">market</div>
              <div className="market__cell">time</div>
              <div className="market__cell mobile-hide">
                price
                {/* <span className="mobile-hide">(usdt)</span> */}
              </div>
              <div className="market__cell">
                amount
                {/* <span className="mobile-hide">(btc)</span> */}
              </div>
              <div className="market__cell">
                side
                {/* <span className="mobile-hide">(usdt)</span> */}
              </div>
            </div>
            {trade?.map((data, idx) => (
              <div className="market__row" key={idx}>
                <div className="market__cell">{data.market?.toUpperCase()}</div>
                <div className="market__cell">
                  {width > 750
                    ? moment(data.updated_at).format('MMM Do YY, h:mm a')
                    : moment(data.updated_at).format('MMM Do')}
                </div>
                <div className={`market__cell mobile-hide`}>
                  {data.price && formatINR(parseFloat(data.price))}
                </div>
                <div className="market__cell ">
                  {data?.market === 'shibinr'
                    ? parseFloat(data.total)?.toFixed(5)
                    : parseFloat(data.total)?.toFixed(2)}
                </div>
                <div
                  className={`market__cell ${
                    data.side === 'buy' ? 'positive' : 'negative'
                  }`}
                >
                  {data?.side?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default ExchangeMarketTable;
