import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../actions/userAction';
import { parseJwt } from '../../utils/jwt';

function PrivateRoute({ children, ...otherProps }) {
  const { userLogin, networkCheck, userDetails } = useSelector(
    (state) => state
  );
  const { userInfo } = userLogin;
  const { response, loading } = networkCheck;
  const dispatch = useDispatch();
  const { userData, error: userError } = userDetails;

  useEffect(() => {
    if (userInfo) {
      if (parseJwt(userInfo.token.access)) {
        const decodedJwt = parseJwt(userInfo.token.access);
        if (decodedJwt.exp * 1000 < Date.now()) {
          dispatch(logout());
        }
      }
    }
  });

  // useEffect(() => {
  //   if (
  //     (userData &&
  //       (!userData.payload.name || !userData.payload.name === '') &&
  //       response.payload.level === 1) ||
  //     userError?.toLowerCase() == 'user not found'
  //   ) {
  //     history.push('/profile');
  //   }
  // }, []);

  return (
    <Route
      {...otherProps}
      render={({ location }) =>
        //   userInfo?.token && response.payload.level === 0 ? (
        //     <Redirect
        //       to={{
        //         pathname: '/verify',
        //         state: { from: location },
        //       }}
        //     />
        //   ) : (
        //     <Redirect
        //       to={{
        //         pathname: '/signin',
        //         state: { from: location },
        //       }}
        //     />
        //   )
        // }
        userInfo?.token && response && response.payload.level > 0 ? (
          userData && userData?.payload?.name ? (
            children
          ) : (
            ((userData && !userData?.payload?.name) ||
              userError === 'User Not found') && (
              <Redirect
                to={{
                  pathname: '/profile',
                  search: location.search,
                  state: { from: location },
                }}
              />
            )
          )
        ) : userInfo?.token && response && response.payload.level === 0 ? (
          <Redirect
            to={{
              pathname: '/verify',
              search: location.search,
              state: { from: location },
            }}
          />
        ) : (
          !userInfo && (
            <Redirect
              to={{
                pathname: '/signin',
                search: location.search,
                state: { from: location },
              }}
            />
          )
        )
      }
    />
  );
}

export default PrivateRoute;
