import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  paytmStart,
  paytmStartv2,
  paytmStatusv2,
} from '../../../actions/walletAction';
import QRCodeImg from '../../../assests/img/qr-code.png';
import useBalance from '../../../utils/useBalance';
import useWebSocketData from '../../../utils/useWebSocketData';
import CustomButton from '../../common/custom-button/custom-button';
import Icon from '../../common/icons/icons';
import Popup from '../../common/popup/popup';
import WalletWithdrawFormInput from '../wallet-withdraw/wallet-withdraw-input/wallet-withdraw-input';
import DespositOperationItem from './desposit-operation-item/desposit-operation-item';
import WalletBankPopup from '../wallets-bank-popup/wallets-bank-popup';
import { parseJwt } from '../../../utils/jwt';
import { getBalance, logout } from '../../../actions/userAction';
import { toogleSetting } from '../../../actions/settingAction';
import useAnalytics from '../../../utils/useAnalytics';
import { payTmKey } from '../../../utils/urls';
import { getQueryParams } from '../../../utils/query-helper';
import { useHistory, useLocation } from 'react-router-dom';
import PaymentStatusContainer from '../../payment-status/payment-status-container/payment-status-container';
import paymentPendingAnimationData from '../../../assests/json/payment-pending.json';
import paymentSuccessAnimationData from '../../../assests/json/payment-success.json';
import paymentFailedAnimationData from '../../../assests/json/payment-failed.json';

const InstructionForDeposit = ({ amount, userData }) => {
  return (
    <div className="notes__instant">
      <ul>
        <li>
          Open your UPI app that is connected with this UPI ID:{' '}
          {userData.payload.upi_id}
          (Google Pay, PhonePe, etc)
        </li>
        <li>Pay for the money request of Rs.{amount}</li>
        <li>Click on the button below after you've paid the money</li>
        <li>For any issue, contact support@letskrypto.com</li>
      </ul>
    </div>
  );
};

const KycComparisonItem = ({ label, yes }) => {
  return (
    <li>
      {label}{' '}
      <i
        className={`fas ${yes ? 'fa-check-circle' : 'fas fa-times-circle'}`}
        style={{ color: yes ? '#4fbf67' : 'red' }}
      ></i>
    </li>
  );
};

function KycComparison({ toogleSetting, disableDepositbtn }) {
  const dispatch = useDispatch();
  return (
    <div className="comparison-whole-container">
      <div className="comparison-title h5">You can do more with KYC 🚀</div>
      <div className="comparison-container">
        <div className="comparison">
          <div className="h5">Without KYC</div>
          <ul>
            <KycComparisonItem label="Deposit" yes={true} />
            <KycComparisonItem label="Trade" yes={true} />
            <KycComparisonItem label="Withdrawal" yes={false} />
            <KycComparisonItem label="Krypto Pay" yes={false} />
          </ul>
        </div>
        <div className="comparison">
          <div className="h5">With KYC</div>
          <ul>
            <KycComparisonItem label="Deposit" yes={true} />
            <KycComparisonItem label="Trade" yes={true} />
            <KycComparisonItem label="Withdrawal" yes={true} />
            <KycComparisonItem label="Krypto Pay" yes={true} />
          </ul>
        </div>
      </div>
      <div className="comparison_btn">
        <CustomButton
          title="Complete KYC"
          onClick={() =>
            dispatch(
              toogleSetting({
                screenState: 'KYC',
              })
            )
          }
          disabled={disableDepositbtn}
        />
      </div>
    </div>
  );
}

function WalletDesposit({
  visibleSideBar,
  selectedData,
  initiator,
  setInitiatorDepositState,
  initiatorDepositState,
  setVisibleSideBar,
}) {
  const { lastPrice } = useWebSocketData();
  const { getBalanceToInr, roundUpWithSymbol, getInfoAboutCoins, isDecimal } =
    useBalance();
  const location = useLocation();
  const history = useHistory();

  const {
    userLogin: { userInfo },
    networkCheck: { response: networkResponse },
    kycViewDetails: {
      loading: kycLoading,
      error: kycError,
      response: kycResponse,
    },
    userDetails: { userData, loading, error },
    userIpAddress: { ip },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { analytics } = useAnalytics();

  const [paytmDepositState, setPayTmDepositState] = useState('');
  const [payAmount, setPayAmount] = useState(0);
  const [openTransactionPopUp, setOpenTransactionPopup] = useState(false);
  const [sdkReady, setSdkReady] = useState(false);
  const [disableDepositbtn, setDisableDepositbtn] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [transactionId, setTransactionId] = useState(null);
  const [openPaymentStatusPopUp, setOpenPaymentStatusPopUp] = useState(false);
  const [cashDepositState, setCashDepositState] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const PaymentPendingAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentPendingAnimationData,
  };

  const PaymentSuccessAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentSuccessAnimationData,
  };

  const PaymentFailedAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentFailedAnimationData,
  };

  useEffect(() => {
    const queryParams = getQueryParams();
    const invalidParams = ['order_id', 'mid', 'status', 'message'];
    const validParams = {};
    if (Object.keys(queryParams).length != 0 && location.search != '') {
      for (const key in queryParams) {
        const InvalidKey = invalidParams.find((item) => item == key);
        if (InvalidKey) {
          delete queryParams[InvalidKey];
        } else {
          validParams[key] = queryParams[key];
        }
      }
      setQueryParams(validParams);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObj = getQueryParams();
    const invalidParams = ['order_id', 'mid', 'status', 'message'];
    if (Object.keys(queryParamsObj).length != 0 && location.search != '') {
      for (const key in queryParamsObj) {
        const InvalidKey = invalidParams.find((item) => item == key);
        if (InvalidKey) {
          queryParams.delete(InvalidKey);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [location.search]);

  const handleDepositPopUp = () => {
    if (networkResponse && networkResponse.payload.level < 3) {
      return toast.error(
        'Kindly complete the KYC process and the 2FA process in settings'
      );
    }
    analytics?.track('Bank Deposit button clicked!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    setOpenTransactionPopup((prev) => !prev);
  };

  const handleCashDeposit = async () => {
    analytics?.track('Decentro deposit button clicked!', {
      from: 'Wallet Page',
      uid: userInfo?.payload?.uid,
      symbol: selectedData?.currency,
      amount: payAmount,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });

    if (userInfo) {
      if (parseJwt(userInfo.token.access)) {
        const decodedJwt = parseJwt(userInfo.token.access);
        if (decodedJwt.exp * 1000 < Date.now() - 600) {
          toast.error('Your session is expired, Please login again');
          setTimeout(() => {
            return dispatch(logout());
          }, 1500);
        }
      }
    }
    if (parseFloat(payAmount) < 50) {
      return toast.error('Amount less than ₹50, try sending more than ₹50');
    } else if (parseFloat(payAmount) > 100000) {
      return toast.error(
        'Amount greater than ₹1,00,000, try sending less than 1 lakh'
      );
    }
    if (
      ((networkResponse && networkResponse.payload.level > 3) ||
        !networkResponse.payload.otp) &&
      parseFloat(payAmount) > 10000
    ) {
      return toast.error(
        'Kindly complete the KYC process and the 2FA process in settings for deposit more than ₹10,000'
      );
    }
    if (userData.payload.upi_id === '' || !userData.payload.upi_id) {
      return toast.error('Kindly enter the UPI ID in settings');
    }
    try {
      let { data } = await paytmStartv2(userInfo.token.access, payAmount);
      setTransactionId(data.payload.order_id);
      setOpenPaymentStatusPopUp(true);
    } catch (e) {
      toast.error(e ? e : 'Something went wrong');
    }
  };

  const checkCashPaymentStatus = async () => {
    setDisableDepositbtn(true);
    try {
      let { data } = await paytmStatusv2(userInfo.token.access, transactionId);
      setCashDepositState(data);
      if (data.payload.payment_status === 'COMPLETED') {
        dispatch(getBalance());
        setVisibleSideBar(null);
        setTimeout(() => {
          setOpenPaymentStatusPopUp(false);
          clearState();
        }, 6000);
      }
      setTimeout(() => setDisableDepositbtn(false), 30000);
    } catch (e) {
      toast.error(e.data.message);
      setTimeout(() => setDisableDepositbtn(false), 30000);
    }
  };

  const clearState = () => {
    setDisableDepositbtn(false);
    setCashDepositState(null);
    setTransactionId(null);
    setFetchCount(0);
    setPayAmount(0);
  };

  function percentage(percent, total) {
    return ((percent / 100) * total).toFixed(2);
  }

  const totalAmountDeposit = (payAmount) => {
    if (Number(payAmount) >= 999.99) {
      const total = Number(payAmount) + 20;
      return isDecimal(total) ? Math.ceil(total) : total;
    } else {
      let per = 1.5;
      const total = Number(percentage(per, payAmount)) + Number(payAmount);
      return isDecimal(total) ? Math.ceil(total) : total;
    }
  };

  useEffect(() => {
    return () => {
      if (selectedData?.currency !== 'inr') {
        setInitiatorDepositState('');
      }
    };
  }, [visibleSideBar]);

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const maxLengthCheck = (object) => {
    if (isDecimal(object.target.value)) {
      if (
        countDecimals(object.target.value) >=
        Number(getInfoAboutCoins(selectedData?.currency)?.trade_decimal) + 1
      ) {
        object.target.value = payAmount;
      }
    } else {
      let maxLength = 8 + 1;
      const { value } = object.target;
      console.log(String(value).length);
      if (String(value).length >= maxLength) {
        object.target.value = payAmount;
      }
    }
  };

  return (
    <>
      <div
        className="operations__wrap"
        style={{
          display: visibleSideBar === 'deposit' ? 'block' : 'none',
        }}
      >
        {selectedData && (
          <>
            <div className="operations__item">
              <div className="operations__flex">
                <div className="operations__title">Total Balance</div>
                <div className="operations__box">
                  <div className="operations__counter">
                    {roundUpWithSymbol(
                      parseFloat(selectedData.balance),
                      selectedData.currency
                    ) +
                      ' ' +
                      selectedData.currency.toUpperCase()}
                  </div>
                  {selectedData.currency !== 'inr' && (
                    <div className="operations__price">
                      {getBalanceToInr(selectedData).toFixed(2) + ' INR'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!initiatorDepositState?.isLoading &&
              !initiatorDepositState?.fiat &&
              (!initiatorDepositState?.address ||
                initiatorDepositState?.error) && (
                <div className="h6" style={{ textAlign: 'center' }}>
                  Error Occured!
                </div>
              )}
            <div style={{ display: 'grid', placeItems: 'center' }}>
              {initiatorDepositState?.dTag && (
                <DespositOperationItem
                  title="Memo"
                  description="Failure to use the Memomay result in the loss of your deposit."
                  value={initiatorDepositState?.dTag}
                  QRCodeValue={initiatorDepositState?.dTag}
                />
              )}

              {initiatorDepositState?.address && (
                <DespositOperationItem
                  title="Address"
                  description={`Only send ${selectedData.currency?.toUpperCase()} to this address. Sending any other
              asset to this address may result in the loss of your deposit!`}
                  value={initiatorDepositState?.address}
                  QRCodeValue={initiatorDepositState?.address}
                  minValue={
                    getInfoAboutCoins(selectedData?.currency)?.minimum_deposit +
                    ' ' +
                    selectedData.currency.toUpperCase()
                  }
                />
              )}
            </div>
            {/* 
          <div className="operations__info">
            <a href="/">Ethereum</a> deposits are availableafter 2 network
            confirmations.
          </div> */}
            {networkResponse.payload.level < 3 &&
              kycResponse?.payload?.verification_state !== 'pending' &&
              kycResponse?.payload?.verification_state !== 'completed' && (
                <KycComparison
                  toogleSetting={toogleSetting}
                  disableDepositbtn={disableDepositbtn}
                />
              )}
            {initiatorDepositState?.fiat && !initiatorDepositState?.isLoading && (
              <div>
                <p
                  style={{
                    fontFamily: 'Poppins',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  BHIM UPI
                </p>
                <h6 className="fee_note">Fee notes</h6>
                <ul className="fee_info">
                  <li>1.5% transaction fee for amount less than 999.9</li>
                  <li>₹ 20 transaction fee for amount more than 1,000</li>
                </ul>
                <WalletWithdrawFormInput
                  active
                  label="Enter the Amount to Deposit"
                  value={payAmount}
                  type="number"
                  min="0"
                  onInput={maxLengthCheck}
                  autoFocus={true}
                  onChange={(e) => setPayAmount(e.target.value)}
                />
                <WalletWithdrawFormInput
                  label={
                    Number(payAmount) >= 999.99
                      ? 'FEE AMOUNT'
                      : 'FEE PERCENTAGE'
                  }
                  // iconName="info-square"
                  value={Number(payAmount) >= 999.99 ? '₹ 20' : '1.5%'}
                  disabled
                  disabledProperty
                />
                <WalletWithdrawFormInput
                  label="TOTAL AMOUNT TO DEPOSIT"
                  value={totalAmountDeposit(payAmount)}
                  disabled
                  disabledProperty
                />
                <div style={{ display: 'grid', placeItems: 'center' }}>
                  <CustomButton
                    title="Deposit"
                    onClick={handleCashDeposit}
                    disabled={disableDepositbtn}
                  />
                  <p
                    style={{
                      fontFamily: 'Poppins',
                      padding: 10,
                      color: '#808191',
                    }}
                  >
                    or
                  </p>
                  <button
                    className="details__btn btn btn_border"
                    onClick={handleDepositPopUp}
                  >
                    {/* <Icon icon="star" className="details_icon icon" /> */}
                    <span className="btn__text">Bank Transfer</span>
                  </button>
                </div>
              </div>
            )}

            <WalletBankPopup
              state={openTransactionPopUp}
              setState={setOpenTransactionPopup}
            />
          </>
        )}
      </div>

      <Popup
        state={openPaymentStatusPopUp}
        setState={setOpenPaymentStatusPopUp}
        clearState={clearState}
      >
        <div>
          {!cashDepositState ? (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                marginTop: -29,
              }}
            >
              <InstructionForDeposit
                amount={totalAmountDeposit(payAmount)}
                userData={userData}
              />
              <CustomButton
                title="View Status"
                style={{ marginTop: 30 }}
                disabled={disableDepositbtn}
                onClick={() => checkCashPaymentStatus()}
              />
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              {cashDepositState.payload.payment_status === 'COMPLETED' && (
                <PaymentStatusContainer
                  title="Payment"
                  status="Success."
                  animationData={PaymentSuccessAnimationOptions}
                  description={'Transaction successfull'}
                  // btnTitle="View Status"
                  // btnAction={() => checkCashPaymentStatus()}
                  disabled={disableDepositbtn}
                />
              )}
              {cashDepositState.payload.payment_status === 'FAILED' && (
                <PaymentStatusContainer
                  title="Payment"
                  status="Failed."
                  animationData={PaymentFailedAnimationOptions}
                  description={'Transaction failed'}
                  // btnTitle="View Status"
                  // btnAction={() => checkCashPaymentStatus()}
                  disabled={disableDepositbtn}
                />
              )}

              {cashDepositState.payload.payment_status === 'PENDING' && (
                <PaymentStatusContainer
                  title="Payment"
                  status="Pending."
                  animationData={PaymentPendingAnimationOptions}
                  description={cashDepositState?.message}
                  btnTitle="View Status"
                  btnAction={() => checkCashPaymentStatus()}
                  disabled={disableDepositbtn}
                />
              )}
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}

export default WalletDesposit;
