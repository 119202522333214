import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSetting } from '../../../actions/settingAction';
import ProfileImage from '../../../assests/img/profile.svg';

function HeaderProfile() {
  const {
    userDetails: { userData, loading, error },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <div
      className="header__user"
      onClick={() => dispatch(toogleSetting())}
      style={{ cursor: 'pointer' }}
    >
      {!loading && !error && userData && (
        <img
          src={
            userData.payload.img?.file
              ? userData.payload.img.file
              : ProfileImage
          }
          alt=""
        />
      )}
    </div>
  );
}

export default HeaderProfile;
