import React from 'react';
import Icon from '../../common/icons/icons';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function DetailsBox({
  img,
  title,
  subTitle,
  subTitleStatus,
  value,
  color,
  hasBalance,
  description,
}) {
  const {
    theme: { isdark },
  } = useSelector((state) => state);
  return (
    <div className="details__box">
      <div className="details__icon ">
        <img src={img} alt="" className="max-width-50" />
      </div>
      <div className="details__inner">
        <div className="details__category">
          {title}
          <span data-tip={description} data-for={title}>
            <Icon icon="info-circle" className="icon" />
            <ReactTooltip
              place="bottom"
              type={isdark ? 'light' : 'dark'}
              className="tooltip_styles price_details"
              id={title}
            />
          </span>
        </div>
        <div className={'details__price' + color && color}>{value}</div>
        {hasBalance && (
          <div className={subTitleStatus}>{subTitle ? subTitle : 0}</div>
        )}
      </div>
    </div>
  );
}

export default DetailsBox;
