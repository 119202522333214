import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CLEANUP_KYC_DOC } from '../../../../../constants/kycConstant';
import AuthButton from '../../../../auth/button/button';
import CustomButton from '../../../../common/custom-button/custom-button';
import ProfileFormInput from '../../profile/profile-form-input/profile-form-input';

function KycPersonalInfo({ onChange, state, handleAddPersonalInfo }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () =>
      dispatch({
        type: CLEANUP_KYC_DOC,
        payload: null,
      });
  }, []);

  return (
    <div>
      <div className="kyc_stage__title mobile-show">
        <div className="h5">1 of 3 Kyc Verfication</div>
        <div className="h6">
          Personal Information <br /> Unlock all Krypto Features!
        </div>
      </div>
      <div className="popup__fieldset">
        <div className="popup__row">
          <ProfileFormInput
            label="First Name"
            type="text"
            placeholder="Enter your First Name"
            name="firstName"
            value={state.firstName}
            onChange={(e) => onChange(e)}
          />
          <ProfileFormInput
            label="Last Name"
            type="text"
            placeholder="Enter your Last Name"
            name="lastName"
            value={state.lastName}
            onChange={(e) => onChange(e)}

            // onChange={(e) => setUserName(e.target.value)}
            // value={userName}
          />
        </div>
        <div className="popup__row">
          <ProfileFormInput
            label="Date of Birth"
            type="date"
            name="dob"
            value={state.dob}
            onChange={(e) => onChange(e)}
            placeholder="DD/MM/YYYY"
          />
          <ProfileFormInput
            label="Address"
            type="text"
            name="address"
            value={state.address}
            onChange={(e) => onChange(e)}
            placeholder="Enter your Address"
            // onChange={(e) => setUserName(e.target.value)}
            // value={userName}
          />
        </div>
        <div className="popup__row">
          {/* <ProfileFormInput
            label="State"
            type="text"
            name="state"
            value={state.state}
            placeholder="Eg. Delhi"
            onChange={(e) => onChange(e)}
          /> */}

          <ProfileFormInput
            label="Zip/Postal Code"
            type="text"
            name="zip"
            value={state.zip}
            placeholder="400026"
            onChange={(e) => onChange(e)}
          />
          <ProfileFormInput
            label="City"
            type="text"
            name="city"
            value={state.city}
            placeholder="Eg. Chennai"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>
      {/* <div className="popup__row">
         
      </div> */}
      <div style={{ display: 'grid', placeItems: 'center', marginTop: 10 }}>
        <CustomButton
          title="Next step &#8594;"
          onClick={handleAddPersonalInfo}
        />
      </div>
    </div>
  );
}

export default KycPersonalInfo;
