import React from 'react';

function LoadingBtn({ size, addClassName, bgcolor }) {
  return (
    <button
      className={`more__btn btn btn_orange ${
        size ? 'btn_wide' : ''
      } ${addClassName}`}
      style={{ backgroundColor: bgcolor && bgcolor }}
    >
      <div className="loader"></div>
    </button>
  );
}

export default LoadingBtn;
