import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAssests } from '../../actions/assestsAction';
import { getUserIPAddress } from '../../actions/userAction';
import AddInformation from '../../components/auth/auth-username/auth-username';
import AuthPreviewImage from '../../components/auth/preview-image/previewimage';
import HelmetContainer from '../../components/common/helmet/helmet';
import MotionContainer from '../../components/common/motion-container/motion-container';
import ThemeToogler from '../../components/common/theme-toogler/theme-toogler';
import { getQueryParams } from '../../utils/query-helper';
import useAnalytics from '../../utils/useAnalytics';

function InformationPage() {
  const { analytics } = useAnalytics();
  const {
    getAssests: { assetsData },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const location = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push({
        pathname: '/',
        search: location.search,
      });
    }
  }, []);

  useEffect(() => {
    analytics?.page('User Information page', {
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
  }, [analytics, ip]);

  useEffect(() => {
    if (!assetsData) {
      dispatch(getAssests());
    }
    if (!ip) {
      dispatch(getUserIPAddress());
    }
  }, [dispatch]);

  return (
    <MotionContainer>
      <div className="login">
        <HelmetContainer title="Sign Up" />
        <div className="login__row">
          <AuthPreviewImage
            title="Asia's Largest Crypto Payment Network and Exchange"
            // subtitle="UI Design Kit"
          />
          <AddInformation />
        </div>
        <ThemeToogler />
      </div>
    </MotionContainer>
  );
}

export default InformationPage;
