import * as userConstants from '../constants/userConstant';
import axios from 'axios';
import { prefix } from '../store';
import { baseUrl, devUrl, url } from '../utils/urls';

const prodLogin = (email, password, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/captcha/auth/login/`, {
      email,
      password,
      token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const prodOtpLogin = (email, password, otp, token) => async (dispatch) => {
  //console.log(email, password, otp);
  try {
    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/captcha/auth/login/otp/`, {
      email,
      password,
      otp: otp,
      token: token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const prodRegister = (email, password, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${baseUrl}/captcha/auth/signup/`, {
      email,
      password,
      token,
    });

    dispatch({
      type: userConstants.USER_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const forgotPassword = (email, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/captcha/auth/reset/password/`,
      {
        email,
        token: token,
      }
    );
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
const devLogin = (email, password, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/auth/login/`, {
      email,
      password,
      token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const devOtpLogin = (email, password, otp, token) => async (dispatch) => {
  //console.log(email, password, otp);
  try {
    dispatch({
      type: userConstants.USER_LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/auth/login/otp/`, {
      email,
      password,
      otp: otp,
      token: token,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const devRegister = (email, password, token) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${baseUrl}/auth/signup/`, {
      email,
      password,
      token,
    });

    dispatch({
      type: userConstants.USER_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const googleLogin = (token, otp) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.GOOGLE_LOGIN_REQUEST,
    });
    const reqData = {};
    if (otp) {
      reqData['token'] = token;
      reqData['otp'] = otp;
    } else {
      reqData['token'] = token;
    }
    const { data } = await axios.post(`${baseUrl}/auth/login/oauth/`, reqData);
    dispatch({
      type: userConstants.GOOGLE_LOGIN_SUCCESS,
      payload: data,
    });
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem(`${prefix}_userInfo`, JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: userConstants.GOOGLE_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = (forceReload) => (dispatch) => {
  localStorage.removeItem(`${prefix}_userInfo`);
  localStorage.removeItem('krypto.theme');
  dispatch({ type: userConstants.USER_LOGOUT });
  dispatch({ type: userConstants.USER_DETAILS_CLEAN });
  if (forceReload) {
    window.location.reload();
  }
};

export const addName =
  (name, nationality = false) =>
  async (dispatch, getState) => {
    let userData;
    if (nationality) {
      userData = {
        name: name,
        nationality: nationality,
      };
    } else {
      userData = {
        name: name,
      };
    }
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: userConstants.USER_ADD_NAME_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/profile/edit/`,
        userData,
        config
      );

      dispatch({
        type: userConstants.USER_ADD_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.USER_ADD_NAME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addUniqueUserName = (username) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/auth/username/`,
      {
        username,
      },
      config
    );
    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_ADD_UNIQUE_USERNAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBalance =
  (currency = undefined) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };

      dispatch({
        type: userConstants.USER_GET_BALANCE_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/getbalances/`,
        {
          currency,
        },
        config
      );
      dispatch({
        type: userConstants.USER_GET_BALANCE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.USER_GET_BALANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userDetails = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_DETAILS_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/user/profile/`, {}, config);
    dispatch({
      type: userConstants.USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBankNumber =
  (accountnumber) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `JWT ${userInfo.token.access}`,
        },
      };
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}/user/bank/edit/`,
        { account_number: accountnumber },
        config
      );
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: userConstants.UPDATE_BANK_ACCOUNT_NUMBER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateUPIDetail = (upiId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    dispatch({
      type: userConstants.UPDATE_UPI_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/bank/edit/`,
      { upi_id: upiId },
      config
    );
    dispatch({
      type: userConstants.UPDATE_UPI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.UPDATE_UPI_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ifsc_code: ifscCode,

export const updateIFSCDetail = (ifscCode) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };
    dispatch({
      type: userConstants.UPDATE_IFSC_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/user/bank/edit/`,
      { ifsc_code: ifscCode },
      config
    );
    dispatch({
      type: userConstants.UPDATE_IFSC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.UPDATE_IFSC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePassword = async (token, password, oldPassword) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_REQUEST,
    // });
    const { data } = await axios.post(
      `${baseUrl}/user/password/update/`,
      { old_password: oldPassword, password: password },
      config
    );
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_SUCCESS,
    //   payload: data,
    // });
    return data;
  } catch (error) {
    // dispatch({
    //   type: userConstants.UPDATE_PASSWORD_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    throw error.response.data.message;
  }
};

export const resendMail = (email, token) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/captcha/auth/verify/email/`, {
      email: email,
      token: token,
    });
    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.SEND_EMAIL_CONFIRMATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const uploadUserProfileImage = async (token, file) => {
  try {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let formData = new FormData();
    let name = file.name.replace(/[^\w\s\.]|_/g, '').replace(/\s+/g, '');

    const renamedFile = new File([file], name);

    formData.append('file', renamedFile);

    const { data } = await axios.post(
      `${baseUrl}/user/image/${name}/`,
      formData,
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getPortolio = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_REQUEST,
    });
    const { data } = await axios.post(
      `${baseUrl}/portfolio/averagebuyprice/`,
      {},
      config
    );
    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_GET_PORTFOLIO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDepositLevel = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `JWT ${userInfo.token.access}`,
      },
    };

    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}/payment/has_deposited/`,
      config
    );
    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_DEPOSIT_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserHasEnabled2FA = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: userConstants.USER_2FA_CHECK_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}/auth/is_2fa_enabled/`, {
      email,
    });
    dispatch({
      type: userConstants.USER_2FA_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_2FA_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserIPAddress = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_REQUEST,
    });
    const response = await fetch('https://checkip.amazonaws.com/');
    const data = await response.text();

    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.USER_GET_IP_ADDRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const login = url == 'dev' ? devLogin : prodLogin;
export const Register = url == 'dev' ? devRegister : prodRegister;
export const otpLogin = url == 'dev' ? devOtpLogin : prodOtpLogin;
