import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../common/icons/icons';

function NavSearch() {
  const {
    loading: coinsLoading,
    error: coinsError,
    coinsDetails,
  } = useSelector((state) => state.getCoins);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.length >= 2) {
      const data = coinsDetails?.convertedArray.filter(
        (data) =>
          data.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          data.symbol
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      );
      setFilteredData(data);
    }
  }, [searchText]);

  return (
    <>
      <div
        className={`details__company ${
          filteredData.length !== 0 && searchText.length > 2 ? 'active' : ''
        } `}
        style={{
          width: '80%',
          position: 'absolute',
          bottom: filteredData.length >= 2 ? 250 : 220,
        }}
      >
        <div className="details__body" style={{ maxHeight: 120 }}>
          {filteredData.map((item, idx) => {
            return (
              <a
                key={idx}
                className="details__item"
                href={`/pricedetails/${item.symbol}`}
              >
                <div className="details__logo">
                  <img src={item.logo} alt="" />
                </div>
                <div className="details__desc">
                  <div className="details__info">{item.name}</div>
                  <div
                    className="details__currency"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {item.symbol}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className="sidebar__search" style={{ position: 'relative' }}>
        <input
          className="sidebar__input"
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="sidebar__start">
          <Icon icon="search" className="icon" />
        </button>
      </div>
    </>
  );
}

export default NavSearch;
