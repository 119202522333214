import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router';
import NotFoundImg from '../../assests/img/Error404.png';
import CustomButton from '../../components/common/custom-button/custom-button';
import notFoundedAnimationData from '../../assests/json/404.json';

function NotFound() {
  const history = useHistory();
  const NotFoundedAnimationData = {
    loop: false,
    autoplay: true,
    animationData: notFoundedAnimationData,
  };
  return (
    <div className="not__found">
      {/* <div className="not__found__img"> */}
      <Lottie options={NotFoundedAnimationData} height={400} width={400} />
      {/* </div> */}
      {/* <img src={NotFoundImg} alt="not_found" className="" /> */}
      <div className="h1">
        Not <span style={{ color: '#ee7c36' }}>found</span>
      </div>
      <CustomButton
        title="Go Back to Home"
        onClick={() => history.push('/')}
        style={{ marginTop: 40, marginBottom: 20 }}
      />
    </div>
  );
}

export default NotFound;
