import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { networkCheck } from '../../../../../actions/kycAction';
import { toogleSetting, verify2fA } from '../../../../../actions/settingAction';
import { getQueryParams } from '../../../../../utils/query-helper';
import useAnalytics from '../../../../../utils/useAnalytics';
import FormInput from '../../../../auth/form-input/forminput';
import CustomButton from '../../../../common/custom-button/custom-button';
import LoadingBtn from '../../../../common/loading-btn/loading-btn';

function VerficationInputBox() {
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const {
    verify2FA: { loading, error, response },
    userLogin: { userInfo },
    userIpAddress: { ip },
  } = useSelector((state) => state);

  const { analytics } = useAnalytics();

  const handleVerify = () => {
    if (otp === '') return toast.error('Enter the otp');
    analytics?.track('Clicked verify 2FA button', {
      from: 'Setting tab',
      uid: userInfo?.payload?.uid,
      QueryParams: { ...getQueryParams() },
      ip: ip,
    });
    dispatch(verify2fA(otp));
  };

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      analytics?.track('verify 2FA success', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      setTimeout(() => {
        dispatch(networkCheck());
        dispatch(toogleSetting({ open: false }));
      }, 1000);
    }
    if (error) {
      analytics?.track('verify 2FA failed', {
        from: 'Setting tab',
        uid: userInfo?.payload?.uid,
        reason: error,
        QueryParams: { ...getQueryParams() },
        ip: ip,
      });
      toast.error(error);
    }
  }, [response, error, loading, analytics]);

  return (
    // <div className="popup__numbers">
    <>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="otp_container"
      >
        <FormInput
          label="Enter six-digit code"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        {!loading ? (
          <CustomButton title="VERIFY" onClick={handleVerify} />
        ) : (
          <LoadingBtn />
        )}
      </div>
      {/* <div className="popup__number success">
        <input
          type="tel"
          id="first"
          ref={elementRef}
          defaultValue="5"
          maxLength="1"
          onChange={(e) => handleChangeOtp(e)}
          onKeyUp={clickEvent(elementRef, 'second')}
        />
      </div>
      <div className="popup__number success">
        <input
          type="tel"
          id="second"
          defaultValue="6"
          ref={elementRef}
          maxLength="1"
          onKeyUp={clickEvent(elementRef, 'third')}
          onChange={(e) => handleChangeOtp(e)}
        />
      </div>
      <div className="popup__number success">
        <input
          type="tel"
          id="third"
          defaultValue="7"
          ref={elementRef}
          maxLength="1"
          onKeyUp={clickEvent(elementRef, 'fourth')}
          onChange={(e) => handleChangeOtp(e)}
        />
      </div>
      <div className="popup__number success">
        <input
          type="tel"
          id="fourth"
          defaultValue="9"
          maxLength="1"
          ref={elementRef}
          onKeyUp={clickEvent(elementRef, 'fifth')}
          onChange={(e) => handleChangeOtp(e)}
        />
      </div>
      <div className="popup__number">
        <input
          type="tel"
          id="fifth"
          ref={elementRef}
          onKeyUp={clickEvent(elementRef, 'sixth')}
          onChange={(e) => handleChangeOtp(e)}
          maxLength="1"
        />
      </div>
      <div className="popup__number">
        <input
          type="tel"
          id="sixth"
          ref={elementRef}
          onKeyUp={clickEvent(elementRef, 'sixth')}
          onChange={(e) => handleChangeOtp(e)}
          maxLength="1"
        />
      </div>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <div className="partitioned_container">
          <input className="partitioned" type="text" maxlength="6" />
        </div>
      </div> */}
    </>
  );
}

export default VerficationInputBox;
